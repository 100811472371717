<template>
	<!-- 开发环境中不开缓存，开启后会导致热更新失效 -->
	<router-view v-if="isDevEnv || ($route.meta && $route.meta.notKeepAlive)" :key="$route.fullPath" />
	<keep-alive v-else :exclude="excludeArr">
		<router-view :key="$route.fullPath" />
	</keep-alive>
</template>
<script>
export default {
	data() {
		return {
			tabCache: [],
      isDevEnv: false,
      excludeArr: []
		};
	},
  created () {
    this.isDevEnv = process.env.NODE_ENV === 'development'
    if(this.$IsMicro) {
      // 当子应用路由发生变化时，通知主应用切换路由
      // window.$wujie?.bus.$on(`keep-alive-change-${this.$AppName}`, (routePaths) => {
      //   routePaths.forEach(routePath => {
      //     const mainRoutes = this.$router.options.routes.find(item => item.path === '/main')
      //     const target = mainRoutes.children.find(item => {
      //       return item.path === routePath.path
      //     })
      //     const { meta } = target
      //     if(meta && !meta.notKeepAlive && meta.componentName) {
      //       this.excludeArr.push(meta.componentName)
      //     }
      //   });

      //   setTimeout(() => {
      //     this.excludeArr = []
      //   }, 200)
      // });
    }
  },
	mounted() {
		this.establishReferences();
	},
	updated() {
		this.establishReferences();
	},
	methods: {
		establishReferences() {
			let node = this.$vnode;
			if (node && node.child && node.child._vnode && node.child._vnode.componentInstance && node.child._vnode.componentInstance.cache) {
				let cache = node.child._vnode.componentInstance.cache;
				this.$bus.$emit('tabCacheAdd', cache);
			}
		}
	},
	watch: {},
	computed: {}
};
</script>
<style>
</style>
