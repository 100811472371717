<template>
	<div>
		<md-toolbar md-elevation="0" class="md-transparent" :class="{
			'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
		}" style="z-index: 5 !important">
			<div class="md-toolbar-row">
				<div class="md-toolbar-section-end">
					<div class="md-collapse">
						<div class="menu-com-list">
							<li class="md-list-item">
								<a :href="`#${$HomePageRoute}`"
									class="md-list-item-router md-list-item-container md-button-clean dropdown">
									<div class="md-list-item-content">
										<k-btn data-descript="驾驶舱" slot="title" class="md-button md-just-icon md-simple"
											data-toggle="dropdown">
											<svg-icon icon-class="icon-cockpit" fill="none" stroke="var(--top-meun-font-color)" width="16px"
												height="16px"></svg-icon>
											<!-- <md-icon md-src="/static/svg/icon-cockpit.svg"></md-icon> -->
										</k-btn>
									</div>
								</a>
							</li>
						</div>

						<md-list class="menu-list">
							<div class="menu-com-list">
								<li class="md-list-item" v-for="(menu, index) in comMenus" :key="index">
									<a :href="'#/main/' + menu.url"
										class="md-list-item-router md-list-item-container md-button-clean dropdown">
										<div class="md-list-item-content">
											<drop-down :ref="'dropDown' + index" direction="down" toggle-type="contextmenu">
												<k-btn :data-descript="menu.menuname" slot="title" class="md-button md-just-icon md-simple"
													data-toggle="dropdown">
													<md-icon class="menu-icon" v-if="menu.icon"
														:md-src="'/static/svg/' + menu.icon + '.svg'"></md-icon>
													<md-icon v-if="!menu.icon" md-src="/static/svg/menu.svg"></md-icon>
												</k-btn>
												<ul class="dropdown-menu dropdown-menu-right">
													<li><a @click="deleteComMenu(menu, index)" href="javascript:void(0)">取消置顶</a></li>
												</ul>
											</drop-down>
										</div>
									</a>
								</li>
							</div>
							<md-list-item v-if="hasMenuMore && !isOpenMenuMore" @click="openMenuMore" href="javascript:void(0)">
								<md-icon md-src="/static/svg/menu-more-left.svg"></md-icon>
							</md-list-item>
							<md-list-item v-if="hasMenuMore && isOpenMenuMore" @click="hideMenuMore" href="javascript:void(0)">
								<md-icon md-src="/static/svg/menu-more-right.svg"></md-icon>
							</md-list-item>

							<div class="change-painting">
								<el-popover placement="bottom" width="140px" trigger="click">
									<div class="table-operate">
										<div v-for="(theme, themeIndex) in themeInfo" :key="themeIndex">
											<k-btn :dataIsText="true" data-size="mini" @click.native="changeTheme(themeIndex)">
												{{ theme.name }}
											</k-btn>
										</div>
									</div>
									<k-btn slot="reference" data-descript="主题切换"
										class="md-button md-just-icon md-simple md-theme-default">
										<svg-icon icon-class="icon-painting" fill="var(--top-meun-font-color)" width="18px"
											height="18px"></svg-icon>
									</k-btn>
								</el-popover>
							</div>

							<md-list-item> <i class="split-line">|</i> </md-list-item>

							<li class="md-list-item">
								<a class="md-list-item-router md-list-item-container md-button-clean dropdown">
									<div class="md-list-item-content">
										<drop-down direction="down">
											<div slot="title" style="display: flex;">
												<k-btn data-descript="用户信息" class="md-button md-button md-just-icon md-simple md-theme-default"
													data-toggle="dropdown">
													<i class="el-icon-user"
														style="color: var(--top-meun-font-color) !important;font-size: 16px !important;"></i>
												</k-btn>
												<span class="TopNav__CurrentWorkday">{{ this.showLoginName }}</span>
											</div>

											<ul class="dropdown-menu dropdown-menu-left" style="margin-left: -80px">
												<li @click="popEditUserInfor()">
													<a> <span class="el-icon-s-custom" style="margin-right: 8px"></span> 个人信息</a>
												</li>
												<li @click="popChangePwd()">
													<a> <span class="el-icon-unlock" style="margin-right: 8px"></span> 修改密码</a>
												</li>
												<li @click="loginOut()">
													<a> <md-icon md-src="/static/svg/loginout.svg" class="login-out"></md-icon> 安全退出</a>
												</li>
											</ul>
										</drop-down>
									</div>
								</a>
							</li>


							<!-- <div class="menu-com-list">
								<li class="md-list-item">
									<a href="#" @click="loginOut"
										class="md-list-item-router md-list-item-container md-button-clean dropdown">
										<div class="md-list-item-content">
											<k-btn data-descript="安全退出" slot="title" class="md-button md-just-icon md-simple"
												data-toggle="dropdown">
												<md-icon md-src="/static/svg/loginout.svg"></md-icon>
											</k-btn>
										</div>
									</a>
								</li>
							</div> -->

							<!-- <li class="md-list-item">
								<a href="javascript:void(0)"
									class="md-list-item-router md-list-item-container md-button-clean dropdown">
									<div class="md-list-item-content">
										<drop-down direction="down">
											<md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
												<md-icon md-src="/static/svg/more.svg"></md-icon>
											</md-button>
											<ul class="dropdown-menu dropdown-menu-right" style="margin-left: -150px">
												<li><a @click="addComMenu" href="javascript:void(0)">+常用功能</a></li>
											</ul>
										</drop-down>
									</div>
								</a>
							</li> -->
						</md-list>
					</div>
				</div>
			</div>
		</md-toolbar>

		<!--    修改用户弹出框   -->
		<k-popup ref="editUserPopup" data-title="编辑用户信息">
			<k-form ref="editUserForm" :data-col="1">
				<k-form-item label="登录名">
					<k-field-text v-model="formData.loginname" data-disabled />
				</k-form-item>
				<k-form-item label="所属机构">
					<k-field-text v-model="formData.orgname" data-disabled />
				</k-form-item>
				<k-form-item label="用户名称">
					<k-field-text v-model="formData.username" @input="forceUpdate" :data-max-length="32"
						:data-allowblank="false" />
				</k-form-item>
				<k-form-item label="移动电话">
					<k-field-text v-model="formData.mobileno" @input="forceUpdate" data-validate-type="number"
						:data-max-length="11" />
				</k-form-item>
				<k-form-item label="电子邮箱">
					<k-field-text v-model="formData.email" data-validate-type="email" @input="forceUpdate"
						:data-max-length="64" />
				</k-form-item>
				<k-form-item label="性别">
					<k-field-select v-model="formData.sex" data-dict="sex" @input="forceUpdate" />
				</k-form-item>
				<k-form-footer data-align="center">
					<k-btn class="md-primary" data-functype="SUBMIT" data-action="User.updateUser" data-from="editUserForm"
						:data-model="formData">确定
					</k-btn>
					<k-btn class="md-plain" data-functype="CLOSE">取消</k-btn>
				</k-form-footer>
			</k-form>
		</k-popup>

		<k-popup ref="resetPwdPopup" data-title="修改密码">
			<k-form ref="resetPwdForm" :data-col="1">
				<k-form-item label="原密码">
					<k-field-text v-model="pwdData.oldPwd" data-show-password :dataAllowblank="false" />
				</k-form-item>
				<k-form-item label="新密码">
					<k-field-text v-model="pwdData.newPwd" data-show-password :dataAllowblank="false" :data-max-length="32"
						:data-regx="regx" :data-regx-text="regxMsg" />
				</k-form-item>
				<k-form-item label="确认密码">
					<k-field-text v-model="pwdData.confirmNewPwd" data-show-password :dataAllowblank="false" :data-max-length="32"
						:data-regx="regx" :data-regx-text="regxMsg" />
				</k-form-item>
				<k-form-footer data-align="center">
					<k-btn class="md-primary" data-functype="SUBMIT" data-action="User.resetPwdCheckOldPwd" :data-model="pwdData"
						:data-handler="resetPwdHandler" data-from="resetPwdForm">确定
					</k-btn>
					<k-btn class="md-plain" data-functype="CLOSE">取消</k-btn>
				</k-form-footer>
			</k-form>
		</k-popup>
	</div>
</template>

<script>
import KFieldDisplay from '../../components/k-element/k-field-display/k-field-display';
import Tools from '@/utils/tools';
import KFormItem from '../../components/k-element/k-from/k-form-item';
import MD5 from '@/frame/md5.js';
import auth from '../../utils/auth';
import KFieldSelect from '@/components/k-element/k-field-select/k-field-select';
import KForm from '@/components/k-element/k-from/k-form';
import { themeInfoArr } from "../../../static/theme/theme.js"
import WujieVue from 'wujie-vue2'

export default {
	components: {
		KForm,
		KFieldSelect,
		KFieldDisplay,
		KFormItem
	},
	props: {
		subMenus: {
			type: Array
		}
	},
	data() {
		return {
			menuData: '',
			comMenus: [],
			minMenus: [],
			allMenus: [],
			hasMenuMore: false,
			isOpenMenuMore: false,
			userRole: '',
			formData: {
				loginname: ''
			},
			showLoginName: '',
			pwdData: {},
			currentWorkday: null,
			menuMap: {},
			regx: '',
			regxMsg: ''
		};
	},
	created() {
		this.themeInfo = themeInfoArr;

		Tools.getLoginUser().then(res => {
			this.showLoginName = res.loginname;
			this.userRole = res.roleids;
			this.formData.userid = res.userid;
			this.formData.loginname = res.loginname;
			this.formData.username = res.username;
			this.formData.mobileno = res.mobileno;
			this.formData.deptname = res.deptname;
			this.formData.passwd = res.passwd;
			this.formData.email = res.email;
			this.formData.sex = res.sex;
		});
		this.loadComMenu();

		this.judgeRegx();
	},
	methods: {
		//切换主题
		changeTheme(index) {
			sessionStorage.setItem("theme_index", index);
			//设置主题
			this.setTheme();
			//通知子应用主题切换
			WujieVue.bus.$emit("theme-change-index", {"index":index})
		},
		//判断密码校验是否需要包含特殊字符
		judgeRegx() {
			let spCharFlag = localStorage.getItem('spCharFlag');
			if (spCharFlag == '1') {
				this.regx = '^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,}$';
				this.regxMsg = '请输入最少八个字符、包含大小写字母、数字、特殊字符';
			} else {
				this.regx = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$';
				this.regxMsg = '请输入最少八个字符、包含大小写字母、数字';
			}
		},
		loginOut() {
			auth.removeToken();
			this.$router.push({
				path: '/login'
			});
		},
		popEditUserInfor() {
			this.$refs.editUserPopup.popup();
		},
		popChangePwd() {
			this.$refs.resetPwdPopup.popup();
			this.getUserPwdInfor();
		},
		loadComMenu() {
			this.httpUtil
				.query({
					url: 'sys/findComMenus.json'
				})
				.then(data => {
					if (data.rows.length <= 5) {
						this.comMenus = data.rows;
						this.hasMenuMore = false;
					} else {
						this.hasMenuMore = true;
						this.minMenus = [];
						this.allMenus = data.rows;
						for (let i = 0; i < 5; i++) {
							this.minMenus.push(data.rows[i]);
						}
						if (this.isOpenMenuMore) {
							this.comMenus = this.allMenus;
						} else {
							this.comMenus = this.minMenus;
						}
					}
				});
		},
		addComMenu() {
			let currentUrl = this.$router.history.current.path;
			this.httpUtil
				.update({
					url: 'sys/addComMenu.json',
					params: {
						url: currentUrl
					}
				})
				.then(data => {
					this.loadComMenu();
				});
		},
		deleteComMenu(menu, index) {
			this.httpUtil
				.update({
					url: 'sys/deleteComMenu.json',
					params: menu
				})
				.then(data => {
					this.loadComMenu();
				});
			if (this.$refs['dropDown' + index][0]) {
				this.$refs['dropDown' + index][0].closeDropDown();
			}
		},
		openMenuMore() {
			this.isOpenMenuMore = true;
			this.comMenus = this.allMenus;
		},
		hideMenuMore() {
			this.isOpenMenuMore = false;
			this.comMenus = this.minMenus;
		},
		getUserPwdInfor() {
			this.pwdData = {};
			Tools.getLoginUser().then(res => {
				this.pwdData.loginname = res.loginname;
				this.pwdData.userid = res.userid;
			});
		},
		resetPwdHandler(params) {
			if (params.newPwd !== params.confirmNewPwd) {
				this.$message.error('两次输入不一致！请重新输入');
				return false;
			}
			params.passwd = MD5.MD5(params.loginname + params.newPwd);
			params.oldPwd = MD5.MD5(params.loginname + params.oldPwd);
			params.newPwd = {};
			params.confirmNewPwd = {};
			return true;
		},
		forceUpdate() {
			this.$forceUpdate();
		}
	}
};
</script>

<style lang="scss" scoped>
.dot1 {
	border-radius: 0.05px;
	right: 3px;
	top: 3px;
}

.menu-list svg {
	width: 20px;
	height: 20px;
}

::v-deep .dropdown-menu a:hover{
	background-color: red !important;
}

@media (max-width: 991px) {
	.md-toolbar .md-collapse {
		display: flex !important;
		width: 100%;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

::v-deep .md-field .md-input-action {
	top: 65%;
}

.k-form {
	width: 197px;
	display: flex;
	align-items: center;
}

.TopNav__CurrentWorkday {
	display: block;
	line-height: 50px;
	color: var(--top-meun-font-color);
}

.split-line {
	color: var(--top-meun-font-color);
}

.icon-user {
	color: var(--top-meun-font-color) !important;
}

.login-out {
	margin: 0px 1px 0px -6px !important;
	width: 14px;
	height: 14px;
}

.change-painting {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}
</style>
