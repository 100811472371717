<template>
  <div class="strategy-rule">
    <k-form ref="strategyForm">
      <k-form-item label="策略名称" :data-col="2">
        <k-field-text v-model="baseFormData.strategyName" :data-allowblank="false" :data-disabled="strategyDisabled" :data-max-length="100"/>
      </k-form-item>
      <k-form-item label="经营场景">
        <k-field-select v-model="baseFormData.businessScene" :data-allowblank="false" data-dict='strategy_business_scene' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="经营目标">
        <k-field-select v-model="baseFormData.businessObjective" :data-allowblank="false" data-dict='strategy_business_objective' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="适用客群">
        <k-field-select v-model="baseFormData.suitGroup" :data-allowblank="false" data-dict='strategy_group' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略类型">
        <k-field-select v-model="baseFormData.strategyType" :data-allowblank="false" data-dict='strategy_type' :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略优先级">
        <k-field-select v-model="baseFormData.strategyPriority" :data-allowblank="false" data-dict='strategy_priority' :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略说明" :data-col="2">
        <k-field-text v-model="baseFormData.remark" data-type="textarea" :data-min-row="3" :data-disabled="strategyDisabled" :data-max-length="500"/>
      </k-form-item>
    </k-form>
  </div>
</template>

<script>
import { assign, isEmpty } from 'lodash';
export default {
	name: 'base-info',
	props: {
		getCurrentStrategy: {
			type: Function,
			default: null
		},
		strategyDisabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			baseFormData: {}
		};
	},
	created() {
		if (this.$router.currentRoute.query.strategyId) {
			this.getCurrentStrategy({ log: true }).then(res => {
				this.baseFormData = res.returndata;
			});
		} else {
			this.baseFormData = {};
		}
	},
	methods: {
		reset() {
			this.$refs.strategyForm.reset();
		},
		valid() {
			let message = [];
			let success = this.$refs.strategyForm.validate();
			if (success) {
				return { success: true };
			} else {
				message.push('策略基本信息不能为空！');
				return { success: false, message: message };
			}
		},
		getData() {
			return assign({}, this.baseFormData);
		},
		save(params) {
			this.httpUtil
				.comnUpdate({
					action: this.baseFormData.strategyId ? 'Strategy.update' : 'Strategy.add',
					params: this.baseFormData
				})
				.then(res => {
					//是否为退出时候的保存
					if (!params?.quit) {
						//更新一下参数
						if (!this.$router.currentRoute.query.strategyId) {
							this.$router.replace({ path: '/main/ma/strategy-deploy', query: { strategyId: res.returndata.strategyId } });
						}
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.strategy-rule {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 240px);
}
</style>
