export const themeInfoArr = [
    {
        "name": "默认主题",
        "theme": [
            //登录界面cppyRight
            {
                "attributeName": "--login-copy-right",
                "value": "#8C9DAF"
            },
            //顶部菜单背景颜色
            {
                "attributeName": "--top-meun-background-color",
                "value": "#FFF"
            },
            //顶部菜单字体颜色
            {
                "attributeName": "--top-meun-font-color",
                "value": "#606266"
            },
            //顶部菜单字体选中颜色
            {
                "attributeName": "--top-meun-font-color-active",
                "value": "#1890FF"
            },
            //左侧菜单背景颜色
            {
                "attributeName": "--left-meun-background-color",
                "value": "#FFF"
            },
            //左侧菜单字体颜色
            {
                "attributeName": "--left-meun-font-color",
                "value": "#606266"
            },
            //左侧菜单选中字体颜色
            {
                "attributeName": "--left-meun-font-color-active",
                "value": "#1890FF"
            },
            //左侧菜单选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active",
                "value": "#1890FF"
            },
            //左侧菜单二级选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active-2",
                "value": "linear-gradient(90deg, rgba(24, 144, 255, 0.16) 0%, rgba(24, 144, 255, 0) 100%)"
            },
            //内容基础按钮颜色
            {
                "attributeName": "--content-base-button-color",
                "value": "#1890FF"
            },
            //内容透明按钮颜色
            {
                "attributeName": "--content-base-button-trans-color",
                "value": "rgba(24,144,255,0.1)"
            },
            //内容基础字体颜色
            {
                "attributeName": "--content-base-font-color",
                "value": "#1890FF"
            },
            //内容基础图标颜色
            {
                "attributeName": "--content-base-img-icon-color",
                "value": "none"
            },
            //内容基础边框颜色
            {
                "attributeName": "--content-base-border-color",
                "value": "#1890FF"
            },
            
        ]
    },
    {
        "name": "主题1",
        "theme": [
            //登录界面cppyRight
            {
                "attributeName": "--login-copy-right",
                "value": "#FFF"
            },
            //顶部菜单背景颜色
            {
                "attributeName": "--top-meun-background-color",
                "value": "#D80A30"
            },
            //顶部菜单字体颜色
            {
                "attributeName": "--top-meun-font-color",
                "value": "#FFF"
            },
            //顶部菜单字体选中颜色
            {
                "attributeName": "--top-meun-font-color-active",
                "value": "#FFF"
            },
            //左侧菜单背景颜色
            {
                "attributeName": "--left-meun-background-color",
                "value": "#FFF"
            },
            //左侧菜单字体颜色
            {
                "attributeName": "--left-meun-font-color",
                "value": "#606266"
            },
            //左侧菜单选中字体颜色
            {
                "attributeName": "--left-meun-font-color-active",
                "value": "#D80A30"
            },
            //左侧菜单选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active",
                "value": "#D80A30"
            },
            //左侧菜单二级选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active-2",
                "value": "linear-gradient(90deg, rgba(216, 10, 48, 0.16) 0%, rgba(216, 10, 48, 0) 100%)"
            },
            //内容基础按钮颜色
            {
                "attributeName": "--content-base-button-color",
                "value": "#D80A30"
            },
            //内容透明按钮颜色
            {
                "attributeName": "--content-base-button-trans-color",
                "value": "rgba(255, 51, 24, 0.1)"
            },
            //内容基础字体颜色
            {
                "attributeName": "--content-base-font-color",
                "value": "#D80A30"
            },
            //内容基础图标颜色
            {
                "attributeName": "--content-base-img-icon-color",
                "value": "hue-rotate(145deg)"
            },
            //内容基础边框颜色
            {
                "attributeName": "--content-base-border-color",
                "value": "#D80A30"
            },

        ]
    },
    {
        "name": "主题2",
        "theme": [
            //登录界面cppyRight
            {
                "attributeName": "--login-copy-right",
                "value": "#8C9DAF"
            },
            //顶部菜单背景颜色
            {
                "attributeName": "--top-meun-background-color",
                "value": "#1890FF"
            },
            //顶部菜单字体颜色
            {
                "attributeName": "--top-meun-font-color",
                "value": "#FFF"
            },
            //顶部菜单字体选中颜色
            {
                "attributeName": "--top-meun-font-color-active",
                "value": "#FFF"
            },
            //左侧菜单背景颜色
            {
                "attributeName": "--left-meun-background-color",
                "value": "#FFF"
            },
            //左侧菜单字体颜色
            {
                "attributeName": "--left-meun-font-color",
                "value": "#606266"
            },
            //左侧菜单选中字体颜色
            {
                "attributeName": "--left-meun-font-color-active",
                "value": "#1890FF"
            },
            //左侧菜单选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active",
                "value": "#1890FF"
            },
            //左侧菜单二级选中背景颜色
            {
                "attributeName": "--left-meun-background-color-active-2",
                "value": "linear-gradient(90deg, rgba(24, 144, 255, 0.16) 0%, rgba(24, 144, 255, 0) 100%)"
            },
            //内容基础按钮颜色
            {
                "attributeName": "--content-base-button-color",
                "value": "#1890FF"
            },
            //内容透明按钮颜色
            {
                "attributeName": "--content-base-button-trans-color",
                "value": "rgba(24,144,255,0.1)"
            },
            //内容基础字体颜色
            {
                "attributeName": "--content-base-font-color",
                "value": "#1890FF"
            },
            //内容基础图标颜色
            {
                "attributeName": "--content-base-img-icon-color",
                "value": "none"
            },
            //内容基础边框颜色
            {
                "attributeName": "--content-base-border-color",
                "value": "#1890FF"
            },
        ]
    }
]
