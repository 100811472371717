var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-design-wrap",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{key:_vm.updateKey,staticClass:"custom-border-box",style:({
      'border-color': _vm.color,
      'border-width': _vm.width + 'px',
      'background-image': `linear-gradient(${_vm.gradientDirection}, ${
        _vm.gradientColor0 ? _vm.gradientColor0 : _vm.gradientColor1
      } , ${_vm.gradientColor1 ? _vm.gradientColor1 : _vm.gradientColor0})`,
      'font-size': _vm.fontSize + 'px',
      'font-weight': _vm.fontWeight,
      opacity: _vm.opacity / 100,
      color: _vm.fontColor
    })},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }