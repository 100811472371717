export default [
	{
		path: 'operate/operations-index',
		name: '运营分析首页面',
		component: () => import('@/pages/operate/operations/operations-index')
	},
	{
		path: 'operate/whole-strategy',
		name: '策略整体分布',
		component: () => import('@/pages/operate/whole-strategy/whole-strategy')
	},
	{
		path: 'operate/single-strategy',
		name: '单一策略运营监测',
		component: () => import('@/pages/operate/single-strategy/single-strategy.vue')
	},
	{
		path: 'operate/strategy-operations-calendar',
		name: '策略运营日历',
		component: () => import('@/pages/operate/strategy-operations-calendar/strategy-operations-calendar')
	},
	{
		path: 'operate/strategy-application',
		name: '策略应用分析',
		component: () => import('@/pages/operate/strategy-application/strategt-application')
	},
	{
		path: 'operate/cover-cust-analysis',
		name: '策略客户分析',
		component: () => import('@/pages/operate/cover-cust-analysis/cover-cust-analysis')
	},
	{
		path: 'operate/strategy-run-analysis',
		name: '策略执行分析',
		component: () => import('@/pages/operate/strategy-run-analysis/strategy-run-analysis')
	},
	{
		path: 'operate/strategy-compare',
		name: '策略对比分析',
		component: () => import('@/pages/operate/strategy-compare/strategy-compare')
	}
];
