<template>
  <div ref="loginRef" class="login" :class="themeIndex == 1 ? 'login_1' : 'login_0'">
    <img ref="logoRef" class="logo" src="../assets/img/ui-transform/login-logo.png">
    <div ref="frameRef" class="frame">
      <div class="title">{{ loginConfig.systemName }}</div>
      <div class="name">
        <el-input v-model="username" placeholder="请输入用户名"></el-input>
      </div>
      <div class="password">
        <el-input v-model="password" ref="password" placeholder="请输入密码" @mouseleave.native="pwdMouseLeave"
          @mouseenter.native="pwdMouseEvent" :type="passwordType" @keyup.enter.native="doLoginOrNot" @focus="focus"
          @blur="blur">
          <template #suffix>
            <div class="password_btns" @click="changePasswordType">
              <!-- <svg-icon icon-class="show" class="close_eye" v-show="(passwordType === 'password') && showEye"></svg-icon> -->
              <svg-icon icon-class="hide" v-show="passwordType === 'text' && showEye"></svg-icon>
            </div>
          </template>
        </el-input>
      </div>
      <div class="code" v-if="verifyFlag != '1'">
        <el-input class="input_code" v-model="answer" @keyup.enter.native="doLogin"></el-input>
        <div class="des">
          <img :src="codeUrl" @click="getCode" />
        </div>
      </div>
      <div class="submit" @click="doLogin">
        <div class="submit-font">登录</div>
      </div>
    </div>
    <div ref="copyRightRef" class="copy_right">
      <!-- <div class="company">{{ loginConfig.companyName }}</div> -->
      <div class="copyright">Copyright@2023 kayak.All Rights Reserved</div>
    </div>
    <k-popup ref='resetPopup' data-title='密码过期 - 重置密码' :dataAppendToBody='true'>
      <k-form :data-col='1'>
        <k-form-item label="新密码">
          <k-field-text v-model="resetData.passwd" :data-allowblank="false" :data-max-length="32" :data-regx="regx"
            :data-regx-text="regxMsg" data-show-password="true" />
        </k-form-item>
        <k-form-item label="确认密码">
          <k-field-text v-model="resetData.confirmPasswd" :data-allowblank="false" :data-max-length="32"
            :data-regx="regx" :data-regx-text="regxMsg" data-show-password="true" />
        </k-form-item>
        <k-form-footer data-align="center">
          <k-btn class="md-primary" @data-on-click="resetPwd">
            <i class="el-icon-circle-check" />确定
          </k-btn>
          <k-btn class="md-info" data-functype="CLOSE">
            <i class="el-icon-circle-close" />取消
          </k-btn>
        </k-form-footer>
      </k-form>
    </k-popup>
  </div>
</template>

<script>
import Tools from "@/utils/tools";
import auth from "@/utils/auth.js"
import MD5 from "@/frame/md5.js"
export default {
  data() {
    return {
      username: '',
      password: '',
      focusStatus: false,
      answer: '',
      t: '',
      codeUrl: '',
      verifyFlag: '',
      regx: '',
      regxMsg: '',
      resetData: {
        oldPwd: "",
        passwd: "",
        confirmPasswd: ""
      },
      loginConfig: {},
      passwordType: 'password',
      showEye: false,
      themeIndex: ""
    }
  },
  methods: {
    pwdMouseEvent() {
      if (this.password) {
        this.showEye = true
      }
    },
    pwdMouseLeave(e) {
      if (this.password && !this.focusStatus) {
        this.showEye = false
      }
    },
    checkSsoLogin() {
      var queryDate = this.$route.query;
      let ssoToKen = queryDate.token;
      if (ssoToKen != null) {
        this.httpUtil.query({
          url: "sso/checkSsoLogin.json",
          params: {
            ssoToKen: ssoToKen
          }
        }).then(data => {
          auth.setToken(data.returndata.token);
          localStorage.setItem("username", data.returndata.username);
          localStorage.setItem("userid", data.returndata.userid);
          localStorage.setItem("servers", data.returndata.servers);
          this.setTab()
          this.$router.push({
            path: '/main'
          });
          this.getCurrUserInfo();
        });
      }
    },
    setTab() {
      sessionStorage.removeItem("kk-tab")
      sessionStorage.removeItem("kk-tab2")
      this.$store.commit("system/setTab", [
        {
          name: "驾驶舱",
          path: this.$HomePageRoute,
          query: {},
          active: true
        },]);
      this.$store.commit("system/setTab2", []);
    },

    getCurrUserInfo() {
      this.httpUtil.query({
        url: "getLoginUser.json",
        params: {}
      }).then(data => {
        // console.log("============",data);
        localStorage.setItem("legalCode", data.returndata.legal_code);
        localStorage.setItem("orgno", data.returndata.orgno);
        localStorage.setItem("legalNo", data.returndata.legal_no);
        localStorage.setItem("busissCode", data.returndata.busissCode);
      })
    },

    getCode() {
      this.t = new Date().getTime()
      this.httpUtil.verifyCode(this.t).then(data => {
        this.codeUrl = data
      })
    },
    doLogin() {
      this.httpUtil.query({
        url: "jwt/login.json",
        params: {
          username: this.username,
          password: MD5.MD5(this.password),
          t: this.t,
          answer: this.answer
        },
        errCallback: (reData) => {
          Tools.alert(reData.returnmsg || reData.rtnDesc || "操作失败", "danger");
          // 获取验证码
          this.getCode()
        }
      }).then(data => {
        //判断密码是否过期，如果过期进行密码重置
        if (data.returndata.resetPasswd === true) {
          this.$refs.resetPopup.popup()
          return
        }

        auth.setToken(data.returndata.token);
        localStorage.setItem("username", data.returndata.username);
        localStorage.setItem("userid", data.returndata.userid);
        localStorage.setItem("loginname", data.returndata.loginname);
        localStorage.setItem("orgcode", data.returndata.orgcode);
        localStorage.setItem("servers", data.returndata.servers);
        localStorage.setItem("firstLoginFlag", data.returndata.firstLoginFlag);
        //判断密码是否过期，如果过期进行密码重置
        if (data.returndata.resetPasswd === true) {
          this.$refs.resetPopup.popup()
          return
        }
        Tools.getLoginUser().then(res => {
          localStorage.setItem("userRole", res.roleids);
        })
        this.setTab()
        this.$router.push({
          path: '/main'
        });
        this.getCurrUserInfo();
      });
    },
    resetPwd() {
      // 校验新密码与旧密码是否一致
      if (this.resetData.passwd != this.resetData.confirmPasswd) {
        Tools.alert("新密码和确认密码不一致", "danger")
        return false;
      }
      this.httpUtil.query({
        url: "jwt/resetPwd.json",
        params: {
          loginname: this.username,
          passwd: MD5.MD5(this.resetData.passwd)
        }
      }).then(data => {
        if (data.success == true) {
          Tools.alert(data.returnmsg);
          this.$refs.resetPopup.close();
        } else {
          Tools.alert(data.returnmsg, "danger");
        }

      });
    },
    doLoginOrNot() {
      if (this.verifyFlag == '1') {
        // 没有验证码
        this.doLogin()
      }
    },
    getLoginConfig() {
      this.httpUtil.query({
        url: "getLoginConfig.json"
      }).then(data => {
        this.loginConfig = data;
        localStorage.setItem("systemName", this.loginConfig.systemName);
        document.title = this.loginConfig.systemName;
      })
    },
    changePasswordType() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
      const value = this.password
      this.password = ' '
      this.$refs.password.focus()
      setTimeout(() => {
        this.password = value
      }, 0)
    },
    focus() {
      this.showEye = true
      this.focusStatus = true
    },
    blur() {
      this.showEye = false
      this.focusStatus = false
    }
  },
  computed: {
    tempData() {
      let temp = {
        loginname: this.username,
        oldPwd: MD5.MD5(this.resetData.oldPwd),
        passwd: MD5.MD5(this.resetData.passwd)
      }
      return temp
    }
  },
  created() {
    this.themeIndex = sessionStorage.getItem("theme_index");
    this.checkSsoLogin();
    // 获取验证码
    this.getCode();
    document.body.classList.remove(['new-side-bar'])
    this.getLoginConfig();
  },
  mounted() {

  }
}
</script>


<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 2.92vw;
  width: 100%;
  font-size: 1.06vw;
}

::v-deep .el-input__inner::placeholder {
  font-family: AlibabaPuHuiTiR;
  font-size: 1.06vw;
  color: #909399;
}

.login_0 {
  background-image: url('@/assets/img/ui-transform/login-background.png');
}

.login_1 {
  background-image: url('@/assets/img/ui-transform/login-background-red.png');
}

.login {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  // background: #D6E0F9 url('../assets/img/ui-transform/login-background.png');
  background-size: cover;

  .copy_right {
    position: absolute;
    bottom: 2.8vh;
    font-family: AlibabaPuHuiTiR;
    font-size: 1.06vw;
    color: var(--login-copy-right);
  }

  .logo {
    position: absolute;
    top: 3vh;
    left: 6vh;
    width: 10.21vw;
  }

  .frame {
    position: absolute;
    width: 26vw;
    // height: 520px;
    background: #FFFFFF;
    border-radius: 4px;
    top: 24vh;
    right: 13vw;

    .title {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 1.67vw;
      color: #303133;
      text-align: center;
      font-style: normal;
      margin: 5.2vh 0;
    }

    .name {
      border-radius: 4px;
      margin: 0px 3.34vw;
    }

    .password {
      border-radius: 4px;
      margin: 2.3vh 3.34vw;
    }

    .code {
      display: flex;
      margin: 0 3.34vw;

      .input_code {
        padding-right: 5px;
      }

      .des {
        width: 100%;
        padding-left: 5px;

        img {
          height: 2.92vw;
          width: 100%;
        }
      }
    }

    .submit {
      height: 2.92vw;
      background: var(--content-base-button-color);
      border-radius: 4px;
      margin: 4.5vh 3.34vw 6.7vh 3.34vw;
      display: flex;
      text-align: center;
      align-items: center;
      cursor: pointer;

      .submit-font {
        width: 100%;
        font-family: AlibabaPuHuiTiB;
        font-size: 1.25vw;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
        font-style: normal;
      }
    }
  }


  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
  }

  input {
    background-color: transparent;
  }

  ::v-deep .el-input__inner:focus {
    border-color: var(--content-base-border-color) !important;
    outline: 0;
  }

}
</style>
