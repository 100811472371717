<template>
  <div class="k-form-search">
    <div class="box-card" style="overflow: visible;position: unset">
      <div v-if="this.dataShowSubscript === null || this.dataShowSubscript">
        <div class="search-header">
          <!-- <div class="card-icon" :style="iconStyle">
            <md-icon md-src="/static/svg/form.svg"></md-icon>
          </div> -->
          <div>
            <i class="el-icon-search" @click="popup"></i>
            <i class="el-icon-d-caret" @click="show"></i>
          </div>
        </div>

      </div>

      <div slot="header" class="clearfix" style="text-align:right">

      </div>
      <div id="show-header" class="show-form">
        <k-form ref="searchForm" :data-col="0" :data-model="searchData">
          <div v-for="(item, index) in formList" :key="index">
            <k-form-item v-if="!item.searchBtnFlag" v-bind:key="item.label" :label="item.label"
              style="margin-bottom: 16px;">
              <k-field-date v-if="item.inputHtml == 'KFieldDate' && item.inputConfig['data-type'] == 'daterange'"
                v-model="item.data" data-type="daterange" @data-startdate-change="item.startDate = $event"
                @data-enddate-change="item.endDate = $event"
                :data-allowblank="item.inputConfig['data-allowblank'] ? item.inputConfig['data-allowblank'] : true"></k-field-date>
              <component v-else v-model="item.data" :is="item.inputHtml" v-bind="item.inputConfig" />
            </k-form-item>
            <div style="margin-bottom: 16px;" v-else-if="item.searchBtnFlag"
              :class="[item.absolute ? 'show-form-btn' : 'show-form-btnMultiLine']">
              <!-- <k-btn class="md-primary" :data-handler="query">
                查 询
              </k-btn>
              <k-btn class="md-plain" data-functype="RESET" data-type="warning" data-from="searchForm">
                重 置
              </k-btn> -->
              <k-btn class="md-primary" :data-handler="query">
                <i class="el-icon-search"></i> 查 询
              </k-btn>
              <k-btn class="md-plain" data-functype="RESET" data-type="warning" data-from="searchForm">
                <i class="el-icon-refresh"></i>重 置
              </k-btn>
            </div>
          </div>
        </k-form>
      </div>

      <div class="k-form-search-footer">

        <slot></slot>
      </div>
    </div>
    <k-popup data-width-percent="40%" ref="dialog" data-title="动态搜索" :data-mask="true" :data-confirm-close="false"
      data-confirm-describe="是否关闭弹出框" data-title-align="center">
      <k-form dataTotalWidth="auto" :data-col="1" :data-model="formData">
        <k-form-item label="查询条件" data-ui="element">
          <k-field-checkbox v-model="formData.selectValue" :data-data="this.list" data-display-field="label"
            data-value-field="label" :data-multiple="true"></k-field-checkbox>
        </k-form-item>
        <k-form-footer dataAlign="center">
          <k-btn class="md-primary" data-functype="CLOSE" data-type="primary" :dataHandler="() => dataConfirm()">
            <i class="el-icon-circle-check" />保存
          </k-btn>
          <k-btn class="md-plain" data-functype="CLOSE">
            <i class="el-icon-circle-close"></i>关闭
          </k-btn>
        </k-form-footer>
      </k-form>
    </k-popup>
  </div>
</template>

<script>
import {
  debounce
} from "lodash";
import KBtn from "../k-btn/k-btn";
import emitter from "@/components/k-element/common/k-emitter.js";
import props from "@/components/k-element/common/k-field-props.js";


import Tools from "@/utils/tools.js";
import event from "@/components/k-element/common/k-field-event";

export default {
  name: 'kFormSearch',
  mixins: [props(), emitter()],
  components: {
    KBtn
  },
  props: {
    dataModelName: {
      type: String
    },
    dataTarget: {
      type: String
    },
    isShowSearch: {
      type: Boolean,
      type: true
    }
  },
  data() {
    return {
      extends: true,
      list: [],
      formList: [],
      obj: {
        label: '',
        value: '',
        inputConfig: {},
      },
      formData: {
        selectValue: ""
      },
      tempSelectValue: "",
      searchData: {},
      dataVisible: false,
      // 必填项默认展示，数据加载完成后添加到展示列表
      notAllowBlankList: [],
      // 用于保存时去掉不为空选项
      notAllowBlanks: {},
      // 初始formList
      originFormList: [],
    }
  },
  computed: {
    iconStyle() {
      let iconStyle = {};
      iconStyle.background = this.$store.state.system.cardBackground
      return iconStyle;
    }
  },
  methods: {
    getParams() {
      let params = {};

      let formQueryData = []
      this.formList.map(item => {
        if (!item.searchBtnFlag) {
          formQueryData.push(item)
        }
      })
      formQueryData.forEach(item => {
        if (item.inputConfig['data-type'] == "daterange") { //日期区间处理
          if (item.startDate) {
            params[item.field] = item.startDate;
          }
          if (item.endDate) {
            params[item.inputConfig['endDateFeild']] = item.endDate;
          }
        } else {
          if (item.data) {
            params[item.field] = item.data
          }
        }
      })
      return params;
    },
    query() {
      this.$emit("click")
      if (!this.dataTarget) {
        console.error("k-from-search需要指定data-target.")
      }
      let target = this.getParentRef(this.dataTarget);
      if (target && target.$options.name == "KGrid") {
        let params = {};

        let formQueryData = []
        this.formList.map(item => {
          if (!item.searchBtnFlag) {
            formQueryData.push(item)
          }
        })
        formQueryData.forEach(item => {
          if (item.inputConfig['data-type'] == "daterange") { //日期区间处理
            if (item.startDate) {
              params[item.field] = item.startDate;
            }
            if (item.endDate) {
              params[item.inputConfig['endDateFeild']] = item.endDate;
            }
          } else {
            if (item.data) {
              params[item.field] = item.data
            }
          }
        })

        let re = this.$refs.searchForm.validate();
        if (re === false) {
          return;
        }

        target.load(params);
      } else {
        console.error("data-target不存在或data-target不是KGrid组件.")
      }

    },
    popup() {
      let dialog = this.$refs.dialog
      if (dialog.dataVisible == true) {
        dialog.close()
      } else if (dialog.dataVisible == false) {
        this.tempSelectValue = this.formData.selectValue;
        dialog.popup()
      }
    },
    closeDialog() {
      this.formData.selectValue = this.tempSelectValue;
    },
    show() {
      let e = document.getElementById('show-header')
      if (this.extends) {
        e.style.display = "none"
      } else {
        e.style.display = ""
      }
      this.extends = !this.extends
    },

    loadDefaultSearch() {
      this.httpUtil.query({
        url: "graphql/searchDefault.json",
        params: {
          modelName: this.dataModelName
        }
      }).then(data => {
        this.formList = [];
        this.formData.selectValue = "";
        let rows = data.rows;
        let temp = {};
        rows.map(model => {
          //名称和组件没配置不显示
          if (!model.label || !model.inputHtml) {
            return;
          }

          if (model.inputConfig) {
            model.inputConfig = Tools.str2Json(model.inputConfig);
          } else {
            model.inputConfig = {};
          }

          temp[model.field] = true;

          this.formList.push(model);
        });
        for (let i = 0; i < this.notAllowBlankList.length; i++) {
          let model = this.notAllowBlankList[i];
          if (temp[model.field]) {
            continue;
          } else {
            this.formList.unshift(model);
          }
        }

        for (let i = 0; i < this.formList.length; i++) {
          let model = this.formList[i];
          if (this.formData.selectValue) {
            this.formData.selectValue += "," + model.label
          } else {
            this.formData.selectValue = model.label
          }
        }
        this.originFormList = JSON.parse(JSON.stringify(this.formList))
        this.countShowFormBtn()
      });
    },
    loadAllSearch() {
      this.httpUtil.query({
        url: "graphql/search.json",
        params: {
          modelName: this.dataModelName
        }
      }).then(data => {
        this.notAllowBlankList = [];
        let rows = data.rows;
        rows.map(model => {
          if (!model.label) { //label没配置不显示
            return;
          }

          if (model.inputConfig) {
            model.inputConfig = Tools.str2Json(model.inputConfig);
          } else {
            model.inputConfig = {};
          }

          if (model.inputConfig.hasOwnProperty('data-allowblank') || model.inputConfig.hasOwnProperty(
            'dataAllowblank')) {
            if (model.inputConfig['data-allowblank'] === false || model.inputConfig['data-allowblank'] ===
              'false' ||
              model.inputConfig['dataAllowblank'] === false || model.inputConfig['dataAllowblank'] ===
              'false') {
              model.disabled = true
              this.notAllowBlankList.push(model);
              this.notAllowBlanks[model.label] = model;
            }
          }

          this.list.push(model);
        });
        this.loadDefaultSearch();
      });
    },
    dataConfirm() {
      let values = this.formData.selectValue.split(",");
      if (values && values.length > 0) {
        let selectValue = "";
        for (let i = 0; i < values.length; i++) {
          let value = values[i];
          if (!this.notAllowBlanks[value]) {
            selectValue += "," + value;
          }
        }
        if (selectValue.length > 0) {
          this.formData.selectValue = selectValue.substring(1);
        } else {
          this.formData.selectValue = "";
        }


      }
      this.tempSelectValue = this.formData.selectValue;
      this.httpUtil.update({
        url: "graphql/updateSearchDefault.json",
        params: {
          modelName: this.dataModelName,
          searchFields: this.formData.selectValue
        }
      }).then(data => {
        this.loadDefaultSearch();
      });
    },
    /**
     * 计算查询框按钮位置
     */
    countShowFormBtn() {
      if (!this.$refs.searchForm || !this.$refs.searchForm.$el) {
        return
      }
      let width = this.$refs.searchForm.$el.offsetWidth
      this.formList = JSON.parse(JSON.stringify(this.originFormList))
      let lineMaxNum = Math.floor(width / 300)
      if (lineMaxNum <= this.formList.length) {
        this.formList.splice(lineMaxNum - 1, 0, { searchBtnFlag: true })
      } else {
        this.formList.splice(lineMaxNum - 1, 0, { searchBtnFlag: true, absolute: true })
      }

    },
  },
  mounted() {
    this.loadAllSearch();
    window.addEventListener("resize", debounce(this.countShowFormBtn, 100))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.countShowFormBtn, 100))
  },

}
</script>
<style lang="scss" scoped>

</style>
