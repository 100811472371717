<template>
	<div class="k_edit_table">
		<!-- 写这两个类名是为了兼容 el-table的全局样式 -->
		<div class="grid-content">
			<div class="k-grid">
				<el-table :data="modelaVal" style="width: 100%">
					<template v-for="item in cloumnData">
						<template v-if="item.show">
							<el-table-column v-if="item.inputType === 'inputValue'" :prop="item.prop" :label="item.label" :width="item.width">
							</el-table-column>
							<el-table-column v-else-if="item.inputType === 'selectValue'" :prop="item.prop" :label="item.label" :width="item.width">
								<template slot-scope="{ row }">
									<span>{{
										showSelectLabel(
											item.selectDataFromRowFlag ? row[item.selectDataFromRowFlag] : item.selectData,
											row[item.prop],
											item.selectValue,
											item.selectLabel
										)
									}}</span>
								</template>
							</el-table-column>
							<el-table-column v-else-if="item.inputType === 'select'" :prop="item.prop" :label="item.label" :width="item.width">
								<template slot-scope="{ row }">
									<template v-if="item.slotName && row.isShowInput">
										<slot :name="item.slotName" :row="row"></slot>
									</template>
									<template v-else>
										<el-select v-show="row.isShowInput" v-model="row[item.prop]" v-bind="{ ...item.elSelectProps }">
											<el-option
												v-for="data in item.selectData"
												:key="data[item.selectValue]"
												:label="data[item.selectLabel]"
												:value="data[item.selectValue]"
											>
											</el-option>
										</el-select>
									</template>

									<span v-show="!row.isShowInput">{{
										showSelectLabel(
											item.selectDataFromRowFlag ? row[item.selectDataFromRowFlag] : item.selectData,
											row[item.prop],
											item.selectValue,
											item.selectLabel
										)
									}}</span>
								</template>
							</el-table-column>
							<el-table-column v-else-if="item.inputType === 'number'" :prop="item.prop" :label="item.label" :width="item.width">
								<template slot-scope="{ row }">
									<el-input-number
										:controls="false"
										v-show="row.isShowInput"
										v-model="row[item.prop]"
										v-bind="{ ...item.elInputNumberProps }"
										:placeholder="`请输入${item.label}`"
									>
									</el-input-number>
									<span v-show="!row.isShowInput">{{ showThousandth(row[item.prop], item.isShowThousandth) }}</span>
								</template>
							</el-table-column>
							<el-table-column v-else :prop="item.prop" :label="item.label" :width="item.width">
								<template slot-scope="{ row }">
									<el-input
										v-bind="{ ...item.elInputProps }"
										v-show="row.isShowInput"
										v-model="row[item.prop]"
										:placeholder="`请输入${item.label}`"
									></el-input>
									<span v-show="!row.isShowInput">{{ row[item.prop] }}</span>
								</template>
							</el-table-column>
						</template>
					</template>
					<el-table-column fixed="right" label="操作" :width="controlsWidth">
						<template slot-scope="{ row }">
							<div v-show="row.isShowInput" class="operate _operate">
								<span class="add" @click="handelAdd(row)">保存</span>
								<span class="cancel" @click="handelCancel(row)">取消</span>
								<!-- <span @click="handelDel(row)">删除</span> -->
							</div>
							<div v-show="!row.isShowInput" class="operate">
								<i class="iconfont icon-bianji" @click="handelEdit(row)" style="margin-right: 5px"></i>
								<i class="iconfont icon-shanchu" @click="handelDel(row)"></i>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="add_data" @click="handleAddRowData">
					<i class="iconfont icon-xinzeng"></i>
					<span>新增一行数据</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { cloneDeep } from "lodash";
import Tools from "@/utils/tools";
export default {
	name: "KEditTable",
	data() {
		return {
			cancelData: null,
		};
	},
	computed: {
		modelaVal() {
			return this.value;
		},
	},
	props: {
		value: {
			type: Array,
			default: null,
		},
		cloumnData: {
			type: Array,
			default: null,
		},
		delFlag: {
			type: String,
			default: "id",
		},
		defaultAddData: {
			type: Object,
		},
		controlsWidth: {
			type: Number,
			default: 200,
		},
		// 保存前事件，参数处理函数，JS函数里返回false阻止按钮动作
		dataHandlerSave: {
			type: Function,
			default: function () {
				return true;
			},
		},
		// 删除前事件，参数处理函数，JS函数里返回false阻止按钮动作
		dataHandlerDel: {
			type: Function,
			default: function () {
				return true;
			},
		},
		delMsg: {
			type: String,
			default: "此操作将永久删除该文件, 是否继续?",
		},
	},
	methods: {
		handelEdit(row) {
			row.isShowInput = true;
			// 缓存数据
			this.cancelData = cloneDeep(this.value);
		},
		// 保存
		async handelAdd(row) {
			let flag = true;
			let message = "";
			this.cloumnData.forEach((item) => {
				const nullArr = ["", undefined, null];
				// 为空时保存
				if (nullArr.includes(row[item.prop]) && item.required) {
					if (flag) {
						// 保存第一次进入的值
						flag = false;
						message = item.label;
					}
				}
			});
			if (flag) {
				//执行data-hanlder-save事件
				if (this.dataHandlerSave) {
					let re = await this.dataHandlerSave(row);
					if (re === false) {
						//不做任何处理
						return;
					}
				}
				row.isShowInput = false;
				row.isFirstAdd && (row.isFirstAdd = false);
				// 数据回传
				this.$emit("input", this.value);
			} else {
				// this.$message.error(`${message}的值不能空`)
				setTimeout(() => {
					Tools.alert(`${message}的值不能空`, "danger");
				}, 300);
			}
		},
		handelDel(row) {
			this.$confirm(this.delMsg, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
				cancelButtonClass: "md-plain",
				confirmButtonClass: "md-golden",
			})
				.then(async () => {
					//执行data-hanlder-del事件
					if (this.dataHandlerDel) {
						let re = await this.dataHandlerDel(row);
						if (re === false) {
							//不做任何处理
							return;
						}
					}
					// 确认删除
					this.del(row);
					// 删除成功 缓存数据
					this.cancelData = cloneDeep(this.value);
					Tools.alert(`删除成功!`, "success");
				})
				.catch(() => {
					Tools.alert(`已取消删除`, "info");
				});
		},
		handelCancel(row) {
			if (!row.isFirstAdd) {
				// 编辑时的取消
				const index = this.value.findIndex((item) => item[this.delFlag] === row[this.delFlag]);
				this.cancelData[index].isShowInput = false;
				if (index !== -1) {
					// 取消一定会存在，但是判断一下
					this.value.splice(index, 1, this.cancelData[index]);
				}
			} else {
				// 新增时的取消 相当于删除
				this.del(row);
			}
		},
		handleAddRowData() {
			// 添加一行数据
			const row = {};
			const data = { ...this.value[this.value.length - 1], isShowInput: false } || { ...this.defaultAddData, isShowInput: false };
			Object.keys(data).forEach((item) => {
				if (item === this.delFlag) {
					row[item] = +data[item] + 1;
				} else if (item === "isShowInput") {
					row[item] = true;
				} else {
					row[item] = "";
				}
			});
			this.value.push({
				...row,
				isFirstAdd: true,
			});
		},
		// 判断编辑是否完成 return boolean
		editingIsCompleted() {
			// 只要一个的值是编辑的就未完成
			return !this.value.some((item) => item.isShowInput === true);
		},
		showSelectLabel(selectData, value, key, label) {
			// selectData 下拉框的所有数据
			// value 绑定的值
			// key 绑定值的标识
			// span显示数据的字段标识
			let content = "";
			selectData &&
				selectData.forEach((item) => {
					if (item[key] === value) {
						content = item[label];
					}
				});
			return content;
		},
		del(row) {
			const index = this.value.findIndex((item) => item[this.delFlag] === row[this.delFlag]);
			if (index !== -1) {
				this.value.splice(index, 1);
			}
		},
		showThousandth(value, flag) {
			if (flag) {
				return value.toLocaleString();
			} else {
				return value;
			}
		},
	},
	created() {
		// 给v-model的值绑定每项添加isShowInput字段
		this.$emit(
			"input",
			this.value.map((item) => ({
				...item,
				isShowInput: item.isShowInput || false,
			}))
		);
	},
};
</script>

<style lang="scss" scoped>
.k_edit_table {
	.add_data {
		background: #2a2d3f;
		border: 1px dashed rgba(172, 172, 172, 1);
		border-radius: 2px;
		text-align: center;
		color: #ebeced;
		line-height: 32px;
		cursor: pointer;
		user-select: none;
		margin: 8px 12px;

		.iconfont {
			margin-right: 5px;
		}
	}
	.operate {
		cursor: pointer;
		user-select: none;
	}
	._operate {
		display: flex;
		text-align: center;
		.add {
			width: 40px;
			height: 24px;
			background-image: linear-gradient(90deg, #f5e9ca 0%, #b39e7b 100%);
			border-radius: 2px;
			font-size: 12px;
			color: #232324;
			margin-right: 8px;
		}
		.cancel {
			width: 40px;
			height: 24px;
			border: 1px solid rgba(60, 67, 89, 1);
			border-radius: 2px;
			font-size: 12px;
			color: #e5e5e7;
		}
	}
	::v-deep .el-table__fixed-body-wrapper {
		top: 40px !important;
	}
}
</style>
