<template>
  <div class="k_field_bswitch"
    :class="[_value ? 'active' : 'unactive', dataDisabled === true || dataDisabled === 'true' ? 'disabledClass' : '']"
    @click="change(!_value)">
    <!-- <span class="title">{{ _value ? dataOnConfirmInfo : dataOffConfirmInfo }}</span> -->
    <span class="dot"></span>
  </div>
</template>

<script>
import props from "@/components/k-element/common/k-field-props.js";
import event from "@/components/k-element/common/k-field-event.js";
import emitter from "@/components/k-element/common/k-emitter.js";
import auth from "@/utils/auth.js"
export default {
  name: 'KFieldBswitch',
  mixins: [props(), event(), emitter()],
  props: {
    dataOnValue: {
      type: [Boolean, String, Number],
      default: true
    },
    dataOffValue: {
      type: [Boolean, String, Number],
      default: false
    },
    dataConfirm: {
      type: [Boolean, String],
      default: false
    },
    dataDisabled: {
      type: [Boolean, String],
      default: false
    },
    dataOnAction: {
      type: String
    },
    dataOffAction: {
      type: String
    },
    dataOnConfirmInfo: {
      type: String,
      default: "开启"
    },
    dataOffConfirmInfo: {
      type: String,
      default: "关闭"
    },
    dataBeforeHandler: {
      type: Function
    },
    dataAfterHandler: {
      type: Function
    }
  },
  data() {
    return {
      isShow: true,
      openPermission: true,
      closePermission: true
    };
  },
  computed: {
    _value: function () {
      return this.value === this.dataOnValue;
    },
    confirmInfo: function () {
      if (this.value === this.dataOnValue) {
        return this.dataOffConfirmInfo;
      } else {
        return this.dataOnConfirmInfo;
      }
    }
  },
  created() {
    /**
     * 判断权限是否显示或者隐藏
     * 满足一个就会展示
     */
    if (this.dataOnAction && this.dataOffAction) {
      this.openPermission = auth.check(this.dataOnAction);
      this.closePermission = auth.check(this.dataOffAction);
      this.isShow = this.openPermission || this.closePermission;
    }
  },
  methods: {
    validate(value) {
      this.$emit('data-validate', value);
    },
    change(value) {
      if (this.dataDisabled === true || this.dataDisabled === 'true') {
        return
      }
      if (value) {
        this.doChange(this.openPermission, this.dataOnValue, this.dataOnAction);
        this.handleChange(this.dataOnValue)
      } else {
        this.doChange(this.closePermission, this.dataOffValue, this.dataOffAction);
        this.handleChange(this.dataOffValue)
      }
    },
    doChange(permission, dataValue, dataAction) {
      if (permission) {
        if (!this.doBeforeHandler(dataValue)) {
          return;
        }
        if (this.dataConfirm === 'true' || this.dataConfirm === true) {
          const h = this.$createElement
          this.$confirm('', {
            message: h('div', null, [
              h('div', { style: 'position: absolute;top: -34px;left: -40px;' }, [
                h('i', { class: 'el-icon-menu', style: 'font-size:16px;color: #00a0e9;' }),
                h('span', { style: 'margin-left:10px;' }, '提示'),
              ]),
              h('p', { style: 'margin-top: 21px;width:100%' }, `确认${this.confirmInfo}吗?`)
            ]),
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'md-golden',
            cancelButtonClass: 'md-plain',
            // customClass:'statusLeft',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                try {
                  if (dataAction) {
                    this.request(dataAction, dataValue, () => {
                      done();
                      instance.confirmButtonLoading = false;
                    })
                  } else {
                    this.handleInput(dataValue);
                    done();
                    instance.confirmButtonLoading = false;
                    if (this.dataAfterHandler) {
                      this.dataAfterHandler(dataValue);
                    }
                  }
                } catch (e) {

                } finally {
                  done();
                  instance.confirmButtonLoading = false;
                }
              } else {
                done();
              }
            }
          }).catch(() => {})
        } else {
          if (dataAction) {
            this.request(dataAction, dataValue)
          } else {
            this.handleInput(dataValue);
            if (this.dataAfterHandler) {
              this.dataAfterHandler(dataValue);
            }
          }
        }
      } else {
        this.$message.warning("权限不足")
        return;
      }
    },
    doBeforeHandler(dataValue) {
      if (this.dataBeforeHandler) {
        let res = this.dataBeforeHandler(dataValue, this.dataParams);

        if (res === false) { //不做任何处理
          return false;
        }
      }
      return true;
    },
    request(action, dataValue, callback) {
      if (!action) {
        return;
      }
      this.httpUtil.comnUpdate({
        action: action,
        params: this.dataParams,
        successAlert: true,
      }).then(data => {
        this.handleInput(dataValue);
        if (callback) {
          callback();
        }
        if (this.dataAfterHandler) {
          this.dataAfterHandler(dataValue, data);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.k_field_bswitch {
  width: 40px;
  position: relative;
  height: 18px;
  border-radius: 12px;
  padding: 4px;
  font-size: 11px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  &.active {
    background: var(--content-base-button-color);

    .title {
      color: var(--content-base-button-color);
      margin-right: 10px;
      margin-left: 8px;
    }

    .dot {
      right: 2px;
      top: 1px;
    }
  }

  &.unactive {
    background: #dcdfe6;

    .title {
      color: #dcdfe6;
      margin-left: 24px;
      margin-right: 8px;
    }

    .dot {
      left: 2px;
      top: 1px;
    }
  }

  &.disabledClass {
    opacity: 0.3 !important;
    cursor: not-allowed;
  }

  // .title {

  // }
  .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #FFFFFF;
    // transition: all ease-in-out .5s;
  }
}
</style>