<template>
	<div class="k_field_tree" v-click-outside="clickOutside">
		<div @click="showContent" class="tree_input" :class="[isShowContent ? 'active' : '', dataDisabled ? 'disabled' : '']">
			<el-tooltip v-if="showSelectArr.length" effect="dark" :content="this.showSelectArr.join(',')" placement="top-start">
				<span class="has_select" v-if="dataMultiple || dataMultiple === 'true'">{{ `已选择${this.showSelectArr.length}项` }}</span>
				<span class="has_select" v-else>
					{{ this.showSelectArr[0] }}
				</span>
			</el-tooltip>
			<span v-else>{{ this.dataPlaceholder }}</span>
			<i class="el-icon-arrow-down"></i>
		</div>
		<el-popover v-model="isShowContent" ref="popover" width="400px" placement="bottom" trigger="click">
			<div class="tree_content" v-if="options.length">
				<el-input placeholder="请输入搜索内容" prefix-icon="el-icon-search" v-model="searchVal" @change="filterSearch"> </el-input>
				<div class="clear_searchval" @click.stop="clearSearchval" v-show="searchVal">
					<i class="el-icon-close"></i>
				</div>
				<div class="select_content" :class="selectArr.length ? 'has_select' : ''">
					<span>已选 {{ selectArr.length }} 项</span>
					<span v-show="selectArr.length" class="clear_all" @click="clearAll">清除已选</span>
				</div>
				<div class="tree_btns">
					<div class="flex" @click="openAll">
						<svg-icon icon-class="treeOpenAll"></svg-icon>
						<span>展开所有</span>
					</div>
					<div class="flex" @click="closeAll">
						<svg-icon icon-class="treeCloseAll"></svg-icon>
						<span>收起所有</span>
					</div>
					<div class="flex" @click="onlyShowSelect" :class="isOnlyShowSelect ? 'onlyShowSelect' : ''">
						<svg-icon icon-class="treeOnlySeeSelect"></svg-icon>
						<span>只看已选</span>
					</div>
					<div class="opento">
						<span @click="isShowOpento = !isShowOpento">展开到<i class="el-icon-arrow-down"></i></span>
						<div v-show="isShowOpento" class="opento_content">
							<template v-for="item in dimensionArr">
								<div
									class="content_item"
									:class="openDimensionArr === item.dimension ? 'active' : ''"
									@click="openToDimension(options, item.dimension, true)"
								>
									{{ item.label }}
								</div>
							</template>
						</div>
					</div>
				</div>
				<!-- 树结构 -->
				<div class="tree">
					<template v-if="dataIsVirtualTree">
						<virtualTree
							:data="options"
							:nodeKey="dataValueField"
							:height="height"
							ref="tree"
							:expand-on-click-node="false"
							:default-expanded-keys="defaultExpandedKeys"
						>
							<template slot-scope="{ data }">
								<div class="tree_item">
									<div class="item">
										<div class="checked_box">
											<el-checkbox :indeterminate="data.indeterminate" v-model="data.isChecked" @change="changeChecked(data, $event)">
												<div class="checked_title" v-html="showFilterVlaue(data[dataDisplayField.split(',')[0]], data)"></div>
												<div class="code">{{ data[dataDisplayField.split(",")[1]] }}</div>
											</el-checkbox>
										</div>
									</div>
								</div>
							</template>
						</virtualTree>
					</template>
					<template v-else>
						<template v-for="item in options">
							<treeItem
								:data="item"
								:filterValue="searchVal"
								:title="dataDisplayField.split(',')[0]"
								:code="dataDisplayField.split(',')[1]"
								:children="dataDisplayChild"
								@showChildren="showChildren"
								@change="changeChecked"
							></treeItem>
						</template>
					</template>
				</div>
			</div>
			<div class="no_data" v-else>暂无数据</div>
		</el-popover>
	</div>
</template>

<script>
import props from "@/components/k-element/common/k-field-props.js";
import event from "@/components/k-element/common/k-field-event.js";
import treent from "@/components/k-element/common/k-field-treent.js";
import emitter from "@/components/k-element/common/k-emitter.js";
import Tools from "@/utils/tools.js";
import treeItem from "./tree-item.vue";
import { cloneDeep } from "lodash";
export default {
	name: "KFieldTree",
	mixins: [props(), event(), emitter(), treent],
	emits: ["input"],
	components: {
		treeItem,
	},
	props: {
		value: {
			type: [Array, String, Number],
			default: null,
		},
		dataUiType: {
			type: String,
			default: "cascader",
		},
		dataDisabled: {
			type: Boolean,
		},
		dataDisableBranchNodes: {
			type: [Boolean, String],
			default: false,
		},
		dataValueSeparator: {
			type: String,
			default: "-",
		},
		dataFilterMethod: {
			type: Function,
			default: undefined,
		},
		dataSearchAble: {
			type: Boolean,
			default: true,
		},
		dataBeforeFilter: {
			type: Function,
			default: undefined,
		},
		// 显示字段，可同时多个
		dataDisplayField: {
			type: String,
			default: undefined,
		},
		// 值字段
		dataValueField: {
			type: String,
			default: undefined,
		},
		dataPlaceholder: {
			type: String,
			default: "Select...",
		},
		// 字段下标
		dataDisplayChild: {
			type: String,
			default: "children",
		},
		// 父节点
		dataParentField: {
			type: String,
			default: undefined,
		},
		// 父节点
		dataChildField: {
			type: String,
			default: undefined,
		},
		//上级级别节点
		dataDiffcondition: {
			type: String,
			default: "orgno,parentorgno",
		},
		dataOnBeforeload: {
			type: Function,
			default: undefined,
		},
		dataOnAfterload: {
			type: Function,
			default: undefined,
		},
		dataMultiple: {
			type: [Boolean, String],
			default: false,
		},
		dataLazy: {
			type: [Boolean, String],
			default: undefined,
		},
		dataFlat: {
			type: Boolean,
			default: true,
		},
		dataAutoSelectDescendants: {
			type: Boolean,
			default: false,
		},
		dataAutoDeselectDescendants: {
			type: Boolean,
			default: false,
		},
		dataAutoDeselectAncestors: {
			type: Boolean,
			default: false,
		},
		dataAutoSelectAncestors: {
			type: Boolean,
			default: false,
		},
		dataDefaultExpandLevel: {
			type: Number,
			default: 0,
		},
		dataNoResultsText: {
			type: String,
			default: "没有匹配的结果",
		},
		dataNoOptionsText: {
			type: String,
			default: "没有内容",
		},
		dataShowCount: {
			type: Boolean,
			default: false,
		},
		dataShowCountOnSearch: {
			type: Boolean,
			default: false,
		},
		//只显示搜索结果
		dataOnlyShowResults: {
			type: Boolean,
			default: false,
		},
		dataAlwaysOpen: {
			type: Boolean,
			default: false,
		},
		dataClearOnSelect: {
			type: Boolean,
			default: true,
		},
		dataType: {
			type: Boolean,
			default: false,
		},
		dataIsVirtualTree: {
			type: Boolean,
			default: false,
		},
		dataVirtualTreeAction: {
			type: String,
		},
	},
	data() {
		return {
			height: 0,
			defaultExpandedKeys: [],
			allDimension: {},
			isShowContent: false,
			isShowOpento: false,
			searchVal: "",
			options: [],
			params: {},
			graphql: String,
			props: {},

			selectArr: [],
			dataDiffcondition0Arr: [],
			isOnlyShowSelect: false,
			dimensionArr: [],
			openDimensionArr: 0,
			showSelectArr: [],
		};
	},
	watch: {
		value(newVal) {
			// 新的value值为空时
			if (!newVal && newVal !== "") {
				this.options = this.handleData(this.options);
			}
		},
	},
	methods: {
		showFilterVlaue(name, item) {
			const str = this.searchVal ? name.split(this.searchVal).join(`<span style="color:#00a0e9;">${this.searchVal}</span>`) : name;
			return str;
		},
		clickOutside() {
			if (!this.dataDisabled && this.isShowContent) {
				this.isShowContent = !this.isShowContent;
			}
		},
		showContent() {
			if (this.dataDisabled) {
				return;
			}
			this.isShowContent = !this.isShowContent;
		},
		loadData(params) {
			this.params = { ...this.params, ...params };
			if (this.dataOnBeforeload) {
				this.dataOnBeforeload(this.params);
			}
			// this.options = []
			if (this.dataAction) {
				// 通过Action加载
				this.dataLazy ? this._loadActionData() : this.loadActionDataTree();
			} else if (this.dataGraphql) {
				// 通过Graphql加载
				this.graphql = this.dataLazy ? this.dataGraphql : this.getGraphqlUrl(this.dataGraphql, this.dataDiffcondition);
				if (this.dataLazy) {
					this._loadGraphqlData();
				} else {
					this.loadGraphqlData();
				}
			} else if (this.dataData) {
				// 直接指定源数据
				// this.options = this.dataType ? this.handleData(this.dataData) : this.dataData
				this.options = this.handleData(cloneDeep(this.dataData));
			} else {
				console.error("不支持的数据获取方式，请检查k-field-cascader的元素属性配置");
			}
			if (this.dataOnAfterload) {
				this.dataOnAfterload(this.options);
			}
		},
		loadOptions({ action, parentNode, callback }) {
			if (action === "LOAD_CHILDREN_OPTIONS") {
				this.params[this.dataParentField] = parentNode[this.dataChildField];
				if (this.dataAction) {
					this.httpUtil
						.comnQuery({
							action: this.dataAction,
							params: this.params,
						})
						.then((data) => {
							let childs = this.handleData(data.rows);
							if (childs.length == 0) {
								parentNode[this.dataDisplayChild] = undefined;
								callback();
								return;
							}
							childs.map((child) => {
								this.$set(child, this.dataDisplayChild, null);
							});
							parentNode[this.dataDisplayChild] = [];
							parentNode[this.dataDisplayChild].push(...childs);
							callback();
						});
				} else {
					this.httpUtil
						.graphqlQurey({
							graphql: this.graphql,
							params: this.params,
						})
						.then((data) => {
							let graphqlFirst = this.graphql.substring(this.graphql.indexOf("{") + 1, this.graphql.indexOf("("));
							let childs = this.handleData(data[graphqlFirst].rows);
							if (childs.length == 0) {
								parentNode[this.dataDisplayChild] = undefined;
								callback();
								return;
							}
							childs.map((child) => {
								this.$set(child, this.dataDisplayChild, null);
							});
							parentNode[this.dataDisplayChild] = [];
							parentNode[this.dataDisplayChild].push(...childs);
							callback();
						});
				}
			}
		},
		_loadActionData() {
			this.httpUtil
				.comnQuery({
					action: this.dataAction,
					params: this.params,
				})
				.then((data) => {
					this.options = this.handleData(data.rows);
					// this.options.map(option=>{
					//   this.$set(option,this.dataDisplayChild,null)
					// })
				});
		},
		_loadGraphqlData() {
			this.httpUtil
				.graphqlQurey({
					graphql: this.graphql,
					params: this.params,
				})
				.then((data) => {
					let graphqlFirst = this.graphql.substring(this.graphql.indexOf("{") + 1, this.graphql.indexOf("("));
					this.options = this.handleData(data[graphqlFirst].rows);
					// this.options.map(option=>{
					//   this.$set(option,this.dataDisplayChild,null)
					// })
				});
		},
		handleData(data) {
			// 获取数据的维度
			this.dimensionArr = [];
			this.setDimensionArr(data, 1);
			this.setAllDefaultExpandedKeys(data, 1);

			let target;
			// 是否选中
			target = this.addObjectKey(data, "isChecked", false);
			// isShow 是否显示该项
			target = this.addObjectKey(target, "isShow", false);
			// isShowChildren 是否显示子类
			target = this.addObjectKey(target, "isShowChildren", false);
			// 子类没全选，父类的状态
			target = this.addObjectKey(target, "indeterminate", false);
			// 是否过滤
			target = this.addObjectKey(target, "isFilter", false);

			// 初始数据完成，将第一维度的数据显示
			target = this.changeObjectValue(target, "isShow", true);

			// 处理默认值的显示
			// this.$emit('input',"200")
			this.selectArr = [];
			this.showSelectArr = [];
			this.dataDiffcondition0Arr = [];
			this.value && this.defaultChecked(target);
			return target;
		},
		setDimensionArr(data, dimension) {
			data.forEach((item) => {
				this.pushDimensionArr(dimension);
				if (item[this.dataDisplayChild]) {
					const i = dimension + 1;
					this.setDimensionArr(item[this.dataDisplayChild], i);
				}
			});
		},
		setAllDefaultExpandedKeys(data, dimension) {
			if (!this.allDimension[`dimensionArr${dimension}`]) {
				this.$set(this.allDimension, `dimensionArr${dimension}`, []);
			}
			const arr = this.allDimension[`dimensionArr${dimension}`];
			data.forEach((item) => {
				if (item[this.dataDisplayChild] && !item.isFilter) {
					if (!arr.some((val) => val === item[this.dataValueField])) {
						arr.push(item[this.dataValueField]);
					}
					const i = dimension + 1;
					this.setAllDefaultExpandedKeys(item[this.dataDisplayChild], i);
				}
			});
		},
		pushDimensionArr(dimension) {
			if (!this.dimensionArr.some((item) => item.dimension === dimension)) {
				this.dimensionArr.push({
					dimension,
					label: dimension + "级目录",
				});
			}
		},
		// 添加key值
		addObjectKey(data, key, value) {
			data.forEach((item) => {
				item[key] = value;
				item[this.dataDisplayChild] && this.addObjectKey(item[this.dataDisplayChild], key, value);
			});
			return data;
		},
		changeObjectValue(data, key, value) {
			data.forEach((item) => {
				item[key] = value;
			});
			return data;
		},
		defaultChecked(option) {
			let defaultSelectArr = [];
			if (typeof this.value === "string") {
				defaultSelectArr = this.value.split(",");
			} else {
				defaultSelectArr = this.value;
			}
			this.setDefaultChecked(option, defaultSelectArr, option);
		},
		setDefaultChecked(option, arr, target) {
			// 第三个参数为原始数据
			option.forEach((item) => {
				if (arr && arr.includes(item[this.dataValueField])) {
					// 只有多选时才需要判断，父类的indeterminate
					if (this.dataMultiple && !this.dataAutoDeselectAncestors) {
						item[this.dataDiffcondition.split(",")[1]] && this.setDefaultParentIndeterminate(target, item, target);
					}
					item.isChecked = true;
					this.pushSelect(item);
				}
				item[this.dataDisplayChild] && this.setDefaultChecked(item[this.dataDisplayChild], arr, target);
			});
		},
		setDefaultParentIndeterminate(option, item, target) {
			// 第三个参数为原始数据
			option.forEach((data) => {
				if (data[this.dataDiffcondition.split(",")[0]] === item[this.dataDiffcondition.split(",")[1]]) {
					data.indeterminate = true;
					data[this.dataDiffcondition.split(",")[1]] && this.setDefaultParentIndeterminate(target, data, target);
				}
				data[this.dataDisplayChild] && this.setDefaultParentIndeterminate(data[this.dataDisplayChild], item, target);
			});
		},

		// 回传的方法 展开缩回子项按钮事件
		showChildren(item) {
			if (!item.isShowChildren) {
				// isShowChildren为false 要打开子项
				// 找到该项 并改变子项的isShow 和 改项的isShowChildren的值
				this.showChildrenByItem(this.options, item);
			} else {
				// isShowChildren为true 子项已打开 需要关闭所有子项
				this.closeChildrenByItem(this.options, item);
			}
		},
		showChildrenByItem(option, item) {
			option.forEach((data) => {
				if (data[this.dataValueField] === item[this.dataValueField]) {
					data.isShowChildren = true;
					data[this.dataDisplayChild] &&
						data[this.dataDisplayChild].forEach((item) => {
							item.isShow = true;
						});
				}
				data[this.dataDisplayChild] && this.showChildrenByItem(data[this.dataDisplayChild], item);
			});
		},
		closeChildrenByItem(option, item) {
			option.forEach((data) => {
				if (data[this.dataValueField] === item[this.dataValueField]) {
					data.isShowChildren = false;
					data[this.dataDisplayChild] && this.changeChildrenIsShowAndIsShowChildren(data[this.dataDisplayChild], false);
				}
				data[this.dataDisplayChild] && this.closeChildrenByItem(data[this.dataDisplayChild], item);
			});
		},
		changeChildrenIsShowAndIsShowChildren(option, value) {
			option.forEach((item) => {
				item.isShow = value;
				item.isShowChildren = value;
				item[this.dataDisplayChild] && this.changeChildrenIsShowAndIsShowChildren(item[this.dataDisplayChild], value);
			});
		},

		// 回传的方法 选中多选事件
		changeChecked(item, value) {
			if (value) {
				// 选中
				this.checkedItem(this.options, item);
			} else {
				// 反选
				this.unCheckedItem(this.options, item);
			}
			this.$emit("input", this.value instanceof Array ? JSON.parse(JSON.stringify(this.selectArr)) : this.selectArr.join(","));
		},
		// 选中的函数开始
		checkedItem(option, item) {
			if (this.dataMultiple) {
				if (this.dataAutoSelectAncestors) {
					// 选中子类默认选中父类
					option.forEach((data) => {
						if (data[this.dataValueField] === item[this.dataValueField]) {
							this.pushSelect(data);
							// 有父类 则选中父类
							data[this.dataDiffcondition.split(",")[1]] && this.changeParentChecked(this.options, data);
						}
						data[this.dataDisplayChild] && this.checkedItem(data[this.dataDisplayChild], item);
					});
				} else {
					// 多选
					option.forEach((data) => {
						if (data[this.dataValueField] === item[this.dataValueField]) {
							this.pushSelect(data);
							// 将第三类状态取放，防止页面显示状态出错
							item.indeterminate = false;
							// 找到该项 有子类 则子类全选
							data[this.dataDisplayChild] && this.changeChildrenChecked(data[this.dataDisplayChild], true);
							// 找到父类 将父类的indeterminate 设置为true
							data[this.dataDiffcondition.split(",")[1]] && this.changeParentIndeterminate(this.options, data, true);
						}
						data[this.dataDisplayChild] && this.checkedItem(data[this.dataDisplayChild], item);
					});
				}
			} else {
				// 单选
				// 如果有选中，先将选中的反选
				this.selectArr[0] && this.changeItemChecked(this.options, this.selectArr[0], false);
				this.changeItemChecked(this.options, item[this.dataValueField], true);
			}
		},
		changeItemChecked(option, id, value) {
			option.forEach((item) => {
				if (item[this.dataValueField] === id) {
					item.isChecked = value;
					value ? this.pushSelect(item) : this.spliceSelect(item);
				}
				item[this.dataDisplayChild] && this.changeItemChecked(item[this.dataDisplayChild], id, value);
			});
		},
		changeChildrenChecked(data, value) {
			data.forEach((item) => {
				item.indeterminate = false;
				item.isChecked = value;
				if (value) {
					this.pushSelect(item);
				} else {
					this.spliceSelect(item);
				}
				item[this.dataDisplayChild] && this.changeChildrenChecked(item[this.dataDisplayChild], value);
			});
		},
		changeParentIndeterminate(option, item, flag) {
			option.forEach((data) => {
				if (data[this.dataDiffcondition.split(",")[0]] === item[this.dataDiffcondition.split(",")[1]]) {
					// flag true 选中
					if (flag) {
						if (data[this.dataDisplayChild].some((data) => data.isChecked === false)) {
							// 子项没有全选
							data.indeterminate = true;
						} else {
							// 子项全选了
							data.indeterminate = false;
							data.isChecked = true;
							this.pushSelect(data);
						}
						data[this.dataDiffcondition.split(",")[1]] && this.changeParentIndeterminate(this.options, data, flag);
					} else {
						// 没选中
						if (data[this.dataDisplayChild].some((data) => data.isChecked === true || data.indeterminate === true)) {
							// 子项有选中
							data.isChecked = false;
							this.spliceSelect(data);
							data.indeterminate = true;
							// 子类有选中，所以要将父类的indeterminate 设置为true
							data[this.dataDiffcondition.split(",")[1]] && this.changeParentIndeterminate(this.options, data, false);
						} else {
							// 子项没选中
							data.indeterminate = false;
							// 子项一个都没有选中，所以需要设置父类的indeterminate 为false
							data[this.dataDiffcondition.split(",")[1]] && this.changeParentIndeterminate(this.options, data, false);
						}
					}
				}
				data[this.dataDisplayChild] && this.changeParentIndeterminate(data[this.dataDisplayChild], item, flag);
			});
		},
		changeParentChecked(option, item) {
			option.forEach((data) => {
				if (data[this.dataDiffcondition.split(",")[0]] === item[this.dataDiffcondition.split(",")[1]]) {
					data.isChecked = true;
					this.pushSelect(data);
					data[this.dataDiffcondition.split(",")[1]] && this.changeParentChecked(this.options, data);
				}
				data[this.dataDisplayChild] && this.changeParentChecked(data[this.dataDisplayChild], item);
			});
		},
		// 选中的函数结束

		// 反选开始函数
		unCheckedItem(option, item) {
			if (this.dataMultiple) {
				if (this.dataAutoSelectAncestors) {
					// 取消选中 如果是父类则子类全部取消，子类全部反选父类也不取消选中
					option.forEach((data) => {
						if (data[this.dataValueField] === item[this.dataValueField]) {
							this.spliceSelect(data);
							data[this.dataDisplayChild] && this.changeChildrenChecked(data[this.dataDisplayChild], false);
						}
						data[this.dataDisplayChild] && this.unCheckedItem(data[this.dataDisplayChild], item);
					});
				} else {
					// 多选
					option.forEach((data) => {
						if (data[this.dataValueField] === item[this.dataValueField]) {
							this.spliceSelect(data);
							data[this.dataDisplayChild] && this.changeChildrenChecked(data[this.dataDisplayChild], false);
							data[this.dataDiffcondition.split(",")[1]] && this.changeParentIndeterminate(this.options, data, false);
						}
						data[this.dataDisplayChild] && this.unCheckedItem(data[this.dataDisplayChild], item);
					});
				}
			} else {
				// 单选
				option.forEach((data) => {
					if (data[this.dataValueField] === item[this.dataValueField]) {
						this.spliceSelect(data);
					}
					data[this.dataDisplayChild] && this.unCheckedItem(data[this.dataDisplayChild], item);
				});
			}
		},
		// 反选函数结束

		pushSelect(item) {
			if (!this.selectArr.includes(item[this.dataValueField])) {
				// 不包含该orgno才添加
				this.selectArr.push(item[this.dataValueField]);
				let str = "";
				this.dataDisplayField.split(",").forEach((data) => {
					str += item[data];
				});
				this.showSelectArr.push(str);
				this.dataDiffcondition0Arr.push(item[this.dataDiffcondition.split(",")[0]]);
			}
		},
		spliceSelect(item) {
			const index = this.selectArr.findIndex((data) => data === item[this.dataValueField]);
			if (index !== -1) {
				this.selectArr.splice(index, 1);
				this.showSelectArr.splice(index, 1);
				this.dataDiffcondition0Arr.splice(index, 1);
			}
		},

		clearAll() {
			this.changeChildrenChecked(this.options, false);
			this.$emit("input", this.value instanceof Array ? JSON.parse(JSON.stringify(this.selectArr)) : this.selectArr.join(","));
		},

		openAll() {
			if (this.dataIsVirtualTree) {
				Object.keys(this.$refs.tree.store.nodesMap).forEach((node) => {
					this.$refs.tree.store.nodesMap[node].expanded = true;
				});
				this.height = 420;
			} else {
				this.options.forEach((item) => {
					item.isShowChildren = true;
					item[this.dataDisplayChild] && this.changeChildrenIsShowAndIsShowChildren(item[this.dataDisplayChild], true);
				});
			}
		},
		closeAll() {
			if (this.dataIsVirtualTree) {
				Object.keys(this.$refs.tree.store.nodesMap).forEach((node) => {
					this.$refs.tree.store.nodesMap[node].expanded = false;
				});
				this.height = 0;
			} else {
				this.options.forEach((item) => {
					item.isShowChildren = false;
					item[this.dataDisplayChild] && this.changeChildrenIsShowAndIsShowChildren(item[this.dataDisplayChild], false);
				});
			}
		},
		onlyShowSelect() {
			if (this.dataIsVirtualTree) {
				this.searchVal = "";
				this.isOnlyShowSelect = !this.isOnlyShowSelect;
				if (this.isOnlyShowSelect) {
					this.httpUtil
						.comnQueryTree({
							action: this.dataVirtualTreeAction,
							params: {
								diffcondition: this.dataDiffcondition,
								orgNameStr: this.showSelectArr.join(","),
								orgno: this.selectArr.length ? this.selectArr.join(",") : this.dataValueField,
								orgcode: this.selectArr.length ? this.selectArr.join(",") : this.dataValueField,
							},
						})
						.then((res) => {
							this.options = this.handleData(res.rows);
							this.$nextTick(() => {
								this.openAll();
							});
						});
				} else {
					this.loadData();
					this.height = 0;
				}
			} else {
				this.searchVal = "";
				this.openAll();
				this.isOnlyShowSelect = !this.isOnlyShowSelect;
				if (this.isOnlyShowSelect) {
					// 只看已选
					if (this.dataMultiple) {
						this.changeIsFilter(this.options, true);
					} else {
						// 单选时要带出父类
						// 先将所有的isFilter设置为true
						this.setIsFilter(this.options, true);
						// 通过selectArr 找到对饮的项
						this.setSelectItemAndParentIsFilter(this.options, this.dataDiffcondition0Arr[0]);
					}
				} else {
					// 查看全部
					this.changeIsFilter(this.options);
				}
			}
		},
		changeIsFilter(option, flag) {
			option.forEach((item) => {
				if (flag) {
					// 需要过滤
					if (item.indeterminate || item.isChecked) {
						item.isFilter = false;
					} else {
						item.isFilter = true;
					}
				} else {
					// 不需要过滤
					item.isFilter = false;
				}
				item[this.dataDisplayChild] && this.changeIsFilter(item[this.dataDisplayChild], flag);
			});
		},
		filterSearch(value) {
			if (this.dataIsVirtualTree) {
				if (value) {
					this.httpUtil
						.comnQueryTree({
							action: this.dataVirtualTreeAction,
							params: {
								diffcondition: this.dataDiffcondition,
								orgNameStr: value,
							},
						})
						.then((res) => {
							this.options = this.handleData(res.rows);
							this.$nextTick(() => {
								this.openAll();
							});
						});
				} else {
					this.loadData();
					this.height = 0;
				}
			} else {
				this.openAll();
				this.searchFilter(this.options, value);
			}
		},
		setIsFilter(option, value) {
			option.forEach((item) => {
				item.isFilter = value;
				item[this.dataDisplayChild] && this.setIsFilter(item[this.dataDisplayChild], value);
			});
		},
		setSelectItemAndParentIsFilter(option, id) {
			option.forEach((item) => {
				if (item[this.dataDiffcondition.split(",")[0]] === id) {
					item.isFilter = false;
					item[this.dataDiffcondition.split(",")[1]] && this.setSelectItemAndParentIsFilter(this.options, item[this.dataDiffcondition.split(",")[1]]);
				}
				item[this.dataDisplayChild] && this.setSelectItemAndParentIsFilter(item[this.dataDisplayChild], id);
			});
		},
		clearSearchval() {
			this.searchVal = "";
			this.filterSearch(this.searchVal);
		},
		searchFilter(option, value) {
			option.forEach((item) => {
				if (item[this.dataDisplayField.split(",")[0]].includes(value)) {
					item.isFilter = false;
					// 存在父类则需要将父类的isFilter 设置为false
					item[this.dataDiffcondition.split(",")[1]] && this.setParentIndeterminate(this.options, item);
				} else {
					item.isFilter = true;
				}
				item[this.dataDisplayChild] && this.searchFilter(item[this.dataDisplayChild], value);
			});
		},
		setParentIndeterminate(option, item) {
			option.forEach((data) => {
				if (data[this.dataDiffcondition.split(",")[0]] === item[this.dataDiffcondition.split(",")[1]]) {
					data.isFilter = false;
					data[this.dataDiffcondition.split(",")[1]] && this.setParentIndeterminate(this.options, data);
				}
				data[this.dataDisplayChild] && this.setParentIndeterminate(data[this.dataDisplayChild], item);
			});
		},

		openToDimension(option, indeterminate, flag) {
			if (this.dataIsVirtualTree) {
				Object.keys(this.$refs.tree.store.nodesMap).forEach((node) => {
					this.$refs.tree.store.nodesMap[node].visible = true;
				});
				if (indeterminate === 1) {
					this.closeAll();
				} else {
					let arr = [];
					for (let i = 1; i < indeterminate; i++) {
						arr = arr.concat(this.allDimension[`dimensionArr${i}`]);
					}
					if (this.defaultExpandedKeys == arr) {
						return;
					} else {
						this.closeAll();
						this.defaultExpandedKeys = arr;
						this.height = 420;
					}
				}
			} else {
				// flag 是否是第一次的，是第一次就需要执行this.closeAll()
				// 关闭所有
				flag && this.closeAll();
				flag && (this.openDimensionArr = indeterminate);
				flag && (this.isShowOpento = false);
				option.forEach((item) => {
					item.isShow = true;
					indeterminate && (item.isShowChildren = true);
					if (indeterminate === 0) {
						return;
					} else {
						item[this.dataDisplayChild] && this.openToDimension(item[this.dataDisplayChild], indeterminate - 1, false);
					}
				});
			}
		},
	},
	created() {
		// 配置验证
		if ((this.dataAction || this.dataGraphql) && (this.dataLazy === true || this.dataLazy === "true") && !this.dataParentField) {
			console.error("没有定义data-parent-field,数据加载失败!");
			return;
		}
		if ((this.dataAction || this.dataGraphql) && (this.dataLazy === true || this.dataLazy === "true") && !this.dataChildField) {
			console.error("没有定义data-child-field,数据加载失败!");
			return;
		}
		if (this.dataParams) {
			this.params = typeof this.dataParams === "string" ? Tools.str2Json(this.dataParams) : this.dataParams === undefined ? {} : this.dataParams;
		}

		if (
			(this.dataAction || this.dataGraphql) &&
			(this.dataLazy === true || this.dataLazy === "true") &&
			!this.params.hasOwnProperty(this.dataParentField)
		) {
			console.error("data-params中没有定义data-parent-field属性，数据加载失败!");
			return;
		}

		if ((this.dataAction || this.dataGraphql) && !(this.dataLazy === true || this.dataLazy === "true") && !this.dataDiffcondition) {
			console.error("没有定义data-diffcondition参数,加载失败!");
			return;
		}

		if (this.dataAction && !(this.dataLazy === true || this.dataLazy === "true") && this.dataDiffcondition) {
			this.params.diffcondition = this.dataDiffcondition;
		}
		if (this.dataAutoLoad === true || this.dataAutoLoad === "true") {
			this.loadData();
		}
	},
};
</script>

<style lang="scss" scoped>
.k_field_tree {
	user-select: none;
	.tree_input {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// width: 34%;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		background: transparent;
		cursor: text;
		user-select: none;

		&:hover {
			border-color: #00a0e9;
		}

		.has_select {
			color: #2c3e50;
			width: 170px;
			overflow: hidden; // 溢出部分隐藏
			white-space: nowrap; // 文字不换行
			text-overflow: ellipsis; // 显示省略号
		}

		span {
			color: #666;
			margin-left: 20px;
		}

		i {
			color: #ddd;
			margin-right: 10px;
			margin-top: 4px;
			transition: all 0.25s ease;
			transform-origin: center center;
		}
	}

	.active {
		border-color: #00a0e9;

		i {
			transform: rotate(180deg);
			margin-top: -2px;
		}
	}
	.disabled {
		cursor: not-allowed;
	}
	.tree_content {
		position: relative;
		.select_content {
			width: 400px;
			height: 28px;
			line-height: 28px;
			color: #fff;
			padding-left: 10px;
			border-top: 1px solid #3c4659;
			border-bottom: 1px solid #3c4659;
			span {
				line-height: 28px;
				opacity: 0.5;
			}
			.clear_all {
				margin-left: 12px;
				color: #00a0e9;
				cursor: pointer;
			}
			&.has_select {
				span {
					opacity: 1;
				}
			}
		}
		.clear_searchval {
			position: absolute;
			top: 6px;
			right: 6px;
			color: #c5c5c5;
			padding: 3px;
			cursor: pointer;
		}
		.tree_btns {
			display: flex;
			height: 48px;
			align-items: center;
			justify-content: space-around;
			color: #f6f6f6;
			cursor: pointer;
			.flex {
				display: flex;
			}
			.svg-icon {
				width: 16px;
				height: 16px;
				margin-right: 4px;
			}
			.opento {
				position: relative;
				.opento_content {
					display: flex;
					flex-direction: column;
					position: absolute;
					top: 20px;
					left: 0;
					width: 88px;
					background: #2a2d3f;
					padding: 3px 0;
					text-align: center;
					line-height: 28px;
					color: #ddd;
					z-index: 2;
					.content_item {
						&:hover,
						&.active {
							background: rgba(255, 255, 255, 0.08);
						}
					}
				}
			}
			i {
				display: inline-block;
				margin: 0 6px;
			}
			.onlyShowSelect {
				color: #00a0e9;
				i {
					color: #00a0e9;
				}
			}
		}
		.tree {
			max-height: 422px;
			overflow-y: auto;
			max-width: 400px;
		}
		::-webkit-scrollbar-track {
			background: #2a2d3f;
		}
		::-webkit-scrollbar-thumb {
			background: #43465a;
		}
	}
	.no_data {
		width: 225px;
		line-height: 32px;
		color: #e5e5e7;
		text-align: center;
	}
}
::v-deep .el-popover {
	border: 1px solid rgba(72, 72, 73, 1);
	border-radius: 2px;
	// background: #33374d;
}
::v-deep .el-input__inner {
	border: none;
}
::v-deep .el-tree-node__content {
	min-height: 26px;
	height: auto;
	.name {
		color: #f6f6f6;
	}
	.code {
		font-size: 12px;
		color: #fff;
		opacity: 0.7;
	}
}
::v-deep .el-tree {
	max-height: 420px;
	overflow-y: auto;
}
.tree_item {
	display: flex;
	flex-direction: column;
	transition: all ease-in-out 0.3;
	.item {
		display: flex;
		align-items: center;
		.open_children_icon {
			.el-icon-caret-right {
				color: #f6f6f6;
				margin-right: 8px;
				transition: all ease-in-out 0.3;
			}
			&.isShowChildren {
				.el-icon-caret-right {
					transform: rotate(90deg);
				}
			}
		}
	}
	.checked_box.noChildren {
		margin-left: 22px;
	}
	.tree_item {
		margin-left: 12px;
	}
}

::v-deep .el-checkbox {
	display: flex;
	align-items: center;
	.checked_title {
		width: 250px;
		font-size: 14px;
		color: #f6f6f6;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		&:hover {
			color: #00a0e9;
		}
	}
	.code {
		width: 250px;
		font-size: 12px;
		color: #fff;
		opacity: 0.7;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.el-checkbox__inner {
		border: 1px solid #929293;
		border-radius: 2px;
	}
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: transparent;
	background-image: linear-gradient(90deg, #ffe6bf 0%, #e6b977 100%) !important;
}
::v-deep .el-checkbox__inner::after {
	border-color: #333;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
	background: #333;
}
::v-deep .el-checkbox__inner {
	transition: none;
}
</style>
