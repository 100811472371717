import NotFound from '@/frame/404.vue';
import Login from '@/frame/Login.vue';
import MainLayout from '@/frame/main/MainLayout.vue';
import Vue from 'vue';
import Router from 'vue-router';
// import DesignEngine from '@/pages/design/designEngine.vue'
import routerFlowable from '@/router/router-flowable.js';
import routerMa from '@/router/router-ma';
import routerCdp from '@/router/router-cdp';
import routerOperate from '@/router/router-operate';
import routerBasis from './router-basis';
import routerBa from '@/router/router-ba';
import { getMicroAppRoutes, HomePageRoute } from '@/micro-app/utils'


const originaPush = Router.prototype.push;
Router.prototype.push = function (location) {
	return originaPush.call(this, location).catch(err => err);
};

Vue.use(Router);

// 微前端环境处理
// const rootPathRoute = window.__POWERED_BY_WUJIE__ ? {
//   path: '/',
//   hidden: true,
// } : {
//   path: '/',
//   hidden: true,
//   redirect: {
//     path: '/login'
//   }
// };

const rootPathRoute = {
  path: '/',
  hidden: true,
  redirect: {
    path: '/login'
  }
};

const routes = [
  {
    path: '/login',
    component: Login,
    name: '',
    hidden: true
  },
  {
    path: '/404',
    component: NotFound,
    name: '',
    hidden: true
  },
  rootPathRoute,
  {
    path: '*',
    hidden: true,
    redirect: {
      path: '/404'
    }
  },
  // {
  //   path: '/lowCodeDesignEngine',
  //   component: DesignEngine,
  //   name: '',
  //   hidden: true
  // },
  // {
  //   path: "/desktop",
  //   name: "首0页",
  //   component: () => import('@/pages/desktop.vue')
  // },
  {
    path: '/backend-code',
    name: '后端代码生成',
    component: () => import('@/pages/develop/backend-code.vue')
  },
  {
    path: '/big-screen/design',
    name: '大屏设计器',
    component: () => import('data-room-ui/BigScreenDesign')
  },
  {
    path: '/big-screen/preview',
    name: '大屏预览-全屏',
    component: () => import('data-room-ui/BigScreenRun')
  },
  {
    path: '/dash-board/design',
    name: '仪表盘设计器',
    component: () => import('dashPackages/DashboardDesign')
  },
  {
    path: '/dash-board/preview',
    name: '仪表盘预览-全屏',
    component: () => import('dashPackages/DashboardRun')
  },
  {
    path: '/main',
    component: MainLayout,
    redirect: HomePageRoute,
    name: 'desktop',
    children: [
      {
        path: 'desktop/:code?',
        name: '驾驶舱',
        component: () => import('dashPackages/DashboardRun'),
        meta: {
          notKeepAlive: true,
          componentName: "code"
        }
      },
      {
        path: 'system/org',
        name: '机构管理',
        meta: {
          notKeepAlive: false,
          componentName: 'org'
        },
        component: () => import('@/pages/system/org.vue')
      },
      {
        path: 'system/menu4_1',
        name: '测试页面',
        component: () => import('@/pages/system/org.vue')
      },
      {
        path: 'system/dept',
        name: '部门管理',
        meta: {
          notKeepAlive: false,
          componentName: 'dept'
        },
        component: () => import('@/pages/system/dept.vue')
      },
      {
        path: 'system/dict',
        name: '字典管理',
        meta: {
          notKeepAlive: false,
          componentName: 'dict'
        },
        component: () => import('@/pages/system/dict.vue')
      },
      {
        path: 'system/system-params',
        name: '系统参数',
        meta: {
          notKeepAlive: false,
          componentName: 'system-params'
        },
        component: () => import('@/pages/system/system-params.vue')
      },
      {
        path: 'system/workday/work-day',
        name: '工作日管理',
        meta: {
          notKeepAlive: false,
          componentName: 'work-day'
        },
        component: () => import('@/pages/system/workday/work-day.vue')
      },
      {
        path: 'system/user',
        name: '用户管理',
        meta: {
          notKeepAlive: false,
          componentName: 'user'
        },
        component: () => import('@/pages/system/user.vue')
      },
      {
        path: 'system/legal',
        name: '法人管理',
        meta: {
          notKeepAlive: false,
          componentName: 'legal'
        },
        component: () => import('@/pages/system/legal.vue')
      },
      {
        path: 'system/role/role',
        name: '角色管理',
        meta: {
          notKeepAlive: false,
          componentName: 'role'
        },
        component: () => import('@/pages/system/role/role.vue')
      },
      {
        path: 'system/announce',
        name: '公告管理',
        meta: {
          notKeepAlive: false,
          componentName: 'announce'
        },
        component: () => import('@/pages/system/announce.vue')
      },
      {
        path: 'system/op-log',
        name: '操作日志',
        meta: {
          notKeepAlive: false,
          componentName: 'op-log'
        },
        component: () => import('@/pages/system/op-log.vue')
      },
      {
        path: 'system/sys-auth-set',
        name: '授权设置',
        meta: {
          notKeepAlive: false,
          componentName: 'sysAuthSet'
        },
        component: () => import('@/pages/system/auth-set/sys-auth-set.vue')
      },
      {
        path: 'develop/backend-code',
        name: '后端代码生成',
        meta: {
          notKeepAlive: false,
          componentName: 'backend-code'
        },
        component: () => import('@/pages/develop/backend-code.vue')
      },
      {
        path: 'big-screen-list',
        name: '大屏管理',
        meta: {
          notKeepAlive: false,
          componentName: 'big-screen-list'
        },
        component: () => import('data-room-ui/BigScreenMag')
      },
      {
        path: 'dash-board-list',
        name: '仪表盘管理',
        meta: {
          notKeepAlive: false,
          componentName: 'dash-board-list'
        },
        component: () => import('dashPackages/DashboardMag')
      },
      {
        path: 'big-screen/preview',
        name: '大屏预览',
        meta: {
          notKeepAlive: false,
          componentName: 'BigScreenRun'
        },
        component: () => import('data-room-ui/BigScreenRun')
      },
      {
        path: 'dash-board/preview',
        name: '仪表盘预览-全屏',
        meta: {
          notKeepAlive: false,
          componentName: 'DashboardRun'
        },
        component: () => import('dashPackages/DashboardRun')
      },
      {
        path: "system/menu",
        name: "菜单配置",
        meta: {
          notKeepAlive: false,
          componentName: 'menuComponent'
        },
        component: () => import('@/pages/system/menu.vue')
      },
      {
        path: 'dynamic/:code?',
        name: '动态大屏',
        component: () => import('dashPackages/DashboardRun'),
        meta: {
          notKeepAlive: true,
          componentName: "DashboardRun"
        }
      },
      {
        path: 'system/authConfig',
        name: '数据权限',
        meta: {
          notKeepAlive: false,
          componentName: 'AuthConfig'
        },
        component: () => import('@/pages/system/authConfig/index.vue')
      },
      {
        path: 'system/position',
        name: '岗位管理',
        meta: {
          notKeepAlive: false,
          componentName: 'Position'
        },
        component: () => import('@/pages/system/position/index.vue')
      },
      ...routerFlowable,
      ...routerMa,
      ...routerCdp,
      ...routerOperate,
      ...routerBasis,
      ...routerBa,
    ]
  }
];

const index = routes.findIndex(item => item.path === "/main")
if(index > -1) {
  routes[index].children = routes[index].children.concat(getMicroAppRoutes())
} else {
  console.error('这是个不可忽视的错误，路由配置错误，未找到/main')
}

export default new Router({
  routes
});
