<template>
	<el-upload
		class="upload-demo"
		ref="upload"
		:value="value"
		:headers="headers"
		:action="_dataUploadUrl"
		:disabled="dataDisabled === true || dataDisabled === 'true'"
		:limit="dataLimit"
		:drag="dataDrag === true || dataDrag === 'true'"
		:list-type="dataType"
		:accept="dataAccept"
		:multiple="dataMultiple === true || dataMultiple === 'true'"
		:data="extraData"
		:auto-upload="dataAutoUpload === true || dataAutoUpload === 'true'"
		:on-preview="dataPreview"
		:on-remove="dataRemove"
		:on-success="_dataSuccess"
		:on-error="dataError"
		:on-progress="dataProgress"
		:before-upload="dataBeforeUpload"
		:before-remove="dataBeforeRemove"
		:on-exceed="onExceed"
		:on-change="dataOnChange"
	>
		<i class="el-icon-upload" v-if="dataDrag"></i>
		<div class="el-upload__text" v-if="dataDrag">将文件拖到此处，或<em>点击上传</em></div>
		<k-btn class="md-success" v-else>
			<md-icon md-src="/static/svg/upload.svg" />
			上传附件
		</k-btn>
	</el-upload>
</template>

<script>
import props from "@/components/k-element/common/k-field-props.js";
import event from "@/components/k-element/common/k-field-event.js";
import emitter from "@/components/k-element/common/k-emitter.js";
import auth from "@/utils/auth.js";
import Tools from "@/utils/tools.js";

export default {
	name: "KFieldUpload",
	mixins: [props(), event(), emitter()],
	data() {
		return {
			uploadSuccessList: [],
			extraData: {},
			headers: {
				Authorization: auth.getToken(),
			},
		};
	},
	props: {
		dataUploadUrl: {
			type: String,
			default: "base/comn-upload.json",
		},
		dataType: {
			type: String,
			default: "text",
		},
		dataDrag: {
			type: [Boolean, String],
			default: true,
		},
		dataAccept: String,
		dataDisabled: {
			type: [Boolean, String],
			default: false,
		},
		dataLimit: {
			type: Number,
			default: 3,
		},
		dataMultiple: {
			type: [Boolean, String],
			default: true,
		},
		dataAutoUpload: {
			type: [Boolean, String],
			default: false,
		},
		dataPreview: {
			type: Function,
		},
		dataRemove: {
			type: Function,
		},
		dataSuccess: {
			type: Function,
		},
		dataError: {
			type: Function,
		},
		dataProgress: {
			type: Function,
		},
		dataBeforeUpload: {
			type: Function,
		},
		dataBeforeRemove: {
			type: Function,
		},
		dataExceed: {
			type: Function,
		},
		dataOnChange: {
			type: Function,
		},
	},
	computed: {
		_dataUploadUrl() {
			return this.httpUtil.basePath + this.dataUploadUrl;
		},
	},
	methods: {
		//外部可调用函数
		//调用方式: this.$refs.xx.method()
		upload(params) {
			//上传函数
			this.extraData = params;
			this.$nextTick(() => {
				this.$refs.upload.submit();
			});
		},
		onExceed() {
			Tools.alert(`超出上传文件的条数限制，只能上传${this.dataLimit}条数据`, "danger");
		},
		getSuccessUpload() {
			//获取成功上传的文件列表
		},
		doReset() {
			//重置上传控件，清空上传列表
			this.uploadSuccessList = [];
			this.extraData = {};
			this.$refs.upload.clearFiles();
		},
		abort(file) {
			//取消上传
			this.$refs.upload.abort(file);
		},
		_dataSuccess(response, file, fileList) {
			this.uploadSuccessList = fileList;

			if (response.success === true) {
				Tools.alert(response.returnmsg ? response.returnmsg : "上传成功!");
				if (this.dataSuccess) {
					this.dataSuccess(file, fileList);
				}
				this.$emit("input", fileList);
			} else {
				Tools.alert(response.returnmsg ? response.returnmsg : "上传失败!", "danger");
				this.$emit("data-on-error");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {

}
</style>
