<template>
	<el-table-column
		:fixed="dataFixed"
		:prop="dataName"
		:label="dataHeader"
		:sortable="dataSortable === 'true' || dataSortable === true ? 'custom' : false"
		:sort-method="dataSortMethod"
		v-if="dataHidden === 'false' || dataHidden === false"
		:width="dataWidth"
		:min-width="dataMinWidth"
		:align="dataAlign"
		:show-overflow-tooltip="dataOverflow">
		<template v-if="!$slots.default || $slots.default.length == 0 || $slots.default[0].componentOptions.tag != 'k-grid-column'" slot-scope="{ row }">
			<div v-if="dataRender" v-html="htmlRender(row)" class="ellipsis-line" />
			<div v-else-if="$scopedSlots.default && (!controlSlotContent || row.slotOpen)">
				<slot :row="{ row }"></slot>
			</div>
			<template v-else-if="isHasManyName(dataName)">
				<span>
					{{ showName(dataName, row) }}
				</span>
			</template>
			<template v-else-if="isHasMoreName(dataName)">
				<span>
					{{ showMoreName(dataName, row) }}
				</span>
			</template>
			<span v-else>{{ format(row) }}</span>
		</template>
		<slot v-if="$slots.default && $slots.default.length > 0 && $slots.default[0].componentOptions.tag == 'k-grid-column'"></slot>
	</el-table-column>
</template>

<script>
import moment from 'moment';
import emitter from '@/components/k-element/common/k-emitter.js';
import Tools from '../../../utils/tools';
import Decimal from 'decimal.js';
const RENDER_PLACEHOLDER_REG = /#{}/g;

export default {
	name: 'KGridColumn',
	mixins: [emitter()],
	props: {
		controlSlotContent: {
			type: Boolean,
			default: false
		},
		dataHeader: {
			type: String,
			default: ''
		},
		dataName: {
			type: String,
			default: ''
		},
		dataSortable: {
			type: [Boolean, String],
			default: false
		},
		dataHidden: {
			type: [Boolean, String],
			default: false
		},
		dataFixed: {
			type: [Boolean, String],
			default: false
		},
		dataSortMethod: {
			type: Function
		},
		dataWidth: {
			type: String,
			default: ''
		},
		dataMinWidth: {
			type: String,
			default: ''
		},
		dataAlign: {
			type: String,
			default: 'left'
		},
		dataOverflow: {
			type: [Boolean, String],
			default: true
		},
		dataRender: {
			type: String
		},
		dataDict: {
			type: String
		},
		dataType: {
			type: String
		},
		renderFunction: {
			type: Function
		},
		dataAction: {
			type: String
		}
	},
	created() {
		if (!this.$slots.default || this.$slots.default.length == 0 || this.$slots.default[0].componentOptions.tag != 'k-grid-column') {
			// console.info(this);
		}
	},
	data() {
		return {};
	},
	methods: {
		setOverFlow() {
			if (this.dataOverflow == true || this.dataOverflow == 'true') {
				return true;
			} else {
				return false;
			}
		},
		format(row) {
			if (!row) {
				return '';
			}
			// 格式化的顺序 dataRender dataType
			if (this.dataRender) {
				return this.getParent('KGrid').$parent[this.dataRender](row);
			}
			return this.doFormat(row);
		},
		htmlRender(row) {
			let config = {};
			config.dataName = this.dataName;
			if (!this.dataRender) {
				console.error('请检查data-render配置。');
				return;
			}

			let com = this.getParent('KGrid');
			let html = '';
			if (com.$parent[this.dataRender]) {
				html = com.$parent[this.dataRender](row, config);
			} else if (com.$parent.$parent[this.dataRender]) {
				html = com.$parent.$parent[this.dataRender](row, config);
			} else {
				html = com.$parent.$parent.$parent[this.dataRender](row, config);
			}

			// let html = this.getParent("KGrid").$parent[this.dataRender](row,config);

			if (this.dataDict || this.dataType) {
				return html.replace(RENDER_PLACEHOLDER_REG, this.doFormat(row));
			} else {
				return html;
			}
		},
		doFormat(row) {
			let val = row[this.dataName];
			if (this.dataDict) {
				if (this.getParent('KGrid') == undefined) {
					if (this.dataType === 'dictKeyVal') {
						let keyArr = val.split(',');
						val = this.getParent('KGridSimple').dictTransfer(this.dataDict, val);
						let valueArr = val.split('，');
						if (valueArr.length) {
							valueArr = valueArr.map((value, index) => {
								if (value) {
									return keyArr[index] + '-' + value;
								}
							});
						}
						val = valueArr.join('，');
					} else {
						val = this.getParent('KGridSimple').dictTransfer(this.dataDict, val);
					}
				} else {
					if (this.dataType === 'dictKeyVal') {
						let keyArr = val.split(',');
						val = this.getParent('KGrid').dictTransfer(this.dataDict, val);
						let valueArr = val.split('，');
						if (valueArr.length) {
							valueArr = valueArr.map((value, index) => {
								if (value) {
									return keyArr[index] + '-' + value;
								}
							});
						}
						val = valueArr.join('，');
					} else {
						val = this.getParent('KGrid').dictTransfer(this.dataDict, val);
					}
				}
			} else if (this.dataAction) {
				val = this.getParent('KGrid').columnDataActionTransfer(this.dataAction, val);
			} else {
				if (this.dataType) {
					if (this.dataType === 'date') {
						val = Tools.formatDate(val);
					}
					if (this.dataType === 'datetime') {
						val = Tools.formatDateTime(val.substr(0,8),val.substr(8));
					}
					if (this.dataType === 'time') {
						val = Tools.formatTime(val);
					}
					if (this.dataType === 'money') {
						val = Tools.formatMoney(val);
					}
					if (this.dataType === 'timestamp' && val) {
						val = moment(val).format('YYYY-MM-DD HH:mm:ss');
					}
					if (this.dataType === 'integerMoney') {
						val = Tools.formatIntegerMoney(val);
					}
					if (this.dataType === 'percentage') {
						val = val ? Decimal(val).mul(Decimal(100)).toNumber() + '%' : '0%';
					}
					if (this.dataType === 'keyValue') {
						val = this.dataName + '-' + val;
					}
					if (this.dataType === 'valueAndValue') {
						let valueArr = this.dataName.split(',');
						if (valueArr.length > 2) {
							console.error('请检查data-name的配置。');
						}
						val = row[valueArr[0]] + '-' + row[valueArr[1]];
					}
				}
			}
			return val;
		},
		doActionFormat(row) {
			this.httpUtil
				.comnQuery({
					action: this.dataAction
				})
				.then(data => {
				});
		}
	},
	computed: {
		isHasManyName() {
			return name => {
				return name.includes(',');
			};
		},
		showName() {
			return (name, row) => {
				let str = '';
				const arr = name.split(',');
				arr.forEach((item, index) => {
					if (index === arr.length - 1) {
						str += row[item];
					} else {
						str += row[item] + '-';
					}
				});
				return str;
			};
		},
		isHasMoreName() {
			return name => {
				return name.includes(';');
			};
		},
		showMoreName() {
			return (name, row) => {
				let arr = name.split(';');
				return row[arr[1]] || row[arr[0]] || '';
			};
		}
	}
};
</script>
