var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-component-button"},[_c('el-button',{style:({
      backgroundColor: _vm.config.customize.type ? null : _vm.config.customize.backgroundColor,
      borderColor: _vm.config.customize.borderStyle.borderColor,
      borderWidth: _vm.config.customize.borderStyle.borderWidth + 'px',
      borderStyle: _vm.config.customize.borderStyle.borderStyle,
      borderRadius: _vm.config.customize.borderStyle.borderRadius + 'px'
    }),attrs:{"type":_vm.config.customize.type,"round":_vm.config.customize.isRound,"plain":_vm.config.customize.isPlain,"loading":_vm.config.customize.isLoading,"icon":_vm.config.customize.icon.position === 'left' ? _vm.config.customize.icon.name : ''},on:{"click":_vm.handleClick}},[_c('span',{style:({ color: _vm.config.customize.fontColor, fontSize: _vm.config.customize.fontSize + 'px', })},[_vm._v("\n      "+_vm._s(_vm.config.title)+"\n      "),(_vm.config.customize.icon.position === 'right' && _vm.config.customize.icon.name)?_c('i',{class:_vm.config.customize.icon.name}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }