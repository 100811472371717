<template>
  <div class="box-card k-form-search" style="overflow: visible;position: unset">
    <!-- <md-card-header class="md-card-header-text md-card-header-green" style="margin-right: 0;">
      <div class="search-header">
        <div class="card-icon" :style="iconStyle">
          <md-icon md-src="/static/svg/form.svg"></md-icon>
        </div>
        <div>
          <i class="el-icon-d-caret" @click="show"></i>
        </div>
      </div>
    </md-card-header> -->

    <div slot="header" class="clearfix" style="text-align:right">

    </div>
    <div class="show-form" :id="uuid + '-show-form'" style="margin: 30px 0;">
      <k-form ref="searchForm" :data-col="0">
        <slot></slot>
      </k-form>

      <div :id="uuid + '-showFormBtn'" style="text-align: center;">
        <k-btn class="md-primary" :data-handler="query">
          <i class="el-icon-search"></i>查 询
        </k-btn>
        <k-btn class="md-plain" data-functype="RESET" data-type="warning" data-from="searchForm">
          <i class="el-icon-refresh"></i>重 置
        </k-btn>
      </div>
    </div>


    <div class="k-form-search-footer" ref="btnRef">
      <slot  name="button"></slot>
    </div>

  </div>
</template>

<script>
  import KBtn from "../k-btn/k-btn";
  import emitter from "@/components/k-element/common/k-emitter.js";
  import props from "@/components/k-element/common/k-field-props.js";
  import { debounce } from 'lodash'
  import Tools from '@/utils/tools'
  export default {
    name: 'kFormSearchCustomize',
    mixins: [props(), emitter()],
    components: {
      KBtn
    },
    props: {
      dataTarget: {
        type: String
      }
    },
    data() {
      return {
        extends: true,
        btnChildDom: {
          dom: null,
          len: 0
        },
        uuid: Tools.uuid()
      }
    },
    computed: {
      iconStyle() {
        let iconStyle = {};
        iconStyle.background = this.$store.state.system.cardBackground
        return iconStyle;
      }
    },
    mounted () {
      this.searchBtnFunc()
      window.addEventListener('resize', debounce(this.searchBtnFunc, 100));

    },
    beforeDestroy () {
      window.removeEventListener('resize', debounce(this.searchBtnFunc, 100));
    },
    methods: {
      searchBtnFunc() {
        if (!this.$refs.searchForm || !this.$refs.searchForm.$el) {
          return
        }
        // 当前屏幕宽度
        let width = this.$refs.searchForm.$el.offsetWidth
        let maxWidth = width - 365
        const kFormBodyElement = this.$refs.searchForm.$el.childNodes[0].childNodes

        // 删除上一次渲染的 `${this.uuid}-kFomrSearchCustomId` 占位组件

        let btnDom = document.getElementById(`${this.uuid}-kFomrSearchCustomId`)
        if(document.getElementById(`${this.uuid}-kFomrSearchCustomId`)) {
          btnDom.remove()
        }

        // 判断是否需要换行
        let wrapFlag = false
        let flagIndex = -1
        for (let index = 0; index < kFormBodyElement.length; index++) {
          let item = kFormBodyElement[index]
          if(maxWidth > 0 && item.nodeType === 1) {
            let itemNodeStyle = window.getComputedStyle(item, null)
            let currentWidht = parseInt(itemNodeStyle.marginLeft) + parseInt(itemNodeStyle.marginRight) + item.offsetWidth
            maxWidth = maxWidth - currentWidht
            if((index === kFormBodyElement.length - 2) && maxWidth < 0) {
              wrapFlag = true
              flagIndex = index + 1
            }
          } else if(wrapFlag === false && item.nodeType === 1) {
            wrapFlag = true
            flagIndex = index
          }
        }
        // 缓存按钮DOM
        let btnNode = document.getElementById(`${this.uuid}-showFormBtn`)
        if(this.btnChildDom.dom === null) {
          this.btnChildDom.dom = btnNode.cloneNode(true)
        } else {
          btnNode = this.btnChildDom.dom
        }


        if(wrapFlag) {    // 换行
          // 创建站位DOM
          let btnContenter = document.createElement('div')
          btnContenter.classList.add('kFomrSearchCustom')
          btnContenter.setAttribute("id",`${this.uuid}-kFomrSearchCustomId`)
          // 添加站位元素
          if(flagIndex-3 < 0) {
            this.$refs.searchForm.$el.childNodes[0].childNodes[0].before(btnContenter)
          } else {
            this.$refs.searchForm.$el.childNodes[0].childNodes[flagIndex-3].after(btnContenter)
          }

          let targetNode = document.getElementById(`${this.uuid}-kFomrSearchCustomId`);
          if(document.getElementById(`${this.uuid}-showFormBtn`)) {
            document.getElementById(`${this.uuid}-showFormBtn`).remove()
          }
          targetNode.appendChild(btnNode);

          if(document.getElementById(`${this.uuid}-showFormBtn`)) {
            document.getElementById(`${this.uuid}-showFormBtn`).classList.remove('show-form-btn')    // 移除按钮单行class
            document.getElementById(`${this.uuid}-showFormBtn`).classList.add('show-form-btnMultiLine')   // 添加按钮多行class
          }
        } else {
          if(document.getElementById(`${this.uuid}-showFormBtn`)) {
            document.getElementById(`${this.uuid}-showFormBtn`).classList.add('show-form-btn')
          } else {
            document.getElementById(`${this.uuid}-show-form`).appendChild(btnNode);
            document.getElementById(`${this.uuid}-showFormBtn`).classList.add('show-form-btn')
          }
        }
      },
      query() {
        this.$emit("click")
        if (!this.dataTarget) {
          console.error("k-from-search需要指定data-target.")
        }
        let target = this.getParentRef(this.dataTarget);
        if (target && target.$options.name === "KGrid") {
          let params = {};

          for (let field in this.value) {
            let valueElement = this.value[field];
            if (valueElement) {
              params[field] = valueElement;
            }
          }

          let re = this.$refs.searchForm.validate();
          if (re === false) {
            return;
          }

          target.load(params)
        } else if(target && target.$options.name === "KReport"){
          let params = {};

          for (let field in this.value) {
            let valueElement = this.value[field];
            if (valueElement) {
              params[field] = valueElement;
            }
          }

          let re = this.$refs.searchForm.validate();
          if (re === false) {
            return;
          }

          this.$emit("loadDataForTable",params)
        } else {
          console.error("data-target不存在或data-target不是KGrid组件.")
        }
      },
      show() {
        let e = document.getElementById(`${this.uuid}-show-form`)
        if (this.extends) {
          e.style.display = "none"
        } else {
          e.style.display = ""
        }
        this.extends = !this.extends
      },

    }

  }
</script>
