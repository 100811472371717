<template>
  <div class="top-tab" id="top-tab" :data-color="activeColor">
    <div class="tab-left" id="tab-left">
      <div class="tab" id="tab">
        <draggable v-model="tabDataArr" :move="onMove" group="site" filter=".forbid"
                   animation="300"
                   dragClass="dragClass" ghostClass="ghostClass"
                   chosenClass="chosenClass" class="tabContainer">
          <transition-group>
            <div class="item"
                 :class="{ 'item-selected': item.active,'forbid': index === 0, 'selected_befor': getLastIndex(index)-2 === index, 'selected_after': getLastIndex(index) === index }"
                 v-for="(item, index) in tabDataArr"
                 @click="clickTab(item, index)" :key="item.path">

              <drop-down :ref="'dropDown'+ index" direction="down" toggle-type="contextmenu"
                         @isOpenChange="isOpenChange(index,$event)">
                <div class="tabContent">
                  <div class="vertical-line" :class="{ 'line-behind':  getLastIndex(index) === index || index === 0}">
                    |
                  </div>
                  <el-tooltip popper-class="darktips" effect="dark" :content="item.name" placement="bottom-end" :disabled="item.name.length < 11">
                    <span class="item-name"> {{ item.name }}</span>
                  </el-tooltip>
                  <div class="close" v-if="index != 0" @click.stop="removeTab(item, index)">×</div>
                </div>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a @click.stop="flashCurrentTabByIndex(index)" href="javascript:void(0)">刷新当前页签</a>
                  </li>
                  <li><a @click.stop="closeAllTab(index)" href="javascript:void(0)">关闭所有页签</a></li>
                  <li><a v-if="index != 0" @click.stop="closeOtherTabByIndex(item,index)"
                         href="javascript:void(0)">关闭其他页签</a>
                  </li>
                </ul>
              </drop-down>
            </div>
          </transition-group>
        </draggable>
        <div class="itemLast"></div>
        <div class="item-tip" v-show="mousePosition.show && a" :style="mousePosition.style">
          <span>{{ mousePosition.showText }}</span>
        </div>
      </div>
      <div class="down" id="down">
        <el-popover width="164" trigger="click" style="padding: 0;" popper-class="top-more-menu" :visible-arrow="false"
                    v-model="showTab2" placement="bottom-start">
          <ul class="more">
            <li style="color: #fbfbfb" @click="closeCurrentTab">关闭当前选项</li>
            <li style="color: #fbfbfb" @click="closeAllTab">关闭所有选项</li>
            <li style="color: #fbfbfb" @click="closeOtherTab">关闭其他选项</li>
            <li class="fg" style="margin:10px 0;width:100%;background:#3C4359" v-show="tab2.length > 0"></li>
            <div class="item" style="height:200px;">
              <li v-for="(item, index) in tab2" @click="clickTab(item, index)"
                :class="{ 'route-active': isActive(item) }" style="padding: 5px 10px 5px 10px;height:36px;align-items: center;" :key="index">
                <!-- <span class="gou">√</span> -->
                <span class="name">{{ item.name }}</span>
                <span class="close" @click.stop="removeTab(item, index)">×</span>
              </li>
            </div>
          </ul>
          <img slot="reference" src="../../assets/svg/k-menu/down2.svg"/>
        </el-popover>
      </div>

    </div>
    <div class="tab-toolbar">
      <top-navbar-dark/>
    </div>

  </div>
</template>

<script>
import TopNavbarDark from './TopNavbarDark.vue'
import draggable from 'vuedraggable';
import Tools from "@/utils/tools";

export default {
  name: "TopTab",
  components: {
    TopNavbarDark,
    draggable
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
    }
  },
  data() {
    return {
      currentIndex: 0,
      showTab2: false,
      showClose: false,
      allMenus: [],
      mousePosition: {
        time: 0,
        show: false,
        showText: "",
        style: {
          left: "0px",
          top: "0px"
        },
      },
      a: false,
      timer: null,
      tabState: {
        oldTime: 0,
        time: 0
      },
      lastIndex: -1,
      menuWidth: 170,
      tabCacheMap: {}, // 组件缓存地址存放
      drowDownList: [],
      menusInterval: {}
    }
  },
  computed: {
    tabDataArr: {
      get() {
        let tabs;
        let data = this.$store.state.system.tab;
        tabs = typeof data === 'string' ? JSON.parse(data) : data;
        tabs[0]['icon'] = "desktop"
        return tabs;
      },
      set(value) {
        this.$store.commit("system/setTab", value);
      },
    },
    tab2() {
      let tabs;
      let data = this.$store.state.system.tab2;
      tabs = typeof data === 'string' ? JSON.parse(data) : data;
      return tabs;
    }
  },
  watch: {
    tab2(newVal) {
      if (newVal.length == 0) {
        // this.showTab2=false
      }
    },
    //监听路由的变化来实现添加或切换tab
    '$route': function (to, from) {
      // 点击tab2或者新加页面, 都作为新加页面处理
      let flag = false;   // true 该tab已经存在   false 新tab
      let index = 0;

      // 设置active 状态
      for (let i = 0; i < this.tabDataArr.length; i++) {
        if (this.tabDataArr[i].active) {
          index = i;
        }
        if (this.tabDataArr[i].path === to.path) {
          if (this.tabDataArr[i].fullPath !== to.fullPath) {
            this.removeCache(to.fullPath);
          }
          flag = true;
          this.$store.commit("system/setTabActive", {index: i, active: true});
        } else {
          this.$store.commit("system/setTabActive", {index: i, active: false});
        }
      }

      let targetNode;
      for (let i = 0; i < this.tab2.length; i++) {
        if (this.tab2[i].path === to.path) {
          if (this.tabDataArr[i]?.fullPath !== to.fullPath) {
            this.removeCache(to.fullPath);
          }
          flag = true;
          targetNode = this.tab2[i];
          targetNode.active = true;
          this.$store.commit("system/setTab2Splice", {index: i, count: 1});
        } else {
          this.$store.commit("system/setTab2Active", {index: i, active: false});
        }
      }

      if (!flag && to.path !== '/main/flushPage') {
        // 能进这个方法说明是新加的页签,即tab和tab2集合中没有该页签
        targetNode = {
          path: to.path,
          fullPath: to.fullPath,
          name: to.name,
          query: to.query,
          active: true,
          meta: to.meta,
          beforeRemoveEvent: to.beforeRemoveEvent
        };

        // 查找标签icon
        this.allMenus.every((item) => {
          if (item.url && to.path.indexOf(item.url) > -1) {
            targetNode['icon'] = item.icon;
            // if (!targetNode['name']) {
              targetNode['name'] = item.menuname;
            // }
            targetNode['parentIcon'] = item['parentIcon'];
            return false;
          } else {
            return true;
          }
        })
      }

      if (targetNode && to.path !== '/main/flushPage') {
        this.flashTab(targetNode, index);
      }

      this.handlerTimer(to.path,from.path);
    },

  },
  created() {
    this.getAllMenu()
  },
  mounted() {
    window.onresize = () => {
      this.setTopTab()
    }
    // this.mouseFlow()
    // 绑定缓存添加事件
    this.$bus.$on('tabCacheAdd', (cache) => {
      this.tabCacheMap = cache;
    });
    // 绑定删除缓存事件
    this.$bus.$on('tabCacheClear', (fullPath) => {
      this.removeCache(fullPath);
    });
    // 页面跳转, 附带确认方式
    this.$bus.$on('openPage', (path, param, confirmMsg) => {
      let tab;
      let isTabShow = true;
      let index;
      for(let i = 0; i < this.tabDataArr.length; i++) {
        let item = this.tabDataArr[i];
        if (item.path === path) {
          tab = item;
          index = i;
        }
      }

      for (let i = 0; i < this.tab2.length; i++) {
        let item = this.tab2[i];
        if (item.path === path) {
          tab = item;
          isTabShow = false;
          index = i;
        }
      }

      let uri = path + "?" + this.encodeSearchParams(param);

      let _this = this;

      if (!tab || tab.fullPath === uri || tab.fullPath.replaceAll(",","%2C") === uri) {
        route(path,param,false);
        return;
      }

      if (confirmMsg) {
        Tools.confirm(() => {
          route(path, param,true);
        }, confirmMsg);
      } else {
        route(path, param,true);
      }

      function route(path, param,removeCache) {
        if (removeCache) {
          for (let k in _this.tabCacheMap) {
            if (k && k.startsWith(path)) {
              _this.removeCache(k);
            }
          }
          if (index) {
            if (isTabShow) {
              _this.$store.commit("system/setTabSplice", {index: index, count: 1});
            } else {
              _this.$store.commit("system/setTab2Splice", {index: index, count: 1});
            }
          }
        }
        _this.$router.push({path, query: param});
      }
    });
    // 页面关闭并跳转新的页面
    this.$bus.$on('closePage', (oldPath, newPath, newPathParam) => {
      for (let i = 0; i < this.tabDataArr.length; i++) {
        let item = this.tabDataArr[i]
        if (item.path === oldPath) {
          for (let k in this.tabCacheMap) {
            if (k && k.startsWith(oldPath)) {
              this.removeCache(k);
            }
          }
          this.$store.commit("system/setTabSplice", {index: i, count: 1});
        }
      }
      for (let i = 0; i < this.tab2.length; i++) {
        for (let i = 0; i < this.tab2.length; i++) {
          let item = this.tab2[i]
          if (item.path === oldPath) {
            for (let k in this.tabCacheMap) {
              if (k && k.startsWith(oldPath)) {
                this.removeCache(k);
              }
            }
            this.$store.commit("system/setTabSplice", {index: i, count: 1});
          }
        }
      }
      if (newPath) {
        if (!newPathParam) {
          newPathParam = {};
        }
        this.$router.push({
          path: newPath,
          query: newPathParam
        });
      }
    });
    // 页面定时器方法绑定，
    this.$bus.$on("intervalSet",(name,f,s) => {
      if (!name || !f) {
        Tools.alert('定时器的名称和方法不能为undefined', 'danger');
      }
      let path = this.$route.path;
      if (!this.menusInterval[path]) {
        this.menusInterval[path] = {};
      }
      let item = this.menusInterval[path];
      if (item[name] && item[name].ik) {
        clearInterval(item[name].ik);
      }
      if (!s) {
        s = 3000;
      }
      let intervalKey = setInterval(f,s);
      this.menusInterval[path][name]= {
        fn: f,
        ik: intervalKey,
        s: s
      };
    });
    this.$bus.$on("destoryInterval",name => {
      if (!name) {
        Tools.alert('定时器的名称不能为undefined', 'danger');
      }
      let path = this.$route.path;
      let item = this.menusInterval[path];
      if (!item || !item[name]) {
        return;
      }
      clearInterval(item[name].ik);
    });
  },


  beforeDestroy() {
    this.tabCacheMap = null;
    this.$bus.$off('tabCacheAdd');
    this.$bus.$off('tabCacheClear');
    this.$bus.$off('openPage');
    this.$bus.$off('closePage');
    this.$bus.$off('intervalSet');
    this.$bus.$off('destoryInterval');
  },
  methods: {
    handlerTimer(toPath, fromPath){
      if (fromPath && this.menusInterval[fromPath]) {
        for (let key in this.menusInterval[fromPath]) {
          let item = this.menusInterval[fromPath][key];
          clearInterval(item.ik);
        }
      }
      if (toPath && this.menusInterval[toPath]) {
        for (let key in this.menusInterval[toPath]) {
          let item = this.menusInterval[toPath][key];
          clearInterval(item.ik);
          item.ik = setInterval(item.fn, item.s);
        }
      }
    },
    encodeSearchParams(obj) {
      const params = []

      Object.keys(obj).forEach((key) => {
        let value = obj[key]
        // 如果值为undefined我们将其置空
        if (value !== undefined) {
          // 对于需要编码的文本（比如说中文）我们要进行编码
          if (value === null) {
            params.push(key);
          } else {
            params.push([key, encodeURIComponent(value)].join('='));
          }
        }

      })

      return params.join('&')
    },
    isOpenChange(val, isOpen) {
      if (!isOpen) {
        return;
      }
      for (let i = 0; i < this.drowDownList.length; i++) {
        let index = this.drowDownList[i];
        if (val !== index) {
          this.closeDropDown(index);
        }
      }
      this.drowDownList = [];
      this.drowDownList.push(val);
    },
    onMove(e) {
      if (e.relatedContext.index === 0) {
        return false;
      }
    },
    updateTabPosition(e) {
      this.$store.commit("system/setTab", this.tabDataArr);
    },
    flashCurrentTabByIndex(index) {
      let t = this.tabDataArr[index];
      let path = t.path;
      let query = t.query;

      this.removeCache(t.fullPath ? t.fullPath : t.path);

      if (!t.active) {
        this.$router.push(
          {
            path: path,
            query: query
          }
        );
      } else {
        // 时间戳
        let time = Date.parse(new Date());
        this.$store.commit("system/setTimeNum", time);
        this.$router.push({
          path: '/main/flushPage',
          query: {
            targetPath: t.path,
            targetQuery: t.query,
            timeNum: time
          }
        })
      }
      this.removeCache('/main/flushPage');
      this.closeDropDown(index);
    },
    closeDropDown(index) {
      this.$refs['dropDown' + index] && this.$refs['dropDown' + index][0] && this.$refs['dropDown' + index][0].closeDropDown();
    },
    flashTab(targetNode, index) {
      if ((index + 1) === this.tabDataArr.length) {
        this.$store.commit("system/setTabPush", targetNode);
      } else {
        let arr = [];
        arr = this.tabDataArr.slice(0, index + 1).concat(targetNode, this.tabDataArr.slice(index + 1, this.tabDataArr.length));
        this.$store.commit("system/setTab", arr);
      }
      this.$nextTick(() => {
        this.setTopTab(index + 1);
      });
    },
    getAllMenu() {
      this.httpUtil.query({
        url: "sys/findMenus.json"
      }).then(data => {
        this.setAllMenus(data.rows)
      });
    },
    showTabTip(name) {
      this.mousePosition.showText = name
      if (this.mousePosition.show == false) {
        this.mousePosition.show = true
        setTimeout(() => {
          this.timer = setInterval(() => {
            if ((new Date().getTime() - this.mousePosition.time) < 800) {
              this.a = false
            } else {
              this.a = true
            }
          }, 200)
        }, 500)
      } else {

      }

    },
    hideTabTip() {
      this.mousePosition.show = false
      clearInterval(this.timer)
      this.a = false
    },
    setAllMenus(arr, parentIcon) {
      arr.forEach((item) => {
        if (parentIcon) {
          item["parentIcon"] = parentIcon
        }
        this.allMenus.push(item)
        if (item.children) {
          this.setAllMenus(item.children, item.icon)
        }
      })
    },
    isActive(item) {
      if (item.path == this.$route.path && JSON.stringify(item.query) == JSON.stringify(this.$route.query)) {
        return true
      } else {
        return false
      }
    },
    dblclickTab(item) {
      if (item.active) {
        this.tabState.flag = false
      } else {
        this.tabState.flag = true
      }
    },
    clickTab(tab, index) {
      if (tab.active) {
        this.tabState.oldTime = this.tabState.time
        this.tabState.time = new Date().getTime()
        if ((this.tabState.time - this.tabState.oldTime) < 400 && index != 0) {
          this.removeTab(tab, index)
        } else {

        }
        return
      }
      this.$router.push(
        {
          path: tab.path,
          query: tab.query
        }
      );
      this.showTab2 = false
    },
    clickTab2(tab, index) {
      this.$store.commit("system/setTab2Splice", {index: index, count: 1});
      this.$nextTick(() => {
        this.setTopTab()
      })
      this.$router.push(
        {
          path: tab.path,
          query: tab.query
        }
      );
      this.showTab2 = false
    },
    closeCurrentTab() {
      // 展示长度为1是，关闭
      if (this.tabDataArr.length == 1) {
        return
      }

      this.tabDataArr.forEach((item, index) => {
        // 首页关闭
        if (item.active && item.path !== this.$HomePageRoute) {
          this.removeTab(item, index)
        }
      })
      this.tab2.forEach((item, index) => {
        if (item.active && item.path !== this.$HomePageRoute) {
          this.removeTab2(item, index)
        }
      })
      this.showTab2 = false
    },
    closeAllTab(oriIndex) {
      if (this.tabDataArr.length === 1 && this.tab2.length === 0) {
        if (typeof oriIndex === 'number') {
          this.closeDropDown(oriIndex);
        } else {
          this.showTab2 = false;
        }
        return;
      }

      this.tabDataArr.forEach((item, index) => {
        if (index != 0) {
          this.setExInclude(item, 1)
        }
      })

      this.tab2.forEach((item, index) => {
        this.setExInclude(item, 1)
      })

      let newArr = []
      let homePage = this.tabDataArr[0];
      newArr.push(homePage);
      this.$store.commit("system/setTab", newArr);
      this.$store.commit("system/setTab2", []);
      this.$router.push(
        {
          path: this.tabDataArr[0].path,
          query: this.tabDataArr[0].query
        }
      );
      // 清除页面缓存
      for (let fullPath in this.tabCacheMap) {
        if (!fullPath.startsWith(homePage.path)) {
          this.removeCache(fullPath);
        }
      }
      if (typeof oriIndex === 'number') {
        this.closeDropDown(oriIndex);
      } else {
        this.showTab2 = false;
      }
    },
    closeOtherTab() {
      if (this.tabDataArr.length === 1 && this.tab2.length === 0) {
        return;
      }

      let homePagePath = this.tabDataArr[0].path;
      let activePagePath;

      let activeIndex = -1
      let istabIndex = true
      this.tabDataArr.forEach((item, index) => {
        if (item.active) {
          activeIndex = index
          istabIndex = true
          activePagePath = item.fullPath;
        }

        if (index != 0 || item.active != true) {
          this.setExInclude(item, 1)
        }
      })

      this.tab2.forEach((item, index) => {
        if (item.active) {
          activeIndex = index
          istabIndex = false
          activePagePath = item.fullPath;
        }

        if (item.active != true) {
          this.setExInclude(item, 1)
        }

      })

      let newArr = []
      newArr.push(this.tabDataArr[0]);
      if (istabIndex) {
        if (activeIndex !== 0) {
          newArr.push(this.tabDataArr[activeIndex]);
        }
      } else {
        newArr.push(this.tab2[activeIndex]);
      }

      this.$store.commit("system/setTab", newArr);
      this.$store.commit("system/setTab2", []);

      // 清除缓存
      for (let fullPath in this.tabCacheMap) {
        if (!fullPath.startsWith(homePagePath) && fullPath !== activePagePath) {
          this.removeCache(fullPath);
        }
      }

      this.showTab2 = false;
    },
    closeOtherTabByIndex(tab, index) {

      let homePagePath = this.tabDataArr[0].path;

      let newArr = []
      newArr.push(this.tabDataArr[0]);
      if (index !== 0) {
        newArr.push(tab);
      }

      this.$store.commit("system/setTab", newArr);
      this.$store.commit("system/setTab2", []);

      // 清除缓存
      for (let fullPath in this.tabCacheMap) {
        if (!fullPath.startsWith(homePagePath) && fullPath !== tab.fullPath) {
          this.removeCache(fullPath);
        }
      }

      if (!tab.active) {
        this.$router.push(
          {
            path: tab.path,
            query: tab.query
          }
        );
      }

      this.closeDropDown(index);
    },
    removeCache(fullPath) {
      let vnode = this.tabCacheMap[fullPath];
      if (vnode) {
        vnode.componentInstance.$destroy();
        delete this.tabCacheMap[fullPath];
      }
    },
    removeTab(tab, index) {
      this.setExInclude(tab, 1)
      this.$store.commit("system/setTabSplice", {index: index, count: 1});
      if (tab.active) {
        this.$router.push(
          {
            path: this.tabDataArr[index - 1].path,
            query: this.tabDataArr[index - 1].query
          }
        );
      }

      // 删除缓存
      this.removeCache(tab.fullPath);

      if (this.tab2.length > 0) {
        let deleteIndex = this.tab2.length - 1;
        this.$store.commit("system/setTabPush", this.tab2[deleteIndex]);
        this.$store.commit("system/setTab2Splice", {index: deleteIndex, count: 1});
      }
    },
    removeTab2(tab, index) {
      this.setExInclude(tab, 1)
      this.$store.commit("system/setTab2Splice", {index: index, count: 1});
      if (tab.active) {
        if (index != 0) {
          this.$router.push(
            {
              path: this.tab2[index - 1].path,
              query: this.tab2[index - 1].query
            }
          )
        } else {
          this.$router.push(
            {
              path: this.tabDataArr[this.tabDataArr.length - 1].path,
              query: this.tabDataArr[this.tabDataArr.length - 1].query
            }
          )
        }
      }
      this.removeCache(tab.fullPath);
    },
    setExInclude(tab, type) {
      if (tab.meta && tab.meta.componentName) {
        //设置为不缓存
        if (type == 1) {
          let e = this.$store.state.system.exincludeList
          let a = false
          e.every((item) => {
            if (item == tab.meta.componentName) {
              a = true
              return false
            } else {
              return true
            }
          })
          if (!a) {
            e.push(tab.meta.componentName)
            this.$store.commit("system/setExincludeList", e);
          }
        } else {
          //设置为缓存
          let e = this.$store.state.system.exincludeList
          let newArray = e.filter((item) => {
            return item != tab.meta.componentName
          })
          this.$store.commit("system/setExincludeList", newArray);
        }
      }
    },
    setTopTab(index) {      // 设置占用位置tab1和tab2占比
      let w = document.getElementById("tab-left").offsetWidth;
      let w3 = this.tabDataArr.length * this.menuWidth;
      if (w3 < w) {
        if (this.tab2.length > 0 && (w - w3) > this.menuWidth) {
          let index = this.tab2.length - 1;
          this.$store.commit("system/setTabPush", this.tab2[index]);
          this.$store.commit("system/setTab2Splice", {index: index, count: 1});
          this.$nextTick(() => {
            this.setTopTab();
          })
        }
      } else {
        if (index && index !== (this.tabDataArr.length - 1)) {
          this.$store.commit("system/setTab2Push", this.tabDataArr[this.tabDataArr.length - 1]);
          this.$store.commit("system/setTabSplice", {index: this.tabDataArr.length - 1, count: 1});
        } else {
          if (this.tabDataArr[1].active && this.tabDataArr.length > 2) {
            this.$store.commit("system/setTab2Push", this.tabDataArr[2]);
            this.$store.commit("system/setTabSplice", {index: 2, count: 1});
          } else {
            this.$store.commit("system/setTab2Push", this.tabDataArr[1]);
            this.$store.commit("system/setTabSplice", {index: 1, count: 1});
          }
        }
        this.$nextTick(() => {
          this.setTopTab();
        })
      }
    },
    getLastIndex() {
      return this.tabDataArr.findIndex(item => item.active === true || item.active === 'true') + 1
    }
  }
}
</script>
<style lang="scss">
.top-more-menu {
  padding: 0;
  border: none;
  min-width: unset !important;
  top:46px !important;
  .more {
    width:176px;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1);
    >li {
      height:36px;
      align-items: center;
      padding: 5px 10px 5px 10px
    }
  }
}

</style>
