var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.uiType == 'element')?_c('div',{staticClass:"el-form-item",class:[
		{
			'is-error': _vm.validateState === 'error',
			'is-required': _vm.field && !_vm.field.dataAllowblank,
			'is-width100': _vm.showIsWidth100,
		},
		'el-form-item--small',
		'el-form-item_display',
	],style:(_vm.itemStyle)},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"el-form-item__label",style:({ width: _vm.kForm.dataLabelWidth || _vm.dataLabelWidth })},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label)+_vm._s(_vm.dataDisplayCommas ? ':' : ''))]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"el-form-item__content",staticStyle:{"flex":"1"},style:({ width: _vm.kForm.dataInputWidth || _vm.dataInputWidth })},[_vm._t("default"),_vm._v(" "),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[(_vm.validateState === 'error')?_vm._t("error",function(){return [_c('div',{staticClass:"el-form-item__error"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.validateMessage)+"\n\t\t\t\t")])]}):_vm._e()],2)],2)]):_c('div',{staticClass:"k-material-from-item",style:(_vm.itemStyle)},[(_vm.kForm && _vm.kForm.dataLabelPosition == 'vertical')?_c('md-field',{class:{ 'md-invalid': _vm.validateState === 'error' }},[_c('label',[_vm._v(_vm._s(_vm.label)+":")]),_vm._v(" "),_vm._t("default"),_vm._v(" "),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(_vm.validateMessage))])],2):_c('div',{staticClass:"md-layout"},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"k-from-horizontal-label",style:(_vm.labelStyle)},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label)+":")]}),_vm._v(" "),_c('div',{staticClass:"redStar"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRedBar),expression:"!showRedBar"}]},[_vm._v("*")])])],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md-layout-item",style:(_vm.inputStyle)},[_c('md-field',{class:{ 'md-invalid': _vm.validateState === 'error', 'md-hide-border': _vm.hideBorder },attrs:{"mdClearable":_vm.clearable},on:{"md-clear":_vm.clearInput}},[_vm._t("default"),_vm._v(" "),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(_vm.validateMessage))])],2)],1)]),_vm._v(" "),_vm._t("operation")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }