<template>
  <dv-border-box-8
    :dur="0"
    :color="['#83bff6', '#00CED1']"
    background-color="transparent"
  />
</template>

<script>
import DvBorderBox8 from '@jiaminghi/data-view/lib/components/borderBox8/src/main.vue'
export default {
  name: 'BorderBox8',
  components: {
    DvBorderBox8
  },
  data () {
    return {}
  }
}
</script>
