export default [
	{
    path: 'ba/viewConfig',
    name: '敏捷分析视图',
    meta: {
      notKeepAlive: true,
      componentName: 'BaView'
    },
    component: () => import('@/pages/ba/viewConfig/index.vue')
  },
  {
    path: 'ba/event',
    name: '事件分析视图',
    meta: {
      notKeepAlive: false,
      componentName: 'BaView'
    },
    component: () => import('@/pages/ba/event/index.vue')
  },
	{
		path: 'ba/funnel',
		name: '漏斗分析',
		meta: {
			notKeepAlive: false,
			componentName: 'Funnel'
		},
		component: () => import('@/pages/ba/funnel/index.vue')
	},
  {
		path: 'ba/attribute',
		name: '属性分析',
		meta: {
			notKeepAlive: false,
			componentName: 'Funnel'
		},
		component: () => import('@/pages/ba/attribute/index.vue')
	},
  {
		path: 'ba/analysis',
		name: '留存分析',
		meta: {
			notKeepAlive: false,
			componentName: 'analysis'
		},
		component: () => import('@/pages/ba/analysis/retention-analysis.vue')
	},
  {
    path: 'ba/ascribe',
    name: '归因分析',
    meta: {
      notKeepAlive: false,
      componentName: 'ba-ascribe'
    },
    component: () => import('@/pages/ba/ascribe/index')
  },
	{
		path: 'ba/lifecycle',
		name: '用户生命周期分析',
		meta: {
			notKeepAlive: false,
			componentName: 'lifecycle'
		},
		component: () => import('@/pages/ba/lifecycle/lifecycle-analysis.vue')
	},
  {
    path: 'ba/rfm',
    name: 'RFM分析',
    meta: {
      notKeepAlive: false,
      componentName: 'ba-rfm'
    },
    component: () => import('@/pages/ba/rfm/index')
  },
  {
  		path: 'ba/distribution',
  		name: '分布分析',
  		meta: {
  			notKeepAlive: false,
  			componentName: 'distribution'
  		},
  		component: () => import('@/pages/ba/distribution/distribution-analysis.vue')
  	}
]
