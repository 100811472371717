<template>
  <form class="el-form k-form" :style="formStyle" :class="[
    labelPosition ? 'el-form--label-' + labelPosition : '',
    { 'el-form--inline': inline === true || inline === 'true' ,
    'k-form_sticky': dataFooterStickyBottom === true || dataFooterStickyBottom === 'true'}
  ]">
    <slot name="header"></slot>
    <div class="k-form-body" :class="[{ 'k-form-body_sticky': dataFooterStickyBottom === true || dataFooterStickyBottom === 'true'}]">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </form>
</template>
<script>
  import emitter from "@/components/k-element/common/k-emitter.js";
  export default {
    name: 'KForm',

    mixins: [emitter()],

    provide() {
      return {
        kForm: this
      };
    },

    props: {
      labelPosition: String,
      dataLabelWidth: {
        type: String,
        default: "100px"
      },
      dataLabelPosition: {
        type: String,
        default: "horizontal"
      },
      dataInputWidth: {
        type: String,
        default: "225px"
      },
      dataItemHeight:{
        type: String,
        default: ""
      },
      dataItemMargin: {
        type: String,
        default: "16px"
      },
      dataCol: {
        type: Number,
        default: 2
      },
      inline: Boolean,
      dataUi: {
        type: String,
        default: "element"
      },
      dataTotalWidth: {
        type: String,
        default: null
      },
      dataFooterStickyBottom: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        formItems: []
      };
    },
    created() {
      this.$on('k.form.addFormItem', (field) => {
        if (field) {
          this.formItems.push(field);
        }
      });

      this.$on('k.form.removeFormItem', (field) => {
        if (field) {
          this.formItems.splice(this.formItems.indexOf(field), 1);
        }
      });
    },
    computed: {
      formStyle() {
        const ret = {};
        if(this.dataCol > 0){
          let dataLabelWidthInt = 0;
          if (this.dataTotalWidth) {
            dataLabelWidthInt = parseInt(this.dataTotalWidth.replace("px", ""));
          } else {
            let itemWidth = 0
            if (this.dataUi != "material" || this.dataLabelPosition == 'horizontal') {
              itemWidth = parseInt(this.dataLabelWidth&&this.dataLabelWidth.replace("px", ""));
            }
            itemWidth += parseInt(this.dataInputWidth&&this.dataInputWidth.replace("px", ""));
            itemWidth += parseInt(this.dataItemMargin&&this.dataItemMargin.replace("px", "")) * 2;
            dataLabelWidthInt = itemWidth * this.dataCol;
          }
          ret.width = (dataLabelWidthInt*1) + "px";
        }else{
          ret.width = "100%";
        }
        return ret;
      },
    },
    methods: {
      //校验函数
      validate() {
        let re = true;
        let focused=false
        this.formItems.map(formItem => {
          if (!formItem.validate()) {
            re = false;
            //将第一个不满足的item聚焦
            if(focused==false&&formItem.field.hasOwnProperty('focus')){
              formItem.field.focus()
              focused=true
            }
          }
        });
        return re;
      },
      validateForProdAdd() {
        let re = true;
        let focused=false
        let label = ''
        this.formItems.map(formItem => {
          if (!formItem.validate()) {
            re = false;
            label === '' && (label = formItem.label)
            //将第一个不满足的item聚焦
            if(focused==false&&formItem.field.hasOwnProperty('focus')){
              formItem.field.focus()
              focused=true
            }
          }
        });
        return {
          validate: re,
          label
        };
      },
      reset() {
        this.formItems.map(formItem => {
          formItem.reset()
        });
      }
    },
    mounted() {
      this.broadcast('KBtn', 'k.form.addForm', this);
      this.dispatch('KPopup', 'k.form.addForm', this);
    },
  };
</script>
