import httpUtil from './httpUtil.js';
import tools from '@/utils/tools';
import global from './global.js';

import flowableFormInfoRegisterPlugin from "../pages/flowable/components/FlowableFormInfoRegisterPlugin";


import KFieldSelect from '@/components/k-element/k-field-select/k-field-select.vue';
import kFieldDate from '@/components/k-element/k-field-date/k-field-date.vue';
import kFieldBswitch from '@/components/k-element/k-field-bswitch/k-field-bswitch.vue';
import KFieldRadio from '@/components/k-element/k-field-radio/k-field-radio.vue';
import KFieldCheckBox from '@/components/k-element/k-field-checkbox/k-field-checkbox.vue';
import KBtn from '@/components/k-element/k-btn/k-btn.vue';
import KFieldCode from '@/components/k-element/k-field-code/k-field-code.vue';
import KFieldTime from '@/components/k-element/k-field-time/k-field-time.vue';
import KPopup from '@/components/k-element/k-popup/k-popup.vue';
import KSteps from '@/components/k-element/k-steps/k-steps.vue';
import KStep from '@/components/k-element/k-steps/k-step.vue';
import KForm from '@/components/k-element/k-from/k-form.vue';
import KFormItem from '@/components/k-element/k-from/k-form-item.vue';
import KFormFooter from '@/components/k-element/k-from/k-form-footer.vue';
import KFieldText from '@/components/k-element/k-field-text/k-field-text.vue';
import kFieldPercent from '@/components/k-element/k-field-percent/k-field-percent.vue';
import KFieldCascader from '@/components/k-element/k-field-cascader/k-field-cascader.vue';
import KFieldRich from '@/components/k-element/k-field-rich/k-field-rich.vue';
import KGrid from '@/components/k-element/k-grid/k-grid.vue';
import KGridColumn from '@/components/k-element/k-grid/k-grid-column.vue';
import KFormSearch from '@/components/k-element/k-form-search/k-form-search.vue';
import KFormCustomize from '@/components/k-element/k-form-search/k-form-customize.vue';
import kFormSearchCustomize from '@/components/k-element/k-form-search/k-form-search-customize.vue';
import KFieldUpload from '@/components/k-element/k-field-upload/k-field-upload.vue';
import KSearchPanel from '@/components/k-element/k-search-panel/k-search-panel.vue';
import KTree from '@/components/k-element/k-tree/k-tree.vue';
import KFieldTree from '@/components/k-element/k-field-tree/k-field-tree.vue';
import KApiRpanel from '@/components/k-element/k-api-rpanel/k-api-rpanel.vue';
import KIcon from '@/components/k-element/k-icon/k-icon.vue';
import KContainer from '@/components/k-element/k-container/k-container.vue';
import KEditTable from '@/components/k-element/k-edit-table/k-edit-table.vue';
import KFieldNotification from '@/components/k-element/k-field-notification/k-field-notification.vue';
import KCharts from '@/components/k-element/k-charts/k-charts.vue';

// 自定义组件
import KGridSimple from '@/components/k-element/k-grid/k-grid-simple.vue';

import Sidebar from '@/components/k-material/SidebarPlugin';
import Notifications from '@/components/k-material/NotificationPlugin';
import DropDown from '@/components/k-material/Dropdown.vue';
import KFransfer from '@/components/k-element/k-transfer/k-transfer.vue';

// echarts 组件
// import PieChart from '@/components/k-echarts/PieChart.vue'

import { CollapseTransition, SlideYDownTransition, FadeTransition, ZoomCenterTransition } from '../../node_modules/vue2-transitions';

import KFieldDisplay from '@/components/k-element/k-field-display/k-field-display.vue';
import KGridDisplay from '@/components/k-element/k-grid/k-grid-display.vue';
import Vue from 'vue';
let kayakPlugin = {};

const components = [
	KFieldSelect,
	kFieldDate,
	kFieldBswitch,
	KBtn,
	KFieldCode,
	KFieldTime,
	KPopup,
	KSteps,
	KStep,
	KForm,
	KFormItem,
	KFormFooter,
	KFieldRadio,
	KFieldCheckBox,
	KFieldText,
	kFieldPercent,
	KFieldCascader,
	KFieldRich,
	KGrid,
	KGridColumn,
	KFieldUpload,
	KSearchPanel,
	KFormCustomize,
	KFormSearch,
	kFormSearchCustomize,
	KSearchPanel,
	KTree,
	KFieldTree,
	KApiRpanel,
	KFieldDisplay,
	KGridDisplay,
	KIcon,
	KContainer,
	KEditTable,
	KFieldNotification,
	// 自定义组件
	KGridSimple,
	KFransfer,
	KCharts,

	// echarts
	// PieChart,
];

kayakPlugin.install = function (Vue, options) {
	//注册全局组件
	components.forEach(component => {
		Vue.component(component.name, component);
	});

	//全局实例
	Vue.prototype.httpUtil = httpUtil;
	Vue.prototype.tools = tools;
	Vue.prototype.global = global;

	//安装实例
	Vue.use(Sidebar);
	Vue.use(Notifications);
	Vue.use(flowableFormInfoRegisterPlugin);
	Vue.component('drop-down', DropDown);
	Vue.component('CollapseTransition', CollapseTransition);
	Vue.component('SlideYDownTransition', SlideYDownTransition);
	Vue.component('FadeTransition', FadeTransition);
	Vue.component('ZoomCenterTransition', ZoomCenterTransition);
	
};

Vue.directive('clickOutside', {
	bind(el, binding, vnode) {
		function clickHandler(e) {
			if (el.contains(e.target)) {
				return false;
			}
			if (binding.expression) {
				binding.value(e);
			}
		}
		el.__vueClickOutside__ = clickHandler;
		document.addEventListener('click', clickHandler);
		document.addEventListener('mouseup', clickHandler);
	},
	update() {},
	unbind(el, binding) {
		document.removeEventListener('click', el.__vueClickOutside__);
		document.removeEventListener('mouseup', el.__vueClickOutside__);
		delete el.__vueClickOutside__;
	}
});

Vue.directive('dialogDrag', {
	bind(el, binding, vnode, oldVnode) {
		if (binding.value === false || binding.value === 'false') {
			return;
		}
		const dialogHeaderEl = el.querySelector('.el-dialog__header');
		const dragDom = el.querySelector('.el-dialog');
		// dialogHeaderEl.style.cursor = 'move';
		dialogHeaderEl.style.cssText += ';cursor:move;';
		dragDom.style.cssText += ';top:0px;';

		// 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
		const sty = (function () {
			if (window.document.currentStyle) {
				return (dom, attr) => dom.currentStyle[attr];
			} else {
				return (dom, attr) => getComputedStyle(dom, false)[attr];
			}
		})();
		dialogHeaderEl.onmousedown = e => {
			// 鼠标按下，计算当前元素距离可视区的距离
			const disX = e.clientX - dialogHeaderEl.offsetLeft;
			const disY = e.clientY - dialogHeaderEl.offsetTop;
			const screenWidth = document.body.clientWidth; // body当前宽度
			const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)
			const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
			const dragDomheight = dragDom.offsetHeight; // 对话框高度
			const minDragDomLeft = dragDom.offsetLeft;
			const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;
			const minDragDomTop = dragDom.offsetTop;
			const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;
			// 获取到的值带px 正则匹配替换
			let styL = sty(dragDom, 'left');
			let styT = sty(dragDom, 'top');
			// 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
			if (styL.includes('%')) {
				// eslint-disable-next-line no-useless-escape
				styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100);
				// eslint-disable-next-line no-useless-escape
				styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100);
			} else {
				styL = +styL.replace(/\px/g, '');
				styT = +styT.replace(/\px/g, '');
			}

			document.onmousemove = function (e) {
				// 通过事件委托，计算移动的距离
				let left = e.clientX - disX;
				let top = e.clientY - disY;

				// 边界处理
				if (-left > minDragDomLeft) {
					left = -minDragDomLeft;
				} else if (left > maxDragDomLeft) {
					left = maxDragDomLeft;
				}
				if (-top > minDragDomTop) {
					top = -minDragDomTop;
				} else if (top > maxDragDomTop) {
					top = maxDragDomTop;
				}

				// 移动当前元素
				dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
			};

			document.onmouseup = function (e) {
				document.onmousemove = null;
				document.onmouseup = null;
			};
			return false;
		};
	}
});

Vue.directive('focus', {
	inserted(el) {
		// 聚焦元素
		if (el.querySelector('input')) {
			el.querySelector('input').focus();
		}
	}
});

export default kayakPlugin;
