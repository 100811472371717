<template>
	<div class="grid-content">
		<template v-if="dataIsContainer">
			<k-grid-container
				v-bind="$attrs"
				v-on="$listeners"
				:data-data="list"
				:data-total="total"
				:data-query-params="cachedParams"
				:data-start="start"
				@changeStart="changeStart">
				<!-- 非作用于插槽 -->
				<template v-for="(slotComponent, slotName) in $slots" :slot="slotName">
					<slot :name="slotName"></slot>
				</template>
				<!-- 作用域插槽 -->
				<template v-for="(slotComponent, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ row }">
					<slot :name="slotName" :row="row"></slot>
				</template>
			</k-grid-container>
		</template>
		<template v-else-if="dataIsCard">
			<k-grid-card
				v-bind="$attrs"
				v-on="$listeners"
				:data-data="list"
				:data-total="total"
				:data-limit="limit"
				:data-query-params="cachedParams"
				:data-start="start"
				@changeStart="changeStart"
				@changeLimit="changeLimit"
				:data-audit-config="dataAuditConfig"
				:data-manger-config="dataMangerConfig">
			</k-grid-card>
		</template>
		<template v-else>
			<div class="k-card" :class="[{ 'no-box-shadow': noBoxShadow, stickyStyle: dataSticky }, dataStickyClass]" style="margin-top: 0px">
				<!-- 批量查询条件 -->
				<!-- <md-card-header v-show="this.$slots.search">
				<div>
					<slot name="search"></slot>
				</div>
			</md-card-header> -->
				<div v-if="dataShowTree && dataOpenExpandBtn" class="expandStyle">
					<span>全部展开</span>
					<k-field-bswitch v-model="isExpndFlag" @data-on-change="changeExpand"></k-field-bswitch>
				</div>
				<md-card-content>
					<div class="k-grid">
						<slot name="btns"></slot>
						<div v-if="$slots.btns" style="height: 16px"></div>
						<el-table
							ref="kgrid"
							:data="list"
							:row-key="tableOptions.dataTreeId"
							:lazy="dataLazy"
							:load="dataLoad"
							:height="tableOptions.dataHeight"
							:stripe="tableOptions.dataStripe"
							:border="tableOptions.dataBorder === true || tableOptions.dataBorder === 'true'"
							:default-expand-all="tableOptions.dataExpandAll === true || tableOptions.dataExpandAll === 'true'"
							@row-click="beforeHandleRowClick"
							@row-dblclick="handleDbRowClick"
							@selection-change="handleSelectionChange"
							@select="handleSelect"
							@select-all="handleSelectAll"
							style="width: 100%"
							@sort-change="sortChange"
							@expand-change="expandChange"
							v-loading="dataLoading"
							:element-loading-text="dataLoadingText"
							:highlight-current-row="highlightCurrentRow"
							:span-method="reSpanMethod"
							:show-summary="dataShowSummary"
							:sum-text="dataSumText"
							:summary-method="summaryMethod"
							:row-class-name="tableRowClassName"
							:cell-class-name="dataOperateColumn === true || dataOperateColumn === 'true' ? '' : 'no-operate-column-cell'">
							<el-table-column v-if="treeGridOptions.length > 0" :fixed="dataFixed" type="expand">
								<template slot-scope="props">
									<el-form label-position="left" inline class="k-tree-expand">
										<el-form-item v-for="item in treeGridOptions" :key="item.header" :label="item.header">
											<span>{{ props.row[item.name] }}</span>
										</el-form-item>
									</el-form>
								</template>
							</el-table-column>

							<el-table-column :fixed="dataFixed" v-if="this.$slots['expand'] || this.$scopedSlots['expand']" type="expand">
								<template slot-scope="{ row }">
									<slot name="expand" :row="row"></slot>
								</template>
							</el-table-column>

							<el-table-column
								v-if="(tableOptions.dataCheckbox === true || tableOptions.dataCheckbox === 'true') && treeGridOptions.length === 0"
								type="selection"
								:selectable="selectableFunction"
								:width="tableOptions.dataCheckboxWidth"
								:fixed="dataFixed"
								align="right"
								:reserve-selection="dataReserveSelection">
							</el-table-column>

							<el-table-column
								:width="operateWidth || operateWidthAutoCalc"
								fixed="right"
								align="center"
								label="操作"
								class="operate"
								v-if="(dataOperateColumn === true || dataOperateColumn === 'true') && dataOperateColumnPosition == 'first'">
								<template slot-scope="{ row }">
									<slot name="operate" :row="{ row }"></slot>
								</template>
							</el-table-column>

							<slot></slot>

							<el-table-column
								:width="operateWidth || operateWidthAutoCalc"
								:fixed="dataOperateFixRight"
								align="center"
								label="操作"
								class="operate"
								v-if="(dataOperateColumn === true || dataOperateColumn === 'true') && dataOperateColumnPosition == 'end'">
								<template slot-scope="{ row }">
									<slot name="operate" :row="{ row }"></slot>
								</template>
							</el-table-column>
						</el-table>

						<k-pagination
							v-if="dataPageSize > 0 && total > 0 && !this.dataShowTree"
							:total="total"
							:page.sync="start"
							:page-sizes="dataPageSizes"
							:limit.sync="limit"
							@pagination="handlePagination"
							:layout="tableOptions.dataPaginationLayout" />
					</div>
				</md-card-content>
			</div>

			<k-grid-display ref="kGridDisplay" :gridColumnOptions="gridColumnOptions" :row="doubleClickRow" v-bind="$attrs" />
		</template>
	</div>
</template>

<script>
import props from '@/components/k-element/common/k-field-props.js';
import event from '@/components/k-element/common/k-field-event.js';
import emitter from '@/components/k-element/common/k-emitter.js';
import { camelCase, forEach, merge } from 'lodash';
import Tools from '@/utils/tools.js';
import sticky from './table-sticky';
import kGridContainer from './k-grid-container.vue';
import kGridCard from './k-grid-card.vue';

import KPagination from './k-grid-pagination';

const descReg = /^[Dd][Ee][Ss][Cc]$/;
const ascReg = /^[Aa][Ss][Cc]$/;

export default {
	name: 'KGrid',
	mixins: [props(), event(), emitter(), sticky],
	components: {
		KPagination,
		kGridContainer,
		kGridCard
	},
	props: {
		dataBorder: {
			type: [Boolean, String],
			default: false
		},
		dataRender: {
			type: String,
			default: ''
		},
		dataParams: {
			type: Object
		},
		dataAlign: {
			type: String,
			default: 'left'
		},
		dataBeforeLoad: {
			type: Function
		},
		dataPageSize: {
			type: Number,
			default: 10
		},
		dataPageSizes: {
			type: Array
		},
		dataAutoload: {
			type: [Boolean, String],
			default: true
		},
		dataOperateColumn: {
			type: [Boolean, String],
			default: true
		},
		dataOperateColumnPosition: {
			type: String,
			default: 'end'
		},
		dataDisplay: {
			type: [Boolean, String],
			default: true
		},
		dataFixed: {
			type: [Boolean, String],
			default: false
		},
		dataOverflow: {
			type: [Boolean, String],
			default: true
		},
		dataShowTree: {
			type: Boolean,
			default: false
		},
		dataReserveSelection: {
			type: Boolean,
			default: false
		},
		dataCheckboxMultiple: {
			type: Boolean,
			default: true
		},
		// 决定某一行是否可以勾选（仅对type-selection有效）
		isable: {
			type: Function
		},
		dataShowLoading: {
			type: [Boolean, String],
			default: false
		},
		dataLoadingText: {
			type: String,
			default: '正在加载中...'
		},
		dataTreeId: {
			type: String
		},
		highlightCurrentRow: {
			type: [Boolean, String],
			default: true
		},
		dataInit: {
			type: [Boolean, String],
			default: true
		},
		// 是否显示外边框，默认false，表示会显示
		noBoxShadow: {
			type: Boolean,
			default: false
		},
		dataSpanMethod: {
			type: String,
			default: ''
		},
		dataShowSummary: {
			type: [Boolean, String],
			default: false
		},
		dataSumText: {
			type: String,
			default: '合计'
		},
		dataSumColumn: {
			type: String,
			default: ''
		},
		dataSumUnit: {
			type: String,
			default: ''
		},
		reSummaryMethod: {
			type: Function
		},
		reSpanMethod: {
			type: Function,
			default: () => {}
		},
		// 双击重载详情
		dataDbClickLoad: {
			type: [Boolean, String],
			default: false
		},
		// 主键字段
		dataKeys: {
			type: String,
			default: ''
		},
		// 传输格式是否为json
		dataJsonType: {
			type: Boolean,
			default: false
		},
		// 吸顶
		dataSticky: {
			type: Boolean,
			default: false
		},
		// 是否显示树形展开按钮
		dataOpenExpandBtn: {
			type: Boolean,
			default: false
		},
		// 为table中某一行添加class
		dataRowClassName: {
			type: Function
		},
		dataContentType: {
			type: String,
			default: 'form'
		},
		dataIsContainer: {
			// 是否是左右的布局
			type: Boolean
		},
		dataIsCard: {
			// 是否为卡片类的组件
			type: Boolean
		},
		dataAuditConfig: {
			//是否用在理财稽核配置页面
			type: Boolean
		},
		dataMangerConfig: {
			type: Boolean
		},
		dataLimit: {
			type: Number | String
		},
		dataLazy: {
			type: Boolean,
			default: false
		},
		dataLoad: {
			type: Function
		},
		dataNoPrint: {
			type: Boolean,
			default: false
		},
		dataExpandChange: {
			type: Function
		},
		dataOperateFixRight: {
			type: [Boolean, String],
			default: 'right'
		}
	},
	created() {
		if (this.dataInit) {
			this.init();
		}
	},
	data() {
		return {
			start: 1,
			limit: this.dataPageSize,
			dir: '',
			sort: '',
			gridColumnOptions: [],
			treeGridOptions: [],
			gridOptions: {},
			btnOptions: {},
			selectRows: [],
			list: [],
			treeList: [],
			total: 0,
			cache: {},
			dictKv: {},
			columnKv: [],
			cachedParams: {},
			doubleClickRow: {},
			selected: [],
			cancerSelecteds: [],
			autoSelected: false,
			dataLoading: false,
			loadParams: {},
			parent: 'k-grid',
			isExpndFlag: true,
			time: null,
			responseData: {}
		};
	},
	computed: {
		showSubscript() {
			if (this.dataShowSubscript != null) {
				return this.dataShowSubscript;
			}
			let parent = this.getParent('KPopup');
			if (parent) {
				return false;
			} else {
				return true;
			}
		},
		tableColumnOptions() {
			return this.gridColumnOptions.filter(option => option.dataHidden == false || (option.dataHidden !== 'true' && option.dataHidden != true));
		},
		tableOptions() {
			return this.gridOptions;
		},
		treeProps() {
			return this.tableOptions.dataTreeProps;
		},
		operateWidth() {
			return this.gridOptions.dataOperateWidth;
		},
		operateWidthAutoCalc() {
			// 获取按钮数量
			let btns = this.$scopedSlots['operate']({ row: { row: {} } }) || [];
			btns = btns.filter(v => v.tag);
			const len = btns.length;
			let sNum = 0; // 开关个数
			let tNum = 0; // 文字开关个数
			let bNum = 0; // 按钮个数
			btns.forEach(v => {
				if (v.tag.includes('KFieldBswitch')) {
					tNum++;
				} else if (v.tag.includes('MdSwitch')) {
					sNum++;
				} else if (v.tag.includes('KBtn')) {
					bNum++;
				}
			});
			const w = sNum + bNum + tNum === 1 ? 60 : sNum * 50 + bNum * 40 + tNum * 80;
			return w;
		},
		defaultSort() {
			for (let i = 0; i < this.tableColumnOptions.length; i++) {
				let o = this.tableColumnOptions[i];
				if (descReg.test(o.dataDefaultSort)) {
					return {
						prop: o.dataName,
						order: 'descending'
					};
				} else if (ascReg.test(o.dataDefaultSort)) {
					return {
						prop: o.dataName,
						order: 'ascending'
					};
				}
			}
			return {
				prop: '',
				order: ''
			};
		},
		iconStyle() {
			let iconStyle = {};
			iconStyle.background = this.$store.state.system.cardBackground;
			return iconStyle;
		}
	},
	watch: {
		list(newVal, oldVal) {
			this.handleDefaultSelected();
			this.handleCancerSelected();
		}
	},
	methods: {
		changeStart(start) {
			this.start = start;
		},
		changeLimit(limit) {
			this.limit = limit;
		},
		init() {
			// buttons
			let btnOptions = [];
			if (!this.dataIsContainer && !this.dataIsCard) {
				this.$slots.default
					.filter(column => {
						return column.tag && column.tag == 'k-grid-btn';
					})
					.forEach(column => {
						let res = {};
						res['text'] = column.children ? column.children[0].text : 'button';
						forEach(column.data.attrs, function (value, key) {
							res[camelCase(key)] = value;
						});
						btnOptions.push(res);
					});
			}
			this.btnOptions = btnOptions;

			// gridOptions
			let gridOptions = {};
			let mergedOption = merge({}, this.$props, this.$attrs);
			forEach(mergedOption, function (value, key) {
				gridOptions[camelCase(key)] = value;
			});
			this.gridOptions = gridOptions;

			// gridColumnOptions
			let gridColumnOptions = [];
			let dicts = [];
			let actions = [];
			if (!this.dataIsContainer && !this.dataIsCard) {
				this.$slots.default
					.filter(column => {
						return column.componentOptions && column.componentOptions.tag == 'k-grid-column';
					})
					.forEach(column => {
						this.loadColumnConfig(gridColumnOptions, dicts, actions, column);
					});
			}
			this.gridColumnOptions = gridColumnOptions;

			// treeGridOptions
			let treeGridOptions = [];

			let dataInnerHeaders = this.tableOptions.dataInnerHeaders;
			let dataInnerNames = this.tableOptions.dataInnerNames;
			if (dataInnerHeaders && dataInnerNames) {
				let innerHeaderArr = dataInnerHeaders.split(',');
				let innerNamesArr = dataInnerNames.split(',');
				if (innerHeaderArr.length !== innerNamesArr.length) {
					throw new Error('tree-grid 的配置不正确');
				}
				innerHeaderArr.forEach((item, idx) => {
					treeGridOptions.push({
						header: innerHeaderArr[idx],
						name: innerNamesArr[idx]
					});
				});
			}
			this.treeGridOptions = treeGridOptions;

			if (this.dataShowTree && this.dataOpenExpandBtn) {
				// 全部展开按钮值初始化
				if (this.tableOptions.dataExpandAll) {
					this.isExpndFlag = this.tableOptions.dataExpandAll;
				} else {
					this.isExpndFlag = false;
				}
			}

			this.$emit('init', this);

			let flag = true;
			if (dicts.length > 0) {
				flag = false;
				let promiseAll = [];
				dicts.forEach(item => {
					promiseAll.push(this.httpUtil.dict(item));
				});

				let _this = this;
				Promise.all(promiseAll).then(function (values) {
					let res = {};
					for (let i = 0; i < values.length; i++) {
						if (values[i].length === 0) {
							console.error('请检查数据字典是否存在');
							continue;
						}
						let kv = (res[values[i][0].dict] = {});
						values[i].forEach(item => {
							kv[item.itemkey] = item.itemval;
						});
					}
					_this.dictKv = res;

					_this.initData();
				});
			}
			if (actions.length > 0) {
				flag = false;
				let promiseAll = [];
				actions.forEach(item => {
					promiseAll.push(
						new Promise(resolve => {
							this.httpUtil
								.comnQuery({
									action: item
								})
								.then(data => {
									if (data.rows.length > 0) {
										resolve({
											k: item,
											v: data.rows
										});
									}
								});
						})
					);
				});
				let _this = this;
				Promise.all(promiseAll).then(function (values) {
					_this.columnKv = values;
					_this.initData();
				});
			}
			if (flag) {
				this.initData();
			}
		},
		selectableFunction(row, index) {
			if (this.isable) {
				return this.isable(row);
			}

			return true;
		},
		initData() {
			if (this.dataData && Object.keys(this.dataData).length != 0) {
				this.responseData = this.dataData;
				this.total = this.dataData.total;
				if (this.dataPageSize > 0 && this.total > 0 && !this.dataShowTree) {
					let reqParams = this.buildQueryParams();
					this.list = this.dataData.rows.slice(reqParams.start, reqParams.start + reqParams.limit);
				} else {
					this.list = this.dataData.rows;
				}
			} else if (this.gridOptions.dataAutoload === true || this.gridOptions.dataAutoload === 'true') {
				this.firstLoad();
			}
		},
		setWidth() {
			if (this.dataSticky) this.headerDragend();
		},
		firstLoad() {
			if (this.defaultSort) {
				this.sortChange(this.defaultSort);
			} else {
				this._load();
			}
		},
		expandChange(row, isOpen) {
			this.dataExpandChange && this.dataExpandChange(row, isOpen);
		},
		sortChange(value) {
			this.dir = value.order == 'ascending' ? 'asc' : 'desc';
			this.sort = value.prop;
			this._load();
		},
		getColumnOptions() {
			return merge({}, this.gridColumnOptions);
		},
		getSelected() {
			return Array.from(this.selectRows);
		},
		getRow(id) {
			let dataCheckboxId = this.gridOptions.dataCheckboxId;
			if (!dataCheckboxId) {
				throw new Error('请指定行主键');
			}
			for (let i = 0; i < this.list.length; i++) {
				let item = this.list[i];
				if (item[dataCheckboxId] === id) {
					return item;
				}
			}
			return null;
		},
		getRowData(ev) {
			if (!ev) {
				return null;
			}

			let idx = null;
			let currentRow = ev.target.closest('.el-table__row');
			if (!currentRow) {
				return null;
			}

			let rows = this.$refs.kgrid.$el.querySelectorAll('.el-table__row');
			for (let index = 0; index < rows.length; index++) {
				const item = rows[index];
				if (currentRow['rowIndex'] === item['rowIndex']) {
					idx = index;
					break;
				}
			}
			return idx != null ? Object.assign({}, this.flattenTableDataDeep()[idx]) : null;
		},
		setSelected(ids) {
			this.$refs.kgrid.clearSelection();
			(ids || []).forEach(id => {
				let row = this.getRow(id);
				row && this.$refs.kgrid.toggleRowSelection(row);
			});
		},
		clearAll() {
			this.$refs.kgrid.clearSelection();
		},
		selectAll() {
			this.$refs.kgrid.toggleAllSelection();
		},
		getCachedParams() {
			return this.cachedParams;
		},
		handlePagination() {
			this._load();
		},
		//设置默认
		handleDefaultSelected() {
			for (var index = 0; index < this.selected.length; index++) {
				var item = this.selected[index];
				this.list.map(item2 => {
					if (item[this.gridOptions.dataTreeId] == item2[this.gridOptions.dataTreeId]) {
						this.autoSelected = true;
						this.$refs.kgrid.toggleRowSelection(item2);
						this.selected.splice(index, 1);
						index--;
					}
				});
			}
		},
		handleCancerSelected() {
			this.cancerSelecteds.map((item, index) => {
				this.list.map(item2 => {
					if (item == item2[this.gridOptions.dataTreeId]) {
						this.autoSelected = true;
						this.$nextTick(() => {
							this.$refs.kgrid.toggleRowSelection(item2, false);
							this.cancerSelecteds.splice(index, 1);
						});
					}
				});
			});
		},
		cancerSelected(value, valueField) {
			let a = true;
			this.selected.map((item, index) => {
				if (item[valueField] == value) {
					this.selected.splice(index, 1);
					a = false;
				}
			});

			if (a == false) {
				return;
			}

			// this.list.map(item => {
			//   if (item[valueField] == value) {
			//     this.autoSelected = true
			//     this.$refs.kgrid.toggleRowSelection(item, false);
			//     a = false
			//   }
			// })

			if (a) {
				this.cancerSelecteds.push(value);
				this.handleCancerSelected();
			}
		},
		load(params = {}, start) {
			if (!start) {
				this.start = 1;
			}
			this.cachedParams = params;
			return this._load();
		},
		buildQueryParams() {
			let reqParams = merge({}, this.cachedParams);

			if (this.dataParams) {
				reqParams = merge(reqParams, this.dataParams);
			}

			if (this.dataBeforeLoad) {
				reqParams = this.dataBeforeLoad(reqParams);
				if (reqParams === false) {
					return;
				}
			}

			if (!this.gridOptions.dataShowTree && this.limit > 0) {
				reqParams.limit = this.limit;
				// 修改分页
				reqParams.start = (this.start - 1) * this.limit;
			}

			if (this.sort && this.dir) {
				reqParams.sort = this.sort;
				reqParams.dir = this.dir;
			}
			return reqParams;
		},
		loadFieldGridData(params, valueField) {
			let gridOptions = this.gridOptions;
			let result;
			let reqParams = merge({}, params);

			if (this.dataParams) {
				reqParams = merge(reqParams, this.dataParams);
			}

			let $this = this;

			if (gridOptions.dataGraphql && gridOptions.dataAction) {
				throw new Error('请设置唯一的请求地址 [data-graphql |  data-action]');
			}

			// 普通 graph grid
			if (gridOptions.dataGraphql) {
				this.httpUtil
					.graphqlQurey({
						graphql: this.gridOptions.dataGraphql,
						params: reqParams
					})
					.then(data => {
						//获取请求头
						let graphqlFirst = this.getGraphKey();
						result = data[graphqlFirst].rows;
						if (result.length > 0) {
							let a = true;
							this.list.map(item => {
								if (item[valueField] == result[0][valueField]) {
									this.autoSelected = true;
									a = false;
									this.$refs.kgrid.toggleRowSelection(item);
								}
							});
							if (a) {
								this.selected.push(result[0]);
							}
							this.$emit('dd', result);
						}
					});
				return;
			}

			// 普通  action  grid
			if (gridOptions.dataAction) {
				let action = this.gridOptions.dataAction;
				if (action.indexOf('/wf/') > -1 || action.indexOf('wf/') > -1) {
					this.httpUtil
						.ajax({
							url: action,
							params: reqParams
						})
						.then(data => {
							result = data.rows;
							if (result.length > 0) {
								let a = true;
								this.list.map(item => {
									if (item[valueField] == result[0][valueField]) {
										this.autoSelected = true;
										a = false;
										// this.$refs.kgrid.toggleRowSelection(item);
									}
								});
								if (a) {
									this.selected.push(result[0]);
									this.$refs.kgrid.toggleRowSelection();
								}
								this.$emit('dd', result);
							}
						});
				} else {
					this.httpUtil
						.comnQuery({
							action: this.gridOptions.dataAction,
							params: reqParams
						})
						.then(data => {
							result = data.rows;
							if (result.length > 0) {
								let a = true;
								this.list.map(item => {
									if (item[valueField] == result[0][valueField]) {
										this.autoSelected = true;
										a = false;
										// this.$refs.kgrid.toggleRowSelection(item);
									}
								});
								if (a) {
									this.selected.push(result[0]);
									this.$refs.kgrid.toggleRowSelection();
								}
								this.$emit('dd', result);
							}
						});
				}
			}
		},
		_load() {
			if (this.dataShowLoading) {
				this.dataLoading = true;
			}
			let gridOptions = this.gridOptions;
			let reqParams = this.buildQueryParams();

			let $this = this;

			// function cb(data) {
			//   gridOptions.dataAfterLoad && $this.$parent[gridOptions.dataAfterLoad] && $this.$parent[gridOptions.dataAfterLoad]
			//   (data);
			// }
			function cb(data) {
				gridOptions.dataAfterLoad && gridOptions.dataAfterLoad(data);
			}

			if (gridOptions.dataGraphql && gridOptions.dataAction) {
				throw new Error('请设置唯一的请求地址 [data-graphql |  data-action]');
			}

			this.loadParams = reqParams;

			//通 graph treegrid
			if (gridOptions.dataShowTree && gridOptions.dataGraphql) {
				this.validateTreetree();
				return this.loadGraphqlTreeData(reqParams, cb);
			}

			//通 action treegrid
			if (gridOptions.dataShowTree && gridOptions.dataAction) {
				this.validateTreetree();
				return this.loadActionTreeData(reqParams, cb);
			}

			//通 url treegrid
			if (gridOptions.dataShowTree && gridOptions.dataUrl) {
				this.validateTreetree();
				return this.loadUrlTreeData(reqParams, cb);
			}

			// 普通 graph grid
			if (gridOptions.dataGraphql) {
				return this.loadGraphqlData(reqParams, cb);
			}

			// 普通  action  grid
			if (gridOptions.dataAction) {
				// 数据返回
				// this.loadActionData(reqParams, cb);
				return this.loadActionData(reqParams, cb);
			}

			// 普通  action  grid
			if (gridOptions.dataUrl) {
				return this.loadUrlData(reqParams, cb);
			}

			if (gridOptions.dataData) {
				return this.loadDataData(reqParams, cb);
			}
		},
		validateTreetree() {
			if (!this.gridOptions.dataTreeId) {
				throw new Error('请配置 data-tree-id');
			}
			if (!this.gridOptions.dataDiffcondition) {
				throw new Error('请配置 data-diffcondition');
			}
		},
		loadGraphqlTreeData(params, cb) {
			let graphql = this.getTreeGraphqlUrl();
			return this.httpUtil
				.graphqlQurey({
					graphql: graphql,
					params: params
				})
				.then(data => {
					//获取请求头
					this.dataLoading = false;
					let graphqlFirst = this.getGraphKey();
					this.list = data[graphqlFirst].rows;
					this.responseData = data[graphqlFirst];
					cb && cb(merge({}, this.list));
					return data;
				});
		},
		loadActionTreeData(params, cb) {
			params.diffcondition = this.gridOptions.dataDiffcondition;
			return this.httpUtil
				.comnQueryTree({
					action: this.gridOptions.dataAction,
					params: params
				})
				.then(data => {
					this.dataLoading = false;
					this.list = data.rows;
					this.responseData = data;
					this.getRowListVal();
					cb && cb(merge({}, data.rows));
					return data;
				});
		},
		//add by zyk 仅额度页面使用,保留原list至treeList中
		getRowListVal() {
			this.treeList = [];
			this.def2(this.list[0]);
			// this.formData.rowList = JSON.stringify(this.rowList)
		},
		def2(row) {
			if (!!row) {
				this.treeList.push(row);
				if (!!row.children) {
					row.children.forEach(item => {
						this.def2(item);
					});
				}
			}
		},
		loadUrlTreeData(params, cb) {
			params.diffcondition = this.gridOptions.dataDiffcondition;
			return this.httpUtil
				.ajax({
					url: this.gridOptions.dataUrl,
					params: params
				})
				.then(data => {
					this.dataLoading = false;
					this.list = data.rows;
					this.responseData = data;
					cb && cb(merge({}, data.rows));
					return data;
				});
		},
		loadGraphqlData(params, cb) {
			return this.httpUtil
				.graphqlQurey({
					graphql: this.gridOptions.dataGraphql,
					params: params
				})
				.then(data => {
					//获取请求头
					this.dataLoading = false;
					let graphqlFirst = this.getGraphKey();
					this.list = data[graphqlFirst].rows;
					this.responseData = data[graphqlFirst];
					this.total = data[graphqlFirst].results;
					cb && cb(merge({}, this.list));
					return data;
				});
		},
		loadActionData(params, cb) {
			let action = this.gridOptions.dataAction;
			if (action.indexOf('/wf/') > -1 || action.indexOf('wf/') > -1) {
				return this.httpUtil
					.ajax({
						url: action,
						params: params
					})
					.then(data => {
						this.dataLoading = false;
						this.list = data.rows;
						this.responseData = data;
						this.total = data.total;
						cb && cb(merge({}, data));
						return data;
					});
			} else {
				return this.httpUtil
					.comnQuery({
						action: this.gridOptions.dataAction,
						params: params,
						dataJson: !!this.dataJsonType
					})
					.then(data => {
						this.dataLoading = false;
						this.list = data.rows;
						this.responseData = data;
						this.total = data.results;
						cb && cb(merge({}, data));
						return data;
					});
			}
		},
		loadDataData(params, cb) {
			if(params.start && params.limit){
				this.list = this.dataData.rows.slice(params.start, params.start + params.limit);
			}else{
				this.list = this.dataData.rows
			}
			cb && cb(merge({}, this.dataData));
			return this.dataData;
		},
		loadUrlData(params, cb) {
			if (this.dataContentType === 'json') {
				return this.httpUtil
					.ajaxJson({
						url: this.gridOptions.dataUrl,
						params: params,
						noPrint: this.dataNoPrint
					})
					.then(data => {
						this.list = data.rows;
						this.responseData = data;
						this.total = data.results;
						cb && cb(merge({}, data.rows));
						return data;
					});
			} else {
				return this.httpUtil
					.ajax({
						url: this.gridOptions.dataUrl,
						params: params
					})
					.then(data => {
						this.dataLoading = false;
						this.list = data.rows;
						this.responseData = data;
						this.total = data.results;
						cb && cb(merge({}, data.rows));
						return data;
					});
			}
		},
		getTreeGraphqlUrl() {
			let gridOptions = this.gridOptions;
			let dataGraphql = gridOptions.dataGraphql;
			let dataDiffcondition = gridOptions.dataDiffcondition;
			let data_graphql = dataGraphql.trim();
			let queryName = data_graphql.substring(data_graphql.indexOf('{') + 1, data_graphql.indexOf('('));
			data_graphql += 'treeConfig{';
			data_graphql += queryName;
			data_graphql += ':{';
			data_graphql += "diffcondition: '" + dataDiffcondition + "'";
			data_graphql += '}';
			data_graphql += '}';
			return data_graphql;
		},
		getGraphKey() {
			let graphql = this.gridOptions.dataGraphql;
			if (graphql) {
				return graphql.substring(graphql.indexOf('{') + 1, graphql.indexOf('('));
			}
			return '';
		},
		// 当选择发生变化时触发
		handleSelectionChange(selection, row) {
			if (!this.autoSelected) {
				//如果为多选
				if (this.dataCheckboxMultiple) {
					this.selectRows = selection;
					selection = selection.concat(this.selected);
					this.$emit('data-select-change', selection);
				} //如果为单选
				else {
					if (selection.length == 0 || selection.length == 1) {
						{
							this.selectRows = selection;
							this.$emit('data-select-change', selection);
						}
					} else {
						this.$refs.kgrid.clearSelection();
						this.$refs.kgrid.toggleRowSelection(selection[selection.length - 1]);
					}
				}
			} else {
				this.autoSelected = false;
			}
		},
		handleSelect(selection, row) {
			this.$emit('data-select', selection, row);
		},
		handleSelectAll(selection) {
			this.$emit('data-select-all', selection);
		},
		tableRowClassName({ row, rowIndex }) {
			row.row_index = rowIndex;
			if (this.dataRowClassName && typeof this.dataRowClassName === 'function') {
				return this.dataRowClassName({ row, rowIndex });
			}
		},
		beforeHandleRowClick(row, column, event) {
			clearTimeout(this.time);
			this.time = setTimeout(() => {
				//添加定时器区分表格的单击或双击
				this.handleRowClick(row, column, event);
			}, 200);
		},
		handleRowClick(row, column, event) {
			if (this.dataDbClickLoad) {
				let gridOptions = this.gridOptions;
				let params = {};
				this.dataKeys.split(',').map(key => {
					params[key] = row[key];
				});
				let reqParams = merge(this.loadParams, params);
				reqParams.desensitzation = 'Y';
				reqParams.start = 0;
				if (gridOptions.dataAction) {
					this.httpUtil
						.comnQuery({
							action: gridOptions.dataAction,
							params: reqParams
						})
						.then(data => {
							this._handleRowClick(data.rows[0], column, event);
						});
				}
				if (gridOptions.dataUrl) {
					this.httpUtil
						.ajax({
							url: gridOptions.dataUrl,
							params: reqParams
						})
						.then(data => {
							this._handleRowClick(data.rows[0], column, event);
						});
				}
			} else {
				this._handleRowClick(row, column, event);
			}
		},
		_handleRowClick(row, column, event) {
			this.$emit('data-row-select', row, column, event);
			if (this.$slots['children'] || this.$scopedSlots['expand']) {
				this.$refs.kgrid.toggleRowExpansion(row);
			}
		},
		handleDbRowClick(row, column, event) {
			clearTimeout(this.time);
			if (!row || JSON.stringify(row) === '{}' || row.slotOpen) {
				return;
			}
			if (this.dataDbClickLoad) {
				let gridOptions = this.gridOptions;
				let params = {};
				this.dataKeys.split(',').map(key => {
					params[key] = row[key];
				});
				let reqParams = merge(this.loadParams, params);
				reqParams.desensitzation = 'Y';
				reqParams.start = 0;
				if (gridOptions.dataAction) {
					this.httpUtil
						.comnQuery({
							action: gridOptions.dataAction,
							params: reqParams
						})
						.then(data => {
							this._handleDbRowClick(data.rows[0], column, event);
						});
				}
				if (gridOptions.dataUrl) {
					this.httpUtil
						.ajax({
							url: gridOptions.dataUrl,
							params: reqParams
						})
						.then(data => {
							this._handleDbRowClick(data.rows[0], column, event);
						});
				}
			} else {
				this._handleDbRowClick(row, column, event);
			}
		},
		_handleDbRowClick(row, column, event) {
			this.doubleClickRow = row;
			if (this.dataDisplay === true || this.dataDisplay === 'true') {
				// 隐藏的字段在双击详情页面也可以展示
				setTimeout(() => {
					this.$refs.kGridDisplay.popup();
				}, 50);
			}
			this.$emit('data-db-click', row, column, event);
		},
		_handleBtnClick(row, option, vm) {
			if (option.dataClick) {
				this.$parent[option.dataClick](row);
			}
		},
		handleBtnDisabled(row, option) {
			if (option.dataDisableHandler) {
				return this.$parent[option.dataDisableHandler](row);
			}
			return false;
		},
		handleBtnShow(row, option) {
			if (option.dataHidenHandler) {
				return !this.$parent[option.dataHidenHandler](row);
			}
			return true;
		},
		dictTransfer(dict, dictVal) {
			if (dict && dictVal) {
				// let dictVals = dictVal.split(',');
				let dictVals = String(dictVal).split(',');
				let transferValue;
				for (let i = 0; i < 1; i++) {
					if (i == 0) {
						transferValue = this.doDictTransfer(dict, dictVals);
						if (transferValue) {
							transferValue = transferValue.toString();
						}
					} else {
						transferValue += '，' + this.doDictTransfer(dict, dictVals);
					}
				}
				return transferValue;
			}
			return dictVal;
		},
		doDictTransfer(dict, dictVal) {
			if (this.dictKv[dict]) {
				if (dictVal.length > 1) {
					let dictVals = '';
					dictVal.forEach(val => {
						dictVals += this.dictKv[dict][val] + '，';
					});
					let reg = /，$/gi;
					dictVals = dictVals.replace(reg, '');
					return dictVals;
				}
				return this.dictKv[dict][dictVal];
			}
			return dictVal;
		},
		columnDataActionTransfer(action, actionVal) {
			if (action && actionVal) {
				let val = '';
				this.columnKv
					.filter(kv => kv.k === action)
					.map(data => {
						if (data.v.some(f => f.itemkey === actionVal)) {
							val = data.v.find(f => f.itemkey === actionVal).itemval;
						} else {
							val = actionVal;
						}
					});
				return val;
			}
			return actionVal;
		},
		flattenDeepHelper(tableData, res) {
			for (let i = 0; i < tableData.length; i++) {
				let item = tableData[i];
				res.push(item);
				if (item.children) {
					this.flattenDeepHelper(item.children, res);
				}
			}
		},
		flattenTableDataDeep() {
			let res = [];
			this.flattenDeepHelper(this.list, res);
			return res;
		},
		/**
		 * 加载表头配置
		 */
		loadColumnConfig(gridColumnOptions, dicts, actions, column) {
			if (column.componentOptions && column.componentOptions.tag == 'k-grid-column') {
				let res = {};
				forEach(column.data.attrs, function (value, key) {
					if (camelCase(key) === 'dataDict') {
						dicts.push(value);
					}
					res[camelCase(key)] = value;
				});
				forEach(column.componentOptions.propsData, function (value, key) {
					if (camelCase(key) === 'dataDict') {
						dicts.push(value);
					}
					if (camelCase(key) === 'dataAction') {
						actions.push(value);
					}
					res[camelCase(key)] = value;
				});
				gridColumnOptions.push(res);

				let childrens = column.componentOptions.children;
				if (childrens && childrens.length > 0) {
					childrens.map(children => {
						this.loadColumnConfig(gridColumnOptions, dicts, actions, children);
					});
				}
			}
		},
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (this.dataSpanMethod && this.dataSpanMethod.length > 0) {
				let str = this.dataSpanMethod;
				let arr = str.split(',').map(Number);
				if (rowIndex == arr.slice(2, 3) && columnIndex == arr.slice(3, 4)) {
					return arr.slice(0, 2);
				}
			}
		},
		summaryMethod(param) {
			if (this.reSummaryMethod && typeof this.reSummaryMethod === 'function') {
				return this.reSummaryMethod(param);
			} else {
				let that = this;
				const { columns, data } = param;
				const sums = [];
				const columnNames = that.dataSumColumn.split(',');
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = that.dataSumText;
						return;
					}
					let values;
					if (that.dataSumColumn.length > 0) {
						values = data.map(item => {
							if (columnNames.includes(column.property)) {
								return Number(item[column.property]);
							} else {
								return NaN;
							}
						});
					} else {
						values = data.map(item => Number(item[column.property]));
					}

					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return (prev * 100 + curr * 100) / 100;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += that.dataSumUnit;
					} else {
						sums[index] = '';
					}
				});
				this.gridColumnOptions.forEach((item, index) => {
					if (item.dataType === 'money') {
						sums[index] = this.setThousandsMark(sums[index]);
					} else if (item.dataType === 'percentage') {
						sums[index] = +parseFloat((sums[index] * 100).toFixed(2)) + '%';
					}
				});
				return sums;
			}
		},
		setThousandsMark(num) {
			return Tools.setThousandsMark(num);
		},
		changeExpand(flag) {
			// 全部展开/全部收起功能
			let $kgrid = this.$refs.kgrid;
			// this.dataLoading = true
			expandFunc(this.list, flag);
			// setTimeout(() => {
			//   this.dataLoading = false
			// }, 2000)
			function expandFunc(list, flag) {
				list.forEach(row => {
					$kgrid.toggleRowExpansion(row, flag);
					if (row.children) {
						expandFunc(row.children, flag);
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
