
import Vue from "vue";
import WujieVue from 'wujie-vue2'
import lifecycles from "./utils/lifecycle";
import router from "@/router";
import { MicroAppConfig, getConfig } from '@/micro-app/utils';
import Store from '@/store';
import Tools from '@/utils/tools.js';
import auth from "@/utils/auth";
import { HomePageRoute, appNameMap } from '@/micro-app/utils'
import { login_user, _Permissions, dynamicRoutes } from './utils/mockData'
import { Loading } from 'element-ui';

Vue.use(WujieVue)
const { setupApp, bus, preloadApp } = WujieVue
Vue.prototype.$HomePageRoute = HomePageRoute;


bus.$on("sub-route-change", (name, routeData) => {
  const { path, name: routerName, query } = routeData
  Object.keys(query).forEach(key => {
    if(query[key] == 'null' ){
      query[key] = null
    }
  });
  const  { routePrefix } = getConfig(name)
  const mainName = `${name}-sub`;
  const mainPath = `/main/${name}${path.replace(routePrefix, '')}`;

  const currentName = router.currentRoute.name;
  const currentPath = router.currentRoute.path;

  // console.log('sub-route-change---', path);
  if(path.includes([`/login`])) {
    Tools.alert('登录过期，请重新登录', 'danger');
    // token过期跳转到登录
    auth.removeToken();
    router.push({
      path: '/login'
    })
  } else {
    Store.commit("system/setChildRouterMap", { key: mainPath,value: routerName});
    if (mainName === currentName && mainPath !== currentPath) {
      router.push({ path: mainPath, query });
    }
  }
});

const degrade = !window.Proxy || !window.CustomElementRegistry;

Store.commit("system/setSubAppAllNum", 0);
Store.commit("system/setSubAppFinishNum", 0);


MicroAppConfig.forEach((appConfig) => {
  const props = {
    appConfig,
    appNameMap
  }

  Store.commit("system/plusSubAppAllNum");
  const { name, url, alive } = appConfig

  if(name == 'cms') {
    props.userInfo = login_user
    props._Permissions = _Permissions
    props.dynamicRoutes = dynamicRoutes
  }

  // 设置子应用默认参数
  setupApp({
    name,
    url,
    exec: true,
    degrade,
    alive,
    props,
    ...lifecycles
  })
  // 预加载
  preloadApp({ name });
})

// 开启loading
window.mircoLoading = Loading.service({
  text: '拼命加载中...',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.7)',
  fullscreen: true
})

setTimeout(() => {
  window.mircoLoading.close()
}, 5000)
