<template>
	<div class="wrapper app-wrapper" :class="siderShow ? '' : 'hideSidebar'" @click="() => { CompileData.show = false }">
		<k-sider-dark ref="darkThemeSider" @reinitScrollbar="reinitScrollbarx" class="menu-container" :title="title"
			:menus="menus" :active-color="sidebarBackground" :background-image="sidebarBackgroundImage"
			:data-background-color="sidebarBackgroundColor" :subMenus="subMenus" :siderShow="siderShow">
		</k-sider-dark>

		<div class="sider-right">
			<div class="top-k-sider-dar">
				<div style="display:flex">
					<div class="top-k-sider-dar-img" @click="hideSider()">
						<svg-icon v-if="siderShow" icon-class="icon-collapse-close" fill="var(--top-meun-font-color)" width="16px"
							height="16px"></svg-icon>
						<svg-icon v-else icon-class="icon-collapse-open" fill="var(--top-meun-font-color)" width="16px"
							height="16px"></svg-icon>
					</div>
					<div class="top-k-sider-dar-logo">
						<span class="k-sidebar_title logo-normal" style="line-height: 50px;">{{ title }}</span>
					</div>
					<div class="top-k-sider-dar-menus">
						<div v-for="(m1, index) in menus" :key="index" class="item flex_c top-k-sider-dar-menus-btn"
							@click="changeAllChildrenToFalse(m1, index)" :class="{ 'nav-top-li-active': isActive(m1) }">
							<span>{{ m1.shortname }}</span>
						</div>
					</div>
				</div>
				<div>
					<TopNavbarDark></TopNavbarDark>
				</div>
			</div>

			<div class="nav-right-content">
				<!-- 顶部栏 -->
				<div v-if="$globalTheme == 'defalutTheme'">
					<!-- <top-navbar :subMenus="subMenus"></top-navbar> -->
					<top-tab ref="topTabRefs" :active-color="sidebarBackground" @contextmenuClick="contextmenuClick"></top-tab>
				</div>
				<div v-if="$globalTheme == 'darkTheme'">
					<top-tab-dark :active-color="sidebarBackground"></top-tab-dark>
				</div>


				<div class="k-work-content" :class="{ content: !$route.meta.hideContent, is_desktop: $route.meta.isDesktop }"
					@click="toggleSidebar">
					<router-view :key="$route.fullPath" />
				</div>

			</div>
		</div>

		<!-- 操作框 -->
		<div class="compileMenu" v-if="CompileData.show"
			v-bind:style="{ top: CompileData.compileMenuY + 'px', left: CompileData.compileMenuX + 'px', }">
			<div class="compileItem" @click="closeTop('now')">关闭当前</div>
			<div class="compileItem" @click="closeTop('rests')">关闭其他</div>
			<div class="compileItem" @click="closeTop('offleft')">关闭左侧</div>
			<div class="compileItem" @click="closeTop('offright')">关闭右侧</div>
			<div class="compileItem" @click="closeTop('all')">关闭全部</div>
		</div>


		<!-- 通知组件 -->
		<k-field-notification ref="kFieldNotification" :showClose="false" :duration="0"
			:position="'bottom-right'"></k-field-notification>

    <!-- 活动平台审核详情 -->
		<transition name="fade">
			<div class="wujie-content" v-show="dialogVisible">
				<div class="iframe-div">
					<div class="iframe-header">
						<span>审核详情</span>
						<i class="el-icon-close" @click="dialogVisible = false"></i>
					</div>
					<div><iframe class="iframe" id="cms-iframe" frameborder="no" border="0" :src="iframeTarget"></iframe>
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import KeepAliveContent from '@/frame/main/KeepAliveContent.vue';
import TopNavbarDark from '@/frame/main/TopNavbar.vue';
import { getCurrentAppConfigForUrl, transformMicroUrlToNormal } from '@/micro-app/utils'
function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function setScollerV() {
	var agent = navigator.userAgent.toLowerCase();

	var regStr_ie = /msie [\d.]+;/gi;
	var regStr_ff = /firefox\/[\d.]+/gi;
	var regStr_chrome = /chrome\/[\d.]+/gi;
	var regStr_saf = /safari\/[\d.]+/gi;

	//IE
	if (agent.indexOf('msie') > 0) {
		return 0.2;
	}

	//firefox
	if (agent.indexOf('firefox') > 0) {
		return 0.2;
	}

	//Chrome
	if (agent.indexOf('chrome') > 0) {
		return 0.08;
	}

	//Safari
	if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
		return 0.2;
	}

	return 0.2;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`, {
			wheelSpeed: setScollerV()
		});
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

function reinitScrollbar() {
	let docClasses = document.body.classList;
	let isWindows = navigator.platform.startsWith('Win');
	if (isWindows) {
		// if we are on windows OS we activate the perfectScrollbar function
		initScrollbar('sidebar');
		initScrollbar('sidebar-wrapper');
		// initScrollbar("main-panel");

		docClasses.add('perfect-scrollbar-on');
	} else {
		docClasses.add('perfect-scrollbar-off');
	}
}

import TopTab from './TopTab';
import TopTabDark from './TopTabDark.vue';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
//import FixedPlugin from "./FixedPlugin.vue";
import ModuleNav from './ModuleNav.vue';
import KSider from '../../components/k-menu/k-sider';
import KSiderDark from '../../components/k-menu/k-sider-dark';
import mockMenuData from './mockMenuData.json'
import WujieVue from 'wujie-vue2'
import auth from '@/utils/auth';
import { MicroAppConfig } from '@/micro-app/utils';
export default {
	components: {
		TopNavbar,
		TopTab,
		ContentFooter,
		// FixedPlugin,
		ModuleNav,
		KSider,
		KSiderDark,
		TopTabDark,
		KeepAliveContent,
		TopNavbarDark
	},
	data() {
		return {
			iframeUrl: '',
			iframeTarget: '',
			dialogVisible: false,
			siderShow: true,
			sidebarBackgroundColor: 'black',
			sidebarBackground: 'green',
			sidebarBackgroundImage: './static/images/sidebar/sidebar-2.jpg',
			sidebarMini: false,
			sidebarImg: true,
			leftMenuLevel: '',
			menus: [],
			menus2: [],
			subMenus: [],
			title: localStorage.getItem('systemName'),
			timer: null, //定时任务
			menuid: 'M20',
			CompileData: {
				show: false,
				compileMenuY: 0,
				compileMenuX: 0,
				item: null,
			}
		};
	},
	created() {
		this.loadMenu();
		this.openPopupContent();
		if (!document.body.classList.contains('new-side-bar')) {
			document.body.classList.add(['new-side-bar']);
		}
		MicroAppConfig.forEach((appConfig) => {
			const { name, url, alive } = appConfig
			if (name == 'cms') {
				this.iframeUrl = url;
				if (this.iframeUrl.indexOf("/#") > -1) {
					this.iframeTarget = this.iframeUrl.split("/#")[0] + "/#/login";
				} else {
					this.iframeTarget =  this.iframeUrl + "/#/login";
				}
			}
			console.log("this.iframeUrl", this.iframeUrl);
			console.log("this.iframeTarget", this.iframeTarget);
		})
	},
	methods: {
		openPopupContent() {
			WujieVue.bus.$on("popup-route-content", (data) => {
				console.log("popup-route-content-main", data);
				const iframe = document.getElementById('cms-iframe')
				iframe.contentWindow.postMessage(data, this.iframeUrl);
				this.dialogVisible = true;
			})
		},
		hideSider() {
			this.siderShow = !this.siderShow;
		},
		/** 加载菜单 */
		loadMenu() {
			this.httpUtil
				.query({
					url: 'sys/findMenus.json'
				})
				.then(data => {
					// this.menus = mockMenuData;
					this.menus = data.rows;
					this.menus.map(menu => {
						if (menu.children) {
							menu.url = this.removeLeadingSlash(menu.url)
							menu.children.map(item => {
								item.url = this.removeLeadingSlash(item.url)
								if (item.children) {
									item.children.map(itemChild => {
										itemChild.url = this.removeLeadingSlash(itemChild.url)
										if (itemChild.url) {
											this.subMenus.push({ label: itemChild.menuname, value: itemChild.url });
										}
									});
								} else {
									if (item.url) {
										this.subMenus.push({ label: item.menuname, value: item.url });
									}
								}
							});
						}
					});
					this.menus.forEach(menu1 => {
						if (menu1.children && menu1.children.length > 0) {
							menu1.children.forEach(menu2 => {
								if (menu2.menutype == 1) {
									this.$set(menu2, 'visiblePopover', false);
								}
							});
						}
					});

					// 设置顶部栏初始状态
					let currentPath = this.$route.path
					if (currentPath.indexOf('/main') > -1) {
						const { name } = getCurrentAppConfigForUrl(currentPath)
						currentPath = currentPath.replace(`/main/${name}/`, '')
						let target = this.findAllPath(this.menus, currentPath)
						if (target) {
							this.menuid = target.menuid
						} else {
							// 默认第一个
							this.menuid = this.menus[0].menuid
						}
					}
				});
		},
		// 递归查找当前的Path对应的菜单
		findAllPath(menusData, currentPath) {
			return menusData.find(item => {
				if (item.children && item.children.length > 0) {
					return this.findAllPath(item.children, currentPath)
				}
				if (item.url && item.url === currentPath) {
					return true
				} else {
					return false
				}
			})
		},
		removeLeadingSlash(str) {
			return str ? str.replace(/^\//, '') : str;
		},
		// 定时任务查询通知消息
		startTimer(millisecond) {
			this.timer = setInterval(() => {
				this.getMessageListFun();
			}, millisecond);
		},
		// 查询通知数据接口
		getMessageListFun() {
			this.httpUtil
				.comnQuery({
					action: 'M007.msgCardQurey'
				})
				.then(res => {
					if (res && res.returndata && res.returndata.messageList) {
						this.$refs.kFieldNotification.beforeOpenNotFun(res.returndata.messageList);
					}
				});
		},
		// 停止定时任务
		endTimer() {
			clearInterval(this.timer);
			this.timer = null;
		},
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
		// sideToggleMinimize(){
		//   this.$refs.fixedPlugin.updateToggleList();
		// },
		clickModuleNav(menus) {
			this.menus2 = menus;
			this.menus2.map(item => {
				item.level = 2;
			});
		},
		reinitScrollbarx() {
			if (this.$globalTheme == 'defalutTheme') {
				reinitScrollbar();
			}
		},
		changeAllChildrenToFalse(m1, index) {
			this.menuid = m1.menuid;
			// data.forEach(item => {
			// 	if (item.children) {
			// 		item.children.forEach(data => {
			// 			data.active = false;
			// 		});
			// 	}
			// });
			this.$refs.darkThemeSider.clickMenuItem(m1, index);
		},
		isActive(m1) {
			if (m1.menuid == this.menuid) {
				return true;
			} else {
				return false;
			}
		},
		findeActiveItem(data, url, root) {
			let flag = true;
			data.forEach(item => {
				transformMicroUrlToNormal(`/main/${url}`)
				if (item.url === transformMicroUrlToNormal(`/main/${url}`)) {
					this.menuid = root.menuid;
					flag = false;
				}
				if (item.children && item.children.length && flag) {
					if (root) {
						this.findeActiveItem(item.children, url, root);
					} else {
						this.findeActiveItem(item.children, url, item);
					}

				}
			});
		},
		contextmenuClick(item, e) {
			this.CompileData.compileMenuX = e.clientX;
			this.CompileData.compileMenuY = e.clientY;
			this.CompileData.item = item;
			this.CompileData.show = true;
		},
		closeTop(closeName) {
			this.$refs.topTabRefs.closeTop(closeName, this.CompileData.item);
		}
	},
	beforeDestroy() {
		clearInterval(this.timer); //清除定时任务
	},
	updated() {
		if (this.$globalTheme == 'defalutTheme') {
			reinitScrollbar();
		}
	},
	mounted() {
		if (this.$globalTheme == 'defalutTheme') {
			reinitScrollbar();
		}
		const iframe = document.getElementById('cms-iframe')
		let _this = this;
		iframe.onload = function (e) {
			console.log("--mounted--this.iframeUrl--", _this.iframeUrl);
			const token = auth.getToken()
			iframe.contentWindow.postMessage({ token: token }, _this.iframeUrl);
		}
	},
	watch: {
		sidebarMini() {
			let isMini = document.body.classList.value.indexOf('sidebar-mini') == -1;
			if (this.sidebarMini != isMini) {
				this.minimizeSidebar();
			}
		},
		'$route.path': function (newVal) {
			if (newVal !== this.$HomePageRoute && newVal) {
				//this.changeMenus(newVal.slice(6));
				this.findeActiveItem(this.menus, newVal.slice(6), null)
			}
		}
	}
};
</script>
<style lang="scss">
@import "MainLayout.scss";

.wujie-content {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	margin: 0;
	background: rgb(0 0 0 / 50%);
	;
	z-index: 4000;
}

.iframe-div {
	position: relative;
	width: 80%;
	margin: 4vh auto;
	background: #fff;

	.iframe-header {
		padding: 12px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		i {
			cursor: pointer;
		}
	}
}

.iframe {
	position: relative;
	width: 100%;
	height: calc(80vh) !important;
}

// 控制淡入淡出效果
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
