<template>
	<div class="k_container_left">
		<div class="left_top">
			<el-input v-model="searchVal" :placeholder="dataPlaceholderText" clearable @change="handleChange"></el-input>
			<i @click="handleChange" class="el-icon-search"></i>
		</div>
		<div class="add">
			<k-btn class="md-plain-golden" data-functype="POPUP" :data-handler="clearFormData" :data-target="dataPopupTarget">
				<i class="iconfont icon-xinzeng"></i> 新增
			</k-btn>
		</div>
		<div class="left_main" ref="leftMain">
			<template v-if="list.length">
				<div class="left_main_item" v-for="item in list" :class="active === item[dataShowParams.value] ? 'active' : ''" @click="handleClick(item)">
					<div class="item_left">
						<slot name="tag" :row="item"></slot>
						<div class="item_main" :style="{ width: mainWidth }">
							<div class="name">{{ item[dataShowParams.value] }}</div>
							<div class="des">{{ item[dataShowParams.des] }}</div>
						</div>
					</div>
					<div class="item_right">
						<div class="operate">
							<slot name="operate" :row="item"></slot>
						</div>
					</div>
				</div>
				<div class="more" @click="loadMore">
					<span>{{ isLoading ? "加载中" : "查看更多" }}</span>
					<i :class="isLoading ? 'el-icon-loading' : 'el-icon-arrow-down'"></i>
				</div>
			</template>
			<div class="not_data" v-else>没有搜索的内容</div>
		</div>
	</div>
</template>

<script>
import emitter from "@/components/k-element/common/k-emitter.js";
import scrollToBottom from "@/components/k-element/common/k-scroll-to-bottom.js";
import { concat } from "lodash";
export default {
	name: "KGrid",
	// mixins: [emitter(),scrollToBottom('_scrollToBottom')],
	mixins: [emitter()],
	emits: ["changeStart"],
	props: {
		dataData: {
			type: Array,
		},
		dataTotal: {
			type: String | Number,
		},
		dataShowParams: {
			type: Object,
		},
		dataPlaceholderText: {
			type: String,
		},
		dataSearchParams: {
			type: String,
		},
		dataTarget: {
			type: String,
		},
		dataPopupTarget: {
			type: String,
		},
		dataQueryParams: {
			type: Object,
		},
		dataStart: {
			type: Number,
		},
		mainWidth: {
			type: String,
			default: "148px",
		},
	},
	data() {
		return {
			list: [],
			active: "",
			searchVal: "",
			total: 0,
			start: 1,
			isLoading: false,
		};
	},
	methods: {
		handleClick(item) {
			this.active = item[this.dataShowParams.value];
			this.$emit("data-row-select", item);
		},
		handleChange() {
			// 如果移除了监听需要重新监听
			// if(!this.isAddScroll){
			//     this.addEvent()
			// }
			this.loadData((this.start = 1));
		},
		clearFormData() {
			this.$emit("clearFormData");
		},
		loadMore() {
			if (this.list.length === this.total) {
				this.$message.info("没有更多数据了");
				// this.removeListener()
			} else {
				// 加载
				this.isLoading = true;
				this.loadData(++this.start);
			}
		},
		loadData(start) {
			this.$emit("changeStart", start);
			this.getParentRef(this.dataTarget)
				.load(
					{
						...this.dataQueryParams,
						[this.dataSearchParams]: this.searchVal,
					},
					start
				)
				.then((data) => {
					if (this.start === 1) {
						this.list = concat([], data.rows);
						// 每次更新的时候重置滚动条
						this.getParentRef("kContainerAside").$el.scrollTop = 0;
					} else {
						const scrollTopVal = this.getParentRef("kContainerAside").$el.scrollTop;
						this.list = concat(this.list, data.rows);
						this.getParentRef("kContainerAside").$el.scrollTop = scrollTopVal;
					}
					this.total = data.results;
					this.isLoading = false;
				});
		},
		getRowData(ev) {
			if (!ev) {
				return null;
			}

			let idx = null;
			let currentRow = ev.target.closest(".left_main_item");
			if (!currentRow) {
				return null;
			}
			let rows = this.$refs.leftMain.querySelectorAll(".left_main_item");
			for (let index = 0; index < rows.length; index++) {
				const item = rows[index];
				if (currentRow === item) {
					idx = index;
					break;
				}
			}
			return idx != null ? Object.assign({}, this.flattenTableDataDeep()[idx]) : null;
		},
		flattenTableDataDeep() {
			let res = [];
			this.flattenDeepHelper(this.list, res);
			return res;
		},
		flattenDeepHelper(tableData, res) {
			for (let i = 0; i < tableData.length; i++) {
				let item = tableData[i];
				res.push(item);
				if (item.children) {
					this.flattenDeepHelper(item.children, res);
				}
			}
		},
	},
	watch: {
		dataData: function (newVal, oldVal) {
			if (this.dataStart === 1) {
				this.list = newVal;
				this.total = this.dataTotal;
				// 判断是否移除监听
				// if(!this.isAddScroll){
				//     this.addEvent()
				// }
			}
		},
	},
	activated() {
		this.handleChange(this.searchVal);
	},
};
</script>

<style lang="scss" scoped>
.k_container_left {
	.left_top {
		display: flex;
		position: sticky;
		top: 0;
		background: #1f232f;
		// margin-bottom: 5px;
		align-items: center;
		z-index: 2;
		padding: 16px 8px 5px;
		color: #2a2d3f;
		.el-icon-search {
			display: block;
			width: 40px;
			height: 32px;
			text-align: center;
			line-height: 32px;
			background-image: linear-gradient(90deg, #f5e9ca 0%, #b39e7b 100%);
			border-radius: 0px 2px 2px 0px;
			cursor: pointer;
		}
	}
	.add {
		position: sticky;
		top: 53px;
		background: #1f232f;
		z-index: 2;
		.md-button {
			border: none;
			font-size: 14px;
			color: #f6f6f6;
			&:hover {
				color: #f6f6f6;
			}
			.icon-xinzeng {
				color: #f6f6f6;
				font-size: 12px !important;
			}
		}
	}
	.left_main {
		padding-right: 5px;
		& > .left_main_item {
			position: relative;
			width: 100%;
			padding: 0 8px;
			height: 48px;
			// border-bottom: 1px solid #000;
			display: flex;
			align-items: center;
			border-radius: 2px;
			cursor: pointer;
			.item_left {
				// width: 148px;
				display: flex;
				align-items: center;
			}
			.name {
				width: 100%;
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*让文本强制在一行不换行*/
				text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
				font-size: 14px;
				color: #f6f6f6;
				line-height: 22px;
			}
			.des {
				opacity: 0.7;
				font-family: PingFangSC-Regular;
				font-size: 12px;
				color: #ffffff;
				line-height: 20px;
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*让文本强制在一行不换行*/
				text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
			}
			.item_right {
				flex: 1;
				display: none;
			}
			.operate {
				display: flex;
				justify-content: flex-end;
				::v-deep .md-button .md-button-content i {
					font-size: 16px !important;
				}
			}
			&:hover,
			&.active {
				background: rgba(255, 255, 255, 0.08);
				.item_right {
					display: block;
				}
			}
		}
		.more {
			display: flex;
			height: 50px;
			align-items: center;
			justify-content: center;
			color: #fff;
			cursor: pointer;
			span {
				margin-right: 3px;
			}
		}
		.not_data {
			color: #909399;
		}
	}
}
</style>
