<template>
  <div>
    <div class="k-sidebar-mini" v-if="!siderShow">
      <div class="sidebar-wrapper">
        <div class="left-logo">
          <img class="left-logo-img" src="../../assets/img/logo.png" alt="" />
        </div>
        <div class="right-menu">
          <ul class="nav">
            <li class="nav-li" v-for="(m2, index) in menus2" :key="m2.menuid" :data-menu-key="'m2-' + m2.menuid"
              v-show="m2.upperid == m1.menuid" :class="{ 'nav-link-active': m2.active }">

              <el-popover v-if="m2.children" placement="right-start" trigger="hover">
                <div class="nav-li-mini-menu-nav-popover">
                  <el-collapse-transition>
                    <ul class="nav-li-menu-nav">
                      <!-- 三级菜单 -->
                      <li v-for="(m3, indexM3) in m2.children" class="nav-li-menu-nav-li" :key="m3.menuid"
                        :data-menu-key="'m3-' + m3.menuid">
                        <div class="nav-li-menu-nav-link flex_sp"
                          :class="{ 'route-active': m3.active, 'nav-li-active': m3.active, }"
                          @click="clickMenu3Item(m3, indexM3)">
                          <template>
                            <div class="menuname m3-menu">
                              {{ m3.menuname }}
                            </div>

                            <i v-show="m3.children && !m3.active" class="el-icon-arrow-down down-icon"></i>
                            <i v-show="m3.children && m3.active" class="el-icon-arrow-up up-icon"></i>
                          </template>
                        </div>

                        <!-- 四级菜单 -->
                        <div class="nav-li-menu">
                          <el-collapse-transition>
                            <ul class="nav-li-menu-nav-m4" v-show="m3.active">
                              <li v-for="m4 in m3.children" class="nav-li-menu-nav-li-m4" :key="m4.menuid"
                                :data-menu-key="'m4-' + m4.menuid">
                                <div class="nav-li-menu-nav-link_4"
                                  :class="{ 'route-active': isActive(m4), 'nav-li-active': isActive(m4), }"
                                  @click="clickMenu4Item(m4)">
                                  <template>
                                    <span class="menuname menuname4">{{ m4.menuname }}</span>
                                  </template>
                                </div>
                              </li>
                            </ul>
                          </el-collapse-transition>
                        </div>
                      </li>
                    </ul>
                  </el-collapse-transition>
                </div>

                <div class="nav-link-right" slot="reference">
                  <svg-icon style="margin: 13px;" :icon-class="m2.icon?m2.icon:'icon-data'" fill="none"
                    :stroke="m2.active ? '#ffffff' : '#606266'" width="18px" height="18px"></svg-icon>

                </div>
              </el-popover>

              <div v-else class="nav-link-right" @click="clickMenu2Item(m2, index)">
                <svg-icon style="margin: 13px;" :icon-class="m2.icon?m2.icon:'icon-data'" fill="none"
                  :stroke="m2.active ? '#ffffff' : '#606266'" width="18px" height="18px"></svg-icon>

              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="k-sidebar" v-else>
      <div class="sidebar-wrapper scrollbar_style" ref="sidebarScrollArea">
        <div class="left-logo">
          <img class="left-logo-img" src="../../assets/img/icon-logo-min.png" alt="" />
        </div>
        <div class="right-menu" ref="rightMenu">
          <ul class="nav">
            <li class="nav-li" v-for="(m2, index) in menus2" :key="m2.menuid" :data-menu-key="'m2-' + m2.menuid"
              v-show="m2.upperid == m1.menuid" :class="{ 'nav-li-unactive': !m2.active }">
              <el-popover v-if="m2.menutype == 1" v-model="m2.visiblePopover" placement="right-start" trigger="click"
                :visible-arrow="false" popper-class="menuPopover" transition="" :open-delay="0" :close-delay="0"
                @show="popoverShow" @hide="popoverHide(m2)">
                <div class="menuPopover_body" :style="{ width: forthWidth }">
                  <div class="menuPopover_bodyItem" v-for="(menuItem, popIndex) in popShowMenu"
                    :key="menuItem.menuid + '-' + String(popIndex)"
                    :data-menu-key="'pop-' + menuItem.menuid + '-' + String(popIndex)">
                    <div v-if="menuItem.isFather" class="menuPopover_bodyItem_header">
                      <img src="../../assets/img/icon-tag.svg" />
                      <span>{{ menuItem.menuname }}</span>
                    </div>
                    <div v-else-if="menuItem.isNull === true" style="height: 20px"></div>
                    <div v-else-if="menuItem.isNullMenu === true" style="height: 20px">
                      {{ menuItem.menuname }}
                    </div>
                    <div v-else class="menuPopover_bodyItem_body">
                      <el-tooltip popper-class="darktips" effect="dark" :content="menuItem.menuname"
                        placement="top-start" :disabled="menuItem.menuname.length < 13">
                        <div class="menuPopover_bodyItem_body_item" @click="jupMenu(menuItem, m2)">
                          {{ menuItem.menuname }}
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="nav-link flex_s" :class="{
      'nav-link-active': isGroupActive(m2, 4),
      'nav-li-unactive': !m2.active,
      'route-active': isActive(m2),
      'children-route-active': isChildrenActive(m2),
      'nav-li-active': isActive(m2),
    }" @click="clickMenu2Item(m2, index)" slot="reference">
                  <template>
                    <span class="menuname">{{ m2.menuname }}</span>
                    <img v-show="m2.children && !m2.url && backgroundColor == 'black'" class="cert"
                      src="../../assets/svg/k-menu/down.svg" />
                    <img v-show="m2.children && !m2.url && backgroundColor == 'red'" class="cert"
                      src="../../assets/svg/k-menu/down.svg" />
                    <img v-show="m2.children && !m2.url && backgroundColor == 'white'" class="cert"
                      src="../../assets/svg/k-menu/menu-down-black.svg" />
                  </template>
                </div>
              </el-popover>
              <!-- 二级菜单分类标题 -->
              <div v-else class="nav-link flex_s" :class="{
      'nav-link-active': m2.active,
      'nav-li-unactive': !m2.active,
      'route-active': isActive(m2, 2),
      'children-route-active': isChildrenActive(m2),
      'nav-li-active': isActive(m2, 2),
    }" @click="clickMenu2Item(m2, index)">
                <template>
                  <div class="nav-link-left"></div>
                  <div class="nav-link-right">
                    <svg-icon style="margin-right: 6px;margin-left: 14px;" :icon-class="m2.icon?m2.icon:'icon-data'"
                      fill="none" :stroke="m2.active ? '#ffffff' : '#606266'" width="18px" height="18px"></svg-icon>

                    <span class="menuname">{{ m2.menuname }}</span>
                    <i v-show="m2.children && !m2.active" class="el-icon-arrow-down down-icon"
                      :class="{ 'up-down-active': m2.active }"></i>
                    <i v-show="m2.children && m2.active" class="el-icon-arrow-up up-icon"
                      :class="{ 'up-down-active': m2.active }"></i>
                  </div>

                </template>
              </div>

              <div class="nav-li-menu" v-if="m2.menutype != 1">
                <el-collapse-transition>
                  <ul class="nav-li-menu-nav" v-show="m2.active">
                    <!--三级菜单-->
                    <li v-for="(m3, indexM3) in m2.children" class="nav-li-menu-nav-li" :key="m3.menuid"
                      :data-menu-key="'m3-' + m3.menuid">
                      <div class="nav-li-menu-nav-link flex_sp"
                        :class="{ 'route-active': m3.active, 'nav-li-active': m3.active, }"
                        @click="clickMenu3Item(m3, indexM3)">
                        <template>
                          <el-popover v-if="m3.children" placement="right-start" trigger="hover">
                            <div class="">
                              <ul class="nav-li-menu-nav-m4">
                                <li v-for="m4 in m3.children" class="nav-li-menu-nav-li-m4" :key="m4.menuid"
                                  :data-menu-key="'m4-' + m4.menuid">
                                  <div class="nav-li-menu-nav-link_4"
                                    :class="{ 'route-active': m4.active, 'nav-li-active': m4.active, }"
                                    @click="clickMenu4Item(m4)">
                                    <template>
                                      <span class="menuname4">{{ m4.menuname }}</span>
                                    </template>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="menuname m3-menu" slot="reference">
                              {{ m3.menuname }}
                            </div>
                          </el-popover>
                          <div v-else class="menuname m3-menu">
                            {{ m3.menuname }}
                          </div>

                        </template>
                      </div>

                    </li>
                  </ul>
                </el-collapse-transition>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { getAppNameForMenu, getConfig, transformMicroUrlToNormal } from "@/micro-app/utils"

export default {
  name: 'k-sider',
  props: {
    title: {
      type: String
    },
    activeColor: {
      type: String,
      default: 'green',
      validator: value => {
        let acceptedValues = ['', 'purple', 'azure', 'green', 'orange', 'danger', 'rose'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    backgroundImage: {
      type: String,
      default: './img/sidebar-2.jpg'
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: value => {
        let acceptedValues = ['', 'black', 'white', 'red'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: './../assets/img/menuLogo.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    siderShow: {
      type: Boolean,
      default: true
    },
    menus: {
      type: Array,
      default: () => []
    },
    subMenus: {
      type: Array
    }
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    },
    menus2() {
      let temp = [];
      this.menus.forEach((item, index) => {
        if (item.children) {
          temp = temp.concat(item.children);
        }
      });
      return temp;
    },
    menus3() {
      let temp = [];
      this.menus2.forEach((item, index) => {
        if (item.children) {
          temp = temp.concat(item.children);
        }
      });
      return temp;
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  data() {
    return {
      currentMenu1Index: -1,
      currentMenu2Index: -1,
      m1: {},
      // showIcon: false,
      menuData: '',
      popShowMenu: [],
      activeItem: null, // 搜索时选中的内容
      forthWidth: '0px' //四级菜单宽度
    };
  },
  watch: {
    menus: function (newVal) {
      if (this.$route.path === this.$HomePageRoute || this.$route.meta.notLinkage) {
        this.clickMenuItem(newVal[0], 0);
      } else {
        this.changeMenus(transformMicroUrlToNormal(this.$route.path));
      }
    },
    '$route.path': function (newVal) {
      if (this.$route.path !== this.$HomePageRoute) {
        this.changeMenus(transformMicroUrlToNormal(this.$route.path));
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[newVal] &&
            this.$refs[newVal][0].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
        }, 300);
      });
    }
  },
  created() { },
  methods: {
    menuChange(url) {
      //判断当前打开的地址是不是已经打开了
      let currentUrl = this.$router.history.current.path;
      let openUrl = '/main/' + url;

      if (currentUrl != openUrl) {
        this.$router.push({
          path: openUrl
        });
      }
    },
    changeMenus(url) {
      this.findeActiveItem(this.menus, url);
      let temp = this.activeItem ? this.activeItem.upperid : '';
      this.changeParentActive(this.menus, temp);
    },
    findeActiveItem(data, url) {
      let flag = true;
      data.forEach(item => {
        if (this.removeLeadingSlash(item.url) === url) {
          this.activeItem = item;
          // 所有的同级菜单取反
          this.changeOtherToFalse(this.menus, item.upperid);
          item.active = true;
          flag = false;
        } else {
          item.active = false;
        }
        if (item.children && item.children.length && flag) {
          this.findeActiveItem(item.children, url);
        }
      });
    },
    changeOtherToFalse(data, upperid) {
      let flag = true;
      data.forEach(item => {
        if (item.menuid === upperid) {
          item.children.forEach(data => {
            data.active = false;
          });
          flag = false;
        }
        if (item.children && flag) {
          this.changeOtherToFalse(item.children, upperid);
        }
      });
    },
    changeParentActive(data, upperid) {
      data.forEach((item, index) => {
        // 找到选中的父级菜单
        if (item.menuid === upperid) {
          if (!item.upperid) {
            // 不存在上级id则为一级菜单
            this.currentMenu1Index = index;
            this.m1 = item;
            // localStorage.setItem('defaultLevel1Menu',item.menuname)
          } else {
            // 非一级菜单
            this.changeOtherToFalse(this.menus, item.upperid);
            item.active = true;
            // 还有父类继续递归
            this.changeParentActive(this.menus, item.upperid);
          }
        }
        if (item.children) {
          this.changeParentActive(item.children, upperid);
        }
      });
    },
    changeAllChildrenToFalse(data) {
      data.forEach(item => {
        if (item.children) {
          item.children.forEach(data => {
            data.active = false;
          });
        }
      });
      console.log("-----this.menus---data----", data);
    },
    handleClose(item) {
      const tab = this.$store.state.system.tab;
      const path = '/main/' + item.value;
      const index = tab.findIndex(data => path === data.path);
      if (index !== -1) {
        this.$store.commit('system/setTabSplice', { index: index, count: 1 });
        this.$router.push({
          path: tab[index - 1].path,
          query: tab[index - 1].query
        });
        this.menuData = '';
      }
    },
    gotoPage() {
      // 查询驾驶仓菜单
      this.httpUtil.comnQuery({
        action: "Menu.find",
        params: { menuid: 'M11' },
      }).then(res => {
        if (res?.rows?.length > 0 && res.rows[0]?.url) {
          let url = res.rows[0]?.url
          if (url.startsWith('/')) {
            url = url.substring(1, url.length)
          }
          let urls = `/main/${url}`.split('desktop/')
          if (urls.length > 1) {
            this.$router.push({
              path: this.$HomePageRoute,
              query: {
                code: urls[1]
              }
            })
          }
        }
      })
    },
    removeLeadingSlash(str) {
      return str ? str.replace(/^\//, '') : str;
    },
    isChildrenActive(m2) {
      let a = false;
      if (m2.children) {
        const appName = getAppNameForMenu(m2)
        m2.children.forEach(item => {
          if (item.url && this.$route.path.startsWith(`/main/${appName}/` + this.removeLeadingSlash(item.url))) {
            a = true;
          }
        });
      }
      return a;
    },
    isActive(menuData, index) {
      const appName = getAppNameForMenu(menuData)
      if (menuData.url && this.$route.path.startsWith(`/main/${appName}/` + this.removeLeadingSlash(menuData.url)) && index != 2) {
        return true;
      } else {
        return false;
      }
    },
    elementType(m2, isParent = true) {
      if (m2.url) {
        return 'router-link';
      } else {
        return 'a';
      }
    },
    clickMenuItem(m1, index) {
      this.currentMenu2Index = -1;
      this.currentMenu1Index = index;
      this.m1 = m1;
      // this.changeAllChildrenToFalse(this.menus);
      this.$nextTick(() => {
        //this.$emit("reinitScrollbar");
      });
      // this.menus2=m1.children
    },
    /**
     * 跳转判断
     */
    getNavigation(menuData) {
      const { url } = menuData
      // 获取具体子应用
      const AppName = getAppNameForMenu(menuData)
      if (AppName) {
        // 微前端子应用跳转
        // const { routePrefix } = getConfig(AppName)
        // eg. "/main/market/cdp/portrait-list"
        return url.startsWith('/') ? `/main/${AppName}${url}` : `/main/${AppName}/${url}`
        // return `/main/${AppName}/${url}`
      } else {
        return `/main/${url}`
      }
    },
    clickMenu2Item(m2, index) {
      if (m2.url) {    // 跳转判定
        // 正常跳转
        this.$router.push({
          path: this.getNavigation(m2)
        })
        return
      }
      this.menus2.forEach((item, index2) => {
        if (index2 == index) {
          if (m2.active) {
            if (m2.menuType == 1) {
              m2.visiblePopover && this.$set(this.menus2[index], 'active', false);
            } else {
              this.$set(this.menus2[index], 'active', false);
            }
          } else {
            this.$set(this.menus2[index], 'active', true);
          }
        } else {
          if (item.upperid == this.m1.menuid) {
            this.$set(this.menus2[index2], 'active', false);
          }
        }
      });
      this.$forceUpdate();
      this.popShowMenu = [];
      let oneColNum = 0;
      m2.children.forEach(menuData3 => {
        menuData3.isFather = true;
        oneColNum++;
        //三级菜单在最后一个时候自动换行
        if (oneColNum == 11) {
          this.popShowMenu.push({ isNull: true });
          oneColNum = 0;
        }
        this.popShowMenu.push(menuData3);
        if (menuData3.children && menuData3.children.length > 0) {
          menuData3.children.forEach(menuData4 => {
            oneColNum++;
            if (oneColNum == 11) {
              oneColNum = 0;
            }

            this.popShowMenu.push(menuData4);
          });
        }
      });
      const showMenuLen = this.popShowMenu.length;
      let nullMenuNum = 11 - Math.floor(showMenuLen % 11);
      const colNum = Math.floor(showMenuLen / 11);
      // 计算宽度
      this.forthWidth = 210 * Math.ceil(showMenuLen / 11) + 'px';
      if (colNum > 0 && nullMenuNum !== 11) {
        for (let index = 0; index < nullMenuNum; index++) {
          // 空数组填充
          this.popShowMenu.push({
            menuid: new Date().getTime() + Math.floor(Math.random() * 1000) + index,
            isNullMenu: true
          });
        }
      }
    },
    clickMenu3Item(m3, index) {
      this.menus3.forEach((item, index3) => {
        if (item.menuid == m3.menuid) {
          if (m3.active) {
            this.$set(this.menus3[index3], 'active', false);
          } else {
            this.$set(this.menus3[index3], 'active', true);
          }
        } else {
          this.$set(this.menus3[index3], 'active', false);
        }
      });
      this.$forceUpdate();
      if (m3.url) {    // 跳转判定
        // 正常跳转
        this.$router.push({
          path: this.getNavigation(m3)
        })
        return
      }
    },
    clickMenu4Item(m4) {
      if (m4.url) {    // 跳转判定
        // 正常跳转
        this.$router.push({
          path: this.getNavigation(m4)
        })
        return
      }
      this.$forceUpdate();
    },
    jupMenu(menuData4, m2) {
      m2.visiblePopover = false;
      m2.active = false;
      this.$router.push({
        path: '/main/' + menuData4.url
      });
    },
    popoverShow() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    popoverHide(m2) {
      m2.active = false;
      // this.$forceUpdate()
    },
    // 分组标题高亮控制
    isGroupActive(item, level) {
      // 四级菜单 判断2级高亮
      if (level === 4 && item.children) {
        const menu3Item = item.children.find(v => v.menuid === this.activeItem?.upperid);
        const menu2Item = this.menus2.find(v => v.menuid === menu3Item?.upperid);
        // console.log(item,menu3Item,menu2Item,item.menuid === menu2Item?.menuid)
        return item.menuid === menu2Item?.menuid;
      } else if (item.menuid === this.activeItem?.menuid) {
        return true;
      } else {
        return item.menuid === this.activeItem?.upperid;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.k-sidebar_home_icon {
  color: rgb(161, 164, 170);
  margin-top: 0px;
  font-size: 16px;
  font-weight: 700;
}
</style>
