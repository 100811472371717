<template>
	<div class="k_transfer">
		<div class="transfer_left">
			<div class="left_title">
				<el-checkbox :disabled="disabled" :indeterminate="indeterminate" v-model="checkAll" @change="selectAll">{{ leftTitle }}</el-checkbox>
				<div class="title_des">{{ checkedNum() }}/{{ selectableNum() }}</div>
			</div>
			<div class="search">
				<el-input placeholder="请输入搜索内容" v-model="leftSearchVal" @change="filterList" />
				<div class="search_btn" @click="filterList">
					<i class="el-icon-search"></i>
				</div>
				<div class="search_close" v-show="leftSearchVal" @click="clearSearchVal">
					<i class="el-icon-close"></i>
				</div>
			</div>
			<div class="main">
				<el-checkbox
					:disabled="disabled"
					v-for="item in list"
					v-show="!item.isSelect && item.isShow"
					v-model="item.isChecked"
					:key="item[returnFlag]"
					@change="changeIsChecked"
					>{{ item[label] }}</el-checkbox
				>
			</div>
		</div>
		<div class="transfer_btn" @click="transferToRight">
			<i class="el-icon-arrow-right"></i>
		</div>
		<div class="transfer_right">
			<div class="right_title">
				<div class="title">{{ rightTitle }}</div>
				<div class="des" v-if="maxLength">最多可选择{{ maxLength }}项</div>
			</div>
			<div class="search">
				<el-input placeholder="请输入搜索内容" v-model="rightSearchVal" @change="rightFilterList" />
				<div class="search_btn" @click="rightFilterList">
					<i class="el-icon-search"></i>
				</div>
				<div class="search_close" v-show="rightSearchVal" @click="rightClearSearchVal">
					<i class="el-icon-close"></i>
				</div>
			</div>
			<div class="main">
				<div class="item" v-for="item in list" :key="item[returnFlag]" v-show="item.isSelect && item.isShow">
					<span class="label" :class="disabled ? 'disabled' : ''">{{ item[label] }}</span>
					<i class="el-icon-close" v-if="!disabled" @click="transferToLeft(item)"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { cloneDeep } from "lodash";
import Tools from "@/utils/tools";
export default {
	name: "KFransfer",
	props: {
		value: {
			type: Array,
		},
		leftTitle: {
			type: String,
		},
		rightTitle: {
			type: String,
		},
		maxLength: {
			type: String | Number,
		},
		data: {
			type: Array,
		},
		label: {
			type: String,
		},
		returnFlag: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
	},
	data() {
		return {
			checkAll: false,
			indeterminate: false,
			list: [],
			leftSearchVal: "",
			rightSearchVal: "",
		};
	},
	methods: {
		init() {
			this.list = this.handleData(this.data);
			if (this.value) {
				this.list.forEach((item) => {
					if (this.value.includes(item[this.returnFlag])) {
						item.isSelect = true;
						item.isChecked = true;
					}
				});
			}
		},
		selectAll(value) {
			this.indeterminate = false;
			if (value) {
				this.selectAllIsShow(true);
			} else {
				this.selectAllIsShow(false);
			}
		},
		selectAllIsShow(value) {
			this.list.forEach((item) => {
				if (!item.isSelect) {
					item.isChecked = value;
				}
			});
		},
		filterList() {
			this.list.forEach((item) => {
				if (!item.isSelect) {
					if (item.label.includes(this.leftSearchVal)) {
						item.isShow = true;
					} else {
						item.isShow = false;
					}
				}
			});
		},
		clearSearchVal() {
			this.leftSearchVal = "";
			this.filterList();
		},
		changeIsChecked(value) {
			if (value) {
				if (this.checkedNum() === this.selectableNum()) {
					this.indeterminate = false;
					this.checkAll = true;
				} else {
					this.indeterminate = true;
				}
			} else {
				this.checkAll = false;
				if (this.hasChecked()) {
					this.indeterminate = true;
				} else {
					this.indeterminate = false;
				}
			}
		},
		checkedNum() {
			return this.list.filter((item) => !item.isSelect && item.isChecked).length;
		},
		selectableNum() {
			return this.list.filter((item) => !item.isSelect).length;
		},
		isCheckedNum() {
			return this.list.filter((item) => item.isSelect).length;
		},
		hasChecked() {
			return this.list.some((item) => !item.isSelect && item.isChecked);
		},
		transferToRight() {
			if (this.checkedNum() > +this.maxLength || this.isCheckedNum() + this.checkedNum() > +this.maxLength) {
				setTimeout(() => {
					Tools.alert(`最多可选择${this.maxLength}项`, "danger");
				}, 300);
			} else {
				let returnData = [];
				this.indeterminate = false;
				this.checkAll = false;
				this.list.forEach((item) => {
					if (item.isChecked) {
						item.isSelect = true;
						returnData.push(item[this.returnFlag]);
					}
				});

				// 数据回传
				this.$emit("input", returnData);
			}
		},
		rightFilterList() {
			this.list.forEach((item) => {
				if (item.isSelect) {
					if (item.label.includes(this.rightSearchVal)) {
						item.isShow = true;
					} else {
						item.isShow = false;
					}
				}
			});
		},
		rightClearSearchVal() {
			this.rightSearchVal = "";
			this.rightFilterList();
		},
		transferToLeft(item) {
			let returnData = [];
			item.isSelect = false;
			item.isChecked = false;
			this.changeIsChecked(false);
			this.list.forEach((item) => {
				if (item.isChecked) {
					returnData.push(item[this.returnFlag]);
				}
			});
			// 数据回传
			this.$emit("input", returnData);
		},
		handleData(data) {
			return cloneDeep(
				data.map((item) => ({
					...item,
					isChecked: false,
					isShow: true,
					isSelect: false,
				}))
			);
		},
	},
	created() {
		this.init();
	},
	activated() {
		this.init();
	},
};
</script>

<style lang="scss" scoped>
.k_transfer {
	display: flex;
	width: 100%;
	align-items: center;
	background: #1f232f;
	.transfer_left {
		display: flex;
		flex: 1;
		flex-direction: column;
		// width: 360px;
		height: 432px;
		border: 1px solid rgba(60, 67, 89, 1);
		padding-bottom: 12px;
		.left_title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 36px;
			padding: 0 22px 0 12px;
			background: rgba(255, 255, 255, 0.08);
			.title_des {
				font-size: 14px;
				color: #9a9a9a;
			}
		}
	}
	.transfer_btn {
		width: 32px;
		height: 32px;
		background: #2a2d3f;
		border: 1px solid rgba(60, 67, 89, 1);
		border-radius: 2px;
		margin: 0 8px;
		cursor: pointer;
		font-size: 14px;
		color: #f6f6f6;
		text-align: center;
		line-height: 32px;
	}
	.transfer_right {
		display: flex;
		flex: 1;
		flex-direction: column;
		// width: 360px;
		height: 432px;
		border: 1px solid rgba(60, 67, 89, 1);
		padding-bottom: 12px;
		.right_title {
			display: flex;
			align-items: center;
			padding: 0 12px;
			height: 36px;
			background: rgba(255, 255, 255, 0.08);
			.title {
				font-size: 14px;
				color: #f6f6f6;
				margin-right: 3px;
			}
			.des {
				font-size: 12px;
				color: #9a9a9a;
			}
		}
	}
	.search {
		position: relative;
		display: flex;
		align-items: center;
		padding: 12px 16px;
		.search_btn {
			width: 40px;
			height: 32px;
			background-image: linear-gradient(90deg, #f5e9ca 0%, #b39e7b 100%);
			border-radius: 0px 2px 2px 0px;
			text-align: center;
			line-height: 32px;
			cursor: pointer;
		}
		.search_close {
			position: absolute;
			right: 65px;
			top: 19px;
			font-size: 14px;
			color: #c5c5c5;
			cursor: pointer;
		}
	}
	.main {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
		padding: 0 16px;
		::v-deep .el-checkbox {
			width: 100%;
			display: flex;
			align-items: center;
			height: 32px;
			border-radius: 2px;
			padding-left: 12px;
			&:hover {
				background: rgba(255, 255, 255, 0.08);
			}
		}
		.item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 32px;
			border-radius: 2px;
			padding-left: 12px;
			font-size: 14px;
			color: #f6f6f6;
			.el-icon-close {
				font-size: 14px;
				color: #c5c5c5;
				margin-right: 12px;
				cursor: pointer;
			}
			.disabled {
				color: rgba(246, 246, 246, 0.3);
			}
			&:hover {
				background: rgba(255, 255, 255, 0.08);
			}
		}
	}
}
::v-deep .el-checkbox__label {
	font-size: 14px;
	color: #f6f6f6;
}
</style>