import { commonConfig } from '../../js/config'

export const settingConfig = {
  time: '',
  theme: 'light',
  // 设置面板属性的显隐
  displayOption: {
    dataAllocation: {
      // 是否存在数据配置
      enable: false
    }
  }
}
const customConfig = {

  type: 'customHtml',
  root: {
    version: '2023071001',
    // 绕x轴旋转角度
    rotateX: 0,
    // 绕y轴旋转角度
    rotateY: 0,
    // 绕z轴旋转角度
    rotateZ: 0,
    // 透视距离
    perspective: 0,
    skewX: 0,
    skewY: 0
  },
  customize: {
    htmlStr: '<h1 style="color:#6b74e4;font-size: 20px;font-style: italic;">我是自定义html内容</h1>' // html的内容
  }

}
export const dataConfig = {
  ...commonConfig(customConfig)
}
