/*
 * @description: 配置，参考https://g2plot.antv.antgroup.com/examples
 * @Date: 2023-03-27 14:38:23
 * @Author: xing.heng
 */

// 配置版本号
const version = '2023071101'
// 分类
const category = 'Line'
// 标题
const title = '多折线图'
// 类别， new Line()
const chartType = 'Line'
// 用于标识，唯一，title的中文转拼音
const name = 'DuoZheXianTu'

// 右侧配置项
const setting = [
  {
    label: '维度',
    // 设置组件类型， select / input / colorPicker
    type: 'select',
    // 字段
    field: 'xField',
    optionField: 'xField', // 对应options中的字段
    // 是否多选
    multiple: false,
    // 绑定的值
    value: '',
    // tab页。 data: 数据， custom: 自定义
    tabName: 'data'
  },
  {
    label: '指标',
    // 设置组件类型
    type: 'select',
    // 字段
    field: 'yField',
    // 对应options中的字段
    optionField: 'yField',
    // 是否多选
    multiple: false,
    value: '',
    tabName: 'data'
  },
  {
    label: '分组',
    // 设置组件类型
    type: 'select',
    // 字段
    field: 'seriesField',
    // 对应options中的字段
    optionField: 'seriesField',
    // 是否多选
    multiple: false,
    value: '',
    tabName: 'data'
  },
  /** 样式配置 **/
  // 图表 graph
  {
    label: '折线宽度',
    type: 'inputNumber',
    field: 'lineStyle_lineWidth',
    optionField: 'lineStyle.lineWidth',
    value: 2,
    tabName: 'custom',
    groupName: 'graph'
  },
  {
    label: '折线颜色',
    // 设置组件类型
    type: 'colorSelect',
    // 字段
    field: 'color',
    // 对应options中的字段
    optionField: 'color',
    value: ['#5B8FF9', '#61DDAA', '#5D7092', '#F6BD16', '#6F5EF9', '#6DC8EC', '#945FB9', '#FF9845', '#1E9493', '#FF99C3'],
    tabName: 'custom',
    groupName: 'graph'
  },
  // 网格线 grid
  {
    label: '虚线',
    type: 'switch',
    field: 'yAxis_grid_line_style_lineDash',
    optionField: 'yAxis.grid.line.style.lineDash',
    value: 0,
    active: 5,
    inactive: 0,
    tabName: 'custom',
    groupName: 'grid'
  },
  {
    label: '宽度',
    type: 'inputNumber',
    field: 'yAxis_grid_line_style_lineWidth',
    optionField: 'yAxis.grid.line.style.lineWidth',
    value: 1,
    tabName: 'custom',
    groupName: 'grid'
  },
  {
    label: '颜色',
    type: 'colorPicker',
    field: 'yAxis_grid_line_style_stroke',
    optionField: 'yAxis.grid.line.style.stroke',
    value: '#d0d0d0',
    tabName: 'custom',
    groupName: 'grid'
  },
  // 图例 legend
  {
    label: '显示',
    type: 'switch', // 设置组件类型
    field: 'legendEnable', // 字段
    optionField: 'legendEnable', // 对应options中的字段
    value: false,
    active: true,
    inactive: false,
    tabName: 'custom',
    groupName: 'legend'
  },
  {
    label: '位置',
    type: 'select', // 设置组件类型
    field: 'legendPosition', // 字段
    optionField: 'legendPosition', // 对应options中的字段
    // 是否多选
    multiple: false,
    value: 'top',
    tabName: 'custom',
    options: [
      { label: '顶部', value: 'top' },
      { label: '左上角', value: 'top-left' },
      { label: '右上角', value: 'top-right' },
      { label: '左侧', value: 'left' },
      // { label: '左上方', value: 'left-top' },
      // { label: '左下方', value: 'left-bottom' },
      { label: '右侧', value: 'right' },
      // { label: '右上方', value: 'right-top' },
      // { label: '右下方', value: 'right-bottom' },
      { label: '底部', value: 'bottom' },
      { label: '左下角', value: 'bottom-left' },
      { label: '右下角', value: 'bottom-right' }
    ],
    groupName: 'legend'
  },
  {
    label: '字体大小',
    type: 'inputNumber',
    field: 'legendItemName_style_fontSize',
    optionField: 'legendItemName.style.fontSize',
    value: 12,
    tabName: 'custom',
    groupName: 'legend'
  },
  {
    label: '字体权重',
    type: 'inputNumber',
    step: 100,
    max: 900,
    field: 'legendItemName_style_fontWeight',
    optionField: 'legendItemName.style.fontWeight',
    value: 400,
    tabName: 'custom',
    groupName: 'legend'
  },
  {
    label: '字体颜色',
    type: 'colorPicker',
    field: 'legendItemName_style_fill',
    optionField: 'legendItemName.style.fill',
    value: '#595959',
    tabName: 'custom',
    groupName: 'legend'
  },
  // X轴 xAxis
  {
    label: '标题',
    type: 'input',
    field: 'xAxis_title_text',
    optionField: 'xAxis.title.text',
    value: '',
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标题位置',
    type: 'select',
    field: 'xAxis_title_position',
    optionField: 'xAxis.title.position',
    value: 'end',
    tabName: 'custom',
    options: [
      {
        label: '左',
        value: 'start'
      },
      {
        label: '中',
        value: 'center'
      },
      {
        label: '右',
        value: 'end'
      }],
    groupName: 'xAxis'
  },
  {
    label: '标题字体大小',
    type: 'inputNumber',
    field: 'xAxis_title_style_fontSize',
    optionField: 'xAxis.title.style.fontSize',
    value: 12,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标题颜色',
    type: 'colorPicker',
    field: 'xAxis_title_style_fill',
    optionField: 'xAxis.title.style.fill',
    // 是否多选
    multiple: false,
    value: '#8C8C8C',
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标签大小',
    type: 'inputNumber',
    field: 'xAxis_label_style_fontSize',
    optionField: 'xAxis.label.style.fontSize',
    value: 12,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标签颜色',
    type: 'colorPicker',
    field: 'xAxis_label_style_fill',
    optionField: 'xAxis.label.style.fill',
    // 是否多选
    multiple: false,
    value: '#8C8C8C',
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '轴线宽度',
    type: 'inputNumber',
    field: 'xAxis_line_style_lineWidth',
    optionField: 'xAxis.line.style.lineWidth',
    value: 1,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '轴线颜色',
    type: 'colorPicker',
    field: 'xAxis_line_style_stroke',
    optionField: 'xAxis.line.style.stroke',
    // 是否多选
    multiple: false,
    value: '#d0d0d0',
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '刻度线宽度',
    type: 'inputNumber',
    field: 'xAxis_tickLine_style_lineWidth',
    optionField: 'xAxis.tickLine.style.lineWidth',
    value: 1,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '刻度线颜色',
    type: 'colorPicker',
    field: 'xAxis_tickLine_style_stroke',
    optionField: 'xAxis.tickLine.style.stroke',
    // 是否多选
    multiple: false,
    value: '#d0d0d0',
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标签过多时旋转',
    type: 'switch',
    field: 'xAxis_label_autoRotate',
    optionField: 'xAxis.label.autoRotate',
    value: true,
    active: true,
    inactive: false,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标签过多时隐藏',
    type: 'switch',
    field: 'xAxis_label_autoHide',
    optionField: 'xAxis.label.autoHide',
    value: true,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  {
    label: '标签过长时省略',
    type: 'switch',
    field: 'xAxis_label_autoEllipsis',
    optionField: 'xAxis.label.autoEllipsis',
    value: true,
    tabName: 'custom',
    groupName: 'xAxis'
  },
  // Y轴 yAxis
  {
    label: '标题',
    type: 'input',
    field: 'yAxis_title_text',
    optionField: 'yAxis.title.text',
    value: '',
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '标题位置',
    type: 'select',
    field: 'yAxis_title_position',
    optionField: 'yAxis.title.position',
    value: 'end',
    tabName: 'custom',
    options: [
      {
        label: '上',
        value: 'end'
      },
      {
        label: '中',
        value: 'center'
      },
      {
        label: '下',
        value: 'start'
      }],
    groupName: 'yAxis'
  },
  {
    label: '标题字体大小',
    type: 'inputNumber',
    field: 'yAxis_title_style_fontSize',
    optionField: 'yAxis.title.style.fontSize',
    value: 12,
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '标题颜色',
    type: 'colorPicker',
    field: 'yAxis_title_style_fill',
    optionField: 'yAxis.title.style.fill',
    // 是否多选
    multiple: false,
    value: '#8C8C8C',
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '显示标签',
    type: 'switch',
    field: 'yAxis_label_style_opacity',
    optionField: 'yAxis.label.style.opacity',
    value: 1,
    active: 1,
    inactive: 0,
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '标签字体大小',
    type: 'inputNumber',
    field: 'yAxis_label_style_fontSize',
    optionField: 'yAxis.label.style.fontSize',
    value: 12,
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '标签字体颜色',
    type: 'colorPicker',
    field: 'yAxis_label_style_fill',
    optionField: 'yAxis.label.style.fill',
    // 是否多选
    multiple: false,
    value: '#8C8C8C',
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '轴线宽度',
    type: 'inputNumber',
    field: 'yAxis_line_lineWidth',
    optionField: 'yAxis.line.style.lineWidth',
    value: 0,
    tabName: 'custom',
    groupName: 'yAxis'
  },
  {
    label: '轴线颜色',
    type: 'colorPicker',
    field: 'yAxis_line_stroke',
    optionField: 'yAxis.line.style.stroke',
    // 是否多选
    multiple: false,
    value: '#d0d0d0',
    tabName: 'custom',
    groupName: 'yAxis'
  },
  // 边距 padding
  {
    label: '图表边距',
    type: 'padding',
    field: 'appendPadding',
    optionField: 'appendPadding',
    value: [16, 16, 16, 16],
    tabName: 'custom',
    groupName: 'padding'
  }
]

// 模拟数据
const data = [
  { year: '1850', value: 0, category: 'Liquid fuel' },
  { year: '1850', value: 54, category: 'Solid fuel' },
  { year: '1850', value: 0, category: 'Gas fuel' },
  { year: '1850', value: 0, category: 'Cement production' },
  { year: '1850', value: 0, category: 'Gas flarinl' },
  { year: '1851', value: 0, category: 'Liquid fuel' },
  { year: '1851', value: 54, category: 'Solid fuel' },
  { year: '1851', value: 0, category: 'Gas fuel' },
  { year: '1851', value: 0, category: 'Cement production' },
  { year: '1851', value: 0, category: 'Gas flarinl' },
  { year: '1852', value: 0, category: 'Liquid fuel' },
  { year: '1852', value: 57, category: 'Solid fuel' },
  { year: '1852', value: 0, category: 'Gas fuel' },
  { year: '1852', value: 0, category: 'Cement production' },
  { year: '1852', value: 0, category: 'Gas flarinl' },
  { year: '1853', value: 0, category: 'Liquid fuel' },
  { year: '1853', value: 59, category: 'Solid fuel' },
  { year: '1853', value: 0, category: 'Gas fuel' },
  { year: '1853', value: 0, category: 'Cement production' },
  { year: '1853', value: 0, category: 'Gas flarinl' },
  { year: '1854', value: 0, category: 'Liquid fuel' },
  { year: '1854', value: 69, category: 'Solid fuel' },
  { year: '1854', value: 0, category: 'Gas fuel' },
  { year: '1854', value: 0, category: 'Cement production' },
  { year: '1854', value: 0, category: 'Gas flarinl' },
  { year: '1855', value: 0, category: 'Liquid fuel' },
  { year: '1855', value: 71, category: 'Solid fuel' },
  { year: '1855', value: 0, category: 'Gas fuel' },
  { year: '1855', value: 0, category: 'Cement production' },
  { year: '1855', value: 0, category: 'Gas flarinl' },
  { year: '1856', value: 0, category: 'Liquid fuel' },
  { year: '1856', value: 76, category: 'Solid fuel' },
  { year: '1856', value: 0, category: 'Gas fuel' },
  { year: '1856', value: 0, category: 'Cement production' },
  { year: '1856', value: 0, category: 'Gas flarinl' },
  { year: '1857', value: 0, category: 'Liquid fuel' },
  { year: '1857', value: 77, category: 'Solid fuel' },
  { year: '1857', value: 0, category: 'Gas fuel' },
  { year: '1857', value: 0, category: 'Cement production' },
  { year: '1857', value: 0, category: 'Gas flarinl' },
  { year: '1858', value: 0, category: 'Liquid fuel' },
  { year: '1858', value: 78, category: 'Solid fuel' },
  { year: '1858', value: 0, category: 'Gas fuel' },
  { year: '1858', value: 0, category: 'Cement production' },
  { year: '1858', value: 0, category: 'Gas flarinl' },
  { year: '1859', value: 0, category: 'Liquid fuel' },
  { year: '1859', value: 83, category: 'Solid fuel' },
  { year: '1859', value: 0, category: 'Gas fuel' },
  { year: '1859', value: 0, category: 'Cement production' },
  { year: '1859', value: 0, category: 'Gas flarinl' },
  { year: '1860', value: 0, category: 'Liquid fuel' },
  { year: '1860', value: 91, category: 'Solid fuel' },
  { year: '1860', value: 0, category: 'Gas fuel' },
  { year: '1860', value: 0, category: 'Cement production' },
  { year: '1860', value: 0, category: 'Gas flarinl' },
  { year: '1861', value: 0, category: 'Liquid fuel' },
  { year: '1861', value: 95, category: 'Solid fuel' },
  { year: '1861', value: 0, category: 'Gas fuel' },
  { year: '1861', value: 0, category: 'Cement production' },
  { year: '1861', value: 0, category: 'Gas flarinl' },
  { year: '1862', value: 0, category: 'Liquid fuel' },
  { year: '1862', value: 96, category: 'Solid fuel' },
  { year: '1862', value: 0, category: 'Gas fuel' },
  { year: '1862', value: 0, category: 'Cement production' },
  { year: '1862', value: 0, category: 'Gas flarinl' },
  { year: '1863', value: 0, category: 'Liquid fuel' },
  { year: '1863', value: 103, category: 'Solid fuel' },
  { year: '1863', value: 0, category: 'Gas fuel' },
  { year: '1863', value: 0, category: 'Cement production' },
  { year: '1863', value: 0, category: 'Gas flarinl' },
  { year: '1864', value: 0, category: 'Liquid fuel' },
  { year: '1864', value: 112, category: 'Solid fuel' },
  { year: '1864', value: 0, category: 'Gas fuel' },
  { year: '1864', value: 0, category: 'Cement production' },
  { year: '1864', value: 0, category: 'Gas flarinl' },
  { year: '1865', value: 0, category: 'Liquid fuel' },
  { year: '1865', value: 119, category: 'Solid fuel' },
  { year: '1865', value: 0, category: 'Gas fuel' },
  { year: '1865', value: 0, category: 'Cement production' },
  { year: '1865', value: 0, category: 'Gas flarinl' },
  { year: '1866', value: 0, category: 'Liquid fuel' },
  { year: '1866', value: 122, category: 'Solid fuel' },
  { year: '1866', value: 0, category: 'Gas fuel' },
  { year: '1866', value: 0, category: 'Cement production' },
  { year: '1866', value: 0, category: 'Gas flarinl' },
  { year: '1867', value: 0, category: 'Liquid fuel' },
  { year: '1867', value: 130, category: 'Solid fuel' },
  { year: '1867', value: 0, category: 'Gas fuel' },
  { year: '1867', value: 0, category: 'Cement production' },
  { year: '1867', value: 0, category: 'Gas flarinl' },
  { year: '1868', value: 0, category: 'Liquid fuel' },
  { year: '1868', value: 134, category: 'Solid fuel' },
  { year: '1868', value: 0, category: 'Gas fuel' },
  { year: '1868', value: 0, category: 'Cement production' },
  { year: '1868', value: 0, category: 'Gas flarinl' },
  { year: '1869', value: 0, category: 'Liquid fuel' },
  { year: '1869', value: 142, category: 'Solid fuel' },
  { year: '1869', value: 0, category: 'Gas fuel' },
  { year: '1869', value: 0, category: 'Cement production' },
  { year: '1869', value: 0, category: 'Gas flarinl' },
  { year: '1870', value: 1, category: 'Liquid fuel' },
  { year: '1870', value: 146, category: 'Solid fuel' },
  { year: '1870', value: 0, category: 'Gas fuel' },
  { year: '1870', value: 0, category: 'Cement production' },
  { year: '1870', value: 0, category: 'Gas flarinl' },
  { year: '1871', value: 1, category: 'Liquid fuel' },
  { year: '1871', value: 156, category: 'Solid fuel' },
  { year: '1871', value: 0, category: 'Gas fuel' },
  { year: '1871', value: 0, category: 'Cement production' },
  { year: '1871', value: 0, category: 'Gas flarinl' },
  { year: '1872', value: 1, category: 'Liquid fuel' },
  { year: '1872', value: 173, category: 'Solid fuel' },
  { year: '1872', value: 0, category: 'Gas fuel' },
  { year: '1872', value: 0, category: 'Cement production' },
  { year: '1872', value: 0, category: 'Gas flarinl' },
  { year: '1873', value: 1, category: 'Liquid fuel' },
  { year: '1873', value: 183, category: 'Solid fuel' },
  { year: '1873', value: 0, category: 'Gas fuel' },
  { year: '1873', value: 0, category: 'Cement production' },
  { year: '1873', value: 0, category: 'Gas flarinl' },
  { year: '1874', value: 1, category: 'Liquid fuel' },
  { year: '1874', value: 173, category: 'Solid fuel' },
  { year: '1874', value: 0, category: 'Gas fuel' },
  { year: '1874', value: 0, category: 'Cement production' },
  { year: '1874', value: 0, category: 'Gas flarinl' },
  { year: '1875', value: 1, category: 'Liquid fuel' },
  { year: '1875', value: 187, category: 'Solid fuel' },
  { year: '1875', value: 0, category: 'Gas fuel' },
  { year: '1875', value: 0, category: 'Cement production' },
  { year: '1875', value: 0, category: 'Gas flarinl' },
  { year: '1876', value: 1, category: 'Liquid fuel' },
  { year: '1876', value: 190, category: 'Solid fuel' },
  { year: '1876', value: 0, category: 'Gas fuel' },
  { year: '1876', value: 0, category: 'Cement production' },
  { year: '1876', value: 0, category: 'Gas flarinl' },
  { year: '1877', value: 2, category: 'Liquid fuel' },
  { year: '1877', value: 192, category: 'Solid fuel' },
  { year: '1877', value: 0, category: 'Gas fuel' },
  { year: '1877', value: 0, category: 'Cement production' },
  { year: '1877', value: 0, category: 'Gas flarinl' },
  { year: '1878', value: 2, category: 'Liquid fuel' },
  { year: '1878', value: 194, category: 'Solid fuel' },
  { year: '1878', value: 0, category: 'Gas fuel' },
  { year: '1878', value: 0, category: 'Cement production' },
  { year: '1878', value: 0, category: 'Gas flarinl' },
  { year: '1879', value: 3, category: 'Liquid fuel' },
  { year: '1879', value: 207, category: 'Solid fuel' },
  { year: '1879', value: 0, category: 'Gas fuel' },
  { year: '1879', value: 0, category: 'Cement production' },
  { year: '1879', value: 0, category: 'Gas flarinl' },
  { year: '1880', value: 3, category: 'Liquid fuel' },
  { year: '1880', value: 233, category: 'Solid fuel' },
  { year: '1880', value: 0, category: 'Gas fuel' },
  { year: '1880', value: 0, category: 'Cement production' },
  { year: '1880', value: 0, category: 'Gas flarinl' },
  { year: '1881', value: 4, category: 'Liquid fuel' },
  { year: '1881', value: 239, category: 'Solid fuel' },
  { year: '1881', value: 0, category: 'Gas fuel' },
  { year: '1881', value: 0, category: 'Cement production' },
  { year: '1881', value: 0, category: 'Gas flarinl' },
  { year: '1882', value: 4, category: 'Liquid fuel' },
  { year: '1882', value: 252, category: 'Solid fuel' },
  { year: '1882', value: 0, category: 'Gas fuel' },
  { year: '1882', value: 0, category: 'Cement production' },
  { year: '1882', value: 0, category: 'Gas flarinl' },
  { year: '1883', value: 3, category: 'Liquid fuel' },
  { year: '1883', value: 269, category: 'Solid fuel' },
  { year: '1883', value: 0, category: 'Gas fuel' },
  { year: '1883', value: 0, category: 'Cement production' },
  { year: '1883', value: 0, category: 'Gas flarinl' },
  { year: '1884', value: 4, category: 'Liquid fuel' },
  { year: '1884', value: 271, category: 'Solid fuel' },
  { year: '1884', value: 0, category: 'Gas fuel' },
  { year: '1884', value: 0, category: 'Cement production' },
  { year: '1884', value: 0, category: 'Gas flarinl' },
  { year: '1885', value: 4, category: 'Liquid fuel' },
  { year: '1885', value: 273, category: 'Solid fuel' },
  { year: '1885', value: 1, category: 'Gas fuel' },
  { year: '1885', value: 0, category: 'Cement production' },
  { year: '1885', value: 0, category: 'Gas flarinl' },
  { year: '1886', value: 5, category: 'Liquid fuel' },
  { year: '1886', value: 275, category: 'Solid fuel' },
  { year: '1886', value: 2, category: 'Gas fuel' },
  { year: '1886', value: 0, category: 'Cement production' },
  { year: '1886', value: 0, category: 'Gas flarinl' },
  { year: '1887', value: 5, category: 'Liquid fuel' },
  { year: '1887', value: 287, category: 'Solid fuel' },
  { year: '1887', value: 3, category: 'Gas fuel' },
  { year: '1887', value: 0, category: 'Cement production' },
  { year: '1887', value: 0, category: 'Gas flarinl' },
  { year: '1888', value: 5, category: 'Liquid fuel' },
  { year: '1888', value: 317, category: 'Solid fuel' },
  { year: '1888', value: 5, category: 'Gas fuel' },
  { year: '1888', value: 0, category: 'Cement production' },
  { year: '1888', value: 0, category: 'Gas flarinl' },
  { year: '1889', value: 6, category: 'Liquid fuel' },
  { year: '1889', value: 318, category: 'Solid fuel' },
  { year: '1889', value: 3, category: 'Gas fuel' },
  { year: '1889', value: 0, category: 'Cement production' },
  { year: '1889', value: 0, category: 'Gas flarinl' },
  { year: '1890', value: 8, category: 'Liquid fuel' },
  { year: '1890', value: 345, category: 'Solid fuel' },
  { year: '1890', value: 3, category: 'Gas fuel' },
  { year: '1890', value: 0, category: 'Cement production' },
  { year: '1890', value: 0, category: 'Gas flarinl' },
  { year: '1891', value: 9, category: 'Liquid fuel' },
  { year: '1891', value: 360, category: 'Solid fuel' },
  { year: '1891', value: 2, category: 'Gas fuel' },
  { year: '1891', value: 0, category: 'Cement production' },
  { year: '1891', value: 0, category: 'Gas flarinl' },
  { year: '1892', value: 9, category: 'Liquid fuel' },
  { year: '1892', value: 363, category: 'Solid fuel' },
  { year: '1892', value: 2, category: 'Gas fuel' },
  { year: '1892', value: 0, category: 'Cement production' },
  { year: '1892', value: 0, category: 'Gas flarinl' },
  { year: '1893', value: 10, category: 'Liquid fuel' },
  { year: '1893', value: 358, category: 'Solid fuel' },
  { year: '1893', value: 2, category: 'Gas fuel' },
  { year: '1893', value: 0, category: 'Cement production' },
  { year: '1893', value: 0, category: 'Gas flarinl' },
  { year: '1894', value: 9, category: 'Liquid fuel' },
  { year: '1894', value: 372, category: 'Solid fuel' },
  { year: '1894', value: 2, category: 'Gas fuel' },
  { year: '1894', value: 0, category: 'Cement production' },
  { year: '1894', value: 0, category: 'Gas flarinl' },
  { year: '1895', value: 11, category: 'Liquid fuel' },
  { year: '1895', value: 393, category: 'Solid fuel' },
  { year: '1895', value: 2, category: 'Gas fuel' },
  { year: '1895', value: 0, category: 'Cement production' },
  { year: '1895', value: 0, category: 'Gas flarinl' },
  { year: '1896', value: 12, category: 'Liquid fuel' },
  { year: '1896', value: 405, category: 'Solid fuel' },
  { year: '1896', value: 2, category: 'Gas fuel' },
  { year: '1896', value: 0, category: 'Cement production' },
  { year: '1896', value: 0, category: 'Gas flarinl' },
  { year: '1897', value: 13, category: 'Liquid fuel' },
  { year: '1897', value: 425, category: 'Solid fuel' },
  { year: '1897', value: 2, category: 'Gas fuel' },
  { year: '1897', value: 0, category: 'Cement production' },
  { year: '1897', value: 0, category: 'Gas flarinl' },
  { year: '1898', value: 13, category: 'Liquid fuel' },
  { year: '1898', value: 449, category: 'Solid fuel' },
  { year: '1898', value: 2, category: 'Gas fuel' },
  { year: '1898', value: 0, category: 'Cement production' },
  { year: '1898', value: 0, category: 'Gas flarinl' },
  { year: '1899', value: 14, category: 'Liquid fuel' },
  { year: '1899', value: 491, category: 'Solid fuel' },
  { year: '1899', value: 3, category: 'Gas fuel' },
  { year: '1899', value: 0, category: 'Cement production' },
  { year: '1899', value: 0, category: 'Gas flarinl' },
  { year: '1900', value: 16, category: 'Liquid fuel' },
  { year: '1900', value: 515, category: 'Solid fuel' },
  { year: '1900', value: 3, category: 'Gas fuel' },
  { year: '1900', value: 0, category: 'Cement production' },
  { year: '1900', value: 0, category: 'Gas flarinl' },
  { year: '1901', value: 18, category: 'Liquid fuel' },
  { year: '1901', value: 531, category: 'Solid fuel' },
  { year: '1901', value: 4, category: 'Gas fuel' },
  { year: '1901', value: 0, category: 'Cement production' },
  { year: '1901', value: 0, category: 'Gas flarinl' },
  { year: '1902', value: 19, category: 'Liquid fuel' },
  { year: '1902', value: 543, category: 'Solid fuel' },
  { year: '1902', value: 4, category: 'Gas fuel' },
  { year: '1902', value: 0, category: 'Cement production' },
  { year: '1902', value: 0, category: 'Gas flarinl' },
  { year: '1903', value: 20, category: 'Liquid fuel' },
  { year: '1903', value: 593, category: 'Solid fuel' },
  { year: '1903', value: 4, category: 'Gas fuel' },
  { year: '1903', value: 0, category: 'Cement production' },
  { year: '1903', value: 0, category: 'Gas flarinl' },
  { year: '1904', value: 23, category: 'Liquid fuel' },
  { year: '1904', value: 597, category: 'Solid fuel' },
  { year: '1904', value: 4, category: 'Gas fuel' },
  { year: '1904', value: 0, category: 'Cement production' },
  { year: '1904', value: 0, category: 'Gas flarinl' },
  { year: '1905', value: 23, category: 'Liquid fuel' },
  { year: '1905', value: 636, category: 'Solid fuel' },
  { year: '1905', value: 5, category: 'Gas fuel' },
  { year: '1905', value: 0, category: 'Cement production' },
  { year: '1905', value: 0, category: 'Gas flarinl' },
  { year: '1906', value: 23, category: 'Liquid fuel' },
  { year: '1906', value: 680, category: 'Solid fuel' },
  { year: '1906', value: 5, category: 'Gas fuel' },
  { year: '1906', value: 0, category: 'Cement production' },
  { year: '1906', value: 0, category: 'Gas flarinl' },
  { year: '1907', value: 28, category: 'Liquid fuel' },
  { year: '1907', value: 750, category: 'Solid fuel' },
  { year: '1907', value: 5, category: 'Gas fuel' },
  { year: '1907', value: 0, category: 'Cement production' },
  { year: '1907', value: 0, category: 'Gas flarinl' },
  { year: '1908', value: 30, category: 'Liquid fuel' },
  { year: '1908', value: 714, category: 'Solid fuel' },
  { year: '1908', value: 5, category: 'Gas fuel' },
  { year: '1908', value: 0, category: 'Cement production' },
  { year: '1908', value: 0, category: 'Gas flarinl' },
  { year: '1909', value: 32, category: 'Liquid fuel' },
  { year: '1909', value: 747, category: 'Solid fuel' },
  { year: '1909', value: 6, category: 'Gas fuel' },
  { year: '1909', value: 0, category: 'Cement production' },
  { year: '1909', value: 0, category: 'Gas flarinl' },
  { year: '1910', value: 34, category: 'Liquid fuel' },
  { year: '1910', value: 778, category: 'Solid fuel' },
  { year: '1910', value: 7, category: 'Gas fuel' },
  { year: '1910', value: 0, category: 'Cement production' },
  { year: '1910', value: 0, category: 'Gas flarinl' },
  { year: '1911', value: 36, category: 'Liquid fuel' },
  { year: '1911', value: 792, category: 'Solid fuel' },
  { year: '1911', value: 7, category: 'Gas fuel' },
  { year: '1911', value: 0, category: 'Cement production' },
  { year: '1911', value: 0, category: 'Gas flarinl' },
  { year: '1912', value: 37, category: 'Liquid fuel' },
  { year: '1912', value: 834, category: 'Solid fuel' },
  { year: '1912', value: 8, category: 'Gas fuel' },
  { year: '1912', value: 0, category: 'Cement production' },
  { year: '1912', value: 0, category: 'Gas flarinl' },
  { year: '1913', value: 41, category: 'Liquid fuel' },
  { year: '1913', value: 895, category: 'Solid fuel' },
  { year: '1913', value: 8, category: 'Gas fuel' },
  { year: '1913', value: 0, category: 'Cement production' },
  { year: '1913', value: 0, category: 'Gas flarinl' },
  { year: '1914', value: 42, category: 'Liquid fuel' },
  { year: '1914', value: 800, category: 'Solid fuel' },
  { year: '1914', value: 8, category: 'Gas fuel' },
  { year: '1914', value: 0, category: 'Cement production' },
  { year: '1914', value: 0, category: 'Gas flarinl' },
  { year: '1915', value: 45, category: 'Liquid fuel' },
  { year: '1915', value: 784, category: 'Solid fuel' },
  { year: '1915', value: 9, category: 'Gas fuel' },
  { year: '1915', value: 0, category: 'Cement production' },
  { year: '1915', value: 0, category: 'Gas flarinl' },
  { year: '1916', value: 48, category: 'Liquid fuel' },
  { year: '1916', value: 842, category: 'Solid fuel' },
  { year: '1916', value: 10, category: 'Gas fuel' },
  { year: '1916', value: 0, category: 'Cement production' },
  { year: '1916', value: 0, category: 'Gas flarinl' },
  { year: '1917', value: 54, category: 'Liquid fuel' },
  { year: '1917', value: 891, category: 'Solid fuel' },
  { year: '1917', value: 11, category: 'Gas fuel' },
  { year: '1917', value: 0, category: 'Cement production' },
  { year: '1917', value: 0, category: 'Gas flarinl' },
  { year: '1918', value: 53, category: 'Liquid fuel' },
  { year: '1918', value: 873, category: 'Solid fuel' },
  { year: '1918', value: 10, category: 'Gas fuel' },
  { year: '1918', value: 0, category: 'Cement production' },
  { year: '1918', value: 0, category: 'Gas flarinl' },
  { year: '1919', value: 61, category: 'Liquid fuel' },
  { year: '1919', value: 735, category: 'Solid fuel' },
  { year: '1919', value: 10, category: 'Gas fuel' },
  { year: '1919', value: 0, category: 'Cement production' },
  { year: '1919', value: 0, category: 'Gas flarinl' },
  { year: '1920', value: 78, category: 'Liquid fuel' },
  { year: '1920', value: 843, category: 'Solid fuel' },
  { year: '1920', value: 11, category: 'Gas fuel' },
  { year: '1920', value: 0, category: 'Cement production' },
  { year: '1920', value: 0, category: 'Gas flarinl' },
  { year: '1921', value: 84, category: 'Liquid fuel' },
  { year: '1921', value: 709, category: 'Solid fuel' },
  { year: '1921', value: 10, category: 'Gas fuel' },
  { year: '1921', value: 0, category: 'Cement production' },
  { year: '1921', value: 0, category: 'Gas flarinl' },
  { year: '1922', value: 94, category: 'Liquid fuel' },
  { year: '1922', value: 740, category: 'Solid fuel' },
  { year: '1922', value: 11, category: 'Gas fuel' },
  { year: '1922', value: 0, category: 'Cement production' },
  { year: '1922', value: 0, category: 'Gas flarinl' },
  { year: '1923', value: 111, category: 'Liquid fuel' },
  { year: '1923', value: 845, category: 'Solid fuel' },
  { year: '1923', value: 14, category: 'Gas fuel' },
  { year: '1923', value: 0, category: 'Cement production' },
  { year: '1923', value: 0, category: 'Gas flarinl' },
  { year: '1924', value: 110, category: 'Liquid fuel' },
  { year: '1924', value: 836, category: 'Solid fuel' },
  { year: '1924', value: 16, category: 'Gas fuel' },
  { year: '1924', value: 0, category: 'Cement production' },
  { year: '1924', value: 0, category: 'Gas flarinl' },
  { year: '1925', value: 116, category: 'Liquid fuel' },
  { year: '1925', value: 842, category: 'Solid fuel' },
  { year: '1925', value: 17, category: 'Gas fuel' },
  { year: '1925', value: 0, category: 'Cement production' },
  { year: '1925', value: 0, category: 'Gas flarinl' },
  { year: '1926', value: 119, category: 'Liquid fuel' },
  { year: '1926', value: 846, category: 'Solid fuel' },
  { year: '1926', value: 19, category: 'Gas fuel' },
  { year: '1926', value: 0, category: 'Cement production' },
  { year: '1926', value: 0, category: 'Gas flarinl' },
  { year: '1927', value: 136, category: 'Liquid fuel' },
  { year: '1927', value: 905, category: 'Solid fuel' },
  { year: '1927', value: 21, category: 'Gas fuel' },
  { year: '1927', value: 0, category: 'Cement production' },
  { year: '1927', value: 0, category: 'Gas flarinl' },
  { year: '1928', value: 143, category: 'Liquid fuel' },
  { year: '1928', value: 890, category: 'Solid fuel' },
  { year: '1928', value: 23, category: 'Gas fuel' },
  { year: '1928', value: 10, category: 'Cement production' },
  { year: '1928', value: 0, category: 'Gas flarinl' },
  { year: '1929', value: 160, category: 'Liquid fuel' },
  { year: '1929', value: 947, category: 'Solid fuel' },
  { year: '1929', value: 28, category: 'Gas fuel' },
  { year: '1929', value: 10, category: 'Cement production' },
  { year: '1929', value: 0, category: 'Gas flarinl' },
  { year: '1930', value: 152, category: 'Liquid fuel' },
  { year: '1930', value: 862, category: 'Solid fuel' },
  { year: '1930', value: 28, category: 'Gas fuel' },
  { year: '1930', value: 10, category: 'Cement production' },
  { year: '1930', value: 0, category: 'Gas flarinl' },
  { year: '1931', value: 147, category: 'Liquid fuel' },
  { year: '1931', value: 759, category: 'Solid fuel' },
  { year: '1931', value: 25, category: 'Gas fuel' },
  { year: '1931', value: 8, category: 'Cement production' },
  { year: '1931', value: 0, category: 'Gas flarinl' },
  { year: '1932', value: 141, category: 'Liquid fuel' },
  { year: '1932', value: 675, category: 'Solid fuel' },
  { year: '1932', value: 24, category: 'Gas fuel' },
  { year: '1932', value: 7, category: 'Cement production' },
  { year: '1932', value: 0, category: 'Gas flarinl' },
  { year: '1933', value: 154, category: 'Liquid fuel' },
  { year: '1933', value: 708, category: 'Solid fuel' },
  { year: '1933', value: 25, category: 'Gas fuel' },
  { year: '1933', value: 7, category: 'Cement production' },
  { year: '1933', value: 0, category: 'Gas flarinl' },
  { year: '1934', value: 162, category: 'Liquid fuel' },
  { year: '1934', value: 775, category: 'Solid fuel' },
  { year: '1934', value: 28, category: 'Gas fuel' },
  { year: '1934', value: 8, category: 'Cement production' },
  { year: '1934', value: 0, category: 'Gas flarinl' },
  { year: '1935', value: 176, category: 'Liquid fuel' },
  { year: '1935', value: 811, category: 'Solid fuel' },
  { year: '1935', value: 30, category: 'Gas fuel' },
  { year: '1935', value: 9, category: 'Cement production' },
  { year: '1935', value: 0, category: 'Gas flarinl' },
  { year: '1936', value: 192, category: 'Liquid fuel' },
  { year: '1936', value: 893, category: 'Solid fuel' },
  { year: '1936', value: 34, category: 'Gas fuel' },
  { year: '1936', value: 11, category: 'Cement production' },
  { year: '1936', value: 0, category: 'Gas flarinl' },
  { year: '1937', value: 219, category: 'Liquid fuel' },
  { year: '1937', value: 941, category: 'Solid fuel' },
  { year: '1937', value: 38, category: 'Gas fuel' },
  { year: '1937', value: 11, category: 'Cement production' },
  { year: '1937', value: 0, category: 'Gas flarinl' },
  { year: '1938', value: 214, category: 'Liquid fuel' },
  { year: '1938', value: 880, category: 'Solid fuel' },
  { year: '1938', value: 37, category: 'Gas fuel' },
  { year: '1938', value: 12, category: 'Cement production' },
  { year: '1938', value: 0, category: 'Gas flarinl' },
  { year: '1939', value: 222, category: 'Liquid fuel' },
  { year: '1939', value: 918, category: 'Solid fuel' },
  { year: '1939', value: 38, category: 'Gas fuel' },
  { year: '1939', value: 13, category: 'Cement production' },
  { year: '1939', value: 0, category: 'Gas flarinl' },
  { year: '1940', value: 229, category: 'Liquid fuel' },
  { year: '1940', value: 1017, category: 'Solid fuel' },
  { year: '1940', value: 42, category: 'Gas fuel' },
  { year: '1940', value: 11, category: 'Cement production' },
  { year: '1940', value: 0, category: 'Gas flarinl' },
  { year: '1941', value: 236, category: 'Liquid fuel' },
  { year: '1941', value: 1043, category: 'Solid fuel' },
  { year: '1941', value: 42, category: 'Gas fuel' },
  { year: '1941', value: 12, category: 'Cement production' },
  { year: '1941', value: 0, category: 'Gas flarinl' },
  { year: '1942', value: 222, category: 'Liquid fuel' },
  { year: '1942', value: 1063, category: 'Solid fuel' },
  { year: '1942', value: 45, category: 'Gas fuel' },
  { year: '1942', value: 11, category: 'Cement production' },
  { year: '1942', value: 0, category: 'Gas flarinl' },
  { year: '1943', value: 239, category: 'Liquid fuel' },
  { year: '1943', value: 1092, category: 'Solid fuel' },
  { year: '1943', value: 50, category: 'Gas fuel' },
  { year: '1943', value: 10, category: 'Cement production' },
  { year: '1943', value: 0, category: 'Gas flarinl' },
  { year: '1944', value: 275, category: 'Liquid fuel' },
  { year: '1944', value: 1047, category: 'Solid fuel' },
  { year: '1944', value: 54, category: 'Gas fuel' },
  { year: '1944', value: 7, category: 'Cement production' },
  { year: '1944', value: 0, category: 'Gas flarinl' },
  { year: '1945', value: 275, category: 'Liquid fuel' },
  { year: '1945', value: 820, category: 'Solid fuel' },
  { year: '1945', value: 59, category: 'Gas fuel' },
  { year: '1945', value: 7, category: 'Cement production' },
  { year: '1945', value: 0, category: 'Gas flarinl' },
  { year: '1946', value: 292, category: 'Liquid fuel' },
  { year: '1946', value: 875, category: 'Solid fuel' },
  { year: '1946', value: 61, category: 'Gas fuel' },
  { year: '1946', value: 10, category: 'Cement production' },
  { year: '1946', value: 0, category: 'Gas flarinl' },
  { year: '1947', value: 322, category: 'Liquid fuel' },
  { year: '1947', value: 992, category: 'Solid fuel' },
  { year: '1947', value: 67, category: 'Gas fuel' },
  { year: '1947', value: 12, category: 'Cement production' },
  { year: '1947', value: 0, category: 'Gas flarinl' },
  { year: '1948', value: 364, category: 'Liquid fuel' },
  { year: '1948', value: 1015, category: 'Solid fuel' },
  { year: '1948', value: 76, category: 'Gas fuel' },
  { year: '1948', value: 14, category: 'Cement production' },
  { year: '1948', value: 0, category: 'Gas flarinl' },
  { year: '1949', value: 362, category: 'Liquid fuel' },
  { year: '1949', value: 960, category: 'Solid fuel' },
  { year: '1949', value: 81, category: 'Gas fuel' },
  { year: '1949', value: 16, category: 'Cement production' },
  { year: '1949', value: 0, category: 'Gas flarinl' },
  { year: '1950', value: 423, category: 'Liquid fuel' },
  { year: '1950', value: 1070, category: 'Solid fuel' },
  { year: '1950', value: 97, category: 'Gas fuel' },
  { year: '1950', value: 18, category: 'Cement production' },
  { year: '1950', value: 23, category: 'Gas flarinl' },
  { year: '1951', value: 479, category: 'Liquid fuel' },
  { year: '1951', value: 1129, category: 'Solid fuel' },
  { year: '1951', value: 115, category: 'Gas fuel' },
  { year: '1951', value: 20, category: 'Cement production' },
  { year: '1951', value: 24, category: 'Gas flarinl' },
  { year: '1952', value: 504, category: 'Liquid fuel' },
  { year: '1952', value: 1119, category: 'Solid fuel' },
  { year: '1952', value: 124, category: 'Gas fuel' },
  { year: '1952', value: 22, category: 'Cement production' },
  { year: '1952', value: 26, category: 'Gas flarinl' },
  { year: '1953', value: 533, category: 'Liquid fuel' },
  { year: '1953', value: 1125, category: 'Solid fuel' },
  { year: '1953', value: 131, category: 'Gas fuel' },
  { year: '1953', value: 24, category: 'Cement production' },
  { year: '1953', value: 27, category: 'Gas flarinl' },
  { year: '1954', value: 557, category: 'Liquid fuel' },
  { year: '1954', value: 1116, category: 'Solid fuel' },
  { year: '1954', value: 138, category: 'Gas fuel' },
  { year: '1954', value: 27, category: 'Cement production' },
  { year: '1954', value: 27, category: 'Gas flarinl' },
  { year: '1955', value: 625, category: 'Liquid fuel' },
  { year: '1955', value: 1208, category: 'Solid fuel' },
  { year: '1955', value: 150, category: 'Gas fuel' },
  { year: '1955', value: 30, category: 'Cement production' },
  { year: '1955', value: 31, category: 'Gas flarinl' },
  { year: '1956', value: 679, category: 'Liquid fuel' },
  { year: '1956', value: 1273, category: 'Solid fuel' },
  { year: '1956', value: 161, category: 'Gas fuel' },
  { year: '1956', value: 32, category: 'Cement production' },
  { year: '1956', value: 32, category: 'Gas flarinl' },
  { year: '1957', value: 714, category: 'Liquid fuel' },
  { year: '1957', value: 1309, category: 'Solid fuel' },
  { year: '1957', value: 178, category: 'Gas fuel' },
  { year: '1957', value: 34, category: 'Cement production' },
  { year: '1957', value: 35, category: 'Gas flarinl' },
  { year: '1958', value: 731, category: 'Liquid fuel' },
  { year: '1958', value: 1336, category: 'Solid fuel' },
  { year: '1958', value: 192, category: 'Gas fuel' },
  { year: '1958', value: 36, category: 'Cement production' },
  { year: '1958', value: 35, category: 'Gas flarinl' },
  { year: '1959', value: 789, category: 'Liquid fuel' },
  { year: '1959', value: 1382, category: 'Solid fuel' },
  { year: '1959', value: 206, category: 'Gas fuel' },
  { year: '1959', value: 40, category: 'Cement production' },
  { year: '1959', value: 36, category: 'Gas flarinl' },
  { year: '1960', value: 849, category: 'Liquid fuel' },
  { year: '1960', value: 1410, category: 'Solid fuel' },
  { year: '1960', value: 227, category: 'Gas fuel' },
  { year: '1960', value: 43, category: 'Cement production' },
  { year: '1960', value: 39, category: 'Gas flarinl' },
  { year: '1961', value: 904, category: 'Liquid fuel' },
  { year: '1961', value: 1349, category: 'Solid fuel' },
  { year: '1961', value: 240, category: 'Gas fuel' },
  { year: '1961', value: 45, category: 'Cement production' },
  { year: '1961', value: 42, category: 'Gas flarinl' },
  { year: '1962', value: 980, category: 'Liquid fuel' },
  { year: '1962', value: 1351, category: 'Solid fuel' },
  { year: '1962', value: 263, category: 'Gas fuel' },
  { year: '1962', value: 49, category: 'Cement production' },
  { year: '1962', value: 44, category: 'Gas flarinl' },
  { year: '1963', value: 1052, category: 'Liquid fuel' },
  { year: '1963', value: 1396, category: 'Solid fuel' },
  { year: '1963', value: 286, category: 'Gas fuel' },
  { year: '1963', value: 51, category: 'Cement production' },
  { year: '1963', value: 47, category: 'Gas flarinl' },
  { year: '1964', value: 1137, category: 'Liquid fuel' },
  { year: '1964', value: 1435, category: 'Solid fuel' },
  { year: '1964', value: 316, category: 'Gas fuel' },
  { year: '1964', value: 57, category: 'Cement production' },
  { year: '1964', value: 51, category: 'Gas flarinl' },
  { year: '1965', value: 1219, category: 'Liquid fuel' },
  { year: '1965', value: 1460, category: 'Solid fuel' },
  { year: '1965', value: 337, category: 'Gas fuel' },
  { year: '1965', value: 59, category: 'Cement production' },
  { year: '1965', value: 55, category: 'Gas flarinl' },
  { year: '1966', value: 1323, category: 'Liquid fuel' },
  { year: '1966', value: 1478, category: 'Solid fuel' },
  { year: '1966', value: 364, category: 'Gas fuel' },
  { year: '1966', value: 63, category: 'Cement production' },
  { year: '1966', value: 60, category: 'Gas flarinl' },
  { year: '1967', value: 1423, category: 'Liquid fuel' },
  { year: '1967', value: 1448, category: 'Solid fuel' },
  { year: '1967', value: 392, category: 'Gas fuel' },
  { year: '1967', value: 65, category: 'Cement production' },
  { year: '1967', value: 66, category: 'Gas flarinl' },
  { year: '1968', value: 1551, category: 'Liquid fuel' },
  { year: '1968', value: 1448, category: 'Solid fuel' },
  { year: '1968', value: 424, category: 'Gas fuel' },
  { year: '1968', value: 70, category: 'Cement production' },
  { year: '1968', value: 73, category: 'Gas flarinl' },
  { year: '1969', value: 1673, category: 'Liquid fuel' },
  { year: '1969', value: 1486, category: 'Solid fuel' },
  { year: '1969', value: 467, category: 'Gas fuel' },
  { year: '1969', value: 74, category: 'Cement production' },
  { year: '1969', value: 80, category: 'Gas flarinl' },
  { year: '1970', value: 1839, category: 'Liquid fuel' },
  { year: '1970', value: 1556, category: 'Solid fuel' },
  { year: '1970', value: 493, category: 'Gas fuel' },
  { year: '1970', value: 78, category: 'Cement production' },
  { year: '1970', value: 87, category: 'Gas flarinl' },
  { year: '1971', value: 1947, category: 'Liquid fuel' },
  { year: '1971', value: 1559, category: 'Solid fuel' },
  { year: '1971', value: 530, category: 'Gas fuel' },
  { year: '1971', value: 84, category: 'Cement production' },
  { year: '1971', value: 88, category: 'Gas flarinl' },
  { year: '1972', value: 2057, category: 'Liquid fuel' },
  { year: '1972', value: 1576, category: 'Solid fuel' },
  { year: '1972', value: 560, category: 'Gas fuel' },
  { year: '1972', value: 89, category: 'Cement production' },
  { year: '1972', value: 95, category: 'Gas flarinl' },
  { year: '1973', value: 2241, category: 'Liquid fuel' },
  { year: '1973', value: 1581, category: 'Solid fuel' },
  { year: '1973', value: 588, category: 'Gas fuel' },
  { year: '1973', value: 95, category: 'Cement production' },
  { year: '1973', value: 110, category: 'Gas flarinl' },
  { year: '1974', value: 2245, category: 'Liquid fuel' },
  { year: '1974', value: 1579, category: 'Solid fuel' },
  { year: '1974', value: 597, category: 'Gas fuel' },
  { year: '1974', value: 96, category: 'Cement production' },
  { year: '1974', value: 107, category: 'Gas flarinl' },
  { year: '1975', value: 2132, category: 'Liquid fuel' },
  { year: '1975', value: 1673, category: 'Solid fuel' },
  { year: '1975', value: 604, category: 'Gas fuel' },
  { year: '1975', value: 95, category: 'Cement production' },
  { year: '1975', value: 92, category: 'Gas flarinl' },
  { year: '1976', value: 2314, category: 'Liquid fuel' },
  { year: '1976', value: 1710, category: 'Solid fuel' },
  { year: '1976', value: 630, category: 'Gas fuel' },
  { year: '1976', value: 103, category: 'Cement production' },
  { year: '1976', value: 108, category: 'Gas flarinl' },
  { year: '1977', value: 2398, category: 'Liquid fuel' },
  { year: '1977', value: 1756, category: 'Solid fuel' },
  { year: '1977', value: 650, category: 'Gas fuel' },
  { year: '1977', value: 108, category: 'Cement production' },
  { year: '1977', value: 104, category: 'Gas flarinl' },
  { year: '1978', value: 2392, category: 'Liquid fuel' },
  { year: '1978', value: 1780, category: 'Solid fuel' },
  { year: '1978', value: 680, category: 'Gas fuel' },
  { year: '1978', value: 116, category: 'Cement production' },
  { year: '1978', value: 106, category: 'Gas flarinl' },
  { year: '1979', value: 2544, category: 'Liquid fuel' },
  { year: '1979', value: 1875, category: 'Solid fuel' },
  { year: '1979', value: 721, category: 'Gas fuel' },
  { year: '1979', value: 119, category: 'Cement production' },
  { year: '1979', value: 98, category: 'Gas flarinl' },
  { year: '1980', value: 2422, category: 'Liquid fuel' },
  { year: '1980', value: 1935, category: 'Solid fuel' },
  { year: '1980', value: 737, category: 'Gas fuel' },
  { year: '1980', value: 120, category: 'Cement production' },
  { year: '1980', value: 86, category: 'Gas flarinl' },
  { year: '1981', value: 2289, category: 'Liquid fuel' },
  { year: '1981', value: 1908, category: 'Solid fuel' },
  { year: '1981', value: 755, category: 'Gas fuel' },
  { year: '1981', value: 121, category: 'Cement production' },
  { year: '1981', value: 65, category: 'Gas flarinl' },
  { year: '1982', value: 2196, category: 'Liquid fuel' },
  { year: '1982', value: 1976, category: 'Solid fuel' },
  { year: '1982', value: 738, category: 'Gas fuel' },
  { year: '1982', value: 121, category: 'Cement production' },
  { year: '1982', value: 64, category: 'Gas flarinl' },
  { year: '1983', value: 2176, category: 'Liquid fuel' },
  { year: '1983', value: 1977, category: 'Solid fuel' },
  { year: '1983', value: 739, category: 'Gas fuel' },
  { year: '1983', value: 125, category: 'Cement production' },
  { year: '1983', value: 58, category: 'Gas flarinl' },
  { year: '1984', value: 2199, category: 'Liquid fuel' },
  { year: '1984', value: 2074, category: 'Solid fuel' },
  { year: '1984', value: 807, category: 'Gas fuel' },
  { year: '1984', value: 128, category: 'Cement production' },
  { year: '1984', value: 51, category: 'Gas flarinl' },
  { year: '1985', value: 2186, category: 'Liquid fuel' },
  { year: '1985', value: 2216, category: 'Solid fuel' },
  { year: '1985', value: 835, category: 'Gas fuel' },
  { year: '1985', value: 131, category: 'Cement production' },
  { year: '1985', value: 49, category: 'Gas flarinl' },
  { year: '1986', value: 2293, category: 'Liquid fuel' },
  { year: '1986', value: 2277, category: 'Solid fuel' },
  { year: '1986', value: 830, category: 'Gas fuel' },
  { year: '1986', value: 137, category: 'Cement production' },
  { year: '1986', value: 46, category: 'Gas flarinl' },
  { year: '1987', value: 2306, category: 'Liquid fuel' },
  { year: '1987', value: 2339, category: 'Solid fuel' },
  { year: '1987', value: 892, category: 'Gas fuel' },
  { year: '1987', value: 143, category: 'Cement production' },
  { year: '1987', value: 44, category: 'Gas flarinl' },
  { year: '1988', value: 2412, category: 'Liquid fuel' },
  { year: '1988', value: 2387, category: 'Solid fuel' },
  { year: '1988', value: 935, category: 'Gas fuel' },
  { year: '1988', value: 152, category: 'Cement production' },
  { year: '1988', value: 50, category: 'Gas flarinl' },
  { year: '1989', value: 2459, category: 'Liquid fuel' },
  { year: '1989', value: 2428, category: 'Solid fuel' },
  { year: '1989', value: 982, category: 'Gas fuel' },
  { year: '1989', value: 156, category: 'Cement production' },
  { year: '1989', value: 41, category: 'Gas flarinl' },
  { year: '1990', value: 2492, category: 'Liquid fuel' },
  { year: '1990', value: 2359, category: 'Solid fuel' },
  { year: '1990', value: 1026, category: 'Gas fuel' },
  { year: '1990', value: 157, category: 'Cement production' },
  { year: '1990', value: 40, category: 'Gas flarinl' },
  { year: '1991', value: 2601, category: 'Liquid fuel' },
  { year: '1991', value: 2284, category: 'Solid fuel' },
  { year: '1991', value: 1051, category: 'Gas fuel' },
  { year: '1991', value: 161, category: 'Cement production' },
  { year: '1991', value: 45, category: 'Gas flarinl' },
  { year: '1992', value: 2499, category: 'Liquid fuel' },
  { year: '1992', value: 2290, category: 'Solid fuel' },
  { year: '1992', value: 1085, category: 'Gas fuel' },
  { year: '1992', value: 167, category: 'Cement production' },
  { year: '1992', value: 36, category: 'Gas flarinl' },
  { year: '1993', value: 2515, category: 'Liquid fuel' },
  { year: '1993', value: 2225, category: 'Solid fuel' },
  { year: '1993', value: 1117, category: 'Gas fuel' },
  { year: '1993', value: 176, category: 'Cement production' },
  { year: '1993', value: 37, category: 'Gas flarinl' },
  { year: '1994', value: 2539, category: 'Liquid fuel' },
  { year: '1994', value: 2278, category: 'Solid fuel' },
  { year: '1994', value: 1133, category: 'Gas fuel' },
  { year: '1994', value: 186, category: 'Cement production' },
  { year: '1994', value: 39, category: 'Gas flarinl' },
  { year: '1995', value: 2560, category: 'Liquid fuel' },
  { year: '1995', value: 2359, category: 'Solid fuel' },
  { year: '1995', value: 1151, category: 'Gas fuel' },
  { year: '1995', value: 197, category: 'Cement production' },
  { year: '1995', value: 39, category: 'Gas flarinl' },
  { year: '1996', value: 2626, category: 'Liquid fuel' },
  { year: '1996', value: 2382, category: 'Solid fuel' },
  { year: '1996', value: 1198, category: 'Gas fuel' },
  { year: '1996', value: 203, category: 'Cement production' },
  { year: '1996', value: 40, category: 'Gas flarinl' },
  { year: '1997', value: 2701, category: 'Liquid fuel' },
  { year: '1997', value: 2409, category: 'Solid fuel' },
  { year: '1997', value: 1197, category: 'Gas fuel' },
  { year: '1997', value: 209, category: 'Cement production' },
  { year: '1997', value: 40, category: 'Gas flarinl' },
  { year: '1998', value: 2763, category: 'Liquid fuel' },
  { year: '1998', value: 2343, category: 'Solid fuel' },
  { year: '1998', value: 1224, category: 'Gas fuel' },
  { year: '1998', value: 209, category: 'Cement production' },
  { year: '1998', value: 36, category: 'Gas flarinl' },
  { year: '1999', value: 2741, category: 'Liquid fuel' },
  { year: '1999', value: 2310, category: 'Solid fuel' },
  { year: '1999', value: 1258, category: 'Gas fuel' },
  { year: '1999', value: 217, category: 'Cement production' },
  { year: '1999', value: 35, category: 'Gas flarinl' },
  { year: '2000', value: 2845, category: 'Liquid fuel' },
  { year: '2000', value: 2327, category: 'Solid fuel' },
  { year: '2000', value: 1289, category: 'Gas fuel' },
  { year: '2000', value: 226, category: 'Cement production' },
  { year: '2000', value: 46, category: 'Gas flarinl' },
  { year: '2001', value: 2848, category: 'Liquid fuel' },
  { year: '2001', value: 2445, category: 'Solid fuel' },
  { year: '2001', value: 1316, category: 'Gas fuel' },
  { year: '2001', value: 237, category: 'Cement production' },
  { year: '2001', value: 47, category: 'Gas flarinl' },
  { year: '2002', value: 2832, category: 'Liquid fuel' },
  { year: '2002', value: 2518, category: 'Solid fuel' },
  { year: '2002', value: 1342, category: 'Gas fuel' },
  { year: '2002', value: 252, category: 'Cement production' },
  { year: '2002', value: 49, category: 'Gas flarinl' },
  { year: '2003', value: 2958, category: 'Liquid fuel' },
  { year: '2003', value: 2695, category: 'Solid fuel' },
  { year: '2003', value: 1397, category: 'Gas fuel' },
  { year: '2003', value: 276, category: 'Cement production' },
  { year: '2003', value: 48, category: 'Gas flarinl' },
  { year: '2004', value: 3043, category: 'Liquid fuel' },
  { year: '2004', value: 2906, category: 'Solid fuel' },
  { year: '2004', value: 1443, category: 'Gas fuel' },
  { year: '2004', value: 298, category: 'Cement production' },
  { year: '2004', value: 54, category: 'Gas flarinl' },
  { year: '2005', value: 3068, category: 'Liquid fuel' },
  { year: '2005', value: 3108, category: 'Solid fuel' },
  { year: '2005', value: 1485, category: 'Gas fuel' },
  { year: '2005', value: 320, category: 'Cement production' },
  { year: '2005', value: 60, category: 'Gas flarinl' },
  { year: '2006', value: 3091, category: 'Liquid fuel' },
  { year: '2006', value: 3293, category: 'Solid fuel' },
  { year: '2006', value: 1534, category: 'Gas fuel' },
  { year: '2006', value: 356, category: 'Cement production' },
  { year: '2006', value: 62, category: 'Gas flarinl' },
  { year: '2007', value: 3071, category: 'Liquid fuel' },
  { year: '2007', value: 3422, category: 'Solid fuel' },
  { year: '2007', value: 1562, category: 'Gas fuel' },
  { year: '2007', value: 382, category: 'Cement production' },
  { year: '2007', value: 66, category: 'Gas flarinl' },
  { year: '2008', value: 3103, category: 'Liquid fuel' },
  { year: '2008', value: 3587, category: 'Solid fuel' },
  { year: '2008', value: 1630, category: 'Gas fuel' },
  { year: '2008', value: 388, category: 'Cement production' },
  { year: '2008', value: 69, category: 'Gas flarinl' },
  { year: '2009', value: 3042, category: 'Liquid fuel' },
  { year: '2009', value: 3590, category: 'Solid fuel' },
  { year: '2009', value: 1584, category: 'Gas fuel' },
  { year: '2009', value: 415, category: 'Cement production' },
  { year: '2009', value: 66, category: 'Gas flarinl' },
  { year: '2010', value: 3107, category: 'Liquid fuel' },
  { year: '2010', value: 3812, category: 'Solid fuel' },
  { year: '2010', value: 1696, category: 'Gas fuel' },
  { year: '2010', value: 446, category: 'Cement production' },
  { year: '2010', value: 67, category: 'Gas flarinl' },
  { year: '2011', value: 3134, category: 'Liquid fuel' },
  { year: '2011', value: 4055, category: 'Solid fuel' },
  { year: '2011', value: 1756, category: 'Gas fuel' },
  { year: '2011', value: 494, category: 'Cement production' },
  { year: '2011', value: 64, category: 'Gas flarinl' },
  { year: '2012', value: 3200, category: 'Liquid fuel' },
  { year: '2012', value: 4106, category: 'Solid fuel' },
  { year: '2012', value: 1783, category: 'Gas fuel' },
  { year: '2012', value: 519, category: 'Cement production' },
  { year: '2012', value: 65, category: 'Gas flarinl' },
  { year: '2013', value: 3220, category: 'Liquid fuel' },
  { year: '2013', value: 4126, category: 'Solid fuel' },
  { year: '2013', value: 1806, category: 'Gas fuel' },
  { year: '2013', value: 554, category: 'Cement production' },
  { year: '2013', value: 68, category: 'Gas flarinl' },
  { year: '2014', value: 3280, category: 'Liquid fuel' },
  { year: '2014', value: 4117, category: 'Solid fuel' },
  { year: '2014', value: 1823, category: 'Gas fuel' },
  { year: '2014', value: 568, category: 'Cement production' },
  { year: '2014', value: 68, category: 'Gas flarinl' }
]

// 配置处理脚本
const optionHandler = 'option.legend = option.legendEnable ? {position: setting.find(settingItem=>settingItem.field === \'legendPosition\').value} : false;' +
  '\n  if (option.legendEnable) {\n' +
  '    option.legend.itemName = option.legendItemName\n' +
  '  };' +
  'option.yAxis.grid.line.style.lineDash = [4,setting.find(settingItem=>settingItem.field === \'yAxis_grid_line_style_lineDash\').value]'

// 数据处理脚本
const dataHandler = ''

// 图表配置 new Line('domName', option)
const option = {
  // 数据将要放入到哪个字段中
  dataKey: 'data',
  data,
  xField: 'year',
  yField: 'value',
  appendPadding: [16, 16, 16, 16], // 设置图标的边距
  legendEnable: false,
  legendLayout: 'vertical',
  legendPosition: 'top',
  legend: false,
  legendItemName: {
    style: {
      fill: '#595959',
      fontSize: 12,
      fontWeight: 400
    }
  },
  color: ['#5B8FF9', '#61DDAA', '#5D7092', '#F6BD16', '#6F5EF9', '#6DC8EC', '#945FB9', '#FF9845', '#1E9493', '#FF99C3'],
  seriesField: 'category',
  xAxis: {
    title: {
      text: '',
      position: 'end',
      style: {
        fill: '#8C8C8C',
        fontSize: 12
      }
    },
    label: {
      autoRotate: false,
      autoHide: true,
      autoEllipsis: true,
      style: {
        fill: '#8C8C8C',
        fontSize: 12
      }
    },
    line: {
      style: {
        stroke: '#d0d0d0',
        lineWidth: 1
      }
    },
    tickLine: {
      style: {
        stroke: '#d0d0d0',
        lineWidth: 1
      }
    }
  },
  yAxis: {
    title: {
      text: '',
      position: 'end',
      autoRotate: false,
      // rotation: Math.PI / 2,
      style: {
        fill: '#8C8C8C',
        fontSize: 12
      }
    },
    grid: {
      line: {
        style: {
          stroke: '#d0d0d0',
          lineWidth: 1,
          lineDash: [4, 5],
          strokeOpacity: 0.7
        }
      }
    },
    label: {
      formatter: (v) => {
        if (v < 1e3) return v
        // 数值格式化为千分位
        if (v >= 1e3 && v < 1e6) return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
        if (v >= 1e6 && v < 1e9) return `${(v / 1e6).toFixed(1)} M`
        if (v >= 1e9 && v < 1e12) return `${(v / 1e9).toFixed(1)} B`
        return `${(v / 10e8).toFixed(1)} B`
      },
      style: {
        fill: '#8C8C8C',
        fontSize: 12,
        opacity: 1
      }
    },
    line: {
      style: {
        stroke: '#d0d0d0',
        lineWidth: 0
      }
    }
  },
  lineStyle: {
    lineWidth: 2
  }
}

export default {
  version,
  category,
  title,
  chartType,
  name,
  option,
  setting,
  optionHandler,
  dataHandler
}
