var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-design-wrap",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"content"},_vm._l((_vm.option.data),function(item,index){return _c('div',{key:index,staticClass:"content_item",style:({
        'border-color': _vm.option.borderColor,
        'border-width': _vm.option.borderWidth + 'px',
        'background-color': _vm.option.bgColor,
        'font-size': _vm.option.fontSize + 'px',
        'min-width': _vm.option.width + 'px',
        'height': _vm.option.height + 'px',
        color: _vm.option.color,
        'border-radius': _vm.option.borderRadius + 'px',
        'font-weight': _vm.option.fontWeight,
        'margin-right':
          index !== _vm.option.data.length - 1 ? _vm.option.marginRight + 'px' : ''
      })},[_vm._v("\n      "+_vm._s(item)+"\n    ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }