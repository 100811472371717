import auth from '@/utils/auth';
import WujieVue from 'wujie-vue2'
import store from '@/store'

const lifecycles = {
  // beforeLoad: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeLoad 生命周期`),
  // beforeMount: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeMount 生命周期`),
  afterMount: (appWindow) => {
    // console.log(`主应用广播给${appWindow.__WUJIE.id}`);
    const appName = appWindow.__WUJIE.id
    const token = auth.getToken()
    if(token) {
      WujieVue.bus.$emit(`updateToken-${appName}`, token)
    }
    store.commit("system/plusSubAppFinishNum");

    const SubAppAllNum = store.getters['system/getSubAppAllNum']
    const SubAppFinishNum = store.getters['system/getSubAppFinishNum']
    if(SubAppAllNum === SubAppFinishNum) {
      window.mircoLoading.close()
    }

    if(window.routerData) {
      console.log('发送 alive-router-change', appName, window.routerData);
      WujieVue.bus.$emit(`alive-router-change-${appName}`, window.routerData, true)
    }

    console.log(`${appName} afterMount 生命周期`)
  },
  // beforeUnmount: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeUnmount 生命周期`),
  // afterUnmount: (appWindow) => console.log(`${appWindow.__WUJIE.id} afterUnmount 生命周期`),
  // activated: (appWindow) => console.log(`${appWindow.__WUJIE.id} activated 生命周期`),
  // deactivated: (appWindow) => console.log(`${appWindow.__WUJIE.id} deactivated 生命周期`),
  loadError: (url, e) => console.log(`${url} 加载失败`, e),
};

export default lifecycles;
