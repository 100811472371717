var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-setting-wrap"},[_c('el-form',{ref:"form",staticClass:"setting-body",attrs:{"model":_vm.config,"label-width":"90px","label-position":"left"}},[_c('div',{staticClass:"setting-inner-wrap"},[_c('el-form',{staticClass:"setting-body",attrs:{"model":_vm.config.customize,"label-position":"left","label-width":"90px"}},[_c('SettingTitle',[_vm._v("标题")]),_vm._v(" "),_c('div',{staticClass:"lc-field-body"},[_c('el-form-item',{attrs:{"label":"链接名称"}},[_c('el-input',{attrs:{"clearable":""},model:{value:(_vm.config.title),callback:function ($$v) {_vm.$set(_vm.config, "title", $$v)},expression:"config.title"}})],1)],1),_vm._v(" "),_c('SettingTitle',[_vm._v("基础")]),_vm._v(" "),_c('div',{staticClass:"lc-field-body"},_vm._l((_vm.config.customize.linkList),function(link,index){return _c('div',{key:index,staticClass:"link-set-item"},[_c('el-form-item',{attrs:{"label":"名称","rules":_vm.rules.name,"prop":'linkList.' + index + '.name'}},[_c('el-input',{attrs:{"type":"text"},model:{value:(link.name),callback:function ($$v) {_vm.$set(link, "name", $$v)},expression:"link.name"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"链接地址","rules":_vm.rules.url,"prop":'linkList.' + index + '.url'}},[_c('el-input',{attrs:{"type":"text"},model:{value:(link.url),callback:function ($$v) {_vm.$set(link, "url", $$v)},expression:"link.url"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"打开方式"}},[_c('el-select',{staticClass:"db-el-select",attrs:{"popper-class":"db-el-select","placeholder":"请选择打开方式","clearable":""},model:{value:(link.target),callback:function ($$v) {_vm.$set(link, "target", $$v)},expression:"link.target"}},_vm._l(([
                    { label: '当前窗口', value: '_self' },
                    { label: '新窗口', value: '_blank' },
                  ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"图标选择","label-width":"100px"}},[_c('IconSelect',{ref:"IconSelect",refInFor:true,attrs:{"link":{
                  icon: link.icon,
                  iconColor: link.iconColor
                }},on:{"showChooseIcon":function($event){return _vm.showChooseIcon(...arguments, link, index)},"chooseIcon":_vm.chooseIcon}})],1),_vm._v(" "),_c('div',{staticClass:"opt-wrap"},[_c('el-button',{attrs:{"type":"default"},on:{"click":function($event){return _vm.addLinkIcon(index)}}},[_vm._v("\n                新增\n              ")]),_vm._v(" "),_c('el-button',{attrs:{"type":"default","disabled":_vm.config.customize.linkList.length === 1},on:{"click":function($event){return _vm.delLink(index)}}},[_vm._v("\n                删除\n              ")]),_vm._v(" "),_c('el-button',{attrs:{"type":"default","icon":"el-icon-bottom"},on:{"click":function($event){return _vm.down(index)}}}),_vm._v(" "),_c('el-button',{attrs:{"type":"default","icon":"el-icon-top"},on:{"click":function($event){return _vm.up(index)}}})],1)],1)}),0)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }