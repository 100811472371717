import { HomePageRoute } from '@/micro-app/utils'

const state = () => ({
  cardBackground: "#4caf50",
  level1Menu:[],
  tab:[
    {
         name: "驾驶舱",
         path: HomePageRoute,
         query: {},
         active: true
       },],
  tab2:[],
  exincludeList:[],
  sidebarType: 2,
  timeNum: 0,
  systemTime: '',
  currentRoute: null,
	childRouterMap: {},
  subAppAllNum: 0,    // 所有子应用数量
  subAppFinishNum: 0,// 已完成子应用数量
})

// getters
const getters = {
  getCardBackground(state) {
    return state.cardBackground
  },
  getCurrentRoute: (state) => state.currentRoute,
  isMicroAfterMount: (state) => {
    return state.subAppAllNum === state.subAppFinishNum
  },
  getSubAppAllNum: (state) => state.subAppAllNum,
  getSubAppFinishNum: (state) => state.subAppFinishNum,
}

// actions
const actions = {}

// mutations
const mutations = {
  setSidebarType(state, sidebarType) {
    state.sidebarType = sidebarType
  },
  setExincludeList(state, exincludeList) {
    state.exincludeList = exincludeList
  },
  setCardBackground(state, color) {
    state.cardBackground = color
  },
  setLevel1Menu(state, level1Menu){
    state.level1Menu = level1Menu
  },
  setSystemTime(state, systemTime) {
    state.systemTime = systemTime;
  },
  setTab(state, tab) {
    state.tab = tab
  },
  setTabPush(state, tabitem) {
    state.tab.push(tabitem)
  },
  setTabSplice(state, param){
    state.tab.splice(param.index,param.count)
  },
  setTabActive(state, param){
    state.tab[param.index]['active']=param.active
    if (param.name) {
      state.tab[param.index]['name']=param.name
    }
  },
  setTab2Active(state, param){
    state.tab2[param.index]['active']=param.active
    if (param.name) {
      state.tab2[param.index]['name']=param.name
    }
  },
  setTab2(state, tab2){
    state.tab2 = tab2
  },
  setTab2Push(state, tabitem) {
    state.tab2.push(tabitem)
  },
  setTab2Splice(state,param){
    state.tab2.splice(param.index,param.count)
  },
  setLoginSecurityKey(state,key){
    state.loginSecurityKey = key;
  },
  setTimeNum(state, timeNum) {
    state.timeNum = timeNum;
  },
	setChildRouterMap(state, { key, value }) {
		state.childRouterMap[key] = value;
	},
  // 设置当前路由
  setCurrentRoute(state, value) {
    state.currentRoute = value;
  },
  setSubAppFinishNum(state, value) {
    state.subAppFinishNum = value;
  },
  plusSubAppFinishNum(state) {
    state.subAppFinishNum++;
  },
  setSubAppAllNum(state, value) {
    state.subAppAllNum = value;
  },
  plusSubAppAllNum(state) {
    state.subAppAllNum++;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
