import { render, staticRenderFns } from "./dataSetSetting.vue?vue&type=template&id=1a7a74dc&scoped=true"
import script from "./dataSetSetting.vue?vue&type=script&lang=js"
export * from "./dataSetSetting.vue?vue&type=script&lang=js"
import style1 from "./dataSetSetting.vue?vue&type=style&index=1&id=1a7a74dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7a74dc",
  null
  
)

export default component.exports