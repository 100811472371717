<template>
	<div style="padding: 0 16px">
		<div class="k_grid_card" v-loading="loading">
			<div
				:class="[
					!dataAuditConfig ? 'card_item height_card' : 'card_item height_card1',
					selectIndex !== '' && item.businessScenarioId == list[selectIndex].businessScenarioId ? 'highLightCard' : ''
				]"
				v-for="(item, index) in list"
				:key="index"
				@click="getIndex(item, index)">
				<div class="line" v-if="!dataManagerConfig" :class="!dataAuditConfig ? 'gold_line' : item.checkFlag === '1' ? 'line2' : 'line1'"></div>
				<k-grid-card-item
					ref="cardItem"
					:data-page="dataPage"
					:data-audit-config="dataAuditConfig"
					:data-manager-config="dataManagerConfig"
					:data-target="dataTarget"
					:data="item"
					v-bind="$attrs"
					v-on="$listeners"></k-grid-card-item>
			</div>
		</div>
		<k-pagination :total="dataTotal" :page.sync="start" :limit="dataLimit" @pagination="handlePagination" />
	</div>
</template>

<script>
// 该组件只支持dataAction 地址，其他地址后期需要在添加
import { merge, concat } from 'lodash';
import KGridCardItem from './k-grid-card-item.vue';
import emitter from '@/components/k-element/common/k-emitter.js';
import scrollToBottom from '@/components/k-element/common/k-scroll-to-bottom.js';
import KPagination from './k-grid-pagination';
export default {
	components: {
		KGridCardItem,
		KPagination
	},
	mixins: [emitter()],
	emits: ['changeStart', 'changeLimit'],
	data() {
		return {
			loading: false,
			list: [],
			isFirstLoad: true,
			start: 1,
			dataPage: 1,
			toolShow: false,
			selectIndex: ''
		};
	},
	props: {
		dataData: {
			type: Array
		},
		dataAuditConfig: {
			type: Boolean,
			default: false
		},
		dataManagerConfig: {
			type: Boolean,
			default: false
		},
		dataTotal: {
			type: Number | String
		},
		dataShowLoading: {
			type: Boolean,
			default: true
		},
		dataTarget: {
			type: String
		},
		dataQueryParams: {
			type: Object
		},
		dataStart: {
			type: Number
		},
		dataLimit: {
			type: Number
		}
	},
	methods: {
		// getCard(){
		//     const cardItem = this.$refs.cardItem
		//     //  const paravalTall = document.querySelectorAll('.paravalTall')
		//     cardItem.forEach((item)=>{
		//      const cp = item.cloneNode(true)
		//       cp.style.cssText = 'position: absolute;opacity:0;display:inline-block';
		//       item.parentElement.appendChild(cp);
		//       cp.style.height = 'auto'
		//       cp.style.whiteSpace='normal'
		//       const height = cp.getBoundingClientRect().height;
		//       cp.remove()
		//       this.toolShow === height < 20
		//     })
		// },
		loadData(start) {
			this.$emit('changeStart', start);
			this.getParentRef(this.dataTarget).load(
				{
					...this.dataQueryParams
				},
				start
			);
		},
		handlePagination(e) {
			this.$emit('changeLimit', e.limit);
			this.loadData(e.page);
			this.dataPage = e.page;
		},
		_scrollToBottom() {
			if (this.list.length === this.dataTotal) {
				this.$message.info('没有更多数据了');
				this.removeListener();
			} else {
				this.loadData(++this.start);
			}
		},
		getIndex(item, i) {
			this.selectIndex = i;
		}
	},
	watch: {
		dataData: function (newVal, oldVal) {
			if (this.dataStart === 1) {
				// this.list = newVal
				this.start = 1;
				// if(!this.isAddScroll){
				//     this.addEvent()
				// }
			}
			this.list = newVal;
			this.list.forEach(item => {
				if (!this.dataAuditConfig && item.confoption) {
					item.checkFlag = '1';
					item.confoption = JSON.parse(item.confoption);
					//  item.confoption.maxLength = Number(item.confoption.maxLength)
					item.confoption.dataAllowblank = item.confoption.dataAllowblank && item.confoption.dataAllowblank === 'true';
					//  item.confoption.multiple = item.confoption.multiple && item.confoption.multiple === "true"
				}
			});
		}
	}
};
</script>

<style scoped lang="scss">
.k_grid_card {
	display: flex;
	min-width: 1595px;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding-top: 16px;
	.height_card {
		height: 174px;
	}
	.height_card1 {
		height: 202px;
	}
	.card_item {
		position: relative;
		overflow: hidden;
		min-width: 303px;
		width: 19%;
		background: #2a2d3f;
		border-radius: 4px;
		margin: 0 1% 1% 0;
		cursor: pointer;
		user-select: none;
		&:hover {
			transform: scale(1.02);
		}
		.line {
			height: 6px;
			background: #00a0e9;
			border-radius: 4px 4px 0px 0px;
		}
		.line1 {
			background: #00a0e9;
		}
		.line2 {
			background: #e6b014;
		}
		.gold_line {
			background-image: -webkit-gradient(linear, left top, right top, from(#f5e9ca), to(#b39e7b)) !important;
			background-image: linear-gradient(to right, #f5e9ca, #b39e7b) !important;
		}
	}
	.highLightCard {
		background: #33374d !important;
		border: 1px solid rgba(0, 160, 233, 1);
		box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1);
		::v-deep .k_grid_card_item1 {
			padding: 12px 15px 0 15px !important;
		}
	}
	::v-deep .grid-content .pagination-container {
		padding-top: 0 !important;
	}
}
</style>
