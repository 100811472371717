/**
 * 子应用配置文件
 */
export const MicroAppConfig = [
	// {
	// 	name: 'market',
	// 	url: 'http://localhost:7548/',
	// 	// url: 'http://172.23.6.30:7070/market/#/',
	// 	routePrefix: '/main', // 路由前缀兼容
  //   alive: true,  // true 保活模式    false 单例模式
  //   appFlag: '0' // 后端菜单接口返回标识
	// },
  {
		name: 'cms',
		url: 'http://172.21.13.71:7071/',
		// url: 'http://172.23.6.30:7070/cms/#/',
		routePrefix: '', // 路由前缀兼容
    alive: true,  // true 保活模式    false 单例模式
    appFlag: '1' // 后端菜单接口返回标识
	}
];

// 首页路由配置
export const homePageConfig = {
  // appName: 'market', // 所属子应用
  // homePath: `/main/desktop`, // 子应用首页路径
}
