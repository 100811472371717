import WujieVue from 'wujie-vue2'
import { MicroAppConfig as devMicroAppConfig, homePageConfig } from '../config'
import Store from '@/store';

const hasOwnProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

// 子应用端口名称配置
export const MicroAppConfig = (() => {

  // 配置参数为非空处理
  try {
    devMicroAppConfig.forEach(appConfig => {
      if(!hasOwnProperty(appConfig, 'name')) {
        throw new Error(`错误, 未配置子应用名称`);
      }
      if(!hasOwnProperty(appConfig, 'url')) {
        throw new Error(`错误, 未配置子应用url`);
      }
      if(!hasOwnProperty(appConfig, 'appFlag')) {
        throw new Error(`错误, 未配置子应用菜单标识`);
      }
      if(!hasOwnProperty(appConfig, 'routePrefix')) {
        appConfig.routePrefix = ''
      }
      if(!hasOwnProperty(appConfig, 'alive')) {
        appConfig.alive = false   // 未配置时，默认单例模式
      }
    })
  } catch (error) {
    console.error(error);
  }

  if (process.env.NODE_ENV === 'production') {
    // 基座应用和子应用部署在同一个域名下，这里使用location.origin进行补全
    return devMicroAppConfig.map((item) => {
      // item.url = `${window.location.origin}/${item.name}/`;
      const hostname = window.location.hostname
      if(item.name==='cms'){
        if (hostname.indexOf("market.test.kkws.cn")>-1) {
          item.url="https://market-cms.test.kkws.cn"
        }else if (hostname.indexOf("market.demo.kayakwise.com")>-1) {
          item.url="https://market-cms.demo.kayakwise.com"
        }
      }
      return item
    });
  } else {
    return devMicroAppConfig
  }
})()

// appName 与 moduleid 之间的映射
// eg.  '0': 'market'
export const appNameMap = (() => {
  const map = {}
  MicroAppConfig.forEach(({ appFlag, name}) => {
    map[appFlag] = name
  })
  return map
})();

export const appKey = 'moduleid'

// 根据 菜单值 获取子应用名称
export const getAppNameForMenu = (menuData) => {
  return appNameMap[menuData[appKey]]
}

// 根据名称获取配置
export const getConfig = (appName) => {
  return MicroAppConfig.find(app => app.name === appName)
}

// 向所有子应用广播 token
export const broadcastToken = (token) => {
  MicroAppConfig.forEach(({ name }) => {
    WujieVue.bus.$emit(`updateToken-${name}`, token)
  })
}

// 微前端url转换为后端返回url
export const transformMicroUrlToNormal = (url) => {
  let urlUnits = url.split('/');
  if(urlUnits[0] === '' && urlUnits[1] === 'main' && MicroAppConfig.some(app => app.name === urlUnits[2]) && urlUnits.length > 3) {
    // 命中 /main/market/xxx/xxxx' 类型路由
    // 活动平台
    return urlUnits.slice(3).join('/')
  } else if(urlUnits[0] === '' && urlUnits[1] === 'main'){
    return urlUnits.slice(2).join('/')
  } else {
    // 其他特殊情况需要进行兼容
    return url
  }
}

// 根据url获取当前为哪个子应用
export const getCurrentAppConfigForUrl = (url) => {
  const targetConfig = devMicroAppConfig.find(appConfig => {
    const { name } = appConfig
    return url ? url.indexOf(`/${name}/`) > -1 : false
  })
  return targetConfig ? targetConfig : false
}



// 判定当前路由name是否为 appName-sub
export const isChildRouter = (curName) => {
  return MicroAppConfig.some(app => `${app.name}-sub` === curName)
}


// 根据配置，构建子应用路由
export const getMicroAppRoutes = () => {
  return MicroAppConfig.map(subApp => {
    const { name, alive } = subApp
    let component
    if(alive) {
      component = () => import('@/micro-app/components/wujie-alive-sub')
    } else {
      component = () => import('@/micro-app/components/wujie-sub')
    }
    return {
      path: `${name}/*`,
      name: `${name}-sub`,
      component,
    }
  });
}

// 获取首页路由（首页来源于子应用）
export const HomePageRoute = (() => {
  // const { appName, homePath } = homePageConfig
  // const { routePrefix } = getConfig(appName)
  // return `/main/${appName}${homePath.replace(routePrefix, '')}`

  return "/main/desktop"
})()


// 通知子应用路由跳转
export const emitAliveRouterChange = (routeData) => {
  const { path: routePath, query} = routeData
  const targetConfig = getCurrentAppConfigForUrl(routePath)
  if(!targetConfig) {
    console.error('路由异常, 未找到对应子应用，请检查: ', routePath);
    return
  }
  const { name, routePrefix } = targetConfig
  // console.log('发送 alive-router-change', name);
  WujieVue.bus.$emit(`alive-router-change-${name}`, {
    name,
    path: `${routePrefix}/${transformMicroUrlToNormal(routePath)}`,
    query
  })

  // 未加载完应用时，将需要跳转的路由暂存
  const isMicroAfterMount = Store.getters['system/isMicroAfterMount']
  if(!isMicroAfterMount) {
    window.routerData = {
      name,
      path: `${routePrefix}/${transformMicroUrlToNormal(routePath)}`,
      query
    }
  }

}

// 通知子应用删除该路由keep-alive（保活模式下生效）
export const emitSubChangeKeepAlive = (routePathArray) => {
  const appRoutePathMap = {}
  // 构建 应用-路由 map
  routePathArray.forEach(routePath => {
    const targetConfig = getCurrentAppConfigForUrl(routePath)
    if(!targetConfig) {
      return
    }
    const { name } = targetConfig
    if(!appRoutePathMap[name]) appRoutePathMap[name] = []
    appRoutePathMap[name].push({
      path: `${transformMicroUrlToNormal(routePath)}`
    })
  })

  // 通知对应子应用关闭路由 keep-alive
  for (const name in appRoutePathMap) {
    if (Object.prototype.hasOwnProperty.call(appRoutePathMap, name)) {
      const routePaths = appRoutePathMap[name];
      WujieVue.bus.$emit(`keep-alive-change-${name}`, routePaths)
    }
  }
}
