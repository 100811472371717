import kayak from "@/frame/kayak.js";
import httpUtil from "@/frame/httpUtil.js";
import moment from "moment";
import { MessageBox } from "element-ui";
import global from "@/frame/global";
import store from "@/store/index";
import { cloneDeep, remove, debounce, assign } from 'lodash'

let Tools = {};

/**
 * lodash 方法
 */
Tools.cloneDeep = cloneDeep
Tools.remove = remove
Tools.debounce = debounce
Tools.assign = assign

Tools.removeTab = function (componentName, autoSkip = true) {
	let tab = store.state.system.tab;
	let tab2 = store.state.system.tab2;

	let find = false;
	tab.some((item, index) => {
		if (item.meta && item.meta.componentName == componentName) {
			Tools.setExInclude(item, 1);
			store.commit("system/setTabSplice", { index: index, count: 1 });
			if (tab2.length > 0) {
				let deleteIndex = tab2.length - 1;
				store.commit("system/setTabPush", tab2[deleteIndex]);
				store.commit("system/setTab2Splice", { index: deleteIndex, count: 1 });
			}
			if ((autoSkip == true) & item.active) {
				window._ROUTER.push({
					path: tab[index - 1].path,
					query: tab[index - 1].query,
				});
			}
			find = true;
			return true;
		}
	});

	if (!find) {
		tab2.some((item, index) => {
			if (item.meta && item.meta.componentName == componentName) {
				Tools.setExInclude(item, 1);
				store.commit("system/setTab2Splice", { index: index, count: 1 });
				if (item.active) {
					if (index != 0) {
						window._ROUTER.push({
							path: tab2[index - 1].path,
							query: tab2[index - 1].query,
						});
					} else {
						window._ROUTER.push({
							path: tab[tab.length - 1].path,
							query: tab[tab.length - 1].query,
						});
					}
				}
				return true;
			}
		});
	}
};

Tools.setExInclude = (tab, type) => {
	if (tab.meta && tab.meta.componentName) {
		//设置为不缓存
		if (type == 1) {
			let e = store.state.system.exincludeList;
			let a = false;
			e.some((item) => {
				if (item == tab.meta.componentName) {
					a = true;
					return true;
				}
			});
			if (!a) {
				e.push(tab.meta.componentName);
				store.commit("system/setExincludeList", e);
			}
		} else {
			//设置为缓存
			let e = this.$store.state.system.exincludeList;
			let newArray = e.filter((item) => {
				return item != tab.meta.componentName;
			});
			this.$store.commit("system/setExincludeList", newArray);
		}
	}
};

Tools.str2Json = function (str) {
	if (!str) {
		return null;
	}
	var json = null;
	json = eval("(" + str + ")");
	return json;
};

Tools.enterConvert = function (fieldval, replaceQuotation) {
	if (fieldval) {
		fieldval = fieldval.replace(/\n/g, "@#n#@");
		if (replaceQuotation === false) {
			fieldval = fieldval.replace(/\"/g, "##");
		}
	}
	return fieldval;
};

Tools.randomString = function (len) {
	len = len || 32;
	var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	/****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	var maxPos = $chars.length;
	var pwd = "";
	for (var i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
};

Tools.formatDate = function (date) {
	if (!date) {
		return "";
	}
	date = date + "";
	let years = date.substring(0, 4);
	let months = date.substring(4, 6);
	let days = date.substring(6, 8);
	let val = "";
	if (years) {
		val = years;
	}
	if (years && months) {
		val = years + "-" + months;
	}
	if (years && months && days) {
		val = years + "-" + months + "-" + days;
	}
	return val;
};

Tools.formatDateTime = function (date, time) {
	if (!date || !time) {
		return "";
	}
	date = date + "";
	time = time + "";

	let result = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8) + " ";
	if (time.length == 6) {
		return result + time.substring(0, 2) + ":" + time.substring(2, 4) + ":" + time.substring(4, 6);
	} else {
		return result + time.substring(0, 2) + ":" + time.substring(2, 4);
	}
};

Tools.diffDateTime = function (date1, time1, date2, time2) {
	let moment1 = moment(date1 + " " + time1);
	let moment2 = moment(date2 + " " + time2);

	let time = moment2.diff(moment1, "second");
	let day = Math.floor(time / 86400);
	time = time % 86400;

	let hour = Math.floor(time / 3600);
	time = time % 3600;

	let minute = Math.floor(time / 60);
	time = time % 60;

	let second = time;

	return day + "天" + hour + "时" + minute + "分" + second + "秒";
};

Tools.formatTime = function (time) {
	if (!time) {
		return "";
	}
	time = time + "";
	let hours = time.substring(0, 2);
	let mins = time.substring(2, 4);
	let seconds = time.substring(4, 6);
	let val = "";
	if (hours) {
		val = hours;
	}
	if (hours && mins) {
		val = hours + ":" + mins;
	}
	if (hours && mins && seconds) {
		val = hours + ":" + mins + ":" + seconds;
	}
	return val;
};

function formatMoney(s) {
	let dot = ",";
	s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(2) + "";
	var l = s.split(".")[0].split("").reverse(),
		r = s.split(".")[1];
	t = "";
	for (i = 0; i < l.length; i++) {
		t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? dot : "");
	}
	// 没有小数时，补充两个小数点
	r = r ? r : ".00";
	// 有小数但少一位，用0补充
	r.length === 2 ? (r += "0") : "";
	return t.split("").reverse().join("") + "." + r;
}

Tools.formatMoney = function formatMoney(s) {
	if (!s) {
		return "0.00";
	}
	let dot = ",";
	s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(2) + "";
	let l = s.split(".")[0].split("").reverse(),
		r = s.split(".")[1];
	let t = "";
	for (let i = 0; i < l.length; i++) {
		t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? dot : "");
	}
	return t.split("").reverse().join("") + "." + r;
};

// 格式化为正整数的金额形式
Tools.formatIntegerMoney = function (s) {
	if (!s) {
		return 0;
	}
	let dot = ",";
	s = (s + "").replace(/[^\d\.-]/g, "");
	let l = s.split("").reverse();
	let t = "";
	for (let i = 0; i < l.length; i++) {
		t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? dot : "");
	}
	return t.split("").reverse().join("");
};

Tools.apply = function (o, c) {
	if (o == null) {
		o = {};
	}
	if (o && c && typeof c == "object") {
		for (var p in c) {
			o[p] = c[p];
		}
	}
	return o;
};

//设置千分位标注
Tools.setThousandsMark = function (value) {
	if (!value) return value;
	// 获取整数部分
	const intPart = Math.trunc(value);
	// 整数部分处理，增加,
	const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
	// 预定义小数部分
	let floatPart = "";
	// 将数值截取为小数部分和整数部分
	const valueArray = value.toString().split(".");
	if (valueArray.length === 2) {
		// 有小数部分
		floatPart = valueArray[1].toString(); // 取得小数部分
		return intPartFormat + "." + floatPart;
	}
	return intPartFormat + floatPart;
};

/**
 * 将数组转换成字符串
 */
Tools.array2str = function (arr, includeFunction) {
	if (arr == null) {
		return "null";
	}
	var s = [],
		v,
		type;
	for (var i = 0; i < arr.length; i++) {
		(v = arr[i]), (type = typeof v);
		switch (type) {
			case "function": //function对象不添加
				if (includeFunction === true) {
					v =
						'"' +
						String(v)
							.replace(/[\n\r\t]/g, "")
							.replace(/[\t]/g, " ") +
						'"';
				} else {
					continue;
				}
				break;
			case "string": //字符串添加双引号
				v = '"' + v + '"';
				break;
			case "object":
				if (Tools.isArray(v)) {
					v = Tools.array2str(v, includeFunction);
				} else {
					v = Tools.json2str(v, includeFunction);
				}
				break;
		}
		s.push(v);
	}
	return "[" + s.join(",") + "]";
};

/**
 * 将JSON对象转换成字符串
 * 参数：includeFunction boolean类型，true则包含function值的属性也转成string，其他值则不转function属性，默认为false
 */
Tools.json2str = function (json, includeFunction, isConfOption) {
	if (json == null) {
		return "null";
	}
	if (Tools.isArray(json)) {
		return Tools.array2str(json, includeFunction, isConfOption);
	}
	var s = [],
		k,
		v,
		type;
	for (k in json) {
		(v = json[k]), (type = typeof v);
		switch (type) {
			case "function": //function对象不添加
				if (includeFunction === true) {
					//避免出现脚本错误，需要把一些特殊字符做替换
					v = String(v)
						.replace(new RegExp("//.*[\n\r]", "g"), " ") // 需要将单行注释给替换掉
						//换行和制表符
						.replace(new RegExp("[\n\r]", "g"), "")
						.replace(new RegExp("[\t]", "g"), " ");
					if (isConfOption === true) {
						//在confOption里
					} else {
						v = '"' + v + '"';
					}
				} else {
					continue;
				}
				break;
			case "string": //字符串添加双引号
				var reg = new RegExp('"', "g"); //创建正则RegExp对象
				v = v.replace(reg, '\\"');
				v = '"' + v + '"';
				break;
			case "object":
				if (Tools.isArray(v)) {
					v = Tools.array2str(v, includeFunction, isConfOption);
				} else {
					if (k == "confOption") {
						v = '"' + Tools.json2str(v, includeFunction, true).replace(/["]/g, '\\"') + '"';
					} else {
						v = Tools.json2str(v, includeFunction, isConfOption);
					}
				}
				break;
		}
		s.push('"' + k + '":' + v);
	}
	return "{" + s.join(", ") + "}";
};

/**
 * 判断对象是否数组
 */
Tools.isArray = function (object) {
	if (object == null) {
		return false;
	}
	return object != null && typeof object == "object" && object.splice != null && object.join != null && object.length != null;
};

Tools.downloadFileByParams = function (download_path, download_name, download_code) {
	$("#downloadForm").remove();
	var downloadFormHtml = '<form id="downloadForm" action="base/comn-download.action" method="post" class="k-hidden" target="_self">';
	downloadFormHtml += '<input type="text" name="download_path" value=' + download_path + " />";
	downloadFormHtml += '<input type="text" name="download_name" value=' + download_name + " />";
	downloadFormHtml += '<input type="text" name="download_code" value=' + download_code + " />";
	downloadFormHtml += "</form>";

	var $downloadForm = $(downloadFormHtml);
	$downloadForm.appendTo($("body"));
	$downloadForm.submit();
};

Tools.download = function (path, filename) {
	httpUtil.download(
		{
			url: "/base/comn-download.json",
			params: { path: path },
		},
		filename
	);
};

Tools.batch_download = function (paths, filename) {
	httpUtil.download(
		{
			url: "/base/comn-batch-download.json",
			params: { paths: paths },
		},
		filename
	);
};

Tools.alert = function (message, type = global.notify_type.success, icon = "add_alert", horizontalAlign = "center", verticalAlign = "top") {
  kayak.app.$notify({
		timeout: type === "danger" ? 99999999999 : 2500,
		message: message,
		icon: icon,
		horizontalAlign: horizontalAlign,
		verticalAlign: verticalAlign,
		type: type,
	});
};

Tools.confirm = function (callback, message, title, type, confirmButtonText, cancelButtonText, buttonClass) {
	let dataDescribe = message ? message : "确定执行该操作吗？";
	MessageBox.confirm(dataDescribe, title ? title : "操作提示", {
		confirmButtonText: confirmButtonText ? confirmButtonText : "确定",
		cancelButtonText: cancelButtonText ? cancelButtonText : "取消",
		cancelButtonClass: buttonClass ? buttonClass : "el-button--info",
		type: type ? type : "warning",
		confirmButtonClass: "md-golden",
		cancelButtonClass: "md-plain",
		beforeClose: (action, instance, done) => {
			if (action === "confirm") {
				instance.confirmButtonLoading = true;
				instance.confirmButtonText = "执行中...";

				callback();
				done();
				instance.confirmButtonLoading = false;
			} else {
				done();
			}
		},
	}).catch(() => {});
};

Tools.getLoginUser = function () {
	return new Promise((resolve, reject) => {
		// if (!Tools.loginUser) {
		httpUtil.ajax({
			url: "getLoginUser.json",
			dataAfterSuccess: function (json) {
				Tools.loginUser = json.returndata;
				resolve(Tools.loginUser);
			},
		});
		// } else {
		//   resolve(Tools.loginUser);
		// }
	});
};

/**
 * 计算字符串字节长度(汉字两个字节)
 * @param str 目标字符串
 * @returns {number} 字节长度
 */
Tools.strByteLength = function (str) {
	//获取字符串的字节数，扩展string类型方法
	if (!str || str.length == 0) {
		return 0;
	}
	let b = 0;
	let l = str.length; //初始化字节数递加变量并获取字符串参数的字符个数
	for (let i = 0; i < l; i++) {
		//遍历字符串，枚举每个字符
		if (str.charCodeAt(i) > 255) {
			//字符编码大于255，说明是双字节字符
			b += 2; //则累加2个
		} else {
			b++; //否则递加一次
		}
	}
	return b; //返回字节数
};

Tools.uuid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0; // d是随机种子
		d = Math.floor(d / 16);
		return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};

// echart图X轴超长提示语
Tools.extensionFun = (chart) => {
	var elementDiv = document.getElementById("extension_more");
	if (!elementDiv) {
		var div = document.createElement("div");
		div.setAttribute("id", "extension_more");
		div.style.display = "block";
		document.querySelector("html").appendChild(div);
	}
	chart.on("mouseover", function (params) {
		if (params.componentType === "xAxis" && params.value && params.value.length > 6) {
			// 文字超6字才显示tip
			var elementDiv = document.querySelector("#extension_more");
			// 设置悬浮文本的位置以及样式
			var elementStyle =
				"position: absolute;z-index: 99999;color: #FFF;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px;max-width:300px";
			elementDiv.style.cssText = elementStyle;
			elementDiv.innerHTML = params.value;
			document.querySelector("html").onmousemove = function (event) {
				var elementDiv = document.querySelector("#extension_more");
				var xx = event.pageX - 10;
				var yy = event.pageY + 15;
				elementDiv.style.top = yy + "px";
				elementDiv.style.left = xx + "px";
			};
		}
	});
	chart.on("mouseout", function (params) {
		if (params.componentType === "xAxis" && params.value && params.value.length > 6) {
			var elementDiv = document.querySelector("#extension_more");
			elementDiv.style.cssText = "display:none";
		}
	});
};

/**
 * moment 时间日期转换
 * @param {*} val
 * @param {*} sourceFormat 源格式
 * @param {*} targetFormat 目标格式
 * @returns
 */
Tools.momentFormat = (val, sourceFormat = 'yyyyMMdd', targetFormat = 'yyyy-MM-dd') => {
	if (typeof val === 'number') {
		return moment(val).format(targetFormat.replace('yyyy', 'YYYY').replace('dd', 'DD'))
	}
	if (!val) {
		return ''
	}
	return moment(val, sourceFormat).format(targetFormat.replace('yyyy', 'YYYY').replace('dd', 'DD'))
}

/**
 * 清除url的ip、端口
 * @param {*} url 
 * @returns 
 */
Tools.cleanUrl = (url) => {
	if (url && url.startsWith('http')) {
		return new URL(url).pathname
	}
	return url
}

export default Tools;
