import auth from '@/utils/auth.js';
import Tools from '@/utils/tools.js';
import axios from 'axios';
import Qs from 'qs';
import kayak from '@/frame/kayak.js';
import { HomePageRoute } from '@/micro-app/utils'

let httpUtil = {};

// 后台网关地址，到根目录下的url-config.js里改
const basePath = getURL().baseUrl;

httpUtil.basePath = basePath;

httpUtil.comnUpdate = function (data) {
	let action = data.action;
	if (!action || action.indexOf('.') == -1) {
		return;
	}
	if (data.successAlert !== false) {
		data.successAlert = true;
	}
	let actions = action.split('.');
	data.url = 'commUpdate/' + actions[0] + '/' + actions[1] + '.json';

	return httpUtil.ajax(data);
};

httpUtil.update = function (data) {
	if (data.url != null) {
		if (data.url.substring(0, 1) == '/') {
			data.url = data.url.substr(1);
		}
	}
	if (data.successAlert !== false) {
		data.successAlert = true;
	}
	return httpUtil.ajax(data);
};

httpUtil.query = function (data) {
	data.mask = false;
	//单独处理js调用时，url多了一个/,去掉
	if (data.url != null) {
		if (data.url.substring(0, 1) == '/') {
			data.url = data.url.substr(1);
		}
	}
	return httpUtil.ajax(data);
};

httpUtil.graphqlQurey = function (data) {
	let graphql = data.graphql;
	if (data.params) {
		let graphqlFirst = graphql.substring(0, graphql.indexOf(')'));
		let graphqlSecond = graphql.substring(graphql.indexOf(')'));
		for (var key in data.params) {
			if (!data.params[key] && data.params[key] !== 0) {
				continue;
			}
			if (typeof data.params[key] == 'string') {
				graphqlFirst += ',' + key + ': "' + Tools.enterConvert(data.params[key], data.replaceQuotation) + '"';
			} else {
				graphqlFirst += ',' + key + ':' + data.params[key];
			}
		}
		graphql = graphqlFirst + graphqlSecond;
	}

	data.params = graphql;

	data.url = 'graphql.json';
	data.mask = false;
	return httpUtil.ajaxJson(data);
};

httpUtil.graphqlUpdate = function (data) {
	let graphql = data.graphql;

	if (data.params) {
		let graphqlFirst = graphql.substring(0, graphql.indexOf(')'));
		let graphqlSecond = graphql.substring(graphql.indexOf(')'));
		for (var key in data.params) {
			if (!data.params[key] && data.params[key] !== 0) {
				continue;
			}
			if (typeof data.params[key] == 'string') {
				graphqlFirst += ',' + key + ': "' + Tools.enterConvert(data.params[key], data.replaceQuotation) + '"';
			} else {
				graphqlFirst += ',' + key + ':' + data.params[key];
			}
		}
		graphql = graphqlFirst + graphqlSecond;
	}

	data.params = graphql;

	data.url = 'graphql.json';
	data.mask = false;
	data.successAlert = true;
	return httpUtil.ajaxJson(data);
};

httpUtil.comnQuery = function (data) {
	let action = data.action;

	if (!action || action.indexOf('.') == -1) {
		return;
	}

	let actions = action.split('.');

	data.url = 'commQuery/' + actions[0] + '/' + actions[1] + '.json';
	data.mask = false;
	return httpUtil.ajax(data);
};

httpUtil.comnQueryTree = function (data) {
	let action = data.action;
	if (!action || action.indexOf('.') == -1) {
		return;
	}
	let actions = action.split('.');
	data.url = 'commTreeQuery/' + actions[0] + '/' + actions[1] + '.json';
	return httpUtil.ajax(data);
};

const returnCode = '000000';
const return_code = '000000';

httpUtil.ajax = function (data) {
	// // 排除login页面，无需添加多法人字段
	if (data.url != 'jwt/login.json' && data.url != 'getLoginUser.json') {
		let legalCode = localStorage.getItem('legalCode');
		// let userOrgCode = localStorage.getItem("orgcode");

		if ((!data.params || !data.params.legalCode) && legalCode != null && legalCode != '') {
			if (data.params === undefined) {
				data.params = {};
			}
			data.params.legalCode = legalCode;
			// data.params.userOrgCode = userOrgCode;
		}
	}

	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: basePath + data.url,
			data: data.dataJson ? JSON.stringify(data.params) : Qs.stringify(data.params),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded',
				Authorization: auth.getToken()
			}
		})
			.then(res => {
				let reData = res.data;
				if (data.callback) {
					data.callback(reData);
				}

				if (reData.success || reData.status === '200' || reData.rtnCode === returnCode || reData.rtn_code === return_code || reData.rows) {
					if (reData.passLogin) {
						kayak.app.$router.push({
							path: HomePageRoute
						});
						return;
					}
					//后端返回内容里包含noPrint则无需打印
					if (!reData.noPrint) {
						// 前端参数控制是否弹出提示
						if (data.successAlert == false || (data.params && data.params.noPrint)) {
							// 无需任何操作
						} else if (reData.rtnCode || reData.rtn_code) {
							Tools.alert(reData.rtnDesc || reData.rtn_desc || '操作成功');
						} else if (data.successAlert) {
							Tools.alert(reData.returnmsg || '操作成功');
						}

						if (data.dataAfterSuccess) {
							data.dataAfterSuccess(reData);
						}
					}
					resolve(reData);
				} else {
					if (reData.login) {
						kayak.app.$router.push({
							path: '/login'
						});
						return;
					}
					if (reData.token_freshen) {
						auth.setToken(reData.token);
						httpUtil.ajax(data).then(_data => {
							resolve(_data);
						});
						return;
					}
					if (data.errCallback) {
						data.errCallback(reData);
					} else {
						Tools.alert(reData.returnmsg || reData.rtnDesc || reData.rtn_desc || '操作失败', 'danger');
						reject(reData)
					}
				}
			})
			.catch(function (err) {
				if (data.callback) {
					data.callback(err);
				}
				Tools.alert('服务器异常，请稍后尝试', 'danger');
				console.log(err);
			});
	});
};

httpUtil.ajaxJson = function (data) {
	// data.params.userOrgCode = localStorage.getItem("orgcode");
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: basePath + data.url,
			data: data.params,
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				Authorization: auth.getToken()
			}
		})
			.then(res => {
				let reData = res.data;
				if (reData.success || (reData && reData.rtnCode === '000000') || reData.rtnCode === return_code || reData.rows || !data.errCode) {
					if (!reData.noPrint && !data.noPrint) {
						if (reData.rtnCode) {
							Tools.alert(reData.rtnDesc || reData.rtn_desc || '操作成功');
						} else if (data.successAlert) {
							Tools.alert(reData.returnmsg || reData.rtn_desc || '操作成功');
						}
						if (data.dataAfterSuccess) {
							data.dataAfterSuccess(reData);
						}
					}
					resolve(reData);
				} else {
					if (data.errCallback && (!data.errCode || data.errCode == reData.rtnCode)) {
						data.errCallback(reData);
					} else {
						Tools.alert(reData.returnmsg || reData.rtnDesc || reData.rtn_desc || (reData.BODY && reData.BODY.rtnDesc) || '操作失败', 'danger');
					}
				}
			})
			.catch(function (err) {
				Tools.alert('服务器异常，请稍后尝试', 'danger');
				console.error(err);
			});
	});
};

httpUtil.download = async function (data, fileName, isPreview = false) {
	if (data.url != null) {
		if (data.url.substring(0, 1) == '/') {
			data.url = data.url.substr(1);
		}
	}

	let _ajaxUrl;
	if (data.url.indexOf('http') == -1) {
		_ajaxUrl = basePath + data.url;
	} else {
		_ajaxUrl = data.url;
	}

	if (!data.params) {
		data.params = {};
	}

	return axios({
		method: 'post',
		url: _ajaxUrl,
		data: Qs.stringify(data.params),
		responseType: 'blob',
		headers: {
			'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			Authorization: auth.getToken()
		}
	})
		.then(response => {
			if (data.callback) {
				data.callback(response);
			}
			if (!response) {
				return;
			}

			if (response.headers.err) {
				if (!isNaN(response.headers.err)) {
					Tools.alert('导出失败！当前导出数据量限制最大量为' + response.headers.err + ',当前导出已超出范围，请增加查询条件减少数据量', 'danger');
					return;
				}
				if (typeof response.headers.err === 'string') {
					Tools.alert(decodeURI(response.headers.err), 'danger');
					return;
				}
				var reader = new FileReader();
				reader.readAsText(response.data, 'utf-8');
				reader.onload = function () {
					let reData = JSON.parse(reader.result);
					Tools.alert(reData.returnmsg || '操作失败', 'danger');
				};
				return;
			}

			let url = window.URL.createObjectURL(new Blob([response.data]));

			// 如果是preview 则显示图片不下载
			if (isPreview) {
				return url;
			}
			let link = document.createElement('a');
			link.style.display = 'none';
			link.href = url;

			let suffix = '';
			console.log(response.headers['filename']);

			if (response.headers['filename']) {
				suffix = response.headers['filename'].substring(response.headers['filename'].lastIndexOf('.'), response.headers['filename'].length);
			}
			if (fileName) {
				fileName = fileName + suffix;
			} else {
				fileName = 'download' + suffix;
			}
			link.setAttribute('download', fileName);

			document.body.appendChild(link);
			link.click();
		})
		.catch(error => {
			if (data.callback) {
				data.callback();
			}
			Tools.alert('下载失败！', 'danger');
			console.info(error);
		});
};

httpUtil.dictCache = {};

httpUtil.dict = function (dict) {
	return new Promise((resolve, reject) => {
		let dt = httpUtil.dictCache[dict];
		if (dt == null) {
			let url = 'base/dict/' + dict + '.json';
			httpUtil
				.ajax({
					url: url,
					mask: false
				})
				.then(data => {
					if (data.rows && data.rows.length > 0) {
						for (const item of data.rows) {
							item.disabled = item.item_status == 'F'
						}
						httpUtil.dictCache[dict] = data.rows;
					}
					return resolve(data.rows);
				});
		} else {
			return resolve(dt);
		}
	});
};

httpUtil.dictTransfer = function (dict, key) {
	return new Promise((resolve, reject) => {
		if (dict === null || dict === '' || key === null || key === '') {
			return resolve('');
		}
		httpUtil.dict(dict).then(rows => {
			//取得字典数据
			if (!rows || rows.length == 0) {
				//取不到字典数据
				return key;
			}

			let vals = [];
			let keys = String(key).split(',');
			let len = keys.length;

			keys.map(key => {
				let value = '';
				rows.map(row => {
					if (row.itemkey == key) {
						value = row.itemval;
					}
				});
				vals.push(value);
			});

			return resolve(vals.join('，'));
		});
	});
};

httpUtil.verifyCode = function (t) {
	return new Promise((resolve, reject) => {
		axios({
			method: 'post',
			url: basePath + 'verify/verifyCode.action',
			params: { t },
			responseType: 'blob'
		})
			.then(response => {
				resolve(window.URL.createObjectURL(new Blob([response.data])));
			})
			.catch(error => {
				console.info(error);
			});
	});
};

export default httpUtil;
