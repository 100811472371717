const fontList = [
  {
    label: '默认',
    value: ''
  },
  {
    label: '时钟加粗倾斜',
    value: 'ds-digitalbold_italic'
  },
  {
    label: '时钟加粗正常',
    value: 'ds-digitalbold'
  },
  {
    label: '时钟倾斜',
    value: 'ds-digitalitalic'
  },
  {
    label: '时钟正常',
    value: 'ds-digitalnormal'
  }]
export default fontList
