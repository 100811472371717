// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueMaterial from 'vue-material';
import Chartist from 'chartist';
import Icon from 'vue2-svg-icon/Icon.vue';
import VueQuillEditor from 'vue-quill-editor';
import * as echarts from 'echarts';
import { Tree as virtualTree } from '@femessage/element-ui';

import App from './App';
import global from '@/frame/global';
import store from '@/store';
import kayakPlugin from '@/frame/kayakPlugin.js';
import router from './router';
import '@/frame/utils.js';
// 引入字体文件
import './assets/fonts/index.scss';
// require styles 引入样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-material/dist/vue-material.min.css';
import './components/k-element/svg-icon/index';

//引用多种主题样式
import { themeInfoArr } from "../static/theme/theme.js"

// 默认主题样式
import "./assets/scss/default-theme/index.scss";
// import './assets/scss/dark-theme/index.scss';
// Vue.prototype.$globalTheme='defalutTheme';
import Tools from '@/utils/tools.js'

// import '@/micro-config'
// wujie微前端
import '@/micro-app'


// 大屏配置
import { registerConfig as bigScreenRegisterConfigDataRoom, $dataRoomAxios } from '@gcpaas/data-room-ui'
bigScreenRegisterConfigDataRoom()
Vue.prototype.$dataRoomAxios = $dataRoomAxios

//仪表盘
import { registerConfig as registerConfigDashBoard, $dashboardAxios } from '@gcpaas/dash-board-ui'
registerConfigDashBoard()
Vue.prototype.$dashboardAxios = $dashboardAxios

Vue.config.productionTip = false;
Vue.use(VueQuillEditor);
Vue.use(ElementUI, { size: 'small' });
Vue.use(kayakPlugin);
Vue.use(router);
Vue.use(VueMaterial);
Vue.component('icon', Icon);
Vue.component('virtualTree', virtualTree);

Vue.prototype.global = global;
Vue.prototype.$Chartist = Chartist;
Vue.prototype.$echarts = echarts;
Vue.prototype.$Tools = Tools;
// Vue.prototype.$globalTheme = 'darkTheme';
Vue.prototype.$globalTheme = 'defalutTheme';

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.fullPath.indexOf('?') > -1) {
    next();
  } else {
    next();
  }
});

/**
* 设置主题
*/
Vue.prototype.setTheme = function () {

  let themeArr = themeInfoArr[0]?.theme;
  //第一次如果缓存中没有设置皮肤主题 则设置缓存
  let tmp = sessionStorage.getItem("theme_index");
  if (!tmp && typeof (tmp) != "undefined" && tmp != 0) {
    //设置缓存
    sessionStorage.setItem("theme_index", 0);
    for (let i = 0; i < themeArr.length; i++) {
      console.log("i========" + i);
      document.documentElement.style.setProperty(themeArr[i].attributeName, themeArr[i].value);
    }
  } else {
    console.log("theme==sessionStorage==" + sessionStorage.getItem("theme_index"));
    let type = sessionStorage.getItem("theme_index");

    themeArr = themeInfoArr[type]?.theme;

    for (let i = 0; i < themeArr.length; i++) {
      console.log("i========" + i);
      document.documentElement.style.setProperty(themeArr[i].attributeName, themeArr[i].value);
    }
  }

}

/* eslint-disable no-new */
// new Vue({
//   router,
//   store,
//   render: h => h(App),
//   beforeCreate() {
//     // 全局事件总线
//     Vue.prototype.$bus = this;
//   }
// }).$mount('#app');

// if (window.__POWERED_BY_WUJIE__) {
//   let instance;
//   window.__WUJIE_MOUNT = () => {
//     console.log('挂载market')
//     instance = new Vue({
//       router,
//       store,
//       render: h => h(App),
//       beforeCreate() {
//         // 全局事件总线
//         Vue.prototype.$bus = this;
//       }
//     }).$mount('#app');
//     document.documentElement.classList.add("isFromIframe")
//   };
//   window.__WUJIE_UNMOUNT = () => {
//     console.log('卸载market')
//     instance && instance.$destroy();
//   };
// } else {
  new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
      // 全局事件总线
      Vue.prototype.$bus = this;
    }
  }).$mount('#app');
// }
