import Cookies from 'js-cookie'
import Tools from "@/utils/tools";
import { broadcastToken } from '@/micro-app/utils';

const TokenKey = 'Authorization'

let auth = {};

var millisecond = new Date().getTime();
var expiresTime = new Date(millisecond + 60 * 1000 * 15);


auth.getToken = function() {
  return sessionStorage.getItem(TokenKey)
};

auth.setToken = function(token) {
  broadcastToken(token)
  return sessionStorage.setItem(TokenKey, token)
};

auth.removeToken = function() {
  Tools.loginUser = undefined
  return Cookies.remove(TokenKey)
};

auth.check = function(server) {
  let userRole = localStorage.getItem("userRole");

  if (userRole.split(',').includes('0')) { //超级管理员不控制权限
    return true;
  }

  let servers = localStorage.getItem("servers");
  return servers.indexOf(server) != -1;
}

auth.isSuperRole = function () {
  if (localStorage.getItem("userRole").split(',').includes('0')) {
    return true
  }
  return false
}

export default auth;
