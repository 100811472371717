<template>
  <span v-if="dataType == 'html'" v-html="displayValue" :style="{'color': dataColor}" />
  <span v-else-if="dataType == 'money'" :style="{'color': dataColor}" class="ds-span" >{{moneyToMicrometer(displayValue)}}</span>
  <span v-else :style="{'color': dataColor}" class="ds-span" >
    <!-- <i :class="dataIcon"></i> -->
    <span style="white-space: normal">{{displayValue}}</span>
  </span>
</template>

<script>
  import props from "@/components/k-element/common/k-field-props.js";
  import event from "@/components/k-element/common/k-field-event.js";
  import emitter from "@/components/k-element/common/k-emitter.js";
  import Tools from "@/utils/tools.js";

  export default {
    name: 'KFieldDisplay',
    mixins: [props(), event(), emitter()],
    props: {
      dataType: '',
      renderFunction: {
        type: Function
      },
      dataColor: {
        type: String,
        default: ''
      },
      dataRow: '',
      dataIcon: {
        type: String
      },
      dataLocalList:{  // 通过传入的数组去通过key值匹配得到value
        type: Array,
        default: function () {
          return []
        }
      },
      dataLocal:{ // 通过传入的key和value动态匹配dataLocalList的key和value
			  type: String,
		  }
    },
    computed:{
    },
    data() {
      return {
        displayValue: '',
        height:"32px"
      }
    },
    watch:{
      value(newVal,oldVal){
        this.init()
      }
    },
    methods:{
      moneyToMicrometer(s){
        if (!isNaN(s)) {
          s =s+""
          var l = s.split(".")[0].split("").reverse(), r = s.indexOf(".") >= 0 ? "."
            + s.split(".")[1]
            : "";
          let t = "";
          for (var i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
          }
          // 没有小数时，补充两个小数点
          r = r ? r : '.00'
          // 有小数但少一位，用0补充
          r.length === 2 ? r += '0' : ''
          return t.split("").reverse().join("") + r;

        } else {
          return s;
        }
      },
      init(){
        if (this.renderFunction) {
          this.displayValue = this.renderFunction(this.dataRow);
        } else if (this.dataDict) {
          this.displayValue = this.value;
          this.httpUtil.dictTransfer(this.dataDict, this.value).then(data => {
            this.displayValue = data;
          });
        } else if(this.dataAction){
          this.displayValue = this.value;
          this.httpUtil.comnQuery({
            action: this.dataAction,
          }).then(res => {
            res.rows.forEach(item => {
              if(item.itemkey === this.value){
                this.displayValue = item.itemval
              }
            })
          })
        }else if(this.dataLocal){
          let keyArr = this.dataLocal.split(",");
          this.displayValue= this.value
          this.dataLocalList.map(item=>{
            if(item[keyArr[0]]==this.value){
              this.displayValue= item[keyArr[1]]
            }
          })
        }else {
          if (this.dataType) {
            if (this.dataType === "date") {
              this.displayValue = Tools.formatDate(this.value);
            } else if (this.dataType === "time") {
              this.displayValue = Tools.formatTime(this.value);
            } else if (this.dataType === "money") {
              this.displayValue = Tools.formatMoney(this.value);
            } else {
              this.displayValue = this.value;
            }
          } else {
            this.displayValue = this.value;
          }
        }
      }
    },
    mounted() {
        this.init()
    }
  }
</script>

<style scoped>
  .ds-span {
    min-height: 32px;
    line-height: 31px;
  }
</style>
