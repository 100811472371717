var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-design-wrap",class:`db-time-count-down-${_vm.customTheme}`},[(_vm.isPast)?_c('span',{staticStyle:{"color":"#ea0b30"}},[_vm._v("(已过期)")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"time-text-box",class:[
      'time',
      {
        'light-theme': _vm.customTheme === 'light',
        'auto-theme': _vm.customTheme == 'auto',
        'dark-theme': _vm.customTheme == 'dark'
      }
    ],style:('font-size:' +
        _vm.config.customize.fontSize +
        'px;color:' +
        _vm.config.customize.color +
        ';font-weight:' +
        _vm.config.customize.fontWeight)},[_vm._v("\n    "+_vm._s(_vm.dateDiff)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }