import Vue from 'vue'
import Vuex from 'vuex'
import system from './modules/system.js'
import strategy from './modules/strategy.js'
import B503 from './modules/B503'
// import createPersistedState from "vuex-persistedstate"
import { $dataRoomStore } from '@gcpaas/data-room-ui'
import { $dashboardStore } from '@gcpaas/dash-board-ui'


Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    system,
    B503,
    strategy,
    // 注册大屏设计器的store
    bigScreen: $dataRoomStore,
    dashboard: $dashboardStore
  },
  // plugins: [createPersistedState(
  //   {
  //     storage: window.sessionStorage,
  //     paths: ['system']
  //   }
  // )]
})
