<template>
	<el-input
		ref="input"
		type="text"
		:style="itemStyle"
		class="k-field-text text-align-right"
		:placeholder="dataPlaceholder"
		:value="dataValue"
		:disabled="dataDisabled === true || dataDisabled === 'true'"
		:minlength="dataMinLength"
		:max-length="dataMaxLength"
		@input="_handleInput"
		@change="percentageHandleChange"
		@focus="_handleFocus"
		@blur="_handleBlur"
	>
		<div slot="append">%</div>
	</el-input>
</template>

<script>
import props from "@/components/k-element/common/k-field-props.js";
import event from "@/components/k-element/common/k-field-event.js";
import emitter from "@/components/k-element/common/k-emitter.js";
import Tools from "@/utils/tools.js";
import Decimal from "decimal.js";

const codeReg = /^[0-9]+$/;
const intReg = /^[+-]?(0|[1-9][0-9]*)$/;
const numberReg = /^-?([1-9]*\.?|[1-9]\d*|[1-9]\d*\.\d*|0\.\d*|0)$/;

export default {
	name: "KFieldPercent",
	mixins: [props(), event(), emitter()],
	props: {
		dataMaxLength: {
			type: Number,
			default: null,
		},
		dataMinLength: {
			type: Number,
			default: null,
		},
		dataRegx: {
			type: String,
			default: null,
		},
		dataRegxText: {
			type: String,
			default: null,
		},
		dataPlaceholder: {
			type: String,
			default: "",
		},
		dataMaxValue: {
			type: String,
		},
		dataMaxValueAlert: {
			type: String,
		},
		dataMinValue: {
			type: String,
		},
		dataMinValueAlert: {
			type: String,
		},
		// 指定多少位小数
		dataDigits: {
			type: [String, Number],
			default: null,
		},
		// 验证输入类型
		dataValidateType: {
			type: String,
			default: null,
		},
		dataIntegerLength: {
			type: [String, Number],
			default: null,
		},
		paddingRight: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			digitsRsg: null,
			isFocus: false,
			passwordLeft: false,
			dataValue: "",
			hiddenValue: "",
		};
	},
	computed: {
		itemStyle() {
			let ret = {};
			if (this.$parent.$options.name === "MdField") {
				if (this.paddingRight) {
					ret.paddingRight = this.paddingRight;
				} else if (this.dataShowPassword && this.passwordLeft) {
					ret.paddingRight = "60px";
				} else {
					ret.paddingRight = "40px";
				}
				return ret;
			}
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.dataValue = this.renderRateFormat(newVal);
				this.hiddenValue = newVal;
			}
		},
	},
	created() {
		const value = this.value;
		const arr = ["0", 0];
		if (arr.includes(value)) {
			this.dataValue = 0;
			this.hiddenValue = 0;
		}
		if (value) {
			this.dataValue = this.renderRateFormat(value);
			this.hiddenValue = value;
		}
		if (this.dataDigits) {
			this.digitsRsg = new RegExp("^-?([1-9]*\\.?|[1-9]\\d*|[1-9]\\d*\\.\\d{0," + this.dataDigits + "}|0\\.\\d{0," + this.dataDigits + "}|0)$");
		}
	},
	methods: {
		renderRateFormat(value) {
			if (value && !isNaN(Number(value))) {
				return Decimal(value).mul(Decimal(100)).toNumber();
			}
		},
		renderUnRateFormat(value) {
			if (value && !isNaN(Number(value))) {
				// 处理当为数字0时候form表单校验不通过问题
				let val = Decimal(value).div(Decimal(100)).toNumber();
				if (val === 0) return 0 + "";
				else return val;
			}
		},

		focus() {
			this.$refs.input.focus();
		},
		clear() {
			this.dataValue = "";
			this.handleInput("");
		},
		_handleFocus(value) {
			this.isFocus = true;
			this.handleFocus(value);
		},
		_handleBlur(value) {
			this.isFocus = false;
			if (this.dataValue) {
				let newVal = this.dataValue;
				if (newVal.length > 16) {
					newVal = this.replaceIfGreaterMaximum(newVal);
				} else {
					newVal = this.replaceIfGreaterMaximum(Number(newVal));
				}
				if (newVal != "" && undefined != newVal && newVal.length > 16) {
					newVal = this.replaceIfLessMinimum(newVal);
				} else {
					newVal = this.replaceIfLessMinimum(Number(newVal));
				}
				if (isNaN(newVal)) {
					newVal = "";
				}
				this.dataValue = newVal;
				this.handleInput(this.renderUnRateFormat(newVal));
			}
			this.handleBlur(value);
		},
		_handleInput(newVal) {
			newVal = newVal + "";
			if (this.dataMaxLength) {
				let length = Tools.strByteLength(newVal);
				if (length > this.dataMaxLength) {
					newVal = this.dataValue;
				}
			}
			// 验证输入类型
			if (newVal && this.dataValidateType === "int") {
				if (!intReg.test(newVal)) {
					newVal = this.dataValue;
				}
			}

			if (newVal && this.dataValidateType == "non-space") {
				// 去除空格
				newVal = newVal.trim();
			}

			if (!(intReg.test(newVal) || numberReg.test(newVal))) {
				newVal = this.dataValue;
			}

			if (this.dataDigits) {
				//小数位数校验
				if (newVal.split(".")[1] && newVal.split(".")[1].length > this.dataDigits) {
					newVal = this.dataValue;
				}
				// if (this.digitsRsg.test(newVal)) {
				//   newVal = this.dataValue
				// }
			}
			this.dataValue = newVal;
			this.handleInput(this.renderUnRateFormat(newVal));
		},
		replaceIfGreaterMaximum(value) {
			if (!this.value || !this.dataMaxValue) {
				return value;
			}
			if (this.dataMaxValue.endsWith(")")) {
				let _dataMaxValue = this.dataMaxValue.substring(0, this.dataMaxValue.length - 1);
				let max = Number(_dataMaxValue);
				if (value >= max) {
					let errMessage = this.dataMaxValueAlert || "不能超过最大值:" + _dataMaxValue;
					this.$message.warning(errMessage);
					return max;
				}
			} else {
				let max = Number(this.dataMaxValue);
				if (value > max) {
					let errMessage = this.dataMaxValueAlert || "不能超过最大值:" + this.dataMaxValue;
					this.$message.warning(errMessage);
					return max;
				}
			}
			return String(value);
		},
		replaceIfLessMinimum(value) {
			if (!value || !this.dataMinValue) {
				return value;
			}
			if (this.dataMinValue.startsWith("(")) {
				let _dataMinValue = this.dataMinValue.substring(1, this.dataMinValue.length);
				let min = _dataMinValue;
				if (value <= min) {
					let errMessage = this.dataMinValueAlert || "不能小于最小值:" + _dataMinValue;
					this.$message.warning(errMessage);
					return min;
				}
			} else {
				let min = this.dataMinValue;
				if (value < min) {
					let errMessage = this.dataMinValueAlert || "不能小于最小值:" + this.dataMinValue;
					this.$message.warning(errMessage);
					return min;
				}
			}
			return String(value);
		},
		removeEndPoint() {
			let dataValue = this.dataValue;
			if (dataValue) {
				dataValue = dataValue + "";
				if (dataValue.charAt(dataValue.length - 1) === ".") {
					this.handleInput(dataValue.substring(0, dataValue.length - 1));
				}
			}
		},
		removeMoneyEnd0(money) {
			if (money.indexOf(".") == -1) {
				return money;
			} else {
				if (money.lastIndexOf("0") != money.length - 1) {
					if (money.indexOf(".") == money.length - 1) {
						return money.substring(0, money.length - 1);
					} else {
						return money;
					}
				} else {
					return this.removeMoneyEnd0(money.substring(0, money.length - 1));
				}
			}
		},
		validate() {
			// 正则优先级高
			if (
				(this.dataAllowblank === false || this.dataAllowblank === "false") &&
				(this.value === "" || this.value === null || this.value === " " || this.value === undefined)
			) {
				return "该项不允许为空";
			}
			//调用自定义验证函数
			if (this.dataValidate) {
				return this.dataValidate(this.value);
			}

			if (this.dataMinLength && this.value && this.value.length > 0) {
				let length = this.value.length;
				if (length < this.dataMinLength) {
					return "至少输入" + this.dataMinLength + "位";
				}
			}
			if (this.value && this.dataRegx) {
				if (new RegExp(this.dataRegx).test(this.value)) {
					return true;
				}
				return this.dataRegxText;
			}

			let newVal = this.value;
			let errorMsg = "";
			const type = this.dataValidateType;
			if (!type) {
				return true;
			}
			if (!newVal) {
				return true;
			}
			// - code：限制只能输入数字文本，左对齐
			// - int： 验证整型数，右对齐
			// - number：验证包含小数点的数字，右对齐
			// - money：验证金额，右对齐
			// - text:允许输入中文，数字和英文字母，右对齐
			let valid = true;
			switch (type) {
				case "email":
					valid = emailReg.test(newVal);
					errorMsg = "请填写正确的邮箱地址";
					break;
				case "telephone":
					valid = telephoneReg.test(newVal);
					errorMsg = "请填写正确的手机号";
					break;
				case "telephoneline":
					valid = telephoneLineReg.test(newVal);
					errorMsg = "请填写正确的手机号或座机号";
					break;
				case "postcode":
					valid = postcodeReg.test(newVal);
					errorMsg = "请填写正确的邮政编码";
					break;
				case "code":
					valid = codeReg.test(newVal);
					errorMsg = "请输入数字文本";
					break;
				case "int":
					valid = intReg.test(newVal * 100);
					errorMsg = "请输入整数";
					break;
				case "number":
					valid = numberReg.test(newVal);
					errorMsg = "请输入数字";
					break;
				case "money":
					valid = numberReg.test(newVal);
					errorMsg = "请输入金额";
					break;
				case "codeletter":
					valid = codeLetterReg.test(newVal);
					errorMsg = "允许输入数字或字母";
				case "codeletterline":
					valid = codeLetterLineReg.test(newVal);
					errorMsg = "允许输入数字、字母或-(横线）";
				case "text":
					valid = textReg.test(newVal);
					errorMsg = "允许输入数字、字母或中文";
				default:
					// default text
					break;
			}
			return valid ? true : errorMsg;
		},
		percentageHandleChange(value) {
			this.hiddenValue = this.renderUnRateFormat(value);
			this.$nextTick(() => {
				this.$emit("data-on-change", this.hiddenValue);
			});
		},
	},
};
</script>
