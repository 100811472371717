export default [
	{
		path: 'ma/indicator',
		name: '指标数据',
		component: () => import('@/pages/ma/indicator/index.vue')
	},
	{
		path: 'ma/sms',
		name: 'MA-短信模板',
		component: () => import('@/pages/ma/sms/index.vue')
	},
	{
		path: 'ma/emailConfig',
		name: 'MA-邮件配置',
		component: () => import('@/pages/ma/emailConfig/index.vue')
	},
	{
		path: 'ma/emailTemplate',
		name: 'MA-邮件模板',
		component: () => import('@/pages/ma/emailTemplate/index.vue')
	},
	{
		path: 'ma/app',
		name: 'MA-APP消息模板',
		component: () => import('@/pages/ma/app/index.vue')
	},
	{
		path: 'ma/shortLink',
		name: 'MA-链接工具',
		component: () => import('@/pages/ma/shortLink/index.vue')
	},
	{
		path: 'ma/spam',
		name: 'MA-消息防骚扰',
		component: () => import('@/pages/ma/spam/index.vue')
	},
	{
		path: 'ma/wechat-channel',
		name: '公众号渠道管理',
		component: () => import('@/pages/ma/wechat/wechat-channel')
	},
	{
		path: 'ma/wechat-template',
		name: '模板消息(业务模板)',
		component: () => import('@/pages/ma/wechat/wechat-template')
	},
	{
		path: 'ma/wechat-draft',
		name: '公众号图文消息',
		component: () => import('@/pages/ma/wechat/wechat-draft')
	},
	{
		path: 'ma/wechat-subscribe-template',
		name: '订阅消息(订阅模板)',
		component: () => import('@/pages/ma/wechat/wechat-subscibe-template')
	},
	{
		path: 'ma/webhook-index',
		name: 'webhook管理',
		component: () => import('@/pages/ma/webhook/webhook-index')
	},
	{
		path: 'ma/xcx-channel',
		name: '小程序渠道管理',
		component: () => import('@/pages/ma/xcx/xcx-channel')
	},
	{
		path: 'ma/xcx-template',
		name: '小程序模板管理',
		component: () => import('@/pages/ma/xcx/xcx-template')
	},
  {
    path: 'ma/wework-channel',
    name: '企业微信管理',
    component: () => import('@/pages/ma/wework/wework-channel')
  },
  {
    path: 'ma/wework-template',
    name: '企业微信模板消息',
    component: () => import('@/pages/ma/wework/wework-template')
  },
	{
		path: 'ma/my-strategy',
		name: '我的策略',
		component: () => import('@/pages/ma/strategy/my-strategy')
	},
	{
		path: 'ma/stratrgy-release-detail',
		name: '策略详情',
		component: () => import('@/pages/flowable/busiComponents/stratrgyReleaseDetail')
	},
	{
		path: 'ma/target-index',
		name: '指标库',
		component: () => import('@/pages/ma/target/target-index')
	},
	{
		path: 'ma/target-property',
		name: '特征管理',
		component: () => import('@/pages/ma/target/target-property')
	},
	{
		path: 'ma/strategy-deploy',
		name: '策略部署',
		component: () => import('@/pages/ma/strategy/strategy-deploy')
	}
];
