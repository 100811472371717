export const login_user = {
	user_id: '2023072709541630581920',
	login_name: 'liuxiaofan',
	user_name: 'liuxiaofan',
	mobile_no: '13512121234',
	emails: '',
	id_type: '10',
	id_code: '429005199506181123',
	org_id: 'market_99999',
	org_no: '99999',
	parent_org_id: 'ROOT',
	parent_org_no: 'ROOT',
	org_name: '总行',
	dept_id: 'market_dept_99009',
	dept_no: '99009',
	dept_name: '总行业务发展规划部',
	org_level: '1',
	role_id: '2022111610343286528672',
	role_name: '操作员',
	role_type: '4',
	last_login_time: '20241009101627',
	pwd_reset_time: '34',
	office_no: '',
	is_first_login: '0',
	node_ids: [
		{
			node_id: '20230627104441609126976',
			node_route: '/',
			name: '20230627104441609126976',
			op_type: '1'
		},
		{
			node_id: '20230627104621404126976',
			node_route: '/org',
			name: '20230627104621404126976',
			op_type: '1'
		},
		{
			node_id: '20230627105030798126976',
			node_route: '/dept',
			name: '20230627105030798126976',
			op_type: '1'
		},
		{
			node_id: '20230627110548031126976',
			node_route: '/param',
			name: '20230627110548031126976',
			op_type: '1'
		},
		{
			node_id: '20230627110825702126976',
			node_route: '/dict',
			name: '20230627110825702126976',
			op_type: '1'
		},
		{
			node_id: '20230627111053362126976',
			node_route: '/fsdfs',
			name: '20230627111053362126976',
			op_type: '1'
		},
		{
			node_id: '20230627113114828126976',
			node_route: '/menu',
			name: '20230627113114828126976',
			op_type: '1'
		},
		{
			node_id: '20230627113250752126976',
			node_route: '',
			name: '20230627113250752126976',
			op_type: '1'
		},
		{
			node_id: '20230627113415135126976',
			node_route: '/user',
			name: '20230627113415135126976',
			op_type: '1'
		},
		{
			node_id: '2023062711510012849152',
			node_route: '/operatelog',
			name: '2023062711510012849152',
			op_type: '1'
		},
		{
			node_id: '2023062711533587049152',
			node_route: '/role',
			name: '2023062711533587049152',
			op_type: '1'
		},
		{
			node_id: '2023062711551225249152',
			node_route: '/review',
			name: '2023062711551225249152',
			op_type: '1'
		},
		{
			node_id: '2023062711581844449152',
			node_route: '',
			name: '2023062711581844449152',
			op_type: '1'
		},
		{
			node_id: '2023062711593065149152',
			node_route: '/taskList',
			name: '2023062711593065149152',
			op_type: '1'
		},
		{
			node_id: '2023062712042584649152',
			node_route: '/taskLog',
			name: '2023062712042584649152',
			op_type: '1'
		},
		{
			node_id: '2023062712052502349152',
			node_route: '/taskImp',
			name: '2023062712052502349152',
			op_type: '1'
		},
		{
			node_id: '20230628164007927106496',
			node_route: '/roleHandle/:id',
			name: '20230628164007927106496',
			op_type: '2'
		},
		{
			node_id: '202306291052082680',
			node_route: 'reffsfs',
			name: '202306291052082680',
			op_type: '3'
		},
		{
			node_id: '2023070310280487949152',
			node_route: '/reviewHandle/:id',
			name: '2023070310280487949152',
			op_type: '2'
		},
		{
			node_id: '2023070414340119036864',
			node_route: '/userHandle/:id',
			name: '2023070414340119036864',
			op_type: '2'
		},
		{
			node_id: '2023082914473545669632',
			node_route: '/home',
			name: '2023082914473545669632',
			op_type: '1'
		},
		{
			node_id: '2023083115003655912288',
			node_route: '',
			name: '2023083115003655912288',
			op_type: '2'
		},
		{
			node_id: '2023090410220264053248',
			node_route: '',
			name: '2023090410220264053248',
			op_type: '4'
		},
		{
			node_id: '2023090410535367653248',
			node_route: '',
			name: '2023090410535367653248',
			op_type: '2'
		},
		{
			node_id: '2023090410543357653248',
			node_route: '',
			name: '2023090410543357653248',
			op_type: '3'
		},
		{
			node_id: '2023090410550116353248',
			node_route: '',
			name: '2023090410550116353248',
			op_type: '4'
		},
		{
			node_id: '20230905100708170110592',
			node_route: '',
			name: '20230905100708170110592',
			op_type: '3'
		},
		{
			node_id: '20230905101020159110592',
			node_route: '',
			name: '20230905101020159110592',
			op_type: '2'
		},
		{
			node_id: '20230905101225807110592',
			node_route: '',
			name: '20230905101225807110592',
			op_type: '3'
		},
		{
			node_id: '20230905102011909110592',
			node_route: '',
			name: '20230905102011909110592',
			op_type: '2'
		},
		{
			node_id: '20230905102220470110592',
			node_route: '',
			name: '20230905102220470110592',
			op_type: '3'
		},
		{
			node_id: '20230905102631751110592',
			node_route: '/userHandle/:id',
			name: '20230905102631751110592',
			op_type: '3'
		},
		{
			node_id: '20230905102717318110592',
			node_route: '',
			name: '20230905102717318110592',
			op_type: '5'
		},
		{
			node_id: '20230905102844576110592',
			node_route: '',
			name: '20230905102844576110592',
			op_type: '4'
		},
		{
			node_id: '20230905103057838110592',
			node_route: '',
			name: '20230905103057838110592',
			op_type: '6'
		},
		{
			node_id: '20230905103227756110592',
			node_route: '/roleHandle/:id',
			name: '20230905103227756110592',
			op_type: '3'
		},
		{
			node_id: '20230905103304363110592',
			node_route: '',
			name: '20230905103304363110592',
			op_type: '4'
		},
		{
			node_id: '20230905103448872110592',
			node_route: '/reviewHandle/:id',
			name: '20230905103448872110592',
			op_type: '3'
		},
		{
			node_id: '20230905103516978110592',
			node_route: '',
			name: '20230905103516978110592',
			op_type: '4'
		},
		{
			node_id: '20230905103931200110592',
			node_route: '',
			name: '20230905103931200110592',
			op_type: '3'
		},
		{
			node_id: '20230905104036621110592',
			node_route: '',
			name: '20230905104036621110592',
			op_type: '5'
		},
		{
			node_id: '20230905104553995110592',
			node_route: '',
			name: '20230905104553995110592',
			op_type: '1'
		},
		{
			node_id: '20230907104903043110592',
			node_route: '/userHandle/:id',
			name: '20230907104903043110592',
			op_type: '1'
		},
		{
			node_id: '20230907105035555110592',
			node_route: '/roleHandle/:id',
			name: '20230907105035555110592',
			op_type: '1'
		},
		{
			node_id: '20230907105813687110592',
			node_route: '/reviewHandle/:id',
			name: '20230907105813687110592',
			op_type: '1'
		},
		{
			node_id: '20231214142349491188416',
			node_route: '/systemMessage',
			name: '20231214142349491188416',
			op_type: '1'
		},
		{
			node_id: '20231216111354730184320',
			node_route: '/newsList',
			name: '20231216111354730184320',
			op_type: '1'
		},
		{
			node_id: '2024041615414587912288',
			node_route: '/custGroup',
			name: '2024041615414587912288',
			op_type: '1'
		},
		{
			node_id: '2024041615430595312288',
			node_route: '/basicLabel',
			name: '2024041615430595312288',
			op_type: '1'
		},
		{
			node_id: '2024041615482143712288',
			node_route: '/metaLabel',
			name: '2024041615482143712288',
			op_type: '1'
		},
		{
			node_id: '2024041616022798612288',
			node_route: '/custGroupHandler/:id',
			name: '2024041616022798612288',
			op_type: '1'
		},
		{
			node_id: '20240419110230029180224',
			node_route: '/sms',
			name: '20240419110230029180224',
			op_type: '1'
		},
		{
			node_id: '20240419110419888180224',
			node_route: '/smsHandle/:id',
			name: '20240419110419888180224',
			op_type: '2'
		},
		{
			node_id: '20240419110755440180224',
			node_route: '/systemSms',
			name: '20240419110755440180224',
			op_type: '1'
		},
		{
			node_id: '20240419111105632180224',
			node_route: '/systemHandle/:id',
			name: '20240419111105632180224',
			op_type: '2'
		},
		{
			node_id: '20240419155947457180224',
			node_route: '/taskProcess',
			name: '20240419155947457180224',
			op_type: '1'
		},
		{
			node_id: '2024042418484918928672',
			node_route: '/subTasks',
			name: '2024042418484918928672',
			op_type: '1'
		},
		{
			node_id: '2024051114402935598304',
			node_route: '',
			name: '2024051114402935598304',
			op_type: '5'
		},
		{
			node_id: '2024051114520474498304',
			node_route: '',
			name: '2024051114520474498304',
			op_type: '2'
		},
		{
			node_id: '2024051114522381798304',
			node_route: '',
			name: '2024051114522381798304',
			op_type: '3'
		},
		{
			node_id: '2024051114533152198304',
			node_route: '',
			name: '2024051114533152198304',
			op_type: '5'
		},
		{
			node_id: '2024051114592962498304',
			node_route: '',
			name: '2024051114592962498304',
			op_type: '2'
		},
		{
			node_id: '2024051114594974798304',
			node_route: '',
			name: '2024051114594974798304',
			op_type: '3'
		},
		{
			node_id: '2024051115002648898304',
			node_route: '',
			name: '2024051115002648898304',
			op_type: '5'
		},
		{
			node_id: '2024051117153640598304',
			node_route: '',
			name: '2024051117153640598304',
			op_type: '2'
		}
	],
	dept_view_scope: '0'
};

export const head_jsession_id = 'cms_session:2024101015074209345056';

export const _Permissions = [
	{
		system_id: 'adm',
		node_id: '20230627104441609126976',
		node_route: '/',
		name: '系统管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'adm',
		node_id: '20230627104621404126976',
		node_route: '/org',
		name: '机构管理',
		op_type: '1',
		node_perms: 'system:org:list'
	},
	{
		system_id: 'adm',
		node_id: '20230627105030798126976',
		node_route: '/dept',
		name: '部门管理',
		op_type: '1',
		node_perms: 'system:dept:list'
	},
	{
		system_id: 'adm',
		node_id: '20230627110548031126976',
		node_route: '/param',
		name: '参数管理',
		op_type: '1',
		node_perms: 'system:param:list'
	},
	{
		system_id: 'adm',
		node_id: '20230627110825702126976',
		node_route: '/dict',
		name: '数据字典',
		op_type: '1',
		node_perms: 'system:dict:list'
	},
	{
		system_id: 'adm',
		node_id: '20230627111053362126976',
		node_route: '/fsdfs',
		name: '新增',
		op_type: '1',
		node_perms: 'system:org:add'
	},
	{
		system_id: 'adm',
		node_id: '20230627113114828126976',
		node_route: '/menu',
		name: '菜单管理',
		op_type: '1',
		node_perms: 'system:menu:list'
	},
	{
		system_id: 'adm',
		node_id: '20230627113250752126976',
		node_route: '',
		name: '权限管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'adm',
		node_id: '20230627113415135126976',
		node_route: '/user',
		name: '用户管理',
		op_type: '1',
		node_perms: 'permission:user:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062711510012849152',
		node_route: '/operatelog',
		name: '操作日志',
		op_type: '1',
		node_perms: 'system:operatelog:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062711533587049152',
		node_route: '/role',
		name: '角色管理',
		op_type: '1',
		node_perms: 'permission:role:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062711551225249152',
		node_route: '/review',
		name: '审批流管理',
		op_type: '1',
		node_perms: 'permission:review:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062711581844449152',
		node_route: '',
		name: '定时任务管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'adm',
		node_id: '2023062711593065149152',
		node_route: '/taskList',
		name: '任务列表',
		op_type: '1',
		node_perms: 'task:taskList:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062712042584649152',
		node_route: '/taskLog',
		name: '任务日志',
		op_type: '1',
		node_perms: 'task:taskLog:list'
	},
	{
		system_id: 'adm',
		node_id: '2023062712052502349152',
		node_route: '/taskImp',
		name: '任务执行情况',
		op_type: '1',
		node_perms: 'task:taskImp:list'
	},
	{
		system_id: 'adm',
		node_id: '20230628164007927106496',
		node_route: '/roleHandle/:id',
		name: '新增',
		op_type: '2',
		node_perms: 'permission:role:add'
	},
	{
		system_id: 'adm',
		node_id: '202306291052082680',
		node_route: 'reffsfs',
		name: '修改',
		op_type: '3',
		node_perms: 'system:org:update'
	},
	{
		system_id: 'adm',
		node_id: '2023070310280487949152',
		node_route: '/reviewHandle/:id',
		name: '新增',
		op_type: '2',
		node_perms: 'permission:review:add'
	},
	{
		system_id: 'adm',
		node_id: '2023070414340119036864',
		node_route: '/userHandle/:id',
		name: '新增',
		op_type: '2',
		node_perms: 'permission:user:add'
	},
	{
		system_id: 'adm',
		node_id: '2023082914473545669632',
		node_route: '/home',
		name: '首页工作台',
		op_type: '1',
		node_perms: 'base:home:list'
	},
	{
		system_id: 'adm',
		node_id: '2023083115003655912288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'system:org:add'
	},
	{
		system_id: 'adm',
		node_id: '2023090410220264053248',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'system:org:remove'
	},
	{
		system_id: 'adm',
		node_id: '2023090410535367653248',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'system:dept:add'
	},
	{
		system_id: 'adm',
		node_id: '2023090410543357653248',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'system:dept:update'
	},
	{
		system_id: 'adm',
		node_id: '2023090410550116353248',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'system:dept:remove'
	},
	{
		system_id: 'adm',
		node_id: '20230905100708170110592',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'system:param:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905101020159110592',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'system:dict:add'
	},
	{
		system_id: 'adm',
		node_id: '20230905101225807110592',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'system:dict:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905102011909110592',
		node_route: '',
		name: '新建',
		op_type: '2',
		node_perms: 'system:menu:add'
	},
	{
		system_id: 'adm',
		node_id: '20230905102220470110592',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'system:menu:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905102631751110592',
		node_route: '/userHandle/:id',
		name: '修改',
		op_type: '3',
		node_perms: 'permission:user:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905102717318110592',
		node_route: '',
		name: '冻结/解冻',
		op_type: '5',
		node_perms: 'permission:user:stop'
	},
	{
		system_id: 'adm',
		node_id: '20230905102844576110592',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'permission:user:remove'
	},
	{
		system_id: 'adm',
		node_id: '20230905103057838110592',
		node_route: '',
		name: '重置密码',
		op_type: '6',
		node_perms: 'permission:user:reset'
	},
	{
		system_id: 'adm',
		node_id: '20230905103227756110592',
		node_route: '/roleHandle/:id',
		name: '修改',
		op_type: '3',
		node_perms: 'permission:role:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905103304363110592',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'permission:role:remove'
	},
	{
		system_id: 'adm',
		node_id: '20230905103448872110592',
		node_route: '/reviewHandle/:id',
		name: '修改',
		op_type: '3',
		node_perms: 'permission:review:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905103516978110592',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'permission:review:remove'
	},
	{
		system_id: 'adm',
		node_id: '20230905103931200110592',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'task:taskList:update'
	},
	{
		system_id: 'adm',
		node_id: '20230905104036621110592',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'task:taskList:stop'
	},
	{
		system_id: 'adm',
		node_id: '20230905104553995110592',
		node_route: '',
		name: '执行',
		op_type: '1',
		node_perms: 'task:taskList:runOnce'
	},
	{
		system_id: 'adm',
		node_id: '20230907104903043110592',
		node_route: '/userHandle/:id',
		name: '查看',
		op_type: '1',
		node_perms: 'permission:user:list'
	},
	{
		system_id: 'adm',
		node_id: '20230907105035555110592',
		node_route: '/roleHandle/:id',
		name: '查看',
		op_type: '1',
		node_perms: 'permission:role:list'
	},
	{
		system_id: 'adm',
		node_id: '20230907105813687110592',
		node_route: '/reviewHandle/:id',
		name: '查看',
		op_type: '1',
		node_perms: 'permission:review:list'
	},
	{
		system_id: 'adm',
		node_id: '20231214142349491188416',
		node_route: '/systemMessage',
		name: '系统消息',
		op_type: '1',
		node_perms: 'system:message:list'
	},
	{
		system_id: 'adm',
		node_id: '20231216111354730184320',
		node_route: '/newsList',
		name: '短信消息',
		op_type: '1',
		node_perms: 'base:system:newslist:list'
	},
	{
		system_id: 'market',
		node_id: '2024041610071111628672',
		node_route: '',
		name: '资源管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041610272056686016',
		node_route: '',
		name: '首页管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041610384816586016',
		node_route: '/guestManage',
		name: '首页配置',
		op_type: '1',
		node_perms: 'homePageManage:guestManage:list'
	},
	{
		system_id: 'market',
		node_id: '202404161047350190',
		node_route: '/homeModal',
		name: '首页弹框设置',
		op_type: '1',
		node_perms: 'homePageManage:homeModal:list'
	},
	{
		system_id: 'market',
		node_id: '202404161054266120',
		node_route: '/homeModal/:pageType',
		name: '首页弹框',
		op_type: '2',
		node_perms: 'homePageManage:homeModal:detail'
	},
	{
		system_id: 'market',
		node_id: '202404161056085130',
		node_route: '/recommendProduct',
		name: '推荐产品设置',
		op_type: '1',
		node_perms: 'homePageManage:recommendProduct:list'
	},
	{
		system_id: 'market',
		node_id: '202404161057282750',
		node_route: '/recommendProduct/:pageType',
		name: '推荐产品',
		op_type: '2',
		node_perms: 'homePageManage:recommendProduct:detail'
	},
	{
		system_id: 'market',
		node_id: '2024041615382219612288',
		node_route: '',
		name: '客户管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'adm',
		node_id: '2024041615414587912288',
		node_route: '/custGroup',
		name: '客群列表',
		op_type: '1',
		node_perms: 'custManage:custGroup:list'
	},
	{
		system_id: 'adm',
		node_id: '2024041615430595312288',
		node_route: '/basicLabel',
		name: '客群标签管理',
		op_type: '1',
		node_perms: 'custManage:basicLabel:list'
	},
	{
		system_id: 'adm',
		node_id: '2024041615482143712288',
		node_route: '/metaLabel',
		name: '元标签管理',
		op_type: '1',
		node_perms: 'custGroup:metaLabel:list'
	},
	{
		system_id: 'market',
		node_id: '2024041615502949612288',
		node_route: '',
		name: '营销管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'adm',
		node_id: '2024041616022798612288',
		node_route: '/custGroupHandler/:id',
		name: '客群',
		op_type: '1',
		node_perms: 'custManage:custGroup:handler'
	},
	{
		system_id: 'market',
		node_id: '2024041616035002812288',
		node_route: '/actBudget',
		name: '营销账户管理',
		op_type: '1',
		node_perms: 'marketing:actBudget:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616051048812288',
		node_route: '/actBudgetHandler/:id',
		name: '营销账户',
		op_type: '1',
		node_perms: 'marketing:actBudget:handler'
	},
	{
		system_id: 'market',
		node_id: '2024041616063723612288',
		node_route: '',
		name: '营销日历',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616083103812288',
		node_route: '/financialCalendar',
		name: '金融日历',
		op_type: '1',
		node_perms: 'marketing:financial:view'
	},
	{
		system_id: 'market',
		node_id: '2024041616100954512288',
		node_route: '/financialCalendar/list',
		name: '金融日历列表',
		op_type: '1',
		node_perms: 'marketing:financial:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616112078012288',
		node_route: '/activitiesCalendar',
		name: '活动日历',
		op_type: '1',
		node_perms: 'marketing:activities:view'
	},
	{
		system_id: 'market',
		node_id: '2024041616121206812288',
		node_route: '',
		name: '活动管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616132824612288',
		node_route: '/myActivity',
		name: '活动列表',
		op_type: '1',
		node_perms: 'marketing:activity:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616151196412288',
		node_route: '/activityCreate',
		name: '活动新增',
		op_type: '2',
		node_perms: 'marketing:activity:create'
	},
	{
		system_id: 'market',
		node_id: '2024041616185140712288',
		node_route: '/activityHandle/add/:act_module_sub',
		name: '活动内容',
		op_type: '2',
		node_perms: 'marketing:activity:add'
	},
	{
		system_id: 'market',
		node_id: '2024041616204944112288',
		node_route: '/activityHandle/:pageType/:act_module_sub/:act_id',
		name: '活动内容',
		op_type: '1',
		node_perms: 'marketing:activity:details'
	},
	{
		system_id: 'market',
		node_id: '2024041616214892712288',
		node_route: '/verifierManage',
		name: '沙龙核验员',
		op_type: '1',
		node_perms: 'marketing:salon:verifier'
	},
	{
		system_id: 'market',
		node_id: '2024041616225033212288',
		node_route: '',
		name: '商户营销',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616242901612288',
		node_route: '/merchant/classify',
		name: '商户分类',
		op_type: '1',
		node_perms: 'marketing:merchant:classify'
	},
	{
		system_id: 'market',
		node_id: '2024041616251672412288',
		node_route: '/merchant/list',
		name: '商户列表',
		op_type: '1',
		node_perms: 'marketing:merchant:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616261371812288',
		node_route: '/merchant/act',
		name: '商户活动',
		op_type: '1',
		node_perms: 'marketing:merchant:activity'
	},
	{
		system_id: 'market',
		node_id: '2024041616274717112288',
		node_route: '/merchant/v/:id',
		name: '详情',
		op_type: '1',
		node_perms: 'marketing:merchant:details'
	},
	{
		system_id: 'market',
		node_id: '2024041616285277312288',
		node_route: '/merchant/order',
		name: '商户订单',
		op_type: '1',
		node_perms: 'merchant:order:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616294539612288',
		node_route: '/merchant/order/details/:id',
		name: '订单详情',
		op_type: '1',
		node_perms: 'merchant:order:details'
	},
	{
		system_id: 'market',
		node_id: '2024041616311280512288',
		node_route: '',
		name: '合伙人营销',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616314536412288',
		node_route: '',
		name: '合伙人信息管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616323581212288',
		node_route: '/partnerSerOrg',
		name: '合伙人服务机构',
		op_type: '1',
		node_perms: 'partner:org:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616333156512288',
		node_route: '/partnerSerOrg/:id',
		name: '合伙人机构',
		op_type: '1',
		node_perms: 'partner:org:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616350508312288',
		node_route: '/partnerTemp',
		name: '合伙人招募模版',
		op_type: '1',
		node_perms: 'partner:temp:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616364202412288',
		node_route: '/partnerTemp/:type',
		name: '招募模版',
		op_type: '1',
		node_perms: 'partner:temp:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616374055612288',
		node_route: '/approval',
		name: '合伙人审批',
		op_type: '1',
		node_perms: 'partner:approval:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616393492512288',
		node_route: '/partner',
		name: '合伙人列表',
		op_type: '1',
		node_perms: 'partner:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616404269712288',
		node_route: '/partnerHandle/:id',
		name: '合伙人详情',
		op_type: '1',
		node_perms: 'partner:details'
	},
	{
		system_id: 'market',
		node_id: '2024041616424911212288',
		node_route: '/incomeDetail',
		name: '收益详情',
		op_type: '1',
		node_perms: 'partner:income'
	},
	{
		system_id: 'market',
		node_id: '2024041616433665412288',
		node_route: '/approvalHandle/:id',
		name: '详情',
		op_type: '1',
		node_perms: 'partner:approval:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616444583112288',
		node_route: '/partnerWhiteList',
		name: '合伙人白名单',
		op_type: '1',
		node_perms: 'partner:white:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616454204812288',
		node_route: '',
		name: '推广管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041616491841512288',
		node_route: '/productPartnerTemp',
		name: '推广模板管理',
		op_type: '1',
		node_perms: 'promotion:temp:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616501737112288',
		node_route: '/productPartnerTemp/:type',
		name: '推广模版',
		op_type: '1',
		node_perms: 'promotion:temp:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616511070812288',
		node_route: '/promotionProduct',
		name: '推广产品管理',
		op_type: '1',
		node_perms: 'promotion:product:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616521044912288',
		node_route: '/promotionProductHandle/:pageType',
		name: '推广产品详情',
		op_type: '1',
		node_perms: 'promotion:product:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616540843612288',
		node_route: '/partnerPageManage',
		name: '合伙人H5首页配置',
		op_type: '1',
		node_perms: 'partner:h5:page'
	},
	{
		system_id: 'market',
		node_id: '2024041616545870112288',
		node_route: '/clien',
		name: '客户信息管理',
		op_type: '1',
		node_perms: 'partner:client:list'
	},
	{
		system_id: 'market',
		node_id: '2024041616560826912288',
		node_route: '/clienHandle',
		name: '客户详情',
		op_type: '1',
		node_perms: 'partner:client:handle'
	},
	{
		system_id: 'market',
		node_id: '2024041616570777612288',
		node_route: '/clienBusinessDetails',
		name: '业务详情',
		op_type: '1',
		node_perms: 'partner:client:details'
	},
	{
		system_id: 'market',
		node_id: '2024041617050661412288',
		node_route: '',
		name: '营销数据',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617053704612288',
		node_route: '',
		name: '活动效果监控',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617060316412288',
		node_route: '',
		name: '报表分析',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617073071312288',
		node_route: '/dataCockpit',
		name: '活动效果概览',
		op_type: '1',
		node_perms: 'market:data:cockpit'
	},
	{
		system_id: 'market',
		node_id: '2024041617082228012288',
		node_route: '/marketingList',
		name: '活动运营效果',
		op_type: '1',
		node_perms: 'marketing:data:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617092317012288',
		node_route: '/marketingData/:actId',
		name: '活动运营效果详情',
		op_type: '1',
		node_perms: 'marketing:data:details'
	},
	{
		system_id: 'market',
		node_id: '2024041617102537012288',
		node_route: '/activityView',
		name: '活动生命周期',
		op_type: '1',
		node_perms: 'marketing:view:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617114657712288',
		node_route: '/lifeCycle/:act_id',
		name: '生命周期',
		op_type: '1',
		node_perms: 'marketing:view:lifeCycle'
	},
	{
		system_id: 'market',
		node_id: '2024041617124251212288',
		node_route: '',
		name: '活动效果',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617131246912288',
		node_route: '',
		name: '活动成本',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617133081812288',
		node_route: '',
		name: '活动业绩',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617135177712288',
		node_route: '',
		name: '活动成效',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617145058112288',
		node_route: '/cpd',
		name: '客户参与明细',
		op_type: '1',
		node_perms: 'report:cpd:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617162943712288',
		node_route: '/ccs',
		name: '客户达标统计',
		op_type: '1',
		node_perms: 'report:ccs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617163271412288',
		node_route: '',
		name: '供应商管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617180647812288',
		node_route: '/ccd',
		name: '客户达标明细',
		op_type: '1',
		node_perms: 'report:ccd:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617191136812288',
		node_route: '/cwd',
		name: '客户中奖明细',
		op_type: '1',
		node_perms: 'report:cwd:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617200639512288',
		node_route: '/crd',
		name: '客户权益明细',
		op_type: '1',
		node_perms: 'report:crd:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617210072212288',
		node_route: '/ocs',
		name: '机构成本统计',
		op_type: '1',
		node_perms: 'report:ocs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617213941912288',
		node_route: '/acs',
		name: '活动成本统计',
		op_type: '1',
		node_perms: 'report:acs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617222398812288',
		node_route: '/mcs',
		name: '商户成本统计',
		op_type: '1',
		node_perms: 'report:mcs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617225889012288',
		node_route: '/scs',
		name: '供应商成本统计',
		op_type: '1',
		node_perms: 'report:scs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617241548312288',
		node_route: '/rcs',
		name: '权益成本统计',
		op_type: '1',
		node_perms: 'report:rcs:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617250107912288',
		node_route: '/aps',
		name: '活动业绩统计',
		op_type: '1',
		node_perms: 'report:aps:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617252544112288',
		node_route: '/supplier',
		name: '供应商列表',
		op_type: '1',
		node_perms: 'supplierManager:supplier:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617254946912288',
		node_route: '/ops',
		name: '机构业绩统计',
		op_type: '1',
		node_perms: 'report:ops:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617272111412288',
		node_route: '/cps',
		name: '渠道业绩统计',
		op_type: '1',
		node_perms: 'report:cps:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617280965812288',
		node_route: '/pps',
		name: '合伙人业绩统计',
		op_type: '1',
		node_perms: 'report:pps:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617284860112288',
		node_route: '/plps',
		name: '平台业绩统计',
		op_type: '1',
		node_perms: 'report:plps:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617285939912288',
		node_route: '/supplier/add',
		name: '新增',
		op_type: '2',
		node_perms: 'supplierManager:supplier:add'
	},
	{
		system_id: 'market',
		node_id: '2024041617293536512288',
		node_route: '/ica',
		name: '活动投入成本分析',
		op_type: '1',
		node_perms: 'report:ica:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617304798712288',
		node_route: '/supplier/:pageType',
		name: '修改',
		op_type: '3',
		node_perms: 'supplierManager:supplier:update'
	},
	{
		system_id: 'market',
		node_id: '2024041617350292312288',
		node_route: '',
		name: '权益管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '2024041617362543112288',
		node_route: '/rights',
		name: '权益列表',
		op_type: '1',
		node_perms: 'rightsManager:rights:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617382354712288',
		node_route: '/rights/:pageType',
		name: '权益',
		op_type: '1',
		node_perms: 'rightsManager:rights:detail'
	},
	{
		system_id: 'market',
		node_id: '2024041617392635312288',
		node_route: '/payout',
		name: '实物权益派发明细',
		op_type: '1',
		node_perms: 'market:payout:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617404129612288',
		node_route: '/distribution',
		name: '权益手工派发',
		op_type: '1',
		node_perms: 'rights:distribution:list'
	},
	{
		system_id: 'market',
		node_id: '2024041617414684212288',
		node_route: '/distribution/details',
		name: '权益手工派发明细',
		op_type: '1',
		node_perms: 'rights:distributionDetails:list'
	},
	{
		system_id: 'market',
		node_id: '20240419103554125180224',
		node_route: '/channel',
		name: '渠道管理',
		op_type: '1',
		node_perms: 'system:channel:list'
	},
	{
		system_id: 'market',
		node_id: '20240419105148864180224',
		node_route: '',
		name: '消息管理',
		op_type: '1',
		node_perms: ''
	},
	{
		system_id: 'market',
		node_id: '20240419105638586180224',
		node_route: '/pushMsg',
		name: '消息推送管理',
		op_type: '1',
		node_perms: 'message:push:list'
	},
	{
		system_id: 'market',
		node_id: '20240419110031232180224',
		node_route: '/pushHandle/:id',
		name: '消息推送',
		op_type: '2',
		node_perms: 'message:push:detail'
	},
	{
		system_id: 'adm',
		node_id: '20240419110230029180224',
		node_route: '/sms',
		name: '短信消息模板管理',
		op_type: '1',
		node_perms: 'message:sms:list'
	},
	{
		system_id: 'adm',
		node_id: '20240419110419888180224',
		node_route: '/smsHandle/:id',
		name: '短信',
		op_type: '2',
		node_perms: 'message:sms:detail'
	},
	{
		system_id: 'adm',
		node_id: '20240419110755440180224',
		node_route: '/systemSms',
		name: '系统消息模板管理',
		op_type: '1',
		node_perms: 'message:systemSms:list'
	},
	{
		system_id: 'adm',
		node_id: '20240419111105632180224',
		node_route: '/systemHandle/:id',
		name: '消息详情',
		op_type: '2',
		node_perms: 'message:systemSms:detail'
	},
	{
		system_id: 'market',
		node_id: '20240419113138734180224',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'actBudget_modify'
	},
	{
		system_id: 'market',
		node_id: '20240419113205012180224',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'actBudget_switch'
	},
	{
		system_id: 'market',
		node_id: '20240419114404780180224',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'actBudget_add'
	},
	{
		system_id: 'market',
		node_id: '20240419114852915180224',
		node_route: '/activityHandle/:pageType/:act_module_sub',
		name: '修改',
		op_type: '3',
		node_perms: 'activity_modify'
	},
	{
		system_id: 'market',
		node_id: '20240419115000874180224',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'activity_switch'
	},
	{
		system_id: 'market',
		node_id: '20240419115108936180224',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'activity_delete'
	},
	{
		system_id: 'market',
		node_id: '20240419115206981180224',
		node_route: '',
		name: '终止',
		op_type: '5',
		node_perms: 'activity_stop'
	},
	{
		system_id: 'market',
		node_id: '20240419142640658180224',
		node_route: '/materialList',
		name: '素材管理',
		op_type: '1',
		node_perms: 'resource:material:list'
	},
	{
		system_id: 'market',
		node_id: '20240419142818063180224',
		node_route: '/productManage',
		name: '产品同步',
		op_type: '1',
		node_perms: 'product:sync:list'
	},
	{
		system_id: 'market',
		node_id: '20240419143334930180224',
		node_route: '/addMaterial',
		name: '新增',
		op_type: '2',
		node_perms: 'resource:material:add'
	},
	{
		system_id: 'market',
		node_id: '20240419143443246180224',
		node_route: '/detailMaterial',
		name: '素材详情',
		op_type: '1',
		node_perms: 'resource:material:detail'
	},
	{
		system_id: 'adm',
		node_id: '20240419155947457180224',
		node_route: '/taskProcess',
		name: '流程配置管理',
		op_type: '1',
		node_perms: 'task:taskLog:process'
	},
	{
		system_id: 'market',
		node_id: '2024042418004231932768',
		node_route: '/markDataBatchExeService',
		name: '数据批次执行详情',
		op_type: '1',
		node_perms: 'market:data:batch'
	},
	{
		system_id: 'market',
		node_id: '2024042418024577732768',
		node_route: '/markDataBatchPrizeService',
		name: '权益派发详情',
		op_type: '1',
		node_perms: 'market:prize:dispense'
	},
	{
		system_id: 'adm',
		node_id: '2024042418484918928672',
		node_route: '/subTasks',
		name: '子任务',
		op_type: '1',
		node_perms: 'task:sub:list'
	},
	{
		system_id: 'market',
		node_id: '2024051015550385694208',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'merchant_add'
	},
	{
		system_id: 'market',
		node_id: '2024051015572186994208',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'merchant_modify'
	},
	{
		system_id: 'market',
		node_id: '2024051015581077994208',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'merchant_delete'
	},
	{
		system_id: 'market',
		node_id: '20240510160239881126976',
		node_route: '/merchant/activityHandle/add/:act_module_sub',
		name: '新增',
		op_type: '2',
		node_perms: 'merchant_act_add'
	},
	{
		system_id: 'market',
		node_id: '20240510160332284126976',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'merchant_switch'
	},
	{
		system_id: 'market',
		node_id: '20240510160947525126976',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'merchant_act_modify'
	},
	{
		system_id: 'market',
		node_id: '20240510161043137126976',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'merchant_act_delete'
	},
	{
		system_id: 'market',
		node_id: '20240510162714131126976',
		node_route: '/merchant/activityHandle/:pageType/:act_module_sub/:act_id',
		name: '详情',
		op_type: '1',
		node_perms: 'merchant_act_detail'
	},
	{
		system_id: 'market',
		node_id: '20240510164131385126976',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'merchant_act_switch'
	},
	{
		system_id: 'market',
		node_id: '20240510164154891126976',
		node_route: '',
		name: '终止',
		op_type: '5',
		node_perms: 'merchant_act_stop'
	},
	{
		system_id: 'market',
		node_id: '20240510171214037126976',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'classify_add'
	},
	{
		system_id: 'market',
		node_id: '20240510171300887126976',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'classify_modify'
	},
	{
		system_id: 'market',
		node_id: '20240510171416640126976',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'classify_delete'
	},
	{
		system_id: 'adm',
		node_id: '2024051114402935598304',
		node_route: '',
		name: '执行',
		op_type: '5',
		node_perms: 'task:process:run'
	},
	{
		system_id: 'market',
		node_id: '2024051114434317598304',
		node_route: '',
		name: '新建',
		op_type: '2',
		node_perms: 'message:push:add'
	},
	{
		system_id: 'market',
		node_id: '2024051114441089798304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'message:push:update'
	},
	{
		system_id: 'market',
		node_id: '2024051114455529998304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'message:push:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051114473109198304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'message:push:remove'
	},
	{
		system_id: 'adm',
		node_id: '2024051114520474498304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'message:sms:add'
	},
	{
		system_id: 'adm',
		node_id: '2024051114522381798304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'message:sms:update'
	},
	{
		system_id: 'adm',
		node_id: '2024051114533152198304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'message:sms:switch'
	},
	{
		system_id: 'adm',
		node_id: '2024051114592962498304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'message:systemSms:add'
	},
	{
		system_id: 'adm',
		node_id: '2024051114594974798304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'message:systemSms:update'
	},
	{
		system_id: 'adm',
		node_id: '2024051115002648898304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'message:systemSms:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051115562573898304',
		node_route: '',
		name: '更新',
		op_type: '3',
		node_perms: 'homePageManage:guestManage:update'
	},
	{
		system_id: 'market',
		node_id: '2024051115595865098304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'homePageManage:homeModal:add'
	},
	{
		system_id: 'market',
		node_id: '2024051116010038898304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'homePageManage:homeModal:modify'
	},
	{
		system_id: 'market',
		node_id: '2024051116034287398304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'homePageManage:homeModal:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051116120521198304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'homePageManage:recommendProduct:add'
	},
	{
		system_id: 'market',
		node_id: '2024051116124556798304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'homePageManage:recommendProduct:update'
	},
	{
		system_id: 'market',
		node_id: '2024051116131544698304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'homePageManage:recommendProduct:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051116293534598304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'supplierManager:supplier:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051116302730698304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'supplierManager:supplier:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051116342309598304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'rightsManager:rights:add'
	},
	{
		system_id: 'market',
		node_id: '2024051116351851198304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'rightsManager:rights:update'
	},
	{
		system_id: 'market',
		node_id: '2024051116354788198304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'rightsManager:rights:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051116410433698304',
		node_route: '',
		name: '填写运单',
		op_type: '3',
		node_perms: 'market:payout:update'
	},
	{
		system_id: 'adm',
		node_id: '2024051117153640598304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'custManage:custGroup:add'
	},
	{
		system_id: 'market',
		node_id: '2024051117180752298304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'custManage:custGroup:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051117195486298304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'custManage:custGroup:modify'
	},
	{
		system_id: 'market',
		node_id: '2024051117205369398304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'custManage:custGroup:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051117251943698304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'custGroup:metaLabel:add'
	},
	{
		system_id: 'market',
		node_id: '2024051117254404398304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'custGroup:metaLabel:update'
	},
	{
		system_id: 'market',
		node_id: '2024051117320967298304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'custGroup:metaLabel:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051117404057798304',
		node_route: '',
		name: '添加提醒事项',
		op_type: '2',
		node_perms: 'marketing:financial:addTodo'
	},
	{
		system_id: 'market',
		node_id: '2024051117443691298304',
		node_route: '',
		name: '删除提醒事项',
		op_type: '4',
		node_perms: 'marketing:financial:rmTodo'
	},
	{
		system_id: 'market',
		node_id: '2024051117443705898304',
		node_route: '',
		name: '修改提醒事项',
		op_type: '4',
		node_perms: 'marketing:financial:updateTodo'
	},
	{
		system_id: 'market',
		node_id: '2024051117533819398304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'verifier_add'
	},
	{
		system_id: 'market',
		node_id: '2024051117541226998304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'verifier_modify'
	},
	{
		system_id: 'market',
		node_id: '2024051117543588498304',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'verifier_delete'
	},
	{
		system_id: 'market',
		node_id: '2024051117582388298304',
		node_route: '',
		name: '发货',
		op_type: '2',
		node_perms: 'merchant:order:fh'
	},
	{
		system_id: 'market',
		node_id: '2024051118024813298304',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'partner:org:add'
	},
	{
		system_id: 'market',
		node_id: '2024051118031476298304',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'partner:org:update'
	},
	{
		system_id: 'market',
		node_id: '2024051118035270698304',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'partner:org:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051311173896912288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'partner:temp:add'
	},
	{
		system_id: 'market',
		node_id: '2024051311175809012288',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'partner:temp:update'
	},
	{
		system_id: 'market',
		node_id: '2024051311260346212288',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'partner:temp:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051311295002512288',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'partner:approval:export'
	},
	{
		system_id: 'market',
		node_id: '2024051311371306712288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'partner:add'
	},
	{
		system_id: 'market',
		node_id: '2024051311373797912288',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'partner:update'
	},
	{
		system_id: 'market',
		node_id: '2024051311414570512288',
		node_route: '',
		name: '冻结或解冻',
		op_type: '5',
		node_perms: 'partner:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051311431117112288',
		node_route: '',
		name: '解除关系',
		op_type: '1',
		node_perms: 'partner:unbind'
	},
	{
		system_id: 'market',
		node_id: '2024051311442650512288',
		node_route: '',
		name: '修改失效时间',
		op_type: '3',
		node_perms: 'partner:update:failureime'
	},
	{
		system_id: 'market',
		node_id: '2024051311455926712288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'partner:white:add'
	},
	{
		system_id: 'market',
		node_id: '2024051311462565912288',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'partner:white:update'
	},
	{
		system_id: 'market',
		node_id: '2024051311515998112288',
		node_route: '',
		name: '移除',
		op_type: '4',
		node_perms: 'partner:white:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051311522574812288',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'partner:white:export'
	},
	{
		system_id: 'market',
		node_id: '2024051311572028412288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'promotion:temp:add'
	},
	{
		system_id: 'market',
		node_id: '2024051311573879412288',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'promotion:temp:update'
	},
	{
		system_id: 'market',
		node_id: '2024051311580201512288',
		node_route: '',
		name: '启停',
		op_type: '5',
		node_perms: 'promotion:temp:switch'
	},
	{
		system_id: 'market',
		node_id: '2024051312012605712288',
		node_route: '',
		name: '新增',
		op_type: '2',
		node_perms: 'promotion:product:add'
	},
	{
		system_id: 'market',
		node_id: '2024051312014766812288',
		node_route: '',
		name: '修改',
		op_type: '3',
		node_perms: 'promotion:product:update'
	},
	{
		system_id: 'market',
		node_id: '2024051312020950712288',
		node_route: '',
		name: '删除',
		op_type: '4',
		node_perms: 'promotion:product:remove'
	},
	{
		system_id: 'market',
		node_id: '2024051314324996294208',
		node_route: '',
		name: '编辑',
		op_type: '3',
		node_perms: 'partner:h5:update'
	},
	{
		system_id: 'market',
		node_id: '2024051314584182794208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'partner:client:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315243669794208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:cpd:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315263467894208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:ccs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315295105094208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:cwd:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315324686094208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:crd:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315352244194208',
		node_route: '',
		name: '核销',
		op_type: '2',
		node_perms: 'report:crd:verification'
	},
	{
		system_id: 'market',
		node_id: '2024051315413932094208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:ocs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315423948994208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:acs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315440629494208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:mcs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315455578094208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:scs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315464927594208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:rcs:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315514045494208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:aps:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315530702994208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:ops:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315550366694208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:cps:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315561781894208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:pps:export'
	},
	{
		system_id: 'market',
		node_id: '2024051315582631794208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:plps:export'
	},
	{
		system_id: 'market',
		node_id: '2024051316005827194208',
		node_route: '',
		name: '导出',
		op_type: '1',
		node_perms: 'report:ica:export'
	},
	{
		system_id: 'market',
		node_id: '2024051516123243553248',
		node_route: '',
		name: '保存',
		op_type: '2',
		node_perms: 'promotion:product:save'
	},
	{
		system_id: 'market',
		node_id: '2024051519273161881920',
		node_route: '/activityCreate',
		name: '活动保存',
		op_type: '2',
		node_perms: 'marketing:activity:save'
	},
	{
		system_id: 'market',
		node_id: '2024051618441712790112',
		node_route: '/commonService',
		name: '任务执行详情',
		op_type: '1',
		node_perms: 'market:data:common'
	}
];

export const dynamicRoutes = [
	{
		systemId: 'adm',
		name: '首页工作台',
		path: '/container/home',
		hidden: false,
		component: 'Container',
		meta: {
			title: '首页工作台',
			icon: '工作台',
			noCache: false,
			systemId: 'adm',
			affix: true,
			activePath: '/home'
		},
		children: [
			{
				systemId: 'adm',
				name: '首页工作台',
				path: '/home',
				hidden: true,
				component: 'home/views/index',
				meta: {
					title: '首页工作台',
					icon: '工作台',
					noCache: false,
					systemId: 'adm',
					affix: true,
					hideBreadcrumb: true,
					activePath: '/container/home'
				}
			},
			{
				systemId: 'adm',
				name: '首页工作台',
				path: '/home',
				hidden: true,
				component: 'ParentView',
				meta: {
					title: '首页工作台',
					icon: '工作台',
					noCache: false,
					systemId: 'adm',
					affix: true,
					hideBreadcrumb: true,
					activePath: '/container/home'
				},
				children: [
					{
						systemId: 'adm',
						name: '系统消息',
						path: '/systemMessage',
						hidden: true,
						component: 'systemMessage/views/index',
						meta: {
							title: '系统消息',
							noCache: false,
							systemId: 'adm',
							activePath: '/container/home'
						}
					}
				]
			}
		],
		redirect: {
			path: '/home',
			redirectExt: 'Container'
		},
		title: 'Container'
	},
	{
		systemId: 'adm',
		name: '系统管理',
		path: '/',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '系统管理',
			icon: '系统管理',
			noCache: false,
			systemId: 'adm',
			activePath: '/'
		},
		children: [
			{
				systemId: 'adm',
				name: '机构管理',
				path: '/org',
				hidden: false,
				component: 'system/views/org',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '机构管理',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/org'
				}
			},
			{
				systemId: 'adm',
				name: '部门管理',
				path: '/dept',
				hidden: false,
				component: 'system/views/dept',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '部门管理',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/dept'
				}
			},
			{
				systemId: 'market',
				name: '渠道管理',
				path: '/channel',
				hidden: false,
				component: 'system/channel',
				meta: {
					title: '渠道管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: '/channel'
				}
			},
			{
				systemId: 'adm',
				name: '参数管理',
				path: '/param',
				hidden: false,
				component: 'system/views/param',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '参数管理',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/param'
				}
			},
			{
				systemId: 'adm',
				name: '数据字典',
				path: '/dict',
				hidden: false,
				component: 'system/views/dict',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '数据字典',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/dict'
				}
			},
			{
				systemId: 'adm',
				name: '菜单管理',
				path: '/menu',
				hidden: false,
				component: 'system/views/menu/index',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '菜单管理',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/menu'
				}
			},
			{
				systemId: 'adm',
				name: '操作日志',
				path: '/operatelog',
				hidden: false,
				component: 'system/views/operatelog',
				meta: {
					title: '操作日志',
					icon: '#',
					noCache: false,
					systemId: 'adm',
					activePath: '/operatelog'
				}
			},
			{
				systemId: 'adm',
				name: '权限管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '权限管理',
					icon: '',
					noCache: false,
					systemId: 'adm',
					activePath: ''
				},
				children: [
					{
						systemId: 'adm',
						name: '用户管理',
						path: '/user',
						hidden: false,
						component: 'permission/views/user/user',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '用户管理',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/user'
						}
					},
					{
						systemId: 'adm',
						name: '用户管理',
						path: '/user',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '用户管理',
							icon: '#',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '查看',
								path: '/userHandle/:id',
								hidden: true,
								component: 'permission/views/user/userHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '查看',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/user'
								}
							},
							{
								systemId: 'adm',
								name: '新增',
								path: '/userHandle/:id',
								hidden: true,
								component: 'permission/views/user/userHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '新增',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/user'
								}
							},
							{
								systemId: 'adm',
								name: '修改',
								path: '/userHandle/:id',
								hidden: true,
								component: 'permission/views/user/userHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '修改',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/user'
								}
							}
						]
					},
					{
						systemId: 'adm',
						name: '角色管理',
						path: '/role',
						hidden: false,
						component: 'permission/views/role/role',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '角色管理',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/role'
						}
					},
					{
						systemId: 'adm',
						name: '角色管理',
						path: '/role',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '角色管理',
							icon: '#',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '查看',
								path: '/roleHandle/:id',
								hidden: true,
								component: 'permission/views/role/roleHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '查看',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/role'
								}
							},
							{
								systemId: 'adm',
								name: '新增',
								path: '/roleHandle/:id',
								hidden: true,
								component: 'permission/views/role/roleHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '新增',
									icon: '#',
									noCache: false,
									systemId: 'adm',
									activePath: '/role'
								}
							},
							{
								systemId: 'adm',
								name: '修改',
								path: '/roleHandle/:id',
								hidden: true,
								component: 'permission/views/role/roleHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '修改',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/role'
								}
							}
						]
					},
					{
						systemId: 'adm',
						name: '审批流管理',
						path: '/review',
						hidden: false,
						component: 'permission/views/review/index',
            type: '1', // 1 调用getBaseView()
						meta: {
							title: '审批流管理',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/review'
						}
					},
					{
						systemId: 'adm',
						name: '审批流管理',
						path: '/review',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '审批流管理',
							icon: '#',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '查看',
								path: '/reviewHandle/:id',
								hidden: true,
								component: 'permission/views/review/reviewHandle',
								meta: {
									title: '查看',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/review'
								}
							},
							{
								systemId: 'adm',
								name: '新增',
								path: '/reviewHandle/:id',
								hidden: true,
								component: 'permission/views/review/reviewHandle',
								meta: {
									title: '新增',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/review'
								}
							},
							{
								systemId: 'adm',
								name: '修改',
								path: '/reviewHandle/:id',
								hidden: true,
								component: 'permission/views/review/reviewHandle',
								meta: {
									title: '修改',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/review'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'adm',
				name: '定时任务管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '定时任务管理',
					icon: '',
					noCache: false,
					systemId: 'adm',
					activePath: ''
				},
				children: [
					{
						systemId: 'adm',
						name: '任务列表',
						path: '/taskList',
						hidden: false,
						component: 'task/views/taskList',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '任务列表',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/taskList'
						}
					},
					{
						systemId: 'adm',
						name: '任务列表',
						path: '/taskList',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '任务列表',
							icon: '#',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '子任务',
								path: '/subTasks',
								hidden: true,
								component: 'task/views/subTasks',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '子任务',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/taskList'
								}
							}
						]
					},
					{
						systemId: 'adm',
						name: '任务日志',
						path: '/taskLog',
						hidden: false,
						component: 'task/views/taskLog',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '任务日志',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/taskLog'
						}
					},
					{
						systemId: 'adm',
						name: '任务执行情况',
						path: '/taskImp',
						hidden: false,
						component: 'task/views/taskImp',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '任务执行情况',
							icon: '#',
							noCache: false,
							systemId: 'adm',
							activePath: '/taskImp'
						}
					},
					{
						systemId: 'adm',
						name: '流程配置管理',
						path: '/taskProcess',
						hidden: false,
						component: 'task/views/taskProcess',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '流程配置管理',
							icon: '',
							noCache: false,
							systemId: 'adm',
							activePath: '/taskProcess'
						}
					},
					{
						systemId: 'adm',
						name: '流程配置管理',
						path: '/taskProcess',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '流程配置管理',
							icon: '',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'market',
								name: '数据批次执行详情',
								path: '/markDataBatchExeService',
								hidden: true,
								component: 'task/process/markDataBatchExeService',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '数据批次执行详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/taskProcess'
								}
							},
							{
								systemId: 'market',
								name: '权益派发详情',
								path: '/markDataBatchPrizeService',
								hidden: true,
								component: 'task/process/markDataBatchPrizeService',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '权益派发详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/taskProcess'
								}
							},
							{
								systemId: 'market',
								name: '任务执行详情',
								path: '/commonService',
								hidden: true,
								component: 'task/process/commonService',
								meta: {
									title: '任务执行详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/taskProcess'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '消息管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '消息管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '消息推送管理',
						path: '/pushMsg',
						hidden: false,
						component: 'platform/pushMessage/pushMessage',
						meta: {
							title: '消息推送管理',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/pushMsg'
						}
					},
					{
						systemId: 'market',
						name: '消息推送管理',
						path: '/pushMsg',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '消息推送管理',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '消息推送',
								path: '/pushHandle/:id',
								hidden: true,
								component: 'platform/pushMessage/pushHandle',
								meta: {
									title: '消息推送',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/pushMsg'
								}
							}
						]
					},
					{
						systemId: 'adm',
						name: '短信消息模板管理',
						path: '/sms',
						hidden: false,
						component: 'system/views/sms/sms',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '短信消息模板管理',
							icon: '',
							noCache: false,
							systemId: 'adm',
							activePath: '/sms'
						}
					},
					{
						systemId: 'adm',
						name: '短信消息模板管理',
						path: '/sms',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '短信消息模板管理',
							icon: '',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '短信',
								path: '/smsHandle/:id',
								hidden: true,
								component: 'system/views/sms/smsHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '短信',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/sms'
								}
							}
						]
					},
					{
						systemId: 'adm',
						name: '系统消息模板管理',
						path: '/systemSms',
						hidden: false,
						component: 'system/views/systemSms/systemSms',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '系统消息模板管理',
							icon: '',
							noCache: false,
							systemId: 'adm',
							activePath: '/systemSms'
						}
					},
					{
						systemId: 'adm',
						name: '系统消息模板管理',
						path: '/systemSms',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '系统消息模板管理',
							icon: '',
							noCache: false,
							systemId: 'adm'
						},
						children: [
							{
								systemId: 'adm',
								name: '消息详情',
								path: '/systemHandle/:id',
								hidden: true,
								component: 'system/views/systemSms/systemHandle',
								type: '1', // 1 调用getBaseView()
								meta: {
									title: '消息详情',
									icon: '',
									noCache: false,
									systemId: 'adm',
									activePath: '/systemSms'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '消息管理',
				hidden: true,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '消息管理',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'adm',
						name: '短信消息',
						path: '/newsList',
						hidden: true,
						component: 'system/views/newList',
						meta: {
							title: '短信消息',
							icon: '',
							noCache: false,
							systemId: 'adm',
							activePath: ''
						}
					}
				]
			}
		]
	},
	{
		systemId: 'market',
		name: '资源管理',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '资源管理',
			icon: 'search',
			noCache: false,
			systemId: 'market',
			activePath: ''
		},
		children: [
			{
				systemId: 'market',
				name: '素材管理',
				path: '/materialList',
				hidden: false,
				component: 'resource/materialList/materialListNew',
				meta: {
					title: '素材管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: '/materialList'
				}
			},
			{
				systemId: 'market',
				name: '素材管理',
				path: '/materialList',
				hidden: true,
				component: 'ParentView',
				meta: {
					title: '素材管理',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'market',
						name: '新增',
						path: '/addMaterial',
						hidden: true,
						component: 'resource/materialList/addMaterialNew',
						meta: {
							title: '新增',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/materialList'
						}
					},
					{
						systemId: 'market',
						name: '素材详情',
						path: '/detailMaterial',
						hidden: true,
						component: 'resource/materialList/detailMaterialNew',
						meta: {
							title: '素材详情',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/materialList'
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '产品同步',
				path: '/productManage',
				hidden: false,
				component: 'resource/product/product',
				meta: {
					title: '产品同步',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: '/productManage'
				}
			},
			{
				systemId: 'market',
				name: '首页管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '首页管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '首页配置',
						path: '/guestManage',
						hidden: false,
						component: 'market/guestManage',
						meta: {
							title: '首页配置',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/guestManage'
						}
					},
					{
						systemId: 'market',
						name: '首页弹框设置',
						path: '/homeModal',
						hidden: false,
						component: 'market/homeModal/homeModal',
						meta: {
							title: '首页弹框设置',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/homeModal'
						}
					},
					{
						systemId: 'market',
						name: '首页弹框设置',
						path: '/homeModal',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '首页弹框设置',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '首页弹框',
								path: '/homeModal/:pageType',
								hidden: true,
								component: 'market/homeModal/homeModalHandle',
								meta: {
									title: '首页弹框',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/homeModal'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '推荐产品设置',
						path: '/recommendProduct',
						hidden: false,
						component: 'market/recommendProduct/recommendProduct',
						meta: {
							title: '推荐产品设置',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/recommendProduct'
						}
					},
					{
						systemId: 'market',
						name: '推荐产品设置',
						path: '/recommendProduct',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '推荐产品设置',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '推荐产品',
								path: '/recommendProduct/:pageType',
								hidden: true,
								component: 'market/recommendProduct/recommendProductHandle',
								meta: {
									title: '推荐产品',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/recommendProduct'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '供应商管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '供应商管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '供应商列表',
						path: '/supplier',
						hidden: false,
						component: 'resource/supplier/index',
						meta: {
							title: '供应商列表',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/supplier'
						}
					},
					{
						systemId: 'market',
						name: '供应商列表',
						path: '/supplier',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '供应商列表',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '新增',
								path: '/supplier/add',
								hidden: true,
								component: 'resource/supplier/handlePage',
								meta: {
									title: '新增',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/supplier'
								}
							},
							{
								systemId: 'market',
								name: '修改',
								path: '/supplier/:pageType',
								hidden: true,
								component: 'resource/supplier/handlePage',
								meta: {
									title: '修改',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/supplier'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '权益管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '权益管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '权益列表',
						path: '/rights',
						hidden: false,
						component: 'resource/rights/Rights',
						meta: {
							title: '权益列表',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/rights'
						}
					},
					{
						systemId: 'market',
						name: '权益列表',
						path: '/rights',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '权益列表',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '权益',
								path: '/rights/:pageType',
								hidden: true,
								component: 'resource/rights/RightsHandle',
								meta: {
									title: '权益',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/rights'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '实物权益派发明细',
						path: '/payout',
						hidden: false,
						component: 'market/payout',
						meta: {
							title: '实物权益派发明细',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/payout'
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '权益管理',
				hidden: true,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '权益管理',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'market',
						name: '权益手工派发',
						path: '/distribution',
						hidden: true,
						component: 'resource/rights/distribution/index',
						meta: {
							title: '权益手工派发',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						}
					},
					{
						systemId: 'market',
						name: '权益手工派发明细',
						path: '/distribution/details',
						hidden: true,
						component: 'resource/rights/distribution/details/index',
						meta: {
							title: '权益手工派发明细',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						}
					}
				]
			}
		]
	},
	{
		systemId: 'market',
		name: '客户管理',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '客户管理',
			icon: 'people',
			noCache: false,
			systemId: 'market',
			activePath: ''
		},
		children: [
			{
				systemId: 'adm',
				name: '客群列表',
				path: '/custGroup',
				hidden: false,
				component: 'custGroup/views/custGroupList/custGroupList',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '客群列表',
					icon: '',
					noCache: false,
					systemId: 'adm',
					activePath: '/custGroup'
				}
			},
			{
				systemId: 'adm',
				name: '客群列表',
				path: '/custGroup',
				hidden: true,
				component: 'ParentView',
				meta: {
					title: '客群列表',
					icon: '',
					noCache: false,
					systemId: 'adm'
				},
				children: [
					{
						systemId: 'adm',
						name: '客群',
						path: '/custGroupHandler/:id',
						hidden: true,
						component: 'custGroup/views/custGroupList/custGroupListHandler',
						type: '1', // 1 调用getBaseView()
						meta: {
							title: '客群',
							icon: '',
							noCache: false,
							systemId: 'adm',
							activePath: '/custGroup'
						}
					}
				]
			},
			{
				systemId: 'adm',
				name: '客群标签管理',
				path: '/basicLabel',
				hidden: false,
				component: 'custGroup/views/basicLabel',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '客群标签管理',
					icon: '',
					noCache: false,
					systemId: 'adm',
					activePath: '/basicLabel'
				}
			},
			{
				systemId: 'adm',
				name: '元标签管理',
				path: '/metaLabel',
				hidden: false,
				component: 'custGroup/views/metaLabel/metaLabel',
				type: '1', // 1 调用getBaseView()
				meta: {
					title: '元标签管理',
					icon: '',
					noCache: false,
					systemId: 'adm',
					activePath: '/metaLabel'
				}
			}
		]
	},
	{
		systemId: 'market',
		name: '营销管理',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '营销管理',
			icon: 'dashboard',
			noCache: false,
			systemId: 'market',
			activePath: ''
		},
		children: [
			{
				systemId: 'market',
				name: '营销账户管理',
				path: '/actBudget',
				hidden: false,
				component: 'market/actBudget/actBudget',
				meta: {
					title: '营销账户管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: '/actBudget'
				}
			},
			{
				systemId: 'market',
				name: '营销账户管理',
				path: '/actBudget',
				hidden: true,
				component: 'ParentView',
				meta: {
					title: '营销账户管理',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'market',
						name: '营销账户',
						path: '/actBudgetHandler/:id',
						hidden: true,
						component: 'market/actBudget/actBudgetHandler',
						meta: {
							title: '营销账户',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/actBudget'
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '营销日历',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '营销日历',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '金融日历',
						path: '/financialCalendar',
						hidden: false,
						component: 'financialCalendar/index',
						meta: {
							title: '金融日历',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/financialCalendar'
						}
					},
					{
						systemId: 'market',
						name: '金融日历',
						path: '/financialCalendar',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '金融日历',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '金融日历列表',
								path: '/financialCalendar/list',
								hidden: true,
								component: 'financialCalendar/list',
								meta: {
									title: '金融日历列表',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/financialCalendar'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动日历',
						path: '/activitiesCalendar',
						hidden: false,
						component: 'activitiesCalendar/index',
						meta: {
							title: '活动日历',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/activitiesCalendar'
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '活动管理',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '活动管理',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '活动列表',
						path: '/myActivity',
						hidden: false,
						component: 'market/activity/myActivity/myActivity',
						meta: {
							title: '活动列表',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/myActivity'
						}
					},
					{
						systemId: 'market',
						name: '活动列表',
						path: '/myActivity',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '活动列表',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '修改',
								path: '/activityHandle/:pageType/:act_module_sub',
								hidden: true,
								component: 'market/activity/activitylist/index',
								meta: {
									title: '修改',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/myActivity'
								}
							},
							{
								systemId: 'market',
								name: '活动内容',
								path: '/activityHandle/:pageType/:act_module_sub/:act_id',
								hidden: true,
								component: 'market/activity/activitylist/index',
								meta: {
									title: '活动内容',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/myActivity'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动新增',
						path: '/activityCreate',
						hidden: false,
						component: 'market/activity/activityCreate/activityCreate',
						meta: {
							title: '活动新增',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/activityCreate'
						}
					},
					{
						systemId: 'market',
						name: '活动新增',
						path: '/activityCreate',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '活动新增',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '活动内容',
								path: '/activityHandle/add/:act_module_sub',
								hidden: true,
								component: 'market/activity/activitylist/index',
								meta: {
									title: '活动内容',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/activityCreate'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '沙龙核验员',
						path: '/verifierManage',
						hidden: false,
						component: 'market/activity/verifierManage/verifierManage',
						meta: {
							title: '沙龙核验员',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/verifierManage'
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '活动管理',
				hidden: true,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '活动管理',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'market',
						name: '活动保存',
						path: '/activityCreate',
						hidden: true,
						component: 'market/activity/activityCreate/activityCreate',
						meta: {
							title: '活动保存',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						}
					}
				]
			},
			{
				systemId: 'market',
				name: '商户营销',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '商户营销',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '商户分类',
						path: '/merchant/classify',
						hidden: false,
						component: 'merchant/classify/index',
						meta: {
							title: '商户分类',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/merchant/classify'
						}
					},
					{
						systemId: 'market',
						name: '商户列表',
						path: '/merchant/list',
						hidden: false,
						component: 'merchant/list/index',
						meta: {
							title: '商户列表',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/merchant/list'
						}
					},
					{
						systemId: 'market',
						name: '商户列表',
						path: '/merchant/list',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '商户列表',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '详情',
								path: '/merchant/v/:id',
								hidden: true,
								component: 'merchant/list/handle/index',
								meta: {
									title: '详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/merchant/list'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '商户活动',
						path: '/merchant/act',
						hidden: false,
						component: 'merchant/activity/index',
						meta: {
							title: '商户活动',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/merchant/act'
						}
					},
					{
						systemId: 'market',
						name: '商户活动',
						path: '/merchant/act',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '商户活动',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '详情',
								path: '/merchant/activityHandle/:pageType/:act_module_sub/:act_id',
								hidden: true,
								component: 'market/activity/activitylist/index',
								meta: {
									title: '详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/merchant/act'
								}
							},
							{
								systemId: 'market',
								name: '新增',
								path: '/merchant/activityHandle/add/:act_module_sub',
								hidden: true,
								component: 'market/activity/activitylist/index',
								meta: {
									title: '新增',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/merchant/act'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '商户订单',
						path: '/merchant/order',
						hidden: false,
						component: 'merchant/orderList/index',
						meta: {
							title: '商户订单',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/merchant/order'
						}
					},
					{
						systemId: 'market',
						name: '商户订单',
						path: '/merchant/order',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '商户订单',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '订单详情',
								path: '/merchant/order/details/:id',
								hidden: true,
								component: 'merchant/orderList/details/index',
								meta: {
									title: '订单详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/merchant/order'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '合伙人营销',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '合伙人营销',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '合伙人信息管理',
						hidden: false,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '合伙人信息管理',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '合伙人服务机构',
								path: '/partnerSerOrg',
								hidden: false,
								component: 'partnerManage/partnerOrg/partnerOrg',
								meta: {
									title: '合伙人服务机构',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/partnerSerOrg'
								}
							},
							{
								systemId: 'market',
								name: '合伙人服务机构',
								path: '/partnerSerOrg',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '合伙人服务机构',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '合伙人机构',
										path: '/partnerSerOrg/:id',
										hidden: true,
										component: 'partnerManage/partnerOrg/partnerOrgHandle',
										meta: {
											title: '合伙人机构',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/partnerSerOrg'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '合伙人招募模版',
								path: '/partnerTemp',
								hidden: false,
								component: 'partnerManage/partnerTemp/partnerTemp',
								meta: {
									title: '合伙人招募模版',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/partnerTemp'
								}
							},
							{
								systemId: 'market',
								name: '合伙人招募模版',
								path: '/partnerTemp',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '合伙人招募模版',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '招募模版',
										path: '/partnerTemp/:type',
										hidden: true,
										component: 'partnerManage/partnerTemp/partnerTempHandle',
										meta: {
											title: '招募模版',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/partnerTemp'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '合伙人审批',
								path: '/approval',
								hidden: false,
								component: 'partnerManage/partnerMaintenance/approval/approval',
								meta: {
									title: '合伙人审批',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/approval'
								}
							},
							{
								systemId: 'market',
								name: '合伙人审批',
								path: '/approval',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '合伙人审批',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '详情',
										path: '/approvalHandle/:id',
										hidden: true,
										component: 'partnerManage/partnerMaintenance/approval/approvalHandle',
										meta: {
											title: '详情',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/approval'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '合伙人列表',
								path: '/partner',
								hidden: false,
								component: 'partnerManage/partnerMaintenance/partner/partner',
								meta: {
									title: '合伙人列表',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/partner'
								}
							},
							{
								systemId: 'market',
								name: '合伙人列表',
								path: '/partner',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '合伙人列表',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '合伙人详情',
										path: '/partnerHandle/:id',
										hidden: true,
										component: 'partnerManage/partnerMaintenance/partner/partnerHandle',
										meta: {
											title: '合伙人详情',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/partner'
										}
									},
									{
										systemId: 'market',
										name: '收益详情',
										path: '/incomeDetail',
										hidden: true,
										component: 'partnerManage/partnerMaintenance/partner/incomeDetail',
										meta: {
											title: '收益详情',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/partner'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '合伙人白名单',
								path: '/partnerWhiteList',
								hidden: false,
								component: 'partnerManage/partnerMaintenance/whiteList/whiteList',
								meta: {
									title: '合伙人白名单',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/partnerWhiteList'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '推广管理',
						hidden: false,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '推广管理',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '推广模板管理',
								path: '/productPartnerTemp',
								hidden: false,
								component: 'partnerManage/productPartnerTemp/partnerTemp',
								meta: {
									title: '推广模板管理',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/productPartnerTemp'
								}
							},
							{
								systemId: 'market',
								name: '推广模板管理',
								path: '/productPartnerTemp',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '推广模板管理',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '推广模版',
										path: '/productPartnerTemp/:type',
										hidden: true,
										component: 'partnerManage/productPartnerTemp/partnerTempHandle',
										meta: {
											title: '推广模版',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/productPartnerTemp'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '推广产品管理',
								path: '/promotionProduct',
								hidden: false,
								component: 'partnerManage/promotionProduct/promotionProduct',
								meta: {
									title: '推广产品管理',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/promotionProduct'
								}
							},
							{
								systemId: 'market',
								name: '推广产品管理',
								path: '/promotionProduct',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '推广产品管理',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '推广产品详情',
										path: '/promotionProductHandle/:pageType',
										hidden: true,
										component: 'partnerManage/promotionProduct/promotionProductHandle',
										meta: {
											title: '推广产品详情',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/promotionProduct'
										}
									}
								]
							}
						]
					},
					{
						systemId: 'market',
						name: '合伙人H5首页配置',
						path: '/partnerPageManage',
						hidden: false,
						component: 'partnerManage/partnerPageManage/partnerPageManage',
						meta: {
							title: '合伙人H5首页配置',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/partnerPageManage'
						}
					},
					{
						systemId: 'market',
						name: '客户信息管理',
						path: '/clien',
						hidden: false,
						component: 'partnerManage/clientManage/clien',
						meta: {
							title: '客户信息管理',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/clien'
						}
					},
					{
						systemId: 'market',
						name: '客户信息管理',
						path: '/clien',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '客户信息管理',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '客户详情',
								path: '/clienHandle',
								hidden: true,
								component: 'partnerManage/clientManage/clienHandle',
								meta: {
									title: '客户详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/clien'
								}
							},
							{
								systemId: 'market',
								name: '业务详情',
								path: '/clienBusinessDetails',
								hidden: true,
								component: 'partnerManage/clientManage/clienBusinessDetails',
								meta: {
									title: '业务详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/clien'
								}
							}
						]
					}
				]
			}
		]
	},
	{
		systemId: 'market',
		name: '营销数据',
		hidden: false,
		redirect: 'noRedirect',
		component: 'Layout',
		alwaysShow: true,
		meta: {
			title: '营销数据',
			icon: 'chart',
			noCache: false,
			systemId: 'market',
			activePath: ''
		},
		children: [
			{
				systemId: 'market',
				name: '活动效果监控',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '活动效果监控',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '活动效果概览',
						path: '/dataCockpit',
						hidden: false,
						component: 'dataCockpit/index',
						meta: {
							title: '活动效果概览',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/dataCockpit'
						}
					},
					{
						systemId: 'market',
						name: '活动运营效果',
						path: '/marketingList',
						hidden: false,
						component: 'marketingData/list',
						meta: {
							title: '活动运营效果',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/marketingList'
						}
					},
					{
						systemId: 'market',
						name: '活动运营效果',
						path: '/marketingList',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '活动运营效果',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '活动运营效果详情',
								path: '/marketingData/:actId',
								hidden: true,
								component: 'marketingData/activityData',
								meta: {
									title: '活动运营效果详情',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/marketingList'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动生命周期',
						path: '/activityView',
						hidden: false,
						component: 'market/activity/activityView/activityView',
						meta: {
							title: '活动生命周期',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: '/activityView'
						}
					},
					{
						systemId: 'market',
						name: '活动生命周期',
						path: '/activityView',
						hidden: true,
						component: 'ParentView',
						meta: {
							title: '活动生命周期',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '生命周期',
								path: '/lifeCycle/:act_id',
								hidden: true,
								component: 'market/activity/activityView/lifeCycle/index',
								meta: {
									title: '生命周期',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/activityView'
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '报表分析',
				hidden: false,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '报表分析',
					icon: '',
					noCache: false,
					systemId: 'market',
					activePath: ''
				},
				children: [
					{
						systemId: 'market',
						name: '活动效果',
						hidden: false,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动效果',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '客户参与明细',
								path: '/cpd',
								hidden: false,
								component: 'report/actEffect/cpd',
								meta: {
									title: '客户参与明细',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/cpd'
								}
							},
							{
								systemId: 'market',
								name: '客户达标统计',
								path: '/ccs',
								hidden: false,
								component: 'report/actEffect/ccs',
								meta: {
									title: '客户达标统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/ccs'
								}
							},
							{
								systemId: 'market',
								name: '客户达标统计',
								path: '/ccs',
								hidden: true,
								component: 'ParentView',
								meta: {
									title: '客户达标统计',
									icon: '',
									noCache: false,
									systemId: 'market'
								},
								children: [
									{
										systemId: 'market',
										name: '客户达标明细',
										path: '/ccd',
										hidden: true,
										component: 'report/actEffect/ccd',
										meta: {
											title: '客户达标明细',
											icon: '',
											noCache: false,
											systemId: 'market',
											activePath: '/ccs'
										}
									}
								]
							},
							{
								systemId: 'market',
								name: '客户中奖明细',
								path: '/cwd',
								hidden: false,
								component: 'report/actEffect/cwd',
								meta: {
									title: '客户中奖明细',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/cwd'
								}
							},
							{
								systemId: 'market',
								name: '客户权益明细',
								path: '/crd',
								hidden: false,
								component: 'report/actEffect/crd',
								meta: {
									title: '客户权益明细',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/crd'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动成本',
						hidden: false,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动成本',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '机构成本统计',
								path: '/ocs',
								hidden: false,
								component: 'report/actCost/ocs',
								meta: {
									title: '机构成本统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/ocs'
								}
							},
							{
								systemId: 'market',
								name: '活动成本统计',
								path: '/acs',
								hidden: false,
								component: 'report/actCost/acs',
								meta: {
									title: '活动成本统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/acs'
								}
							},
							{
								systemId: 'market',
								name: '商户成本统计',
								path: '/mcs',
								hidden: false,
								component: 'report/actCost/mcs',
								meta: {
									title: '商户成本统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/mcs'
								}
							},
							{
								systemId: 'market',
								name: '权益成本统计',
								path: '/rcs',
								hidden: false,
								component: 'report/actCost/rcs',
								meta: {
									title: '权益成本统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/rcs'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动成本',
						hidden: true,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动成本',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '供应商成本统计',
								path: '/scs',
								hidden: true,
								component: 'report/actCost/scs',
								meta: {
									title: '供应商成本统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: ''
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动业绩',
						hidden: false,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动业绩',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '活动业绩统计',
								path: '/aps',
								hidden: false,
								component: 'report/actPerformance/aps',
								meta: {
									title: '活动业绩统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/aps'
								}
							},
							{
								systemId: 'market',
								name: '机构业绩统计',
								path: '/ops',
								hidden: false,
								component: 'report/actPerformance/ops',
								meta: {
									title: '机构业绩统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/ops'
								}
							},
							{
								systemId: 'market',
								name: '渠道业绩统计',
								path: '/cps',
								hidden: false,
								component: 'report/actPerformance/cps',
								meta: {
									title: '渠道业绩统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: '/cps'
								}
							}
						]
					},
					{
						systemId: 'market',
						name: '活动业绩',
						hidden: true,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动业绩',
							icon: '',
							noCache: false,
							systemId: 'market'
						},
						children: [
							{
								systemId: 'market',
								name: '合伙人业绩统计',
								path: '/pps',
								hidden: true,
								component: 'report/actPerformance/pps',
								meta: {
									title: '合伙人业绩统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: ''
								}
							},
							{
								systemId: 'market',
								name: '平台业绩统计',
								path: '/plps',
								hidden: true,
								component: 'report/actPerformance/plps',
								meta: {
									title: '平台业绩统计',
									icon: '',
									noCache: false,
									systemId: 'market',
									activePath: ''
								}
							}
						]
					}
				]
			},
			{
				systemId: 'market',
				name: '报表分析',
				hidden: true,
				redirect: 'noRedirect',
				component: 'ParentView',
				alwaysShow: true,
				meta: {
					title: '报表分析',
					icon: '',
					noCache: false,
					systemId: 'market'
				},
				children: [
					{
						systemId: 'market',
						name: '活动成效',
						hidden: true,
						redirect: 'noRedirect',
						component: 'ParentView',
						alwaysShow: true,
						meta: {
							title: '活动成效',
							icon: '',
							noCache: false,
							systemId: 'market',
							activePath: ''
						},
						children: [
							{
								systemId: 'market',
								name: '活动投入成本分析',
								path: '/ica',
								hidden: true,
								component: 'report/actResult/ica',
								meta: {
									title: '活动投入成本分析',
									icon: '',
									noCache: false
								}
							}
						]
					}
				]
			}
		]
	}
];
