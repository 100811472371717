<template>
	<div>
		<k-popup ref="announcePop" :data-title="announce.title">
			<k-form ref="announceForm" :data-col="1" data-ui="element">
				<k-form-item label="内容" data-ui="element">
					<k-field-display v-model="announce.content" data-type="html" />
				</k-form-item>
				<k-form-item label="附件" data-ui="element">
					<a href="javascript:void(0)" @click="download(announce.annfilepath, announce.annfilename)">{{ announce.annfilename }}</a>
				</k-form-item>
			</k-form>
		</k-popup>
		<md-toolbar
			md-elevation="0"
			class="md-transparent"
			:class="{
				'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
			}"
		>
			<div class="md-toolbar-row">
				<!-- <div class="md-toolbar-section-start">
          <h3 class="md-title">{{ $route.name }}</h3>
        </div> -->
				<div class="md-toolbar-section-end">
					<div class="md-collapse">
						<!-- <k-form :data-col="1" data-input-width="177px" data-label-width="0px">
              <k-form-item>
                <k-field-select v-model="menuData" @data-on-change="menuChange" :data-data="subMenus" data-placeholder="搜索菜单..."
                                data-display-field="label" data-value-field="value"/>
              </k-form-item>
            </k-form> -->
						<md-list class="menu-list">
							<!-- <div class="menu-com-list">
                <li class="md-list-item" v-for="(menu,index) in comMenus" :key="index">
                  <a :href="'#/main/' + menu.url" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                    <div class="md-list-item-content">
                      <drop-down :ref="'dropDown'+ index" direction="down" toggle-type="contextmenu">
                        <k-btn :data-descript="menu.menuname" slot="title" class="md-button md-just-icon md-simple"
                               data-toggle="dropdown">
                          <md-icon class="menu-icon" v-if="menu.icon" :md-src="'/static/svg/'+menu.icon+'.svg'"></md-icon>
                          <md-icon v-if="!menu.icon" :md-src="'/static/svg/'+menu.icon+'.svg'"></md-icon>
                        </k-btn>
                        <ul class="dropdown-menu dropdown-menu-right">
                          <li><a @click="deleteComMenu(menu,index)" href="javascript:void(0)">取消置顶</a></li>
                        </ul>
                      </drop-down>
                    </div>
                  </a>
                </li>
              </div> -->
							<!-- <md-list-item v-if="hasMenuMore && !isOpenMenuMore" @click="openMenuMore" href="javascript:void(0)">
                <md-icon md-src="/static/svg/menu-more-left.svg"></md-icon>
              </md-list-item>
              <md-list-item v-if="hasMenuMore && isOpenMenuMore" @click="hideMenuMore" href="javascript:void(0)">
                <md-icon md-src="/static/svg/menu-more-right.svg"></md-icon>
              </md-list-item>

              <md-list-item>
                |
              </md-list-item> -->
							<!-- <div class="menu-com-list">
                <li class="md-list-item">
                  <a :href="`#${$HomePageRoute}`" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                    <div class="md-list-item-content">
                      <k-btn data-descript="驾驶舱" slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                        <md-icon md-src="/static/svg/home.svg"></md-icon>
                      </k-btn>
                    </div>
                  </a>
                </li>
              </div> -->
              <li class="md-list-item">
                <k-btn slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown" :data-handler="toEditJava">
                  <i class="el-icon-edit"></i>
                </k-btn>
              </li>

							<li class="md-list-item">
								<span class="current-workday" v-show="currentWorkday"><span>系统日期：</span>{{ currentWorkday }}</span>
							</li>

							<li class="md-list-item">
								<a href="javascript:void(0)" @click="loadAnnounce" class="md-list-item-router md-list-item-container md-button-clean dropdown">
									<div class="md-list-item-content">
										<drop-down direction="down">
											<k-btn slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
												<i class="el-icon-bell"></i>
											</k-btn>
											<ul class="dropdown-menu dropdown-menu-right">
												<li v-for="item in announces" :key="item.annid" @click="popAnnounce(item)">
													<a href="javascript:void(0)" v-html="item.title"></a>
												</li>
											</ul>
										</drop-down>
									</div>
								</a>
							</li>

							<li class="md-list-item">
								<a class="md-list-item-router md-list-item-container md-button-clean dropdown">
									<div class="md-list-item-content">
										<drop-down direction="down" class="drop-down-user">
											<k-btn
												data-descript="用户信息"
												slot="title"
												class="md-button md-button md-just-icon md-simple md-theme-default"
												data-toggle="dropdown"
											>
												<img src="../../assets/img/dark-user-avatar.png" class="user-avatar" />
											</k-btn>
											<div class="user-name">{{ user.loginname }}</div>
											<i class="el-icon-caret-bottom" />

											<ul class="dropdown-menu dropdown-menu-left" style="min-width: 112px; z-index: 9999">
												<li @click="popEditUserInfor()">
													<a> <span class="el-icon-user"></span><span>个人信息</span></a>
												</li>
												<li @click="popChangePwd()">
													<a> <span class="el-icon-edit"></span><span>修改密码</span> </a>
												</li>
												<li @click="loginOut()">
													<a> <span class="el-icon-switch-button"></span><span>安全退出</span> </a>
												</li>
											</ul>
										</drop-down>
									</div>
								</a>
							</li>

							<!-- <div class="menu-com-list">
                <li class="md-list-item">
                  <a href="#" @click="loginOut" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                    <div class="md-list-item-content">
                      <k-btn data-descript="安全退出" slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                        <md-icon md-src="/static/svg/loginout.svg"></md-icon>
                      </k-btn>
                    </div>
                  </a>
                </li>
              </div> -->

							<!-- <li class="md-list-item">
                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                        <md-icon md-src="/static/svg/more.svg"></md-icon>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right" style="margin-left: -150px;">
                        <li><a @click="addComMenu" href="javascript:void(0)">+常用功能</a></li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li> -->
						</md-list>
					</div>
				</div>
			</div>
		</md-toolbar>

		<!--    修改用户弹出框   -->
		<k-popup ref="editUserPopup" data-title="编辑用户信息">
			<k-form ref="editUserForm" :data-col="1">
				<k-form-item label="登录名">
					<k-field-text v-model="formData.loginname" data-disabled />
				</k-form-item>
				<k-form-item label="所属机构">
					<k-field-text v-model="formData.orgname" data-disabled />
				</k-form-item>
				<k-form-item label="用户名称">
					<k-field-text v-model="formData.username" @input="forceUpdate" :data-max-length="32" :data-allowblank="false" />
				</k-form-item>
				<k-form-item label="移动电话">
					<k-field-text v-model="formData.mobileno" @input="forceUpdate" data-validate-type="number" :data-max-length="11" />
				</k-form-item>
				<k-form-item label="电子邮箱">
					<k-field-text v-model="formData.email" data-validate-type="email" @input="forceUpdate" :data-max-length="64" />
				</k-form-item>
				<k-form-item label="性别">
					<k-field-select v-model="formData.sex" data-dict="sex" @input="forceUpdate" />
				</k-form-item>
				<k-form-footer data-align="center">
					<k-btn class="md-primary" data-functype="SUBMIT" data-action="User.updateUserInfor" data-from="editUserForm" :data-model="formData">
						<i class="icon-confirm" />确定
					</k-btn>
					<k-btn class="md-info" data-functype="CLOSE"> <i class="icon-cancel" />取消</k-btn>
				</k-form-footer>
			</k-form>
		</k-popup>

		<k-popup ref="resetPwdPopup" data-title="修改密码">
			<k-form ref="resetPwdForm" :data-col="1">
				<k-form-item label="原密码">
					<k-field-text v-model="pwdData.oldPwd" data-show-password :dataAllowblank="false" />
				</k-form-item>
				<k-form-item label="新密码">
					<k-field-text
						v-model="pwdData.newPwd"
						data-show-password
						:dataAllowblank="false"
						:data-max-length="32"
						:data-regx="regx"
						:data-regx-text="regxMsg"
					/>
				</k-form-item>
				<k-form-item label="确认密码">
					<k-field-text
						v-model="pwdData.confirmNewPwd"
						data-show-password
						:dataAllowblank="false"
						:data-max-length="32"
						:data-regx="regx"
						:data-regx-text="regxMsg"
					/>
				</k-form-item>
				<k-form-footer data-align="center">
					<k-btn class="md-primary" :data-handler="resetPwdHandler" data-target="userGrid"> <i class="icon-confirm" />确定 </k-btn>
					<k-btn class="md-info" data-functype="CLOSE"> <i class="icon-cancel" />取消</k-btn>
				</k-form-footer>
			</k-form>
		</k-popup>
	</div>
</template>

<script>
import httpUtil from "../httpUtil";
import { merge } from "lodash";
import KFieldDisplay from "../../components/k-element/k-field-display/k-field-display";
import Tools from "@/utils/tools";
import KFormItem from "../../components/k-element/k-from/k-form-item";
import MD5 from "@/frame/md5.js";
import auth from "../../utils/auth";
import KFieldSelect from "@/components/k-element/k-field-select/k-field-select";
import KForm from "@/components/k-element/k-from/k-form";

export default {
	components: {
		KForm,
		KFieldSelect,
		KFieldDisplay,
		KFormItem,
	},
	props: {
		subMenus: {
			type: Array,
		},
	},
	data() {
		return {
			menuData: "",
			comMenus: [],
			minMenus: [],
			allMenus: [],
			hasMenuMore: false,
			isOpenMenuMore: false,
			announces: [],
			announce: {},
			userRole: "",
			formData: {
				loginname: "",
			},
			pwdData: {},
			currentWorkday: null,
			user: {},
			regx: "",
			regxMsg: "",
		};
	},
	created() {
		Tools.getLoginUser().then((res) => {
			this.userRole = res.roleids;
			this.user = res ? res : {};
		});
		this.loadComMenu();

		this.judgeRegx();

	},

	// 监听路由 根据当前所在的菜单 改变当前系统时间
	watch: {
		$route: function (to, from) {
			// let fromValue = this.menuMap[from.path];
			// let toValue = this.menuMap[to.path];

			// if (fromValue == toValue || toValue == null) {
			// 	return;
			// }
			// this.httpUtil
			// 	.comnQuery({
			// 		action: "M003.findParaByParaId",
			// 		params: {
			// 			paraid: toValue,
			// 		},
			// 	})
			// 	.then((data) => {
			// 		this.currentWorkday = data.rows[0].paravalue.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3");
			// 	});
		},
	},

	methods: {
		//判断密码校验是否需要包含特殊字符
		judgeRegx() {
			let spCharFlag = localStorage.getItem("spCharFlag");
			if (spCharFlag == "1") {
				this.regx = "^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,}$";
				this.regxMsg = "请输入最少八个字符、包含大小写字母、数字、特殊字符";
			} else {
				this.regx = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$";
				this.regxMsg = "请输入最少八个字符、包含大小写字母、数字";
			}
		},

		loadAnnounce() {
			// let graphql =
			//   '{queryAnnounceInfo(action:"show") {rows{title, content, createdate, createtime, annfilepath, annfilename},results}}'
			// this.httpUtil
			//   .graphqlQurey({
			//     graphql: graphql,
			//     params: {
			//       "roleid": this.userRole
			//     }
			//   })
			//   .then(data => {
			//     this.announces = data["queryAnnounceInfo"].rows;
			//   });

			//获取公告信息
			this.httpUtil
				.comnQuery({
					action: "M007.show",
					params: {
						roleid: this.userRole,
					},
				})
				.then((data) => {
					this.announces = data.rows;
				});
		},
		download(path, filename) {
			this.httpUtil.download({
				url: "/base/comn-download2.json",
				params: {
					path: path,
				},
				filename: filename,
			});
		},
		loginOut() {
			auth.removeToken();
			// 重置 tab
			let tabHome = this.$store.state.system.tab[0];
			tabHome.active = true;
			this.$store.commit("system/setTab", [tabHome]);
			this.$store.commit("system/setTab2", []);
			sessionStorage.removeItem("vuex");
			this.$router.push({
				path: "/login",
			});
		},
		popAnnounce(announce) {
			this.announce = announce;
			this.$refs.announcePop.popup();
		},
		popEditUserInfor() {
			this.$refs.editUserPopup.popup();
			this.getUserInfor();
		},
		popChangePwd() {
			this.$refs.resetPwdPopup.popup();
			this.getUserPwdInfor();
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
		menuChange(url) {
			//判断当前打开的地址是不是已经打开了
			let currentUrl = this.$router.history.current.path;
			let openUrl = "/main/" + url;

			if (currentUrl != openUrl) {
				this.$router.push({
					path: openUrl,
				});
			}
		},
		loadComMenu() {
			this.httpUtil
				.query({
					url: "sys/findComMenus.json",
				})
				.then((data) => {
					if (data.rows.length <= 5) {
						this.comMenus = data.rows;
						this.hasMenuMore = false;
					} else {
						this.hasMenuMore = true;
						this.minMenus = [];
						this.allMenus = data.rows;
						for (let i = 0; i < 5; i++) {
							this.minMenus.push(data.rows[i]);
						}
						if (this.isOpenMenuMore) {
							this.comMenus = this.allMenus;
						} else {
							this.comMenus = this.minMenus;
						}
					}
				});
		},
		addComMenu() {
			let currentUrl = this.$router.history.current.path;
			this.httpUtil
				.update({
					url: "sys/addComMenu.json",
					params: {
						url: currentUrl,
					},
				})
				.then((data) => {
					this.loadComMenu();
				});
		},
		deleteComMenu(menu, index) {
			this.httpUtil
				.update({
					url: "sys/deleteComMenu.json",
					params: menu,
				})
				.then((data) => {
					this.loadComMenu();
				});
			if (this.$refs["dropDown" + index][0]) {
				this.$refs["dropDown" + index][0].closeDropDown();
			}
		},
		openMenuMore() {
			this.isOpenMenuMore = true;
			this.comMenus = this.allMenus;
		},
		hideMenuMore() {
			this.isOpenMenuMore = false;
			this.comMenus = this.minMenus;
		},
		getUserInfor() {
		},
		getUserPwdInfor() {
			this.pwdData = {};
			Tools.getLoginUser().then((res) => {
				this.pwdData.loginname = res.loginname;
				this.pwdData.userid = res.userid;
			});
		},
		resetPwdHandler() {
			if (this.pwdData.newPwd !== this.pwdData.confirmNewPwd) {
				this.$message.error("两次输入不一致！请重新输入");
				return false;
			}
			let ret = this.$refs.resetPwdForm.validate();
			if (!ret) {
				return false;
			}
			let passwd = MD5.MD5(localStorage.getItem("loginname") + this.pwdData.newPwd);
			let oldPwd = MD5.MD5(localStorage.getItem("loginname") + this.pwdData.oldPwd);
			// this.pwdData.newPwd = {}
			// this.pwdData.confirmNewPwd = {}
			this.httpUtil
				.comnQuery({
					action: "M006.resetPwd3",
					params: {
						passwd: passwd,
						oldPwd: oldPwd,
					},
					successAlert: false,
				})
				.then((res) => {
					if (res.returndata["errTime"] == null) {
						this.$message.success("修改密码成功");
						this.$refs.resetPwdPopup.close();
					} else if (res.returndata["errTime"] == "4") {
						this.loginOut();
						this.$message.error("原密码输入错误达到5次，用户已被冻结，请联系管理员解冻");
					} else {
						this.$message.error("原密码输入错误(达到5次将冻结用户)，修改密码失败");
						return false;
					}
				});
		},
    toEditJava(){
		  window.open(getJavaEditUrl().baseUrl+'?token='+auth.getToken())
    },
		forceUpdate() {
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
// .menu-list svg {
//   width: 20px;
//   height: 20px;
// }

// ::v-deep .dropdown-menu {
//   // margin-top: 10px;
//   // right: auto;
//   left: -35px;
// }
.dropdown-menu {
	box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1);
}
.dropdown-menu > li {
	height: 36px;
	> a {
		padding: 7px 10px;
		> span {
			margin-right: 5px;
			font-size: 14px;
		}
	}
}
.md-toolbar .md-list-item .md-button.md-just-icon i {
	// color:#fff !important;
	font-size: 16px !important;
}
.dropdown-menu > li:not(:nth-child(3)) {
	border-bottom: 1px solid #3c4359;
}
@media (max-width: 991px) {
	.md-toolbar .md-collapse {
		display: flex !important;
		width: 100%;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

::v-deep .md-field .md-input-action {
	top: 65%;
}

.k-form {
	width: 197px;
	display: flex;
	align-items: center;
}

::v-deep .el-form-item__label {
	line-height: 60px !important;
}
.svg-icon {
	width: 16px !important;
	height: 16px !important;
	fill: #bdbec1 !important;
}
.el-icon-caret-bottom {
	color: #fff;
	opacity: 0.7;
}
</style>
