import Tools from '@/utils/tools.js'
import { remove } from 'lodash'

export default {
  methods: {
    getGraphqlUrl(dataGraphql,dataDiffcondition){
      let data_graphql = dataGraphql.trim()
      let queryName = data_graphql.substring(data_graphql.indexOf("{")+1,data_graphql.indexOf("("))
      data_graphql += "treeConfig{"
      data_graphql += queryName
      data_graphql += ":{"
      data_graphql += "diffcondition: '"+ dataDiffcondition + "'"
      data_graphql += "}"
      data_graphql += "}"
      return data_graphql
    },
    //处理数据
    handleData(rows) {
      const options = []
      const dataDisplayFields = this.dataDisplayField.split(',')
      rows.map(row => {
        // 获取值
        const valueField = row[this.dataValueField]
        // 获取显示值
        let displayField
        let disabled = false
        for (let i = 0; i < dataDisplayFields.length; i++) {
          if (i === 0) {
            displayField = row[dataDisplayFields[i]]
          } else {
            displayField += this.dataDisplaySeparator + row[dataDisplayFields[i]]
          }
        }
        if (this.dataValueDisabled && row.hasOwnProperty(this.dataValueDisabled)) {
          disabled = true
        }
        if (this.props.children && row.hasOwnProperty(this.props.children) && row[this.props.children] instanceof Array) {
          const childoptions = this.handleData(row[this.props.children])
          const _row = {}
          _row[this.props.label] = displayField
          _row[this.props.value] = valueField
          Tools.apply(_row, row)
          if (disabled) {
            this.setRowDisabld(_row)
          }
          _row[this.props.children] = childoptions
          options.push(_row)
        } else {
          const _row = {}
          _row[this.props.label] = displayField
          _row[this.props.value] = valueField
          Tools.apply(_row, row)
          if (disabled) {
            this.setRowDisabld(_row)
          }
          options.push(_row)
        }
      })
      // // 为每一项添加是否选中字段
      // let target = this.addKeyOrChangeValue(options)
      // // 初始渲染时，如果vmodel绑定的值不为空
      // target = this.addKeyOrChangeValue(target,this.value,true)
      // return target
      return options
    },
    addKeyOrChangeValue(arr,id,value){
      // return arr.map(item => ({
      //   ...item,
      //   isChecked:false,
      //   children: item.children ? this.addKey(item.children) : ''
      // }))
      let idArr;
      if(id instanceof Array){
        idArr = id
      }else{
        idArr = (id && id.split(',')) || []
      }
      arr.forEach(item => {
        if(item.isChecked !== undefined){
          if(idArr.includes(item[this.dataValueField])){
            // 更改数据
            item.isChecked = value
            if(this.dataMultiple){
              // 多选
              if(value){
                // 初始渲染的时候有默认的选中值
                // 判断item是否有子项，如果有，说明所有子项已选中
                if(item.children){
                  item.children.forEach(data => {
                    if(!this.returnData.some(id => id === item[this.dataValueField])){
                      this.pushData(item)
                      this.returnData.push(item[this.dataValueField])
                    }
                  })
                }else{
                  // 没有子项，需要判断，该默认选中的parentId是否存在idArr中
                  if(idArr.includes(item[this.dataParentId])){
                    // 存在值添加returnData
                    this.returnData.push(item[this.dataValueField])
                  }else{
                    // 不存在，则selectArr和returnData全添加
                    if(!this.returnData.some(id => id === item[this.dataValueField])){
                      this.pushData(item)
                      this.returnData.push(item[this.dataValueField])
                    }
                  }
                }

              }
              else{
                remove(this.selectArr,(data) => item[this.dataValueField] === data.id)
                // 解决多选删除不更新视图
                this.$forceUpdate();
              }
            }else {
              // 单选
              if(value){
                // 选中
                if (!this.selectArr.some(k => k.id === item[this.dataValueField])) {
                  this.pushData(item)
                }
              }else{
                // 取消选中
                this.selectArr = []
              }
            }
          }
        }else{
          item.isChecked = false
        }
        // 只有第一次是addkey的功能，其他都是修改isChecked的值
        if(item.children && item.children instanceof Array){
          this.addKeyOrChangeValue(item.children,id,value)
        }
      });
      return arr
    },
    // 递归处理
    recursiveFunc(options, dataDisplayFields) {
      options.showLabel = ''
      dataDisplayFields.forEach((displayField, index) => {
        if(index === 0) {
          options.showLabel = options[displayField]
        } else {
          options.showLabel += '-' + options[displayField]
        }
      });
      if(options[this.dataDisplayChild] && options[this.dataDisplayChild].length > 0) {
        options[this.dataDisplayChild].forEach(option => {
          this.recursiveFunc(option, dataDisplayFields)
        })
      }

    },
    setRowDisabld(row) {
      if (row[this.dataValueDisabled] === true || row[this.dataValueDisabled] === 'true' || row[this.dataValueDisabled] === 'disabled' || (this.dataValueMethod !== undefined ? this.dataValueMethod(row) : false)) {
        row[this.props.disabled] = 'disabled'
      }
    },
    //异步加载
    loadActionData() {
      this.httpUtil.comnQuery({
        action: this.dataAction,
        params: this.params
      }).then(data => {
        this.options = this.handleData(data.rows)
      });
    },
    loadGraphqlData() {
      this.httpUtil.graphqlQurey({
        graphql: this.graphql,
        params: this.params
      }).then(data => {
        let graphqlFirst = this.graphql.substring(this.graphql.indexOf("{") + 1, this.graphql.indexOf("("));
        this.options = this.handleData(data[graphqlFirst].rows);
      });
    },
    loadActionDataTree() {
      this.httpUtil.comnQueryTree({
        action: this.dataAction,
        params:this.params
      }).then(data => {
        this.options = this.handleData(data.rows);
      });
    },
    showNode (node,resolve) {
      const { data } = node
      if (data === undefined || data.length === 0 || node.level === 0) {
        return
      }
      const params = {}
      if (this.params.hasOwnProperty(this.dataParentField)) {
        params[this.dataParentField] = data[this.props.value]
      }
      setTimeout(() => {
        if (this.dataAction) {
          this.httpUtil.comnQuery({
            action: this.dataAction,
            params: params
          }).then(data => {
            resolve(this.handleData(data.rows))
          })
        } else if (this.dataGraphql) {
          this.httpUtil.graphqlQurey({
            graphql: this.dataGraphql,
            params: params
          }).then(data => {
            // 获取请求头
            const graphqlFirst = this.dataGraphql.substring(this.dataGraphql.indexOf('{') + 1, this.dataGraphql.indexOf('('))
            resolve(this.handleData(data[graphqlFirst].rows))
          })
        }
      }, 1000)
    }
  }
}
