export default [
{
  path: "flowable/flowFormField",
  name: "表单字段配置",
  component: () => import('@/pages/flowable/formField/formField.vue')
},
{
  path: "flowable/flowModel",
  name: "流程模型",
  component: () => import('@/pages/flowable/model/model.vue')
},
{
  path: "flowable/flowParam",
  name: "流程参数配置",
  component: () => import('@/pages/flowable/param/flowParam.vue')
},
{
  path: "flowable/flowDeploy",
  name: "部署管理",
  component: () => import('@/pages/flowable/deploy/deploy.vue')
},
{
  path: "flowable/flowBusinessConfig",
  name: "业务配置",
  component: () => import('@/pages/flowable/businessConfig/businessConfig.vue')
},
{ path: "flowable/flowTrack",
  name: "流程追踪",
  component: () => import('@/pages/flowable/track/flowTrack.vue')
},
{ path: "flowable/flowUserToDoTask",
  name: "待审核任务",
  component: () => import('@/pages/flowable/userToDoTask/userToDoTask.vue')
},
{ path: "flowable/flowSurrogate",
  name: "转审批",
  component: () => import('@/pages/flowable/surrogate/surrogate.vue')
},
{ path: "flowable/flowBusinessStatus",
  name: "业务流程状态",
  component: () => import('@/pages/flowable/businessStatus/businessStatus.vue')
},
{ path: "flowable/flowMonitor",
  name: "流程监控",
  component: () => import('@/pages/flowable/monitor/monitor.vue')
}]