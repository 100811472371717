var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-design-wrap",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{key:_vm.updateKey,staticClass:"custom-border-box",style:({
      'border-top-left-radius': _vm.radiusLeftTop + 'px',
      'border-top-right-radius': _vm.radiusRightTop + 'px',
      'border-bottom-left-radius': _vm.radiusLeftBottom + 'px',
      'border-bottom-right-radius': _vm.radiusRightBottom + 'px',
      border: `${_vm.width}px solid ${_vm.color} `,
      opacity: _vm.opacity / 100,
      'background-image': `linear-gradient(${_vm.gradientDirection}, ${
        _vm.gradientColor0 ? _vm.gradientColor0 : _vm.gradientColor1
      } , ${_vm.gradientColor1 ? _vm.gradientColor1 : _vm.gradientColor0})`
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }