<template>
	<div>
		<k-popup ref="kGridDisplayPopup" data-title="详情" :class="dataLabelSize">
			<k-form ref="displayForm" :data-col="2" dataTotalWidth="780px">
				<template v-for="(value, key, index) in gridColumnOptions">
					<k-form-item data-width="360px" v-if="value.dialogDisplay != 'hidden'" :key="index" :label="value.dataHeader">
						<template v-if="value.dataName && value.dataName.includes(',')">
							<span>{{ showName(value.dataName, row) }}</span>
						</template>
						<template v-else>
							<k-field-display
								v-model="row[value.dataName]"
								:data-action="value.dataAction"
								:data-dict="value.dataDict"
								:data-type="value.dataType"
								:data-clearable="false"
								:render-function="value.renderFunction"
								:data-row="row"
								:data-local-list="value.dataLocalList"
								:data-local="value.dataLocal"
							/>
						</template>
					</k-form-item>
				</template>
			</k-form>
		</k-popup>
	</div>
</template>



<script>
import props from "@/components/k-element/common/k-field-props.js";
import event from "@/components/k-element/common/k-field-event.js";
import emitter from "@/components/k-element/common/k-emitter.js";

export default {
	name: "KGridDisplay",
	mixins: [props(), event(), emitter()],
	props: {
		row: {
			type: Object,
		},
		gridColumnOptions: {
			type: Array,
		},
		dataLabelSize: {
			type: String,
			default: "label-size4",
		},
	},
	methods: {
		popup() {
			for (let i = 0; i < this.gridColumnOptions.length; i++) {
				if (this.gridColumnOptions[i].dataHidden) {
					this.gridColumnOptions.splice(i, 1);
				}
			}
			this.$refs.kGridDisplayPopup.popup();
		},
	},
	computed: {
		showName() {
			return (name, row) => {
				let str = "";
				const arr = name.split(",");
				arr.forEach((item, index) => {
					if (index === arr.length - 1) {
						str += row[item];
					} else {
						str += row[item] + "-";
					}
				});
				return str;
			};
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .md-field:after,
.md-field:before {
	bottom: 10px;
	border: 1px solid wh;
}
</style>
