<template>
  <div class="user">
    <div class="photo">
      <img :src="'/static/svg/'+icon+'.svg'" alt="avatar" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
        <span>
          <span style="margin-left:3px">{{ title }}</span>
          <b  class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div class="one-menu-transition" v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li v-for="menu in menus" @click="clickModuleNav(menu)">
                <a href="javascript:void(0);">
                  <md-icon v-if="menu.icon" :md-src="'/static/svg/'+menu.icon+'.svg'" style="margin-right: 20px !important;"></md-icon>
                  <span class="sidebar-normal" v-text="menu.menuname">系统管理</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      avatar: {
        type: String,
        default: "./img/faces/avatar.jpg"
      },
      menus:{
        type:Array,
        default:[]
      }
    },
    watch:{
      menus(newVal){
        this.title=newVal[1].menuname
        this.icon=newVal[1].icon
        let a=false
        newVal.forEach(item=>{
             if(item.menuname==localStorage.getItem("defaultLevel1Menu")){
               this.clickModuleNav(item)
               a=true
             }
        })
        if(!a){
          this.clickModuleNav(newVal[1])
        }

      }
    },
    data() {
      return {
        title:"财富平台",
        icon:"",
        isClosed: false,
        currentSecondMenu:[]
      };
    },
    created() {

    },
    mounted() {
      // console.log(this.menus,"菜单")
      // window.addEventListener('beforeunload', e => {
      //   localStorage.setItem("ss",this.$route.path)
      // });
    },
    methods: {
      clickModuleNav(menu){
        this.title=menu.menuname
        this.icon=menu.icon
        this.toggleMenu()
        this.$emit("clickModuleNav",menu.children?menu.children:[])
      },
      clicked: function(e) {
        e.preventDefault();
      },
      toggleMenu: function() {
        this.isClosed = !this.isClosed;
      }
    }
  };
</script>
<style lang="scss">
  .collapsed {
    transition: opacity 1s;
  }
  .one-menu-transition{
    /*animation: none !important;*/
  }
</style>
