<template></template>

<script>
import { Notification } from "element-ui";
import { debounceFun } from "@/utils/index.js";
export default {
	name: "KFieldNotification",
	// 字段说明文档在doc/k-field-notification.md
	props: {
		tagType: {
			type: String,
			default: "",
		},
		dangerouslyUseHTMLString: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "info",
		},
		duration: {
			type: Number,
			default: 4500,
		},
		position: {
			type: String,
			default: "top-right",
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		offset: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			notifications: [],
			questNotifications: [],
		};
	},
	methods: {
		// 显示通知前需要比较数据，如果存在已显示的通知就不再显示
		beforeOpenNotFun(list) {
			if (this.questNotifications && this.questNotifications.length > 0) {
				// 已经提示了公告
				let ggList = this.questNotifications.filter((v) => {
					return v && v.tagType === "审批提醒";
				});
				let lcList = list.filter((v) => {
					return v && v.tagType === "审批提醒";
				});
				if (ggList && ggList.length > 0) {
					if (lcList && lcList.length > 0) {
						if (lcList[0].message != ggList[0].message) {
							//内容不相同才删除
							this.closeNotification("09999999");
						}
					} else {
						// list返回没有审批提醒，但是提示的公告中有也删除
						this.closeNotification("09999999");
					}
				}
			}
			list = list.map((item) => {
				if (item.tagType === "审批提醒") {
					this.$set(item, "messageId", "09999999");
				}
				return item;
			});
			this.nextOpenNotFun(list);
		},
		nextOpenNotFun(list) {
			if (list && list.length > 0) {
				if (this.questNotifications && this.questNotifications.length > 0) {
					//取list的差集数据，只显示还没有显示的通知
					let valList = list.filter((v) => {
						return this.questNotifications.every((e) => e.messageId != v.messageId);
					});
					this.openNotFun(valList);
				} else {
					this.openNotFun(list);
				}
			}
		},
		async openNotFun(list) {
			var that = this;
			that.questNotifications = that.questNotifications.concat(list);
			if (list && list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					const h = that.$createElement;
					let notify = await Notification({
						//解决消息框重叠  也可以通过加延迟解决
						message: h("div", [
							h("div", { class: "msg-header" }, [
								h("div", { class: list[i].tagType || that.tagType ? "msg-tag" : "" }, list[i].tagType || that.tagType),
								h("div", { class: list[i].tagType || that.tagType ? "msg-title" : "msg-title width-300" }, list[i].title),
							]),
							h("p", { class: "msg-content" }, list[i].message),
							h("div", { class: "msg-btn md-plain-golden" }, [
								h(
									"md-button",
									{
										class: list[i].tagType === "审批提醒" ? "dispaly-none" : "msg-btn-left",
										on: {
											click: () => {
												that.nextTimeFun(list[i]);
											},
										},
									},
									"下次提醒我"
								),
								h(
									"md-button",
									{
										class: list[i].detailUrl ? "msg-btn-right" : "dispaly-none",
										on: {
											click: () => {
												that.goToDetail(list[i]);
											},
										},
									},
									"查看详情 >"
								),
							]),
						]),
						dangerouslyUseHTMLString: list[i].dangerouslyUseHTMLString || that.dangerouslyUseHTMLString,
						duration: list[i].duration != undefined ? list[i].duration : that.duration,
						position: list[i].position != undefined ? list[i].position : that.position,
						offset: that.offset,
						showClose: list[i].showClose != undefined ? list[i].showClose : that.showClose,
						customClass: list[i].type != undefined ? that.calssType(list[i].type) : that.calssType(that.type),
						iconClass: "notice-iconClass",
						onClick() {
							// 点击回调
							that.onClickFun(list[i]);
						},
						onClose() {
							// 关闭回调
							that.onCloseFun(list[i]);
						},
					});
					that.notifications[list[i].messageId] = notify;
				}
			}
		},
		// 消息类型class
		calssType(type) {
			var calssTypeName = "";
			switch (type) {
				case "success":
					calssTypeName = "success-notice-customClass";
					break;
				case "warning":
					calssTypeName = "warning-notice-customClass";
					break;
				case "info":
					calssTypeName = "info-notice-customClass";
					break;
				case "error":
					calssTypeName = "error-notice-customClass";
					break;
				default:
					calssTypeName = "info-notice-customClass";
					break;
			}
			return "el-notification-new " + calssTypeName;
		},
		// 点击回调
		onClickFun(item) {
			this.$emit("onClickFun", item);
		},
		// 关闭回调
		onCloseFun(item) {
			this.$emit("onCloseFun", item);
		},
		// 查看详情
		goToDetail: debounceFun(function (item) {
			if (item.tagType === "审批提醒") {
				this.$router.push({
					path: "/main/" + item.detailUrl,
					query: { tmap: new Date() },
				});
			}
			if (item.dealAction) {
				let params = {};
				this.$set(params, item.keyId, item.messageId);
				this.httpUtil
					.comnQuery({
						action: item.dealAction,
						params: params,
					})
					.then((res) => {
						this.closeNotification(item.messageId);
						this.$router.push({
							path: "/main/" + item.detailUrl,
							query: { tmap: new Date(), ...params },
						});
					});
			} else {
				console.log("未返回dealAction字段");
			}
		}),
		// 下次提醒
		nextTimeFun: debounceFun(function (item) {
			if (item.nextDealAction) {
				let params = {};
				this.$set(params, item.keyId, item.messageId);
				this.httpUtil
					.comnQuery({
						action: item.nextDealAction,
						params: params,
					})
					.then((res) => {
						this.closeNotification(item.messageId);
					});
			} else {
				console.log("未返回nextDealAction字段");
			}
		}),
		//关闭单个通知
		closeNotification(messageId) {
			this.questNotifications = this.questNotifications.filter((item) => item.messageId !== messageId);
			this.notifications[messageId].close();
			delete this.notifications[messageId];
		},
		//关闭所有通知
		closeAllNotification() {
			this.questNotifications = [];
			Notification.closeAll();
		},
	},
	beforeDestroy() {
		this.closeAllNotification();
	},
};
</script>
<style lang="scss">
.el-notification-new {
	padding: 12px 16px 16px;
	background: #1f232f;
	box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.1);
	border: unset;
	border-radius: 0;
	color: #ffffff;
}
.el-notification-new > .el-notification__group {
	margin: 0;
}
.el-notification-new > .el-notification__title {
	display: none;
}
.el-notification-new {
	.msg-header {
		display: flex;
		.msg-tag {
			font-size: 12px;
			border-radius: 2px;
			width: 70px;
			margin-right: 8px;
			text-align: center;
			font-weight: 400;
		}
		.msg-title {
			width: 222px;
			font-size: 14px;
			color: #ffffff;
			opacity: 0.9;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.width-300 {
			width: 300px;
		}
	}
	.msg-content {
		margin-top: 6px;
		font-size: 12px;
		color: #ffffff;
		opacity: 0.7;
		font-weight: 400;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.msg-btn {
		display: flex;
		justify-content: flex-end;
		margin-top: 14px;
		.md-ripple {
			margin: unset !important;
			padding: unset !important;
		}
		.md-button {
			margin: unset !important;
		}
		.msg-btn-left {
			cursor: pointer;
			font-weight: 400 !important;
			font-size: 12px !important;
			padding: 4px 12px !important;
			color: #e5e5e7 !important;
			background: #3c4359 !important;
			border: 1px solid rgba(60, 67, 89, 1) !important;
			border-radius: 2px !important;
			box-shadow: unset !important;
		}
		.msg-btn-right {
			cursor: pointer;
			font-weight: 400 !important;
			font-size: 12px !important;
			padding: 4px 12px !important;
			margin-left: 12px !important;
			color: #232324 !important;
			background-image: linear-gradient(90deg, #f5e9ca 0%, #b39e7b 100%) !important;
			border-radius: 2px !important;
			box-shadow: unset !important;
		}
	}
}

.success-notice-customClass {
	border-top: 4px solid #27c346;
	.msg-tag {
		color: #27c346;
		background: rgba(39, 195, 70, 0.15);
	}
}

.warning-notice-customClass {
	border-top: 4px solid #ff9626;
	.msg-tag {
		color: #ff9626;
		background: rgba(255, 150, 38, 0.15);
	}
}

.error-notice-customClass {
	border-top: 4px solid #f76965;
	.msg-tag {
		color: #f76965;
		background: rgba(247, 105, 101, 0.15);
	}
}

.info-notice-customClass {
	border-top: 4px solid #00a0e9;
	.msg-tag {
		color: #00a0e9;
		background: rgba(0, 160, 233, 0.15);
	}
}

.notice-iconClass {
	display: none;
}
.dispaly-none {
	display: none;
}
</style>
