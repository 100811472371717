<template>
    <el-container class="k_container">
        <el-aside width="272px" class="k_container_aside" ref="kContainerAside">
            <slot name="containerAsider"></slot>
        </el-aside>
        <el-container>
            <el-header height="" class="k_container_header">
                <slot name="containerHeader"></slot>
            </el-header>
            <el-main class="k_container_main">
                <slot name="containerMain"></slot>
            </el-main>
        </el-container>
    </el-container>
</template>


<script>
export default {
    name: 'KContainer'
}
</script>