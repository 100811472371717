// 左侧plot组件的排序
export default [
  '基础折线图',
  '基础曲线图',
  '基础折线点图',
  '多折线图',
  '多折线动画图',
  '折线趋势填充图',
  '多阶梯折线图',


  '迷你基础折线图',
  '迷你面积图',

  '基础柱状图',
  '分组柱状图',
  '堆叠柱状图',
  '圆角柱状图',

  '基础面积图',
  '渐变色面积图',
  '堆叠面积图',

  '基础条形图',
  '分组条形图',
  '堆叠条形图',

  '基础饼图',
  '基础环图',
  '玫瑰图',

  '基础玉珏图',

  '水波图',
  '矩形水波图',
  '钻石水波图',
  '仪表盘',
  '进度仪表盘',
  '进度条',
  '圆角进度条',
  '进度环图',
  '进度指标环图',

  '词云图',
  '基础矩形树图',
  '基础雷达图',
  '分组雷达图',

  '基础漏斗图',
  '对比漏斗图',
  '分面漏斗图',
  '柱线混合图'
]
