<template>
	<div class="k-charts">
		<div :id="id" :style="chartsStyle"></div>
	</div>
</template>
<script>
/**
 * 使用说明：
 *
 * 1、如何更改option，
 * 	1》通过dataOption初始化默认值
 * 	2》通过dataHandler切入进行修改
 *
 * 2、使用例子
		<k-charts ref="kCharts" :dataOption="dataOption" dataChartType="bar,line" dataServerName="CdpServer"
				dataAction="CdpCrowdPortrait.getChart,CdpCrowdPortrait.getChart2" :dataParams="dataParams"
				dataXAxisType="name0" dataKey="value" :dataHandler="chartHandler"></k-charts>
 *
 */
export default {
	name: 'KCharts',
	components: {},
	mixins: [],
	props: {
		// 图表类型，多个series时，可以为每个系列设置不同的图表类型，如：bar,bar,line
		dataChartType: {
			type: String
		},
		// 初始化option配置（默认配置）
		dataOption: {
			type: Object
		},
		// 图表ID
		dataId: {
			type: String
		},
		// 是否自动加载数据
		dataAutoLoad: {
			type: Boolean,
			default: true
		},
		dataWidth: {
			type: String,
			default: '100%'
		},
		dataHeight: {
			type: String,
			default: '100%'
		},
		dataMinHeight: {
			type: String,
			default: '300px'
		},
		// 服务名
		dataServerName: {
			type: String
		},
		// Action配置，支持多个 User.find,Role.find,User.find
		dataAction: {
			type: String
		},
		// 传入完整URL，将不会走后台统一处理图表接口，由当前接口组装返回完整数据
		dataUrl: {
			type: String
		},
		// 接口参数
		dataParams: {
			type: Object
		},
		// 接口参数，当dataAction接口参数不同时，可以为不同接口配置不同参数
		dataParamsList: {
			type: Array
		},
		// 从后台接口参数中获取x轴数据的key值，可以为空，为空时，x轴的数据需要在前端配置
		dataXAxisType: {
			type: String
		},
		// 从后台接口参数中获取series数据的key值
		dataKey: {
			type: String
		},
		// 主题
		dataTheme: {
			type: String
		},
		// 通过切入API，更改option数据
		dataHandler: {
			type: Function
		}
	},
	data() {
		return {
			idPrefix: 'k-charts-',
			option: {
				title: null,
				tooltip: null,
				toolbox: null,
				legend: null,
				xAxis: null,
				yAxis: null,
				series: null
			},
			chart: null,
			resizeObserver: null,
		};
	},
	created() {
		if (this.dataOption) {
			this.option = this.dataOption;
		}
		// 自动加载数据
		if (this.dataAutoLoad) {
			this.loadData();
		}
	},
	computed: {
		id() {
			if (this.dataId) {
				return this.idPrefix + this.dataId;
			}
			return this.idPrefix + this.$Tools.uuid();
		},
		chartsStyle() {
			return {
				width: `${this.dataWidth}`,
				height: `${this.dataHeight}`,
				'min-height': `${this.dataMinHeight}`
			};
		}
	},
	mounted() {
		// 添加监听事件
		this.resizeObserver = new ResizeObserver(this.$Tools.debounce(this.resizeChart), 250)
		this.resizeObserver.observe(this.$el)
	},
	methods: {
		/**
		 * 加载图表数据
		 */
		loadData() {
			// 调用接口
			if (this.dataUrl || this.dataServerName && this.dataAction) {
				let params = {};

				if (this.dataParams) {
					params = Object.assign({}, this.dataParams);
				}
				if (this.dataParamsList) {
					params.dataParamsList = JSON.stringify(this.dataParamsList);
				}

				this.httpUtil
					.ajax({
						url: this.dataUrl ? this.dataUrl : `server/form/${this.dataServerName}/chart/commQuery.json`,
						params: {
							...params,
							chartType: this.dataChartType,
							action: this.dataAction,
							xAxisType: this.dataXAxisType,
							dataKey: this.dataKey
						}
					})
					.then(res => {
						this.handleBackendData(res);

						// 通过切入API，更改option数据
						if (this.dataHandler) {
							let resOption = this.dataHandler(this.option);
							if (resOption) {
								this.option = resOption;
							}
						}

						// 刷新图表
						this.refreshChart();
					});
			} else {
				// 通过切入API，更改option数据
				if (this.dataHandler) {
					let resOption = this.dataHandler(this.option);
					if (resOption) {
						this.option = resOption;
					}
				}

				// 刷新图表
				this.refreshChart();
			}
		},
		/**
		 * 处理后台接口响应数据
		 */
		handleBackendData(res) {
			try {
				if (res && res.returndata) {
					let data = res.returndata;
					let chartTypes = this.dataChartType.split(',');

					// xAxis数据处理
					if (data.xAxis) {
						if (!this.option.xAxis) {
							this.option.xAxis = [];
						}
						for (let i = 0; i < data.xAxis.length; i++) {
							const xAxisitem = data.xAxis[i];
							if (!xAxisitem) {
								continue;
							}
							if (this.option.xAxis[i]) {
								this.option.xAxis[i].data = xAxisitem.data;
								continue;
							}
							if (Object.prototype.toString.call(this.option.xAxis) === '[object Object]') this.option.xAxis = [];
							this.option.xAxis.push({
								type: 'category',
								data: xAxisitem.data
							});
						}
					}
					// series 数据处理
					if (data.series) {
						if (!this.option.series) {
							this.option.series = [];
						}
						for (let i = 0; i < data.series.length; i++) {
							const seriesitem = data.series[i];
							if (!seriesitem) {
								continue;
							}
							if (this.option.series[i]) {
								this.option.series[i].type = seriesitem.type;
								this.option.series[i].data = seriesitem.data;
								continue;
							}
							let currentChartType = chartTypes[0];
							if (chartTypes.length != 1) {
								currentChartType = chartTypes[i];
							}
							this.option.series.push({
								type: currentChartType,
								data: seriesitem.data
							});
						}
					}
				}
			} catch (error) {
				console.error('处理后台接口图表数据出错', error);
			}
		},
		/**
		 * 更新图表数据
		 */
		refreshChart() {
			this.$nextTick(() => {
				let dom = document.getElementById(this.id)
				if (!dom) {
					return
				}
				this.chart = this.$echarts.init(dom, this.dataTheme)
				this.chart.setOption(this.option, { notMerge: true })
			});
		},
		/**
		 * 根据浏览器宽度变化，动态渲染图表（防抖）
		 */
		resizeChart() {
			this.chart?.resize()
		}
	}
};
</script>

<style lang="scss" scoped></style>
