<template>
  <div class="k-sidebar" :data-color="activeColor" :data-image="backgroundImage" :data-background-color="backgroundColor"
       :style="sidebarStyle">
    <div class="logo">
      <a href="javascript:void(0)" class="simple-text logo-mini" >
        <div class="logo-img">
          <img src="../../assets/img/logo.png" alt=""></img>
        </div>
      </a>
      <a href="javascript:void(0)" class="simple-text logo-normal">
        <template>{{ title }}</template>
      </a>
      <div class="navbar-minimize">
        <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </md-button>
      </div>
    </div>

<!--    <div class="sidebar-fg">-->
<!--       <div class="sidebar-fg2">-->

<!--       </div>-->
<!--    </div>-->

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
         <div class="left-menu">
               <div style="height: 10px;border-bottom-right-radius: 3px;">

               </div>
               <div v-for="(m1,index) in menus" class="item" :key="index" :class="{'item-selected':currentMenu1Index==index}" @click="clickMenuItem(m1,index)">
                 <div class="item-content">
                   <svg-icon :iconClass='m1.icon'></svg-icon>
<!--                   <md-icon :md-src="'static/svg/'+m1.icon+'.svg'"></md-icon>-->
                   <span>{{m1.shortname}}</span>
                 </div>
                 <div class="fg"></div>
               </div>
         </div>

         <div class="right-menu">
            <div class="m1-menuname">
                {{m1.menuname}}
            </div>
            <ul class="nav">
               <li class="nav-li" v-for="(m2,index) in menus2" :key="index" v-show="m2.upperid==m1.menuid" :class="{'nav-li-active':m2.active,'nav-li-unactive':!m2.active}">
                  <component class="nav-link"  :is="elementType(m2)" :class="{'nav-link-active':m2.active,'nav-li-unactive':!m2.active,'route-active':isActive(m2),'children-route-active':isChildrenActive(m2)}" :to="'/main/'+m2.url" :href="m2.url?'#/main/'+m2.url:'javascript:void(0);'" @click="clickMenu2Item(m2,index)">
                      <template>
                        <md-icon v-if="m2.icon" :md-src="'/static/svg/'+m2.icon+'.svg'"></md-icon>
                        <span class="menuname">{{m2.menuname}}</span>
                        <img v-show="m2.children&&!m2.url&&backgroundColor=='black'" class="cert" src="../../assets/svg/k-menu/down.svg"/>
                        <img v-show="m2.children&&!m2.url&&backgroundColor=='red'" class="cert" src="../../assets/svg/k-menu/down.svg"/>
                        <img v-show="m2.children&&!m2.url&&backgroundColor=='white'" class="cert" src="../../assets/svg/k-menu/menu-down-black.svg"/>
                      </template>
                  </component>

                  <div class="nav-li-menu">
                    <ul class="nav-li-menu-nav" :style="setStyle(index,m2)">
                      <li v-for="(m3, indexM3) in m2.children" class="nav-li-menu-nav-li" :key="indexM3">
                        <component class="nav-li-menu-nav-link" :class="{'route-active':isActive(m3)}"  :is="elementType(m3)" :to="'/main/'+m3.url" :href="m3.url?'#/main/'+m3.url:'javascript:void(0);'" @click="clickMenu3Item(m3,indexM3)">
                          <template>
                            <span class="menuname">{{m3.menuname}}</span>
                            <img v-if="m3.children&&!m3.url" class="cert" src="../../assets/svg/k-menu/down.svg"/>
                          </template>
                        </component>
                        <div class="nav-li-menu">
                          <ul class="nav-li-menu-nav" :style="setStyle(indexM3,m3)">
                            <li v-for="(m4, indexM4) in m3.children" class="nav-li-menu-nav-li" :key="indexM4">
                              <component class="nav-li-menu-nav-link_4" :class="{'route-active':isActive(m4)}"  :is="elementType(m4)" :to="'/main/'+m4.url" :href="m4.url?'#/main/'+m4.url:'javascript:void(0);'">
                                <template>
                                  <span class="menuname">{{m4.menuname}}</span>
                                  <img v-if="m4.children&&!m4.url" class="cert" src="../../assets/svg/k-menu/down.svg"/>
                                </template>
                              </component>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
               </li>

            </ul>
         </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "k-sider",
    props: {
      title: {
        type: String
      },
      activeColor: {
        type: String,
        default: "green",
        validator: value => {
          let acceptedValues = [
            "",
            "purple",
            "azure",
            "green",
            "orange",
            "danger",
            "rose"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      backgroundImage: {
        type: String,
        default: "./img/sidebar-2.jpg"
      },
      backgroundColor: {
        type: String,
        default: "black",
        validator: value => {
          let acceptedValues = ["", "black", "white", "red"];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      logo: {
        type: String,
        default: "./../assets/img/menuLogo.png"
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      },
      menus : {
        type: Array,
        default: () => []
      },
    },
    computed: {
      sidebarStyle() {
        return {
          backgroundImage: `url(${this.backgroundImage})`,
        };
      },
      setStyle(){
        return function (index,m2) {
              if(m2.active){
                return {
                   maxHeight: m2.children?m2.children.length*60+200+"px":0
                }
              }else{
                return {
                  maxHeight: 0
                }
              }

        }
      },
      menus2(){
        let temp=[]
        this.menus.forEach((item,index)=>{
          if(item.children){
            temp=temp.concat(item.children)
          }
        })
        return temp
      },
      menus3(){
        let temp=[]
        this.menus2.forEach((item,index)=>{
          if(item.children){
            temp=temp.concat(item.children)
          }
        })
        return temp
      },
    },
    provide() {
      return {
        autoClose: this.autoClose,
      };
    },
    data(){
      return{
        currentMenu1Index:-1,
        currentMenu2Index:-1,
        m1:{

        },
      }
    },
    watch:{
      menus(newVal){
        let a=false
        newVal.forEach((item,index)=>{
          // if(item.children){
          //   this.menus2=this.menus2.concat(item.children)
          // }
          //设置默认选中的一级菜单
          if(item.menuname==localStorage.getItem("defaultLevel1Menu")){
            this.clickMenuItem(item,index)
            a=true
          }
        })


        if(!a){
          this.clickMenuItem(newVal[0],0)
        }

      }
    },
    created() {

    },
    methods: {
      isChildrenActive(m2){
        let a=false
          if(m2.children){
            m2.children.forEach((item)=>{
              if(item.url&&this.$route.path.startsWith('/main/' + item.url)){
                a=true
              }
            })
          }
          return a
      },
      isActive(m3) {

        if(m3.url&&this.$route.path.startsWith('/main/' + m3.url)){
          return true
        }else{
          return false
        }

        return false;
      },
      elementType(m2, isParent = true) {
        if (m2.url) {
          return "router-link";
        } else {
          return "a";
        }
      },
      clickMenuItem(m1,index){
          this.currentMenu2Index=-1
          this.currentMenu1Index=index
          this.m1=m1
          this.$nextTick(()=>{
            this.$emit("reinitScrollbar")
          })
          // this.menus2=m1.children
      },
      clickMenu2Item(m2,index){
        this.menus2.forEach((item,index2)=>{
              if(index2==index){
                if(m2.active){
                  this.$set(this.menus2[index],"active",false)
                }else{
                  this.$set(this.menus2[index],"active",true)
                }
              }else{
                if(item.upperid==this.m1.menuid){
                  this.$set(this.menus2[index2],"active",false)
                }
              }
        })
        // this.currentMenu2Index=(this.currentMenu2Index==index)?-1:index
      },
      clickMenu3Item(m3,index){
        this.menus3.forEach((item,index3)=>{
          if(index3==index){
            if(m3.active){
              this.$set(this.menus3[index],"active",false)
            }else{
              this.$set(this.menus3[index],"active",true)
            }
          }else{
            if(item.upperid==this.menus2.menuid){
              this.$set(this.menus3[index3],"active",false)
            }
          }
        })
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
<style lang="scss" scoped>

</style>
