const state = () => ({
	strategyId: '',
	instanceId: ''
});

// getters
const getters = {
	getStrategyId(state) {
		return state.strategyId;
	},
	getInstanceId(state) {
		return state.instanceId;
	}
};

// actions
const actions = {};

// mutations
const mutations = {
	setStrategyId(state, strategyId) {
		state.strategyId = strategyId;
	},
	setInstanceId(state, instanceId) {
		state.instanceId = instanceId;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
