<template>
  <el-time-picker ref="elTimePicker" :value="value" :disabled="dataDisabled === true || dataDisabled === 'true'" @input="handleInput"
                  @change="handleChange" @blur="handleBlur" @focus="handleFocus" :value-format="dataValueFormat" :format="dataDateFormat"
                  :picker-options="{selectableRange: cSelectableRange}" :placeholder="dataPlaceholder" :is-range="dataIsRange"/>
</template>

<script>
  import props from "@/components/k-element/common/k-field-props.js";
  import event from "@/components/k-element/common/k-field-event.js";
  import emitter from "@/components/k-element/common/k-emitter.js";


  export default {
    name: 'KFieldTime',
    mixins: [props(), event(), emitter()],
    props: {
      dataMinValue: {
        type: String,
      },
      dataMaxValue: {
        type: String,
      },
      dataDateFormat: {
        type: String,
        default: 'HH:mm:ss'
      },
      dataValueFormat: {
        type: String,
        default: 'HHmmss'
      },
      dataValidate: {
        type: Function
      },
      dataPlaceholder: {
        type: String
      },
      dataIsRange:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      cSelectableRange() {
        let dataMinValue = this.dataMinValue

        let minDate
        if (!dataMinValue) {
          minDate = new Date(2020,1,0,0,0, 0)
        } else if (dataMinValue.startsWith('(')) {
          minDate = new Date(this.formatDate(dataMinValue, 1).getTime() + 1000)
        } else {
          minDate = new Date(this.formatDate(dataMinValue, 0))
        }

        let dataMaxValue = this.dataMaxValue;
        let maxDate
        if (!dataMaxValue) {
          maxDate = new Date(2020,1,1,23,59,59)
        }else if (dataMaxValue.endsWith(')')) {
          maxDate = new Date(this.formatDate(dataMaxValue, 0).getTime() - 1000)
        } else {
          maxDate = new Date(this.formatDate(dataMaxValue, 0).getTime())
        }
        return minDate.getHours() + ":" + minDate.getMinutes() + ":" + minDate.getSeconds()
          + ' - ' +  maxDate.getHours() + ":" + maxDate.getMinutes() + ":" + maxDate.getSeconds()
      }
    },
    methods: {
      focus(){
        this.$refs.elTimePicker.focus()
      },
      doReset() {
        this.value = ''
      },
      formatDate(value, startIndex) {
        let time = value.substring(startIndex);
        let hour = this.getTime(time, 'H')
        if (hour == -1) {
          hour = this.getTime(time, 'h')
        }
        let minute = this.getTime(time, 'm')
        if (minute == -1) {
          minute = this.getTime(time, 'M')
        }
        let second = this.getTime(time, 's')
        if (second == -1) {
          second = this.getTime(time, 'S')
        }
        let date = new Date();
        date.setHours(hour)
        date.setMinutes(minute)
        date.setSeconds(second)
        return date
      },
      getTime(value, str){
        const dataValueFormat = this.dataValueFormat;
        let first = dataValueFormat.indexOf(str)
        if (first == -1) {
          return -1;
        }
        let lastIndex = dataValueFormat.lastIndexOf(str)

        let length
        if (first == lastIndex) {
          length = 1
        } else {
          length = 2
        }

        return value.substr(first, length)
      }
    },
    watch: {
      value(newVal) {
        this.value = newVal;
      },
      dataMaxValue: function (newVal) {
        // 当最大日期大于当前时间，清空当前时间
        if (this.value && newVal) {
          if (newVal < this.value) {
            this.doReset();
          }
        }
      },
      dataMinValue: function (newVal) {
        // 当最小日期小于当前时间，清空当前时间
        if (this.value && newVal) {
          let minTime = newVal;
          if (newVal.startsWith('(')) {
            minTime = minTime.substring(1);
          }
          if (minTime > this.value) {
            this.doReset();
          }
        }
      }
    }
  };
</script>
