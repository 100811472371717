let flowableFormInfoRegisterPlugin = {};

import roleForm from '@/pages/flowable/test/roleForm.vue';
import stratrgyReleaseInfo from '@/pages/flowable/busiComponents/stratrgyReleaseInfo.vue';

const components = [roleForm,stratrgyReleaseInfo];

flowableFormInfoRegisterPlugin.install = function (Vue, options) {
	//注册全局组件
	components.forEach(component => {
		Vue.component(component.name, component);
	});
};

export default flowableFormInfoRegisterPlugin;
