import { render, staticRenderFns } from "./k-btn.vue?vue&type=template&id=934913ca"
import script from "./k-btn.vue?vue&type=script&lang=js"
export * from "./k-btn.vue?vue&type=script&lang=js"
import style0 from "./k-btn.vue?vue&type=style&index=0&id=934913ca&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports