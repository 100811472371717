const state = () => ({
    PROD: '',
    CUST: '',
    FINA: '',
    FUND: '',
    INSU: '',
    PMTL: '',
    BALA: '',
    IIAP: '',
    PLAN: ''
})

const mutations = {
    changeDate(state, value) {
        state[value[0]] = value[1]
    }
}

export default {
    namespaced: true,
    state,
    mutations
}