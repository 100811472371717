<template>
  <svg :class="svgClass" aria-hidden="true" :width="width" :height="height" :fill="fill" :stroke="stroke">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
  export default {
    name: 'svg-icon',
    props: {
      iconClass: {
        type: String,
        default: ""
      },
      className: {
        type: String
      },
      width: {
        type: String,
        default: '20px'
      },
      height: {
        type: String,
        default: '20px'
      },
      fill: {
        type: String,
        default: '#ffffff'
      },
      stroke: {
        type: String,
        default: '#000000'
      }
    },
    computed: {
      iconName () {
        return `#icon-${this.iconClass}`
      },
      svgClass () {
        if (this.className) {
          return 'svg-icon ' + this.className
        } else {
          return 'svg-icon'
        }
      }
    }
  }
</script>

<style scoped>
  .svg-icon {
    /* width: 20px;
    height: 20px;
    fill: #ffffff; */
    overflow: hidden;
  }
</style>
