<template>
  <div>
    <k-form ref="form" :data-col="2" data-ui="element">
      <k-form-item label="角色-表单信息组件">
        <k-field-text v-model="submitData.rolename" :data-allowblank="false" :data-max-length="30" />
      </k-form-item>
      <k-form-item label="角色描述">
        <k-field-text v-model="submitData.descript" :data-max-length="128" />
      </k-form-item>
    </k-form>
  </div>
</template>
  
  <script>
export default {
	name: "roleForm",
	props: ["submitData"],
	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>
  