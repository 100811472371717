<template>
  <div class="top-tab" id="top-tab" :data-color="activeColor">
    <div class="tab" id="tab">
      <div class="item" :class="{'item-selected':item.active}" v-for="(item,index) in tab"
           @click="clickTab(item,index)" @mouseover="showTabTip(item.name)" @mouseout="hideTabTip"
           @contextmenu="(e)=>{contextmenuClick(item,e)}" :key="index">

        <svg-icon v-if="item.icon" style="margin: 0px 4px;" :icon-class="item.icon" fill="none" :stroke="item.active?'#FFFFFF':'#606266'" width="16px" height="16px"></svg-icon>
        <svg-icon v-else-if="!item.icon && item.parentIcon" style="margin: 0px 4px;" :icon-class="item.parentIcon" fill="none" :stroke="item.active?'#FFFFFF':'#606266'" width="16px" height="16px"></svg-icon>
        <svg-icon v-else style="margin: 0px 4px;" icon-class="icon-tag" fill="none" :stroke="item.active?'#FFFFFF':'#606266'" width="16px" height="16px"></svg-icon>
        <!-- <md-icon v-if="item.icon" :md-src="'static/svg/'+item.icon+'.svg'"></md-icon>
        <md-icon v-if="!item.icon && item.parentIcon" :md-src="'static/svg/'+item.parentIcon+'.svg'"></md-icon> -->
        <!--        <el-tooltip :visible-arrow="false" popper-class="k-tab-popper" :content="item.name" placement="bottom" effect="light">-->
        <!--          <span class="item-name"> {{item.name}}</span>-->
        <!--        </el-tooltip>-->
        <span class="item-name"> {{item.name}}</span>
        <div class="close" v-if="index!=0" @click.stop="removeTab(item,index)">×</div>
      </div>
      <div class="item-tip" v-show="mousePosition.show&&a" :style="mousePosition.style"><span>{{mousePosition.showText}}</span></div>
    </div>
    <div class="down" id="down">
      <el-popover
        width="164"
        trigger="click"
        style="padding: 0;"
        popper-class="top-more-menu"
        :visible-arrow="false"
        v-model="showTab2"
      >
        <ul class="more">
          <li @click="closeCurrentTab">关闭当前选项</li>
          <li @click="closeAllTab">关闭所有选项</li>
          <li @click="closeOtherTab">关闭其他选项</li>
          <li class="fg" v-show="this.tab2.length>0"></li>
          <li v-for="(item,index) in tab2" @click="clickTab2(item,index)" :class="{'route-active':isActive(item)}">
            <span class="gou">√</span>
            <span class="name">{{item.name}}</span>
            <span class="close" @click.stop="removeTab2(item,index)">×</span>
          </li>
        </ul>
        <img slot="reference" src="../../assets/svg/k-menu/down2.svg"/>
      </el-popover>
    </div>
  </div>
</template>

<script>
  import { isChildRouter, emitSubChangeKeepAlive } from "@/micro-app/utils"
  import WujieVue from 'wujie-vue2'

  export default {
    name: "TopTab",
    props: {
      activeColor: {
        type: String,
        default: "green",
      }
    },
    data() {
      return {
        currentIndex: 0,
        showTab2: false,
        showClose: false,
        allMenus: [],
        mousePosition:{
          time:0,
          show:false,
          showText:"",
          style:{
            left:"0px",
            top:"0px"
          },
        },
        a:false,
        timer:null,
        tabState:{
          oldTime:0,
          time:0
        }
      }
    },
    computed: {
      tab() {
        let tabs;
        let data = this.$store.state.system.tab;
        tabs = typeof data === 'string' ? JSON.parse(data) : data;
        tabs[0]['icon'] = "icon-cockpit"
        return tabs;
      },
      tab2() {
        let tabs;
        let data = this.$store.state.system.tab2;
        tabs = typeof data === 'string' ? JSON.parse(data) : data;
        return tabs;
      }
    },
    watch: {
      tab2(newVal) {
        if (newVal.length == 0) {
          // this.showTab2=false
        }
      },
      //监听路由的变化来实现添加或切换tab
      '$route': function (to, oldTo) {
        let flag = false;
        for (let i = 0; i < this.tab.length; i++) {
          if (this.tab[i].path === to.path) {
            flag = true;
            this.$store.commit("system/setTabActive", {index: i, active: true, name: to.query.tabName});
          } else {
            this.$store.commit("system/setTabActive", {index: i, active: false});
          }
        }

        for (let i = 0; i < this.tab2.length; i++) {
          if (this.tab2[i].path === to.path) {
            flag = true;
            this.$store.commit("system/setTab2Active", {index: i, active: true, name: to.query.tabName});
          } else {
            this.$store.commit("system/setTab2Active", {index: i, active: false});
          }
        }

        if (!flag) {
          let data = {
            path: to.path,
            name: to.name,
            query: to.query,
            active: true,
            meta: to.meta
          };


          //查找标签icon
          this.allMenus.some((item) => {
            if (item.url && to.path.indexOf(item.url) > -1) {
              data['icon'] = item.icon
              if (!to.meta.useRouterName) {
                data['name'] = item.menuname
              }
              if (to.query.tabName) {
                data['name'] = to.query.tabName
              }
              data['parentIcon']=item['parentIcon']
              return true
            }
          })

          if(isChildRouter(data.name)) {
            data.name = this.$store.state.system.childRouterMap[to.path]
          }

          if (to.meta.notKeepAlive && to.meta.notKeepAlive == true) {
            this.setExInclude(data, 1)
          } else {
            this.setExInclude(data, 2)
          }
          this.$store.commit("system/setTabPush", data);
          this.$nextTick(() => {
            this.setTopTab()
          })
        }
      },
    },
    created() {
      this.httpUtil.query({
        url: "sys/findMenus.json"
      }).then(data => {
        this.setAllMenus(data.rows)
      });
      this.subCloseTab()
    },
    mounted() {
      window.onresize = () => {
        this.setTopTab()
      }
      this.mouseFlow()
    },
    methods: {
      subCloseTab() {
        WujieVue.bus.$on("sub-close-open-tab", (name, path) => {
          this.closeCurrentTab()
        })
      },
      mouseFlow() {
        // 鼠标跟随tip
        // var sgTip = document.querySelector("sg-tip");
        document.onmousemove = (e)=>{
          if(this.mousePosition.show){
            this.mousePosition.style.left = e.pageX+10+"px"
            this.mousePosition.style.top = e.pageY+25+"px"
            this.mousePosition.time=new Date().getTime()
          }
        };
      },
      showTabTip(name){
        this.mousePosition.showText=name
        if(this.mousePosition.show==false){
          this.mousePosition.show=true
          setTimeout(()=>{
            this.timer=setInterval(()=>{
              if((new Date().getTime()-this.mousePosition.time)<800){
                this.a=false
              }else{
                this.a=true
              }
            },200)
          },500)
        }else{

        }

      },
      hideTabTip(){
        this.mousePosition.show=false
        clearInterval(this.timer)
        this.a=false
      },
      setAllMenus(arr,parentIcon) {
        arr.forEach((item) => {
          if(parentIcon){
            item["parentIcon"]=parentIcon
          }
          this.allMenus.push(item)
          if (item.children) {
            this.setAllMenus(item.children,item.icon)
          }
        })
      },
      isActive(item) {
        if (item.path == this.$route.path && JSON.stringify(item.query) == JSON.stringify(this.$route.query)) {
          return true
        } else {
          return false
        }
      },
      dblclickTab(item){
        if(item.active){
          this.tabState.flag=false
          console.log(2)
        }else{
          console.log(1)
          this.tabState.flag=true
        }
      },
      clickTab(tab,index) {
        if(tab.active){
          this.tabState.oldTime=this.tabState.time
          this.tabState.time=new Date().getTime()
          if((this.tabState.time-this.tabState.oldTime)<400&&index!=0){
            this.removeTab(tab,index)
          }else{

          }
          return
        }
        this.$router.push(
          {
            path: tab.path,
            query: tab.query
          }
        );
      },
      clickTab2(tab,index) {
        this.$store.commit("system/setTab2Splice", {index: index, count: 1});
        this.$store.commit("system/setTabPush", tab);
        this.$nextTick(()=>{
          this.setTopTab()
        })
        this.$router.push(
          {
            path: tab.path,
            query: tab.query
          }
        );
        this.showTab2=false
      },
      closeCurrentTab() {
        if (this.tab.length == 1) {
          return
        }

        this.tab.forEach((item, index) => {
          if (item.active) {
            this.removeTab(item, index)
          }
        })

        this.tab2.forEach((item, index) => {
          if (item.active) {
            this.removeTab2(item, index)
          }
        })
        this.showTab2=false
      },
      closeAllTab() {
        if (this.tab.length == 1) {
          return
        }

        const allTabPaths = []

        this.tab.forEach((item, index) => {
          if (index != 0) {
            allTabPaths.push(item.path)
            this.setExInclude(item, 1)
          }
        })

        this.tab2.forEach((item, index) => {
          allTabPaths.push(item.path)
          this.setExInclude(item, 1)
        })

        // 通知子应用删除该路由keep-alive（保活模式下生效）
        emitSubChangeKeepAlive(allTabPaths)

        let newArr = []
        newArr.push(this.tab[0])
        this.$store.commit("system/setTab", newArr);
        this.$store.commit("system/setTab2", []);
        this.$router.push(
          {
            path: this.tab[0].path,
            query: this.tab[0].query
          }
        );
        this.showTab2 = false
      },
      closeOtherTab() {
        if (this.tab.length == 1) {
          return
        }

        const allTabPaths = []

        let activeIndex = -1
        let istabIndex = true
        this.tab.forEach((item, index) => {
          if (item.active) {
            activeIndex = index
            istabIndex = true
          }

          if (index != 0 || item.active != true) {
            allTabPaths.push(item.path)
            this.setExInclude(item, 1)
          }
        })

        this.tab2.forEach((item, index) => {
          if (item.active) {
            activeIndex = index
            istabIndex = false
          }

          if (item.active != true) {
            allTabPaths.push(item.path)
            this.setExInclude(item, 1)
          }

        })

        // 通知子应用删除该路由keep-alive（保活模式下生效）
        emitSubChangeKeepAlive(allTabPaths)

        let newArr = []
        newArr.push(this.tab[0])
        if (istabIndex) {
          newArr.push(this.tab[activeIndex])
        } else {
          newArr.push(this.tab2[activeIndex])
        }

        this.$store.commit("system/setTab", newArr);
        this.$store.commit("system/setTab2", []);
        this.showTab2 = false
      },
      contextmenuClick(item,e){
        e.preventDefault();
        this.$emit('contextmenuClick',item,e);
      },
      closeTop(closeName, item){
        switch (closeName) {
          case 'now'://当前
            this.closeNowTab(item);
            break;
          case 'rests'://其他
            this.closeRestsTab(item);
            break;
          case 'offleft'://左侧
            this.closeLRTab(item, 'left');
            break;
          case 'offright'://右侧
          this.closeLRTab(item, 'right');
            break;
          case 'all'://全部
            this.closeAllTab();
            break;
        }
      },
      closeNowTab(clickItem) {
        if (this.tab.length == 1) {
          return
        }

        this.tab.forEach((item, index) => {
          if (item.path == clickItem.path) {
            this.removeTab(item, index)
          }
        })

        this.tab2.forEach((item, index) => {
          if (item.path == clickItem.path) {
            this.removeTab2(item, index)
          }
        })
        this.showTab2=false
      },
      closeRestsTab(clickItem) {
        if (this.tab.length == 1) {
          return
        }

        let activeIndex = -1
        let istabIndex = true
        this.tab.forEach((item, index) => {
          if (item.path == clickItem.path) {
            activeIndex = index
            istabIndex = true
            item.active = true
            this.$router.push(
            {
              path: item.path,
              query: item.query
            }
            );
          }

          if (index != 0 || item.path != clickItem.path) {
            this.setExInclude(item, 1)
          }
        })

        this.tab2.forEach((item, index) => {
          if (item.active) {
            activeIndex = index
            istabIndex = false
          }

          if (item.path != clickItem,path) {
            this.setExInclude(item, 1)
          }

        })

        let newArr = []
        newArr.push(this.tab[0])
        if (istabIndex) {
          newArr.push(this.tab[activeIndex])
        } else {
          newArr.push(this.tab2[activeIndex])
        }

        this.$store.commit("system/setTab", newArr);
        this.$store.commit("system/setTab2", []);
        this.showTab2 = false
      },
      closeLRTab(clickItem, direction){
        let offAll = [];
        let offAll2 = [];
        let oldTab = JSON.parse(JSON.stringify(this.tab));
        oldTab = oldTab.slice(1);
        let oldTab2 = JSON.parse(JSON.stringify(this.tab2));
        oldTab2 = oldTab2.slice(1);
        switch (direction) {
          case 'left':
          //去除第一个元素
            const offTab = oldTab.findIndex(item => item.path == clickItem.path);
            if(offTab > 0) {
              offAll = oldTab.filter((_, index) => index < offTab);
            }
            const offTab2 = oldTab2.findIndex(item => item.path == clickItem.path);
            if(offTab2 > 0) {
              offAll2 = oldTab2.filter((_, index) => index < offTab2);
            }
            break;
          case 'right':
            const offTabR = oldTab.findIndex(item => item.path == clickItem.path);
            if(offTabR > -1) {
              if(oldTab[offTabR + 1]){
                offAll = oldTab.slice(offTabR + 1);
              }
            }
            const offTab2R = oldTab2.findIndex(item => item.path == clickItem.path);
            if(offTab2R > -1) {
              if(oldTab2[offTab2R + 1]){
                offAll2 = oldTab2.slice(offTab2R + 1);
              }
            }
            break;
        }

        if (this.tab.length == 1 || offAll.length < 0) {
          return
        }
        let newArr = [];
        let newArr2 = [];
        this.tab.forEach((item, index) => {
          let isOFF = offAll.find(off => off.path == item.path);
          if (isOFF) {
            this.setExInclude(item, 1);
          } else {
            newArr.push(item);
          }
        })
        this.tab2.forEach((item, index) => {
          let isOFF2 = offAll2.find(off2 => off2.path == item.path);
          if (isOFF2) {
            this.setExInclude(item, 1);
          } else {
            newArr2.push(item);
          }
        })
        this.$store.commit("system/setTab", newArr);
        this.$store.commit("system/setTab2", newArr2);
        this.showTab2=false
        this.$router.push(
          {
            path: clickItem.path,
            query: clickItem.query
          }
        );
      },
      removeTab(tab, index) {
        this.setExInclude(tab, 1)
        this.$store.commit("system/setTabSplice", {index: index, count: 1});
        if (tab.active) {
          this.$router.push(
            {
              path: this.tab[index - 1].path,
              query: this.tab[index - 1].query
            }
          );
        }

        if (this.tab2.length > 0) {
          let deleteIndex=this.tab2.length-1
          this.$store.commit("system/setTabPush", this.tab2[deleteIndex]);
          this.$store.commit("system/setTab2Splice", {index: deleteIndex, count: 1});
        }

        // 通知子应用删除该路由keep-alive（保活模式下生效）
        emitSubChangeKeepAlive([tab.path])
      },
      removeTab2(tab, index) {
        this.setExInclude(tab, 1)
        this.$store.commit("system/setTab2Splice", {index: index, count: 1});
        if (tab.active) {
          if (index != 0) {
            this.$router.push(
              {
                path: this.tab2[index - 1].path,
                query: this.tab2[index - 1].query
              }
            )
          } else {
            this.$router.push(
              {
                path: this.tab[this.tab.length - 1].path,
                query: this.tab[this.tab.length - 1].query
              }
            )
          }
        }

        // 通知子应用删除该路由keep-alive（保活模式下生效）
        emitSubChangeKeepAlive([tab.path])
      },
      setExInclude(tab, type) {
        if (tab.meta && tab.meta.componentName) {
          //设置为不缓存
          if (type == 1) {
            let e = this.$store.state.system.exincludeList
            let a = false
            e.every((item) => {
              if (item == tab.meta.componentName) {
                a = true
                return false
              } else {
                return true
              }
            })
            if (!a) {
              e.push(tab.meta.componentName)
              this.$store.commit("system/setExincludeList", e);
            }
          } else {
            //设置为缓存
            let e = this.$store.state.system.exincludeList
            let newArray = e.filter((item) => {
              return item != tab.meta.componentName
            })
            this.$store.commit("system/setExincludeList", newArray);
          }
        }
      },
      setTopTab(isAdd) {
        let w = document.getElementById("tab").offsetWidth + document.getElementById("down").offsetWidth + 20
        let w2 = document.getElementById("top-tab").offsetWidth
        if (w <= w2) {
          if (this.tab2.length > 0 && (w2 - w) > 174) {
            let index = this.tab2.length - 1
            this.$store.commit("system/setTabPush", this.tab2[index]);
            this.$store.commit("system/setTab2Splice", {index: index, count: 1});
            this.$nextTick(() => {
              this.setTopTab()
            })
          }
        } else {
          let index = this.tab.length - 1
          while (this.tab[index].active) {
            index--;
          }
          if(index==0){
            return
          }
          this.$store.commit("system/setTab2Push", this.tab[index]);
          this.$store.commit("system/setTabSplice", {index: index, count: 1});
          this.$nextTick(() => {
            this.setTopTab()
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "TopTab";
</style>
