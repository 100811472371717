<template>

	<el-button v-if="dataIsText && isShow" type="text" size="medium" @click="handle($event)" :disabled="loading || dataDisabled === true || dataDisabled === 'true'">
		<slot> </slot>
	</el-button>
	<!-- <md-button v-if="dataIsText && isShow" @click="handle($event)" type="text">
		<slot> </slot>
	</md-button> -->

	<el-tooltip v-else-if="dataDescript && isShow" class="item" effect="dark" :content="dataDescript" placement="bottom">
		<md-button @click="handle($event)" :disabled="loading || dataDisabled === true || dataDisabled === 'true'"
			:class="['md-sm', disabledGrey ? 'md-disabled' : '']">
			<i v-show="loading" class="el-icon-loading"></i>
			<slot> </slot>
		</md-button>
	</el-tooltip>

	<!-- <el-button
		v-else-if="dataIsText&&isShow"
		@click="handle($event)"
		:disabled="loading || dataDisabled === true || dataDisabled === 'true'"
		type = "text">
		<slot> </slot>
	</el-button> -->
	<md-button v-else-if="isShow" @click="handle($event)"
		:disabled="loading || dataDisabled === true || dataDisabled === 'true'"
		:class="[disabledGrey ? 'md-disabled' : '', smallSize ? 'md-small-size' : '']">
		<i v-show="loading" class="el-icon-loading"></i>
		<slot> </slot>
	</md-button>
</template>

<script>
import emitter from '@/components/k-element/common/k-emitter.js';
import { debounceFun } from '@/utils/index.js';
import Tools from '@/utils/tools.js';
import auth from '@/utils/auth.js';

export default {
	name: 'KBtn',
	mixins: [emitter()],
	emits: ['getPreviewData'],
	props: {
		dataErrCallback: {
			type: Function
		},
		dataTarget: {
			type: String
		},
		dataParams: {
			type: [Object, String]
		},
		dataIsText: {
			type: Boolean,
			default: false
		},
		dataDisabled: {
			type: [Boolean, String],
			default: false
		},
		dataDisabledGrey: {
			type: Boolean,
			default: true
		},
		dataFunctype: {
			type: String
		},
		dataAction: {
			type: String
		},
		dataGraphql: {
			type: String
		},
		dataUrl: {
			type: String
		},
		dataAfterSuccess: {
			type: Function
		},
		dataHandler: {
			type: Function
		},
		/**
		 * 调用外部Handler方法时，额外传递的参数
		 */
		dataHandlerParams: {
			type: Object,
		},
		dataConfirm: {
			type: [Boolean, String],
			default: false
		},
		dataFrom: {
			type: String
		},
		dataModel: {},
		dataDescript: {
			type: String
		},
		dataValidateForm: {
			type: Boolean,
			default: true
		},
		dataExportName: {
			type: String,
			default: undefined
		},
		dataDownloadName: {
			type: [String, Function]
		},
		exportParams: {
			type: Object,
			default: () => { }
		},
		exportList: {
			type: Array,
			default: function () {
				return [];
			}
		},
		smallSize: {
			type: Boolean,
			default: false
		},
		keepPopup: {
			type: Boolean,
			default: false
		},
		dataExcelTemplate: {
			type: [String],
			default: null
		},
		dataExcelStartLine: {
			type: [Number, String],
			default: 1
		},
		dataExcelStartCol: {
			type: [Number, String],
			default: 0
		},
		dataContentType: {
			type: String,
			default: 'form'
		},
		// 路由跳转时，如果有配置dataCustomPageParams，仅会把dataCustomPageParams参数作为路由参数
		dataCustomPageParams: {
			type: Object,
		},
	},
	data() {
		return {
			form: null, //表单对象
			loading: false,
			oldData: '',
			isShow: true,
			mdSVGLoader: null
		};
	},
	computed: {
		disabledGrey: function () {
			return this.dataDisabledGrey && (this.dataDisabled === true || this.dataDisabled === 'true');
		},
		justIcon: function () {
			return this.$el.className.indexOf('md-simple') > -1 && this.$el.className.indexOf('md-just-icon') > -1;
		}
	},
	created() {
		this.$on('k.form.addForm', form => {
			if (form) {
				this.form = form;
			}
		});
		setTimeout(() => {
			if (this.dataModel) {
				this.oldData = Tools.json2str(this.dataModel);
			}
		}, 300);

		//判断权限是否显示或者隐藏
		if (this.dataAction) {
			this.isShow = auth.check(this.dataAction);
		}
		if (this.dataUrl) {
			this.isShow = auth.check(this.dataUrl);
			if (this.dataUrl.indexOf('wf') !== -1) {
				this.isShow = true;
			}
		}
		if (this.dataFunctype == 'DOWNLOAD' || this.dataFunctype == 'EXPORT' || this.dataFunctype == 'EXPORTPART' || this.dataFunctype == 'PREVIEW') {
			this.isShow = true;
		}
	},
	// updated(){
	// 	this.$nextTick(() => {
	//     	if(this.dataIsText){
	// 			this.$refs.btnRef;
	// 			console.log(this.$refs.btnRef.$el);
	// 			this.$refs.btnRef.$el.className = "text";
	// 			this.$refs.btnRef.$el._prevClass = "text";
	// 		}
	//   	});
	// },
	mounted() {
		// this.$nextTick(() => {
		// 	if (this.dataIsText) {
		// 		this.$refs.btnRef;
		// 		this.$refs.btnRef.$el.className = "text";
		// 		this.$refs.btnRef.$el._prevClass = "text";
		// 	}
		// });
		if (this.disabledGrey) {
			let mdSVGLoader = this.getChildrens('MdSVGLoader', this.$children);
			if (mdSVGLoader && mdSVGLoader.length === 1) {
				this.mdSVGLoader = mdSVGLoader[0];

				this.$watch('mdSVGLoader.html', function (newVal, oldVal) {
					if (newVal && newVal != oldVal) {
						let fill;
						if (this.justIcon) {
							// 置灰
							fill = 'fill="#6c6868"';
						} else {
							// 转为白色
							fill = 'fill="#FFFFFF"';
						}
						this.mdSVGLoader.html = newVal.replace(/fill=[\"\'].*[\"\']/, fill);
					}
				});
			}
		}
	},
	methods: {
		handle($event) {
			// this.$nextTick(() => {
			// 	if (this.dataIsText) {
			// 		this.$refs.btnRef;
			// 		this.$refs.btnRef.$el.className = "text";
			// 		this.$refs.btnRef.$el._prevClass = "text";
			// 	}
			// });
      if(this.disabledGrey) return;

			this.$emit('data-on-click', $event);
			this.$emit('data-on-click-hasEvent', $event);
			let params = {};

			if (this.dataParams && typeof this.dataParams === "string") {
				params = Object.assign(params, Tools.str2Json(this.dataParams));
			}

			if (this.dataParams && Object.prototype.toString.call(this.dataParams) === "[object Object]") {
				params = Object.assign(params, this.dataParams);
			}

			//如果默认表单对象不存在，尝试获取指定表单
			if (!this.form) {
				if (this.dataFrom) {
					this.form = this.getParentRef(this.dataFrom);
				}
			}

			if (this.dataModel) {
				//如果定义data-model，则获取引用对象
				params = Object.assign(params, this.dataModel);
			}

			// 从表格行获取对象
			let kGrid = this.getParent('KGrid');
			if (kGrid) {
				let formerData = kGrid.getRowData($event);
				this.oldData = Tools.json2str(formerData);
				params = Object.assign(params, formerData);
			}

			if (Object.keys(params).length == 0) {
				params = Object.assign(params, this.dataHandlerParams);
			}

			//执行data-hanlder事件
			if (this.dataHandler) {
				let re = this.dataHandler(params, this.dataHandlerParams);
				console.log("xxxx", params)
				if (re === false) {
					//不做任何处理
					return;
				}
			}

			if (this.oldData && this.dataContentType !== 'json') {
				params.oldData = this.oldData;
			}
			var children = $event?.currentTarget.childNodes[0]?.childNodes[0]?.childNodes;
			var iconList = [];
			if (children) {
				for (var i = 0; i < children.length; i++) {
					if (children[i].nodeName == 'I') {
						iconList.push(children[i]);
					}
				}
			}

			if (this.dataFunctype == 'RESET') {
				//重置表单
				if (this.form) {
					this.form.reset();
				}
			} else if (this.dataFunctype == 'PAGE') {
				//打开新页面
				if (!this.dataTarget) {
					console.error('为定义data-target属性');
					return;
				}
				this.$router.push({
					path: this.dataTarget,
					query: this.dataCustomPageParams ? this.dataCustomPageParams : params
				});
			} else if (this.dataFunctype == 'EXPORT') {
				//导出表格
				if (this.dataTarget) {
					let target = this.getParentRef(this.dataTarget);
					//判断dataTarget是不是表格，如果是表格，则刷新表格
					if (target && target.$options.name == 'KGrid') {
						let legalCode = localStorage.getItem('legalCode');
						let queryParams = target.buildQueryParams();
						if (queryParams.hasOwnProperty('start')) {
							delete queryParams['start'];
							delete queryParams['limit'];
						}
						queryParams = Object.assign({}, this.exportParams, { legalCode: legalCode }, queryParams);
						let headers = '';
						let gridColumnOptions = target.gridColumnOptions;
						let unToDict = [];
						gridColumnOptions.forEach(o => {
							console.log(o.dataHeader, o.dataName, o.dataHidden);
							// 若没有头部，没有名称，有隐藏列
							if (!o.dataHeader || !o.dataName || o.dataHidden) {
								return;
							}
							// 后台会根据此处参数来返回下载列表列
							if (!(o.dataName == 'modelId' && this.dataTarget == 'prodInfoGrid')) {
								headers = headers + o.dataHeader + ':';
								headers = headers + o.dataName + ':';
								headers = headers + (o.dataType ? o.dataType : '') + ':';
								headers = headers + (o.dataDict ? o.dataDict : '') + ':';
								headers = headers + ',';
							}

							if (o['dataExportDict'] == 'false' || o['dataExportDict'] == false) {
								unToDict.push(o['dataName']);
							}
						});
						let params = {
							headers: headers,
							action_params: JSON.stringify(queryParams),
							dataList: JSON.stringify(this.exportList),
							action: target.dataAction || target.dataUrl,
							unToDict: unToDict.join(','),
							type: target.dataAction ? 0 : 1
						};
						if (this.dataExcelTemplate) {
							params['dataExcelTemplate'] = this.dataExcelTemplate;
							params['dataExcelStartLine'] = this.dataExcelStartLine;
							params['dataExcelStartCol'] = this.dataExcelStartCol;
						}
						this.setIconStyle(0, iconList);
						this.httpUtil.download(
							{
								url: 'excel/download.json',
								params: params,
								callback: () => {
									this.setIconStyle(1, iconList);
								}
							},
							this.dataExportName
						);
					} else {
						console.error('表格导出需配置操作的表格引用');
					}
				}
			} else if (this.dataFunctype == 'EXPORTPART') {
				//导出表格
				if (this.dataTarget) {
					let target = this.getParentRef(this.dataTarget);
					//判断dataTarget是不是表格
					if (target && target.$options.name == 'KGrid') {
						let headers = '';
						let gridColumnOptions = target.gridColumnOptions;
						let unToDict = [];
						gridColumnOptions.forEach(o => {
							if (!o.dataHeader || !o.dataName) {
								return;
							}
							headers = headers + o.dataHeader + ':';
							headers = headers + o.dataName + ':';
							headers = headers + (o.dataType ? o.dataType : '') + ':';
							headers = headers + (o.dataDict ? o.dataDict : '') + ':';
							headers = headers + ',';
							1;
							if (o['dataExportDict'] == 'false' || o['dataExportDict'] == false) {
								unToDict.push(o['dataName']);
							}
						});
						let params = {
							headers: headers,
							dataList: JSON.stringify(this.exportList),
							action: target.dataAction || target.dataUrl,
							unToDict: unToDict.join(','),
							type: target.dataAction ? 0 : 1
						};
						this.setIconStyle(0, iconList);
						this.httpUtil.download(
							{
								url: 'excel/downloadPart.json',
								params: params,
								callback: () => {
									this.setIconStyle(1, iconList);
								}
							},
							this.dataExportName
						);
					} else {
						console.error('表格导出需配置操作的表格引用');
					}
				}
			} else if (this.dataFunctype == 'DOWNLOAD') {
				//下载文件
				//校验表单
				if ((this.dataValidateForm == true || this.dataValidateForm == 'true') && this.form) {
					let re = this.form.validate();
					if (re === false) {
						return;
					}
				}
				this.setIconStyle(0, iconList);
				this.httpUtil.download(
					{
						url: this.dataUrl,
						params: params,
						callback: () => {
							this.setIconStyle(1, iconList);
						}
					},
					this.dataDownloadName
				);
			} else if (this.dataFunctype == 'POPUP') {
				//弹窗
				setTimeout(() => {
					let target = this.getParentRef(this.dataTarget);
					//判断dataTarget是不是弹窗，如果是弹窗，则关闭弹窗
					if (target && target.$options.name == 'KPopup') {
						target.popup();
					}
				}, 50);
			} else if (this.dataFunctype == 'CLOSE') {
				//关闭弹窗
				let kPopup = this.getParent('KPopup');
				if (kPopup) {
					kPopup.close();
				}
			} else if (this.dataFunctype == 'SUBMIT') {
				//提交

				//校验表单
				if ((this.dataValidateForm == true || this.dataValidateForm == 'true') && this.form) {
					let re = this.form.validate();
					if (re === false) {
						return;
					}
				}
				if (this.dataConfirm === 'true' || this.dataConfirm === true) {
					const h = this.$createElement;
					let _dataDescript = this.dataDescript ? this.dataDescript : '执行该操作';
					this.$confirm('', {
						message: h('div', null, [
							h('div', { style: 'position: absolute;top: -34px;left: -40px;' }, [
								h('i', { class: 'el-icon-menu', style: 'font-size:16px;color: #00a0e9;' }),
								h('span', { style: 'margin-left:20px;' }, '提示')
							]),
							h('p', { style: 'margin-top: 21px;width:100%' }, `确认${_dataDescript}吗?`)
						]),
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						confirmButtonClass: 'md-golden',
						cancelButtonClass: 'md-plain',
						closeOnClickModal: false,
						// customClass:'statusLeft',
						type: 'warning',
						beforeClose: (action, instance, done) => {
							if (action === 'confirm') {
								instance.confirmButtonLoading = true;
								instance.confirmButtonText = '执行中...';
								this.submitHandler(
									params,
									data => {
										if (this.dataTarget) {
											let target = this.getParentRef(this.dataTarget);
											//判断dataTarget是不是表格，如果是表格，则刷新表格
											if (target && target.$options.name == 'KGrid') {
												target.load(target.getCachedParams());
											}
										}
										if (!this.keepPopup) {
											let kPopup = this.getParent('KPopup');
											if (kPopup) {
												kPopup.close();
											}
										}
										done();
										instance.confirmButtonLoading = false;
									},
									() => {
										done();
										instance.confirmButtonLoading = false;
									}
								);
							} else {
								done();
							}
						}
					}).catch(() => { });
				} else {
					this.setIconStyle(0, iconList)
					this.submitHandler(
						params,
						() => {
							this.setIconStyle(1, iconList)
							if (this.dataTarget) {
								let target = this.getParentRef(this.dataTarget);
								//判断dataTarget是不是表格，如果是表格，则刷新表格
								if (target && target.$options.name == 'KGrid') {
									target._load(target.getCachedParams());
								}
							}
							if (!this.keepPopup) {
								let kPopup = this.getParent('KPopup');
								if (kPopup) {
									kPopup.close();
								}
							}
						},
						() => {
							this.setIconStyle(1, iconList)
						}
					);
				}
			} else if (this.dataFunctype === 'PREVIEW') {
				if ((this.dataValidateForm == true || this.dataValidateForm == 'true') && this.form) {
					let re = this.form.validate();
					if (re === false) {
						return;
					}
				}
				this.setIconStyle(0, iconList);
				this.httpUtil
					.download(
						{
							url: this.dataUrl,
							params: params,
							callback: () => {
								this.setIconStyle(1, iconList);
							}
						},
						this.dataDownloadName,
						true
					)
					.then(url => {
						console.log(url);
						// 数据回传
						this.$emit('getPreviewData', url);
						let target = this.getParentRef(this.dataTarget);
						//判断dataTarget是不是弹窗，如果是弹窗，则关闭弹窗
						if (target && target.$options.name == 'KPopup') {
							target.popup();
						}
					});
			}
		},
		//按钮提交操作
		submitHandler: debounceFun(function (params, afterSuccess, callback) {
			if (this.dataAction) {
				//通过Action提交
				this.submitAction(params, afterSuccess, callback);
			} else if (this.dataGraphql) {
				//通过Graphql提交
				this.submitGraphql(params, afterSuccess, callback);
			} else if (this.dataUrl) {
				//通过url提交
				this.submitUrl(params, afterSuccess, callback);
			}
		}, 800),
		submitAction(params, afterSuccess, callback) {
			this.httpUtil
				.comnUpdate({
					action: this.dataAction,
					params: params,
					mask: false,
					callback: callback,
					dataAfterSuccess: this.dataAfterSuccess,
					errCallback: this.dataErrCallback,
					errCode: this.dataErrCode
				})
				.then(data => {
					if (afterSuccess) {
						afterSuccess(data);
					}
				});
		},
		submitGraphql(params, afterSuccess, callback) {
			this.httpUtil
				.graphqlUpdate({
					action: this.dataGraphql,
					params: params,
					mask: false,
					callback: callback,
					dataAfterSuccess: this.dataAfterSuccess
				})
				.then(data => {
					if (afterSuccess) {
						afterSuccess(data);
					}
				});
		},
		submitUrl(params, afterSuccess, callback) {
			if (this.dataContentType === 'json') {
				this.httpUtil
					.ajaxJson({
						url: this.dataUrl,
						params: params,
						mask: false,
						successAlert: true,
						callback: callback,
						dataAfterSuccess: this.dataAfterSuccess
					})
					.then(data => {
						if (afterSuccess) {
							afterSuccess(data);
						}
					});
			} else {
				this.httpUtil
					.update({
						url: this.dataUrl,
						params: params,
						mask: false,
						callback: callback,
						dataAfterSuccess: this.dataAfterSuccess
					})
					.then(data => {
						if (afterSuccess) {
							afterSuccess(data);
						}
					});
			}
		},
		setIconStyle(type, iconList) {
			if (type == 0) {
				this.loading = true;
				if (iconList.length > 1) {
					iconList[1].setAttribute('style', 'display:none;');
				}
			} else {
				this.loading = false;
				if (iconList.length > 1) {
					iconList[1].setAttribute('style', 'display:inline;');
				}
			}
		}
	}
};
</script>

<style lang="scss">
.text {
	color: #1890FF !important;
	border: none !important;
	background-color: transparent !important;
	padding: 6px 5px;
	cursor: pointer;
}

.statusLeft {
	width: auto;
	height: 146px;

	.el-message-box__container {
		margin: 0 64px;
	}

	.el-message-box__btns {
		text-align: center !important;
		margin-top: 3px;

		.el-button:nth-child(1) {
			float: right;
			margin-right: 25%;
		}

		.el-button:nth-child(2) {
			float: left;
			margin-left: 25%;
		}
	}
}
</style>
