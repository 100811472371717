<template>
  <div class="k-form-footer-content" :class="[{
      'k-form-footer-align-left': dataAlign === 'left',
      'k-form-footer-align-center': dataAlign === 'center',
      'k-form-footer-align-right': dataAlign === 'right',
      'k-form-footer-align-default': !dataAlign,
      'k-form-footer_sticky': kForm.dataFooterStickyBottom === true || kForm.dataFooterStickyBottom === 'true'
    }]">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'KFormFooter',

    provide() {
      return {
        kFormItem: this
      };
    },
    inject: ['kForm'],
    props: {
      dataAlign: {
        type: String
      },
    },
    created() {},

    data() {
      return {};
    }

  };
</script>
