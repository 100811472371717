var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"db-design-wrap",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{key:_vm.updateKey,staticClass:"custom-border-box",style:({
      width: _vm.width + 'px',
      opacity: _vm.opacity / 100,
      'background-image': `linear-gradient(to bottom, ${
        _vm.gradientColor0 ? _vm.gradientColor0 : _vm.gradientColor1
      } , ${_vm.gradientColor1 ? _vm.gradientColor1 : _vm.gradientColor0})`
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }