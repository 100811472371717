export default [
	{
		path: 'cdp/portrait-list',
		name: '客群画像',
		component: () => import('@/pages/cdp/portrait/portrait-list.vue')
	},
	{
		path: 'cdp/portrait-add',
		name: '画像创建',
		component: () => import('@/pages/cdp/portrait/portrait-popup.vue')
	},
	{
		path: 'cdp/portrait-edit',
		name: '画像编辑',
		component: () => import('@/pages/cdp/portrait/portrait-popup.vue')
	},
	{
		path: 'cdp/tag-factory',
		name: '标签工厂',
		component: () => import('@/pages/cdp/tag-factory.vue')
	},
	{
		path: 'cdp/crowd-factory',
		name: '分群工厂',
		component: () => import('@/pages/cdp/crowd-factory.vue')
	},
	{
		path: 'cdp/asset-index',
		name: '首页面',
		component: () => import('dashPackages/DashboardRun')
		// component: () => import('@/pages/cdp/asset-index.vue')
	},
	{
		path: 'cdp/rule-crowd-manual',
		name: '规则分群',
		meta: {
			// 设置为true，该页面不会缓存，详情页面一般配置为true
			notKeepAlive: true,
			// 配置组件名称，删除tab后，再次进入页面会刷新缓存
			componentName: 'rule-crowd'
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-manual.vue')
	},
	{
		path: 'cdp/rule-crowd',
		name: '规则分群',
		meta: {
			// 设置为true，该页面不会缓存，详情页面一般配置为true
			notKeepAlive: true,
			// 配置组件名称，删除tab后，再次进入页面会刷新缓存
			componentName: 'rule-crowd'
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd.vue')
	},
	{
		path: 'cdp/rule-crowd-model',
		name: '模型分群',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-model.vue')
	},
	{
		path: 'cdp/rule-crowd-diff',
		name: '分群交并差创建',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-diff.vue')
	},
	{
		path: 'cdp/rule-crowd-split',
		name: '分群拆分创建',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-split.vue')
	},
	{
		path: 'cdp/rule-crowd-split-edit',
		name: '分群拆分编辑',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-split-edit.vue')
	},
	{
		path: 'cdp/rule-crowd-tag-save-edit',
		name: '标签转存',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-tag-save-edit.vue')
	},
	{
		path: 'cdp/rule-crowd-sql',
		name: '客群SQL创建',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/crowd/rule-crowd-sql.vue')
	},
	{
		path: 'cdp/rule-tag-feature',
		name: '首/末次特征标签',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/tag/rule-tag-feature.vue')
	},
	{
		path: 'cdp/rule-tag-rfm',
		name: 'rfm标签',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/tag/rule-tag-rfm.vue')
	},
	{
		path: 'cdp/rule-tag-preference',
		name: '偏好标签',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/tag/rule-tag-preference.vue')
	},
	{
		path: 'cdp/rule-tag-sql',
		name: '标签SQL创建',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/tag/rule-tag-sql.vue')
	},
	{
		path: 'cdp/rule-tag-model',
		name: '模型标签',
		meta: {
			notKeepAlive: true
		},
		component: () => import('@/pages/cdp/tag/rule-tag-model.vue')
	},
	{
		path: 'cdp/rule-tag',
		name: '规则标签',
		meta: {
			notKeepAlive: true,
			componentName: 'rule-tag'
		},
		component: () => import('@/pages/cdp/tag/rule-tag.vue')
	},
	{
		path: 'cdp/manual-tag',
		name: '手工标签',
		meta: {
			notKeepAlive: true,
			componentName: 'manual-tag'
		},
		component: () => import('@/pages/cdp/tag/manual-tag.vue')
	},
	{
		path: 'cdp/his-manual-tag',
		name: '历史手工标签',
		component: () => import('@/pages/cdp/tag/his-manual-tag.vue')
	},
	{
		path: 'cdp/tag-group-list',
		name: '标签分组列表',
		component: () => import('@/pages/cdp/tag/tag-group-list.vue')
	},
	{
		path: 'cdp/crowd-group-list',
		name: '客群分组列表',
		component: () => import('@/pages/cdp/crowd/crowd-group-list.vue')
	},
	{
		path: 'cdp/crowd-info-show',
		name: '客群详情列表',
		component: () => import('@/pages/cdp/crowd/crowd-info-show.vue')
	},
	{
		path: 'cdp/dimension-list',
		name: '维度表',
		component: () => import('@/pages/cdp/dimension/dimension-list.vue')
	},
	{
		path: 'cdp/dimension-detail',
		name: '数据资产详情',
		component: () => import('@/pages/cdp/dimension/dimension-detail.vue')
	},
	{
		path: 'cdp/dimension-detaill-cust',
		name: '数据资产详情',
		component: () => import('@/pages/cdp/dimension/dimension-detaill-cust.vue')
	},
	{
		path: 'cdp/dict-list',
		name: '字典表',
		component: () => import('@/pages/cdp/dict/dict-list.vue')
	},
	{
		path: 'cdp/customer-entity-list',
		name: '客户属性',
		component: () => import('@/pages/cdp/entity/customer-entity-list.vue')
	},
	{
		path: 'cdp/event-entity-list',
		name: '行为事件',
		component: () => import('@/pages/cdp/entity/customer-entity-list.vue')
	},
	{
		path: 'cdp/custom-entity-list',
		name: '自定义实体',
		component: () => import('@/pages/cdp/entity/custom-entity-list.vue')
	},
	{
		path: 'cdp/custom-entity-detail',
		name: '实体详情',
		component: () => import('@/pages/cdp/entity/customer-entity-list.vue')
	},
	{
		path: 'cdp/tag-info-list',
		name: '标签信息列表',
		meta: {
			notKeepAlive: true,
			componentName: 'tag-info-list'
		},
		component: () => import('@/pages/cdp/tag/tag-info-list.vue')
	},
	{
		path: 'cdp/tag-info-show',
		name: '实体资产详情',
		component: () => import('@/pages/cdp/tag/tag-info-show.vue')
	},
	{
		path: 'cdp/crowd-info-list',
		name: '客群信息列表',
		meta: {
			notKeepAlive: true,
			componentName: 'crowd-info-list'
		},
		component: () => import('@/pages/cdp/crowd/crowd-info-list.vue')
	},
	// {
	//   path: "cdp/entity-assets-list",
	//   name: "实体资产",
	//   component: () => import('@/pages/cdp/assets/entity-assets-list.vue')
	// },
	// {
	//   path: "cdp/entity-assets-info-list",
	//   name: "实体资产详情",
	//   component: () => import('@/pages/cdp/assets/entity-assets-info-list.vue')
	// },
	{
		path: 'cdp/edit-entity-list',
		name: '实体数据编辑',
		component: () => import('@/pages/cdp/assets/edit-entity-list')
	},
	{
		path: 'cdp/show-entity-list',
		name: '实体数据展示',
		component: () => import('@/pages/cdp/assets/show-entity-list')
	},
	{
		path: 'cdp/event-source',
		name: '事件源表',
		component: () => import('@/pages/cdp/event/event-source')
	},
	{
		path: 'cdp/entity-add',
		name: '实体新增',
		component: () => import('@/pages/cdp/entity/entity-add')
	},
	{
		path: 'cdp/entity-edit',
		name: '实体编辑',
		component: () => import('@/pages/cdp/entity/entity-edit')
	},
	{
		path: 'cdp/entity-config-data',
		name: '实体编辑',
		component: () => import('@/pages/cdp/entity/entity-config-data')
	},
	{
		path: 'cdp/project_manage',
		name: '项目上报',
		component: () => import('@/pages/cdp/reporting/project_manage')
	},
	{
		path: 'cdp/data-reporting-list',
		name: '数据上报',
		component: () => import('@/pages/cdp/reporting/data-reporting-list')
	},
	{
		path:'cdp/entity-show-list',
		name: '实体数据展示',
		component: () => import('@/pages/cdp/entity/entity-show-list')
	},
	{
		path:'cdp/cdp-model',
		name: '实体数据展示',
		component: () => import('@/pages/cdp/entity/cdp-model')
	}
];
