var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"k-form-footer-content",class:[{
    'k-form-footer-align-left': _vm.dataAlign === 'left',
    'k-form-footer-align-center': _vm.dataAlign === 'center',
    'k-form-footer-align-right': _vm.dataAlign === 'right',
    'k-form-footer-align-default': !_vm.dataAlign,
    'k-form-footer_sticky': _vm.kForm.dataFooterStickyBottom === true || _vm.kForm.dataFooterStickyBottom === 'true'
  }]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }