// 颜色选择器的预设颜色
export const predefineColors =[
  '#007aff',
  '#1aa97b',
  '#ff4d53',
  '#1890FF',
  '#DF0E1B',
  '#0086CC',
  '#2B74CF',
  '#00BC9D',
  '#ED7D32'
]
