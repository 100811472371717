<template>
	<div class="tree_item">
		<template v-if="!data.isFilter">
			<div class="item" v-show="data.isShow">
				<div class="open_children_icon" v-if="data[children]" @click="showChildren(data)" :class="data.isShowChildren ? 'isShowChildren' : ''">
					<i class="el-icon-caret-right"></i>
				</div>
				<div class="checked_box" :class="data[children] ? '' : 'noChildren'">
					<el-checkbox :indeterminate="data.indeterminate" v-model="data.isChecked" @change="change(data, $event)">
						<div class="checked_title" v-html="showFilterVlaue(data[title], data)"></div>
						<div class="code">{{ data[code] }}</div>
					</el-checkbox>
				</div>
			</div>
		</template>
		<template v-if="data[children]">
			<template v-for="item in data[children]">
				<treeItem
					:data="item"
					@showChildren="showChildren"
					:filterValue="filterValue"
					:title="title"
					:code="code"
					:children="children"
					@change="change"
				>
				</treeItem>
			</template>
		</template>
	</div>
</template>

<script>
export default {
	name: "treeItem",
	emits: ["showChildren", "change"],
	props: {
		data: {
			type: Object,
		},
		filterValue: {
			type: String,
		},
		title: {
			type: String,
		},
		code: {
			type: String,
		},
		children: {
			type: String,
		},
	},
	methods: {
		showChildren(item) {
			this.$emit("showChildren", item);
		},
		change(data, value) {
			this.$emit("change", data, value);
		},
		showFilterVlaue(name, item) {
			const str = this.filterValue ? name.split(this.filterValue).join(`<span style="color:#00a0e9;">${this.filterValue}</span>`) : name;
			return str;
		},
	},
};
</script>

<style lang="scss" scoped>
.tree_item {
	display: flex;
	flex-direction: column;
	transition: all ease-in-out 0.3;
	.item {
		display: flex;
		align-items: center;
		.open_children_icon {
			.el-icon-caret-right {
				color: #f6f6f6;
				margin-right: 8px;
				transition: all ease-in-out 0.3;
			}
			&.isShowChildren {
				.el-icon-caret-right {
					transform: rotate(90deg);
				}
			}
		}
	}
	.checked_box.noChildren {
		margin-left: 22px;
	}
	.tree_item {
		margin-left: 12px;
	}
}

::v-deep .el-checkbox {
	display: flex;
	align-items: center;
	.checked_title {
		width: 300px;
		font-size: 14px;
		color: #f6f6f6;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		&:hover {
			color: #00a0e9;
		}
	}
	.code {
		font-size: 12px;
		color: #fff;
		opacity: 0.7;
	}
	.el-checkbox__inner {
		border: 1px solid #929293;
		border-radius: 2px;
	}
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: transparent;
	background-image: linear-gradient(90deg, #ffe6bf 0%, #e6b977 100%) !important;
}
::v-deep .el-checkbox__inner::after {
	border-color: #333;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
	background: #333;
}
::v-deep .el-checkbox__inner {
	transition: none;
}
</style>