<template>
    <div>
    <div class="k_grid_card_item" @click="showPopup(data)" v-if="dataAuditConfig">
        <div class="flex">
            <el-tooltip :content="data.checkName">
                <div class="check_name">
                    {{ data.checkName }}
                </div>
            </el-tooltip>
            <!-- <div class="is_enables" :class="!!+this.data.isEnables ? 'enables' : 'not_enables' " @click.stop="changeIsEnables(data)">
                <div class="dot"></div>
                <div class="title">{{ !!+this.data.isEnables ? '生效' : '不生效' }}</div>
            </div> -->
            <div class="is_work" :class="!!+this.data.isEnables ? 'work' : 'unwork'">
                {{ !!+this.data.isEnables ? '启用' : '停用' }}
            </div>
        </div>
        <div class="code">{{ data.checkCode }}</div>
        <div class="flex">
            <span class="label">批检说明：</span>
            <span class="light_color over_title">{{ data.checkExplain }}</span>
        </div>
        <div class="flex">
            <span class="label">备注：</span>
            <span class="light_color over_title">{{ data.remark }}</span>
        </div>
        <div class="flex">
            <span class="label">是否卡批：</span>
            <!-- <div class="is_enables" :class="!!+data.isStop ? 'enables' : 'not_enables' " @click.stop="changeIsStop(data)">
                <div class="dot"></div>
                <div class="title">{{ !!+data.isStop ? '卡批' : '不卡批' }}</div>
            </div> -->
            <span class="light_color">{{ !!+data.isStop ? '卡批' : '不卡批' }}</span>
        </div>
        <div class="flex mt8">
            <span class="label">比较符：</span>
            <!-- <span class="light_color box">{{ data.comparator | filterComparator }}</span> -->
            <span class="light_color">{{ data.comparator | filterComparator }}</span>
            <span class="label">比较值：</span>
            <!-- <span class="light_color box">{{ data.comparaVal }}</span> -->
            <span class="light_color">{{ data.comparaVal }}</span>
        </div>
        <div class="check_flag">
            <el-image :src="data.checkFlag === '1' ? checkFlag1 : checkFlag0" lazy></el-image>
        </div>
    </div>
    <div class="k_grid_card_item k_grid_card_item1" @click="showPopup(data)" v-else-if="dataManagerConfig">
        <div class="flex">
            <span class="label">业务系统：</span>
            <span class="light_color over_title">{{ dictTransfer('system_no', data.systemNo) }}</span>
        </div>
        <div class="flex">
            <span class="label">业务场景：</span>
            <span class="light_color over_title">{{ data.businessScenario }}</span>
        </div>
        <div class="flex">
            <span class="label">发送场景：</span>
            <span class="light_color over_title">{{ dictTransfer('msgSendScenarios', data.msgSendScenarios) }}</span>
        </div>
        <div class="flex">
            <span class="label">使用场景：</span>
            <span class="light_color over_title">{{ dictTransfer('useScenarios', data.useScenarios) }}</span>
        </div>
        <div class="btn_box">
            <k-btn
            class="md-info md-just-icon md-simple"
            data-descript="添加动态参数"
            data-functype="POPUP"
            data-size="mini"
            data-target="addBusinessScenario"
            >
            <i class="iconfont icon-dongtaicanshu"/>
            </k-btn>

            <k-btn class="md-info md-just-icon md-simple" data-descript="配置接收人信息" data-functype="POPUP" data-size="mini"
                    data-target="addSendBusinessPopup" >
            <i class="iconfont icon-peizhijieshourenxinxi"/>
            </k-btn>

            <k-btn
            class="md-danger md-just-icon md-simple"
            data-functype="SUBMIT"
            data-action="SmsBusinessScenarioConfigureDetail.deleteSmsBusinessScenario"
            data-size="mini"
            data-type="danger"
            data-target="M237Grid"
            :data-confirm="true"
            data-descript="删除短信业务场景">
            <i class="iconfont icon-shanchu"/>
            </k-btn>
        </div>
    </div>
    <div class="k_grid_card_item" @click="showPopup(data)" ref="gridCard" v-else>
        <k-form :data-col="1" v-model="data" ref="paramVal" class="pa_value_style">
        <div class="flex">
            <el-tooltip placement="bottom-start" :content="data.paraname" popper-class="botTip">
                <div class="check_name">
                    {{ data.paraname}}
                </div>
            </el-tooltip>
          </div>
          <div class="code">{{ data.paraid }}</div>

            <div class="mt8" v-if="editStatus">

                    <k-form-item label="参数值">
                      <component  :is="data.fieldtype"
                       :data-min-value="data.confoption.minValue"  :data-max-value="data.confoption.maxValue" :data-validate-type="data.functype"
                        :data-dict="data.dict" v-model="data.paravalue" :dataAllowblank="data.confoption.allowBlank"
                        :data-max-length="data.confoption.maxLength" :dataMultiple="data.confoption.multiple" :data-disabled="false" :dataClearable="false"></component>

                    </k-form-item>

            </div>
            <div class="mt8" v-else>
              <span class="label"><span style="color: #F56C6C; margin-right: 4px;" v-if="!data.confoption.allowBlank"> *</span>参数值：</span>
              <el-tooltip :content="getValue" :disabled="data.fieldtype!=='k-field-text'" placement="bottom-start" popper-class="botTip">

                 <span class="paravalTall para_title" ref="paravalTal">{{ getValue }}</span>

            </el-tooltip>
            </div>
            <div>
              <span class="label">备注：</span>
              <span class="para_title">{{ data.remark }}</span>
            </div>
            <k-form-footer data-align="center" style="padding-top:12px;background:#2A2D3F" v-if="editStatus">
                <k-btn class="md-golden edit-class"  :data-handler="editHandler"
                 data-target="gridCard">
                   确定
                </k-btn>
                <k-btn class="md-plain edit-class" :data-handler="cancelHandler">
                 取消</k-btn>
            </k-form-footer>
          <!-- <k-form-footer data-align="center" style="padding-top:12px;background:#2A2D3F" v-else>
            <k-btn class="md-golden edit-class" :data-handler="()=>this.editStatus = true" >
              编辑
          </k-btn>
        </k-form-footer> -->
    </k-form>
    </div>
</div>
</template>

<script>
import checkFlag0 from './images/check_flag0.png';
import Tools from '@/utils/tools'
import checkFlag1 from './images/check_flag1.png';
import emitter from "@/components/k-element/common/k-emitter.js";
import { h } from 'vue';
export default {
    mixins: [emitter()],
    data() {
        return {
            checkFlag0,
            checkFlag1,
            editStatus:false,
            toolShow:true,
            getValue:'',
            dictKv: {},
        }
    },
    emits: ['editData'],
    props: {
        data: {
            type: Object
        },
        dataPopupTarget: {
            type: String
        },
        dataAuditConfig: {
            type: Boolean,
            default: false,
        },
        dataManagerConfig: {
            type: Boolean,
            default: false,
        },
        dataPage:{
            type:Number,
            default:1
        },
        dataFunctype: {
            type: String
        },
        dataTarget: {
            type: String
        },
        dataPopupSubmitAction: {
            type: String
        }
    },
    methods: {
        cancelHandler(){
             this.editStatus = false
             this.dataAfterSuccess()
        },
        checkData(){
            if(Number(this.data.paravalue) > Number(this.data.confoption.maxValue)){
             setTimeout(()=>{
                Tools.alert(`${this.data.paraname}最大值要小于${this.data.confoption.maxValue}`, "danger");
             },300)
                return false
            }
            if(Number(this.data.paravalue) < Number(this.data.confoption.minValue)){
                setTimeout(()=>{
                Tools.alert(`不能低于最小值${this.data.confoption.minValue}`, "danger");
             },300)
                return false
            }
            return true
        },
        success(){
            this.editStatus = false
            this.dataAfterSuccess()
        },
        editHandler(val){
            if(!this.$refs.paramVal.validate() ) {
             this.$refs.paramVal.validate()
            return
        }
        if (!this.checkData()) {
			return
		}else{
               const paramsVal = {...this.data}
               paramsVal.confoption = JSON.stringify(paramsVal.confoption)
               const modelCode= (this.data.systemNo == 'PROD' ? 'M003' : 'M003'+this.data.systemNo)
               this.httpUtil.update({
               url: `commUpdate/${modelCode}/updateSysParam.json`,
               params: paramsVal,
               dataAfterSuccess:this.success,
             })
            }

        },
        changeIsEnables(data){
            if(data.isEnables === '0'){
                this.submitData({
                    ...data,
                    isEnables: '1'
                })
            }else if(data.isEnables === '1'){
                this.submitData({
                    ...data,
                    isEnables: '0'
                })
            }
        },
        changeIsStop(data){
            if(data.isStop === '0'){
                this.submitData({
                    ...data,
                    isStop: '1'
                })
            }else if(data.isStop === '1'){
                this.submitData({
                    ...data,
                    isStop: '0'
                })
            }
        },
        submitData(params){
            this.httpUtil.comnUpdate({
                action: this.dataPopupSubmitAction,
                params,
                mask: false,
                dataAfterSuccess: this.dataAfterSuccess
            })
        },
        dataAfterSuccess(){
            const target = this.getParentRef(this.dataTarget)
            target._load(target.getCachedParams())
        },
        dictSelect(){
            if(this.data.dict && this.data.fieldtype === 'k-field-select'){
                this.httpUtil.dict(this.data.dict).then((res)=>{
                    res.map((item)=>{
                        if(item.itemkey === this.data.paravalue){
                            this.getValue = item.itemval
                        }
                    })
                })
            }else{
                this.getValue = this.data.paravalue
            }
        },
        showPopup(data){
            if(this.dataFunctype === 'POPUP'){
                // consoel.log(this.$emit)
                this.$emit('editData',data)
                setTimeout(() => {
                    let target = this.getParentRef(this.dataPopupTarget);
                    //判断dataTarget是不是弹窗，如果是弹窗，则关闭弹窗
                    if (target && target.$options.name == "KPopup") {
                    target.popup();
                    }
                }, 50)
            }
        },
		dictTransfer(dict, dictVal) {
			if (dict && dictVal) {
				let dictVals = dictVal.split(",");
				let transferValue;
				for (let i = 0; i < 1; i++) {
					if (i == 0) {
						transferValue = this.doDictTransfer(dict, dictVals);
						if (transferValue) {
							transferValue = transferValue.toString();
						}
					} else {
						transferValue += "，" + this.doDictTransfer(dict, dictVals);
					}
				}
				return transferValue;
			}
			return dictVal;
		},
		doDictTransfer(dict, dictVal) {
			if (this.dictKv[dict]) {
				if (dictVal.length > 1) {
					let dictVals = "";
					dictVal.forEach((val) => {
						dictVals += this.dictKv[dict][val] + "，";
					});
					let reg = /，$/gi;
					dictVals = dictVals.replace(reg, "");
					return dictVals;
				}
				return this.dictKv[dict][dictVal];
			}
			return dictVal;
		},
    },
    filters: {
        filterComparator(value){
            const arr = ['>','<','<=','>=','=','!=']
            if(value !== ''){
                if(!isNaN(+value)){
                    return arr[+value]
                }else{
                    return value
                }
            }
        }
    },

    mounted(){
        this.dictSelect()

    },
    watch:{
        dataPage(newVal,oldVal){
            this.editStatus = false
        }
    },
    beforeUpdate(){
        this.dictSelect()
    },
    created() {
        // 数据字典
        let promiseAll = [];
        let dicts = ['system_no', 'msgSendScenarios', 'useScenarios'];
        dicts.forEach((item) => {
            promiseAll.push(this.httpUtil.dict(item));
        });

        let _this = this;
        Promise.all(promiseAll).then(function (values) {
            let res = {};
            for (let i = 0; i < values.length; i++) {
                if (values[i].length === 0) {
                    console.error("请检查数据字典是否存在");
                    continue;
                }
                let kv = (res[values[i][0].dict] = {});
                values[i].forEach((item) => {
                    kv[item.itemkey] = item.itemval;
                });
            }
            _this.dictKv = res;
        });
        // this.$refs.cardItem.forEach(item =>{
                // item.$refs.paramVal.reset()
            // })
        // if(!this.dataAuditConfig){
        //       this.data.confoption = JSON.parse(this.data.confoption)
        //       this.data.confoption.maxLength = Number(this.data.confoption.maxLength)
        //       this.data.confoption.allowBlank = this.data.confoption.allowBlank && this.data.confoption.allowBlank === "true"
        //    }
          // this.isEnables = !!+this.data.isEnables
    }
}

</script>

<style scoped lang="scss">
.botTip {
    max-width: 400px !important;
}
.edit-class{
    width: 64px;
    height: 24px !important;
    font-size: 14px;
}
.k_grid_card_item {
    font-size: 14px;
    padding: 12px 16px 16px;
    overflow: hidden;
    color: rgba(255,255,255,.7);
    .flex {
        display: flex;
        align-items: center;
    }
    .btn_box {
        height: 32px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-top: 1px solid #373d52;
        margin-top: 12px;
        padding-top: 6px;
    }
    .is_enables {
        position: relative;
        box-sizing: border-box;
        width: 64px;
        height: 24px;
        border-radius: 12px;
        transition: all ease-in-out .5;
        .dot {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #EBEBEE;
            top: 15%;
        }
        .title {
            font-size: 12px;
            color: #EBEBEE;
            line-height: 24px;
            // transform: scale(0.8);
            user-select: none;
        }
    }

    .pa_value_style .el-form-item {
        margin:0 !important;
        width: 100% !important;
        height: 28px !important;
        .k-field-text,.el-select,.el-date-editor{
            width: 100% !important;
            height: 28px !important;
        }
        .el-input--small .el-input__inner {
            height: 28px !important;
            line-height: 28px !important;
        }
    }
    .enables {
        background-image: linear-gradient(90deg, #F5E9CA 0%, #B39E7B 100%);
        .dot {
            right: 3px;
        }
        .title {
            text-align: left;
            margin-left: 12px;
            color: #000;
        }
    }
    .not_enables {
        color: #EBEBEE;
        background: #3C4359;
        .dot {
            left: 3px;
        }
        .title {
            text-align: right;
            margin-right: 4px;
        }
    }
    .light_color {
        color: rgba(255,255,255,.9);
    }

    .check_name {
        width: 208px;
        font-size: 16px;
        color: #fff;
        line-height: 24px;
        margin-right: 15px;
        overflow: hidden; // 溢出部分隐藏
        white-space: nowrap; // 文字不换行
        text-overflow: ellipsis; // 显示省略号
    }
    .is_work {
        width: 44px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        &.work {
            background: rgba(39,195,70,.15);
            color: #27C346;
        }
        &.unwork {
            background: rgba(255,255,255,.08);
            color: rgba(255,255,255,.7);
        }
    }

    .code {
        font-size: 12px;
        color: rgba(255,255,255,.5);
        line-height: 20px;
        width: 100%;
    }
    .label {
        display: inline-block;
        line-height: 28px;
        width: 70px;
        text-align: right;
        font-size: 14px;
        color: rgba(255,255,255,.7);
    }
    .over_title {
        display: inline-block;
        line-height: 28px;
        width: 200px;
        margin-top: -1px;
        vertical-align: middle;
        overflow: hidden; // 溢出部分隐藏
        white-space: nowrap; // 文字不换行
        text-overflow: ellipsis; // 显示省略号
    }

    .para_title {
        display: inline-block;
        line-height: 28px;
        width: 190px;
        margin-top: -1px;
        vertical-align: top;
        white-space: nowrap;
        // word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
    }
    .is_stop {
        color: #1fbf44;
    }
    .mt8 {
        margin-top: 8px;
    }
    .box {
        width: 66px;
        height: 30px;
        background: #33374D;
        border: 1px solid rgba(60,67,89,1);
        border-radius: 2px;
        line-height: 30px;
        text-indent: 1em;
    }
    .check_flag {
        position: absolute;
        right: 16px;
        bottom: 14px;
        width: 48px;
        height: 48px;
    }

    .w200 {
        width: 200px;
    }

    .ml30 {
        margin-left: 30px;
    }
}
.k_grid_card_item1 {
    padding: 12px 16px 0 16px;
}
</style>
