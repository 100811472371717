<template>
  <div id="app" @click="clickApp" class="label-size4">
    <notifications ref="notifications"></notifications>
    <router-view />
  </div>
</template>

<script>
import kayak from '@/frame/kayak.js'

export default {
  name: 'App',
  data: function () {
    return {}
  },
  watch: {
    $route() {
      if (this.$IsMicro) {
        // 当子应用路由发生变化时，通知主应用切换路由
        // window.$wujie?.bus.$emit("sub-route-change", this.$AppName, this.$route);
        this.$store.commit('system/setCurrentRoute', this.$route.path);
      }
    },
  },
  created() {
    kayak.app = this;
    //单页面初始化时 设置主题
    this.setTheme();
  },
  methods: {
    clickApp() {
      this.$refs["notifications"].notificationStore.clear()
    }
  }
}
</script>
<style lang="scss">
.isFromIframe {
  .el-popper {
    position: absolute !important;
  }
}

.bs-body-theme-wrap {
  position: relative;
}
</style>
