<template>
  <div style="margin: 20px 20px">
    <k-form ref="strategyForm" :data-col="2">
      <k-form-item label="策略名称" :data-col="2">
        <k-field-text v-model="submitData.strategyName" :data-allowblank="false" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="经营场景">
        <k-field-select v-model="submitData.businessScene" :data-allowblank="false" data-dict='strategy_business_scene' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="经营目标">
        <k-field-select v-model="submitData.businessObjective" :data-allowblank="false" data-dict='strategy_business_objective' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="适用客群">
        <k-field-select v-model="submitData.suitGroup" :data-allowblank="false" data-dict='strategy_group' dataDisplayField="itemkey,itemval" :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略类型">
        <k-field-select v-model="submitData.strategyType" :data-allowblank="false" data-dict='strategy_type' :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略优先级">
        <k-field-select v-model="submitData.strategyPriority" :data-allowblank="false" data-dict='strategy_priority' :data-disabled="strategyDisabled" />
      </k-form-item>
      <k-form-item label="策略说明" :data-col="2">
        <k-field-text v-model="submitData.remark" data-type="textarea" :data-min-row="3" :data-disabled="strategyDisabled" />
      </k-form-item>
    </k-form>
    <div class="btn-content">
      <k-btn class="md-primary" :data-handler="quit">
        查看策略详情
      </k-btn>
    </div>

  </div>
</template>
<script>
import Tools from '@/utils/tools';
import baseInfo from '@/pages/ma/strategy/base-info.vue';
import { cloneDeep } from 'lodash';

export default {
	name: 'stratrgyReleaseInfo',
	components: {
		baseInfo
	},
	props: {
		submitData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			strategyDisabled: true
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		quit() {
			this.$router.push({
				path: '/main/ma/stratrgy-release-detail',
				query: { strategyId: this.submitData.strategyId }
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-content {
	bottom: 0;
	right: 18px;
	margin-bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>
