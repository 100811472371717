export default [
	{
		path: 'cdp/meta-entity-list',
		name: '源数据列表',
		component: () => import('@/pages/basis/meta-entity/meta-entity-list')
	},
	{
		path: 'cdp/meta-entity-add',
		name: '源数据列表新增',
		component: () => import('@/pages/basis/meta-entity/meta-entity-add')
	},
	{
		path: 'cdp/meta-entity-edit',
		name: '源数据编辑',
		component: () => import('@/pages/basis/meta-entity/meta-entity-edit')
	},
	{
		path: 'cdp/meta-entity-config',
		name: '数据计算',
		component: () => import('@/pages/basis/meta-entity-config/meta-entity-config')
	},
	{
		path: 'basis/meta-source-list',
		name: '数据列表',
		component: () => import('@/pages/basis/meta-source-list/meta-source-list')
	},
	{
		path: 'basis/table-list',
		name: '元数据列表',
		component: () => import('@/pages/basis/table-list/table-list')
	}
];
